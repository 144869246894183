import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const ScoreCardStyle = theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    item: {
        padding: theme.spacing(2),
    },
    title: {
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    textVal: {
        fontSize: "200%",
    },
    verticalVal: {
        textAlign: "left",
    },
    horizontalVal: {
        textAlign: "right",
    },
})

class ScoreCard extends React.Component {
    render() {
        const title = this.props.title
        const value = this.props.value
        const typeOfVal = typeof(value)
        const typeOfTitle = typeof(title)

        const mb = this.props.hasOwnProperty('mb') ? this.props.mb : 0
        const fullWidth = this.props.fullWidth
        const width = fullWidth
            ? 'initial' :
            (this.props.hasOwnProperty('width') ? this.props.width : "100%")

        const paperProps = {
            ...{style:{
                width:width
            }},
            ...this.props.hasOwnProperty('paperProps') ? this.props.paperProps : {}
        }

        const orientation = this.props.hasOwnProperty('orientation') ? this.props.orientation : 'horizontal'
        const classVal = orientation === 'vertical' ? 'verticalVal' : 'horizontalVal'
        const gridItemPropsTitle = this.props.hasOwnProperty('gridItemPropsTitle')
            ? this.props.gridItemPropsTitle
            : (orientation === 'vertical' ? {xs: 12} : {xs: true})
        const gridItemPropsValue = this.props.hasOwnProperty('gridItemPropsValue')
            ? this.props.gridItemPropsValue
            : (orientation === 'vertical' ? {xs: 12} : {xs: "auto"})

        let { classes } = this.props

        return <Box mb={mb}>
            <Paper className={classes.item} {...paperProps}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item {...gridItemPropsTitle} className={classes.title}>
                        {React.isValidElement(title)
                            ? React.cloneElement(
                                title,{}
                            )
                            : (typeOfTitle === 'object'
                                ? JSON.stringify(title)
                                : (typeOfTitle === 'function'
                                    ? title()
                                    : <Typography component="div" variant="body1">
                                        {title}
                                    </Typography>))}
                    </Grid>
                    <Grid item {...gridItemPropsValue} className={classes[classVal]}>
                        {React.isValidElement(value)
                            ? React.cloneElement(
                                value,{}
                            )
                            : (typeOfVal === 'object'
                                ? JSON.stringify(value)
                                : (typeOfVal === 'function'
                                    ? value()
                                    : <span className={classes.textVal}>{value}</span>))}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    }

}

export default withStyles(ScoreCardStyle)(ScoreCard)