import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const styles = (theme) => ({})
  
class FieldText extends React.Component {

    render() {
        const title = this.props.title ? this.props.title : ""
        const type = this.props.type ? this.props.type : "text"
        const id = this.props.id ? this.props.id : ""
        const name = this.props.name
        const error = this.props.error
        let value = this.props.value === null ? '' : this.props.value
        const variant = this.props.variant
        const placeholder = this.props.placeholder
        const adornment = this.props.adornment
        const helperText = this.props.helperText
        const disabled = this.props.disabled
        const multiline = this.props.multiline
        const rows = this.props.rows
        const maxRows = this.props.maxRows

        let InputLabelProps = {
            ...this.props.InputLabelProps,
            ...{shrink: true,},
        }
        let InputProps = {
            ...this.props.InputProps,
            ...{
                startAdornment: <InputAdornment position="start"><React.Fragment>{adornment}</React.Fragment></InputAdornment>,
                inputProps: this.props.inputProps
            },
        }
        let inputProps = this.props.hasOwnProperty('inputProps') ? this.props.inputProps : {}
        const fullWidth = this.props.fullWidth ? this.props.fullWidth : false
        const formControlStyle = {
            width: inputProps.hasOwnProperty('width') ? inputProps.width : '100%'
        }

        let MultilineProps = {}
        if( multiline ) MultilineProps.multiline = this.props.multiline
        if( rows ) MultilineProps.rows = this.props.rows
        if( maxRows ) MultilineProps.multiline = this.props.maxRows

        const limit_chars = this.props.hasOwnProperty('limit_chars') ? this.props.limit_chars : 0
        if( limit_chars ) value = value.substring(0,limit_chars)
        const str_length = String(value).length

        const theme = this.props.theme
        
        return <FormControl style={formControlStyle}>
            <TextField
                id={id}
                error={error}
                name={name}
                label={title}
                helperText={<React.Fragment>
                    {limit_chars
                        ? <Typography component="span" variant="caption" color={str_length >= limit_chars ? "error" : "inherit"} style={{marginRight: theme.spacing(1)}}>
                            {str_length >= limit_chars ? "Chars limit exceeded" : "Counter"}: {str_length}/{limit_chars}
                        </Typography>
                        : null}
                    {helperText}
                </React.Fragment>}
                disabled={disabled}
                type={type}
                value={value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                InputLabelProps={InputLabelProps}
                variant={variant}
                fullWidth={fullWidth}
                placeholder={placeholder}
                margin="normal"
                InputProps={InputProps}
                inputProps={inputProps}
                {...MultilineProps}
            />
        </FormControl>
    }
}

export default withStyles(styles)(withTheme(FieldText))