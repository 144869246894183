import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'

import CallIcon from '@material-ui/icons/Call'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import EmailIcon from '@material-ui/icons/Email'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import RateReviewIcon from '@material-ui/icons/RateReview'
import SmsIcon from '@material-ui/icons/Sms'

class FieldIdReportType extends React.Component {
    render() {
        const id_report_type = this.props.id_report_type
        switch( id_report_type ) {
            default: return <RateReviewIcon color="secondary" />
            case 1: return <Tooltip title="phone" placement="right"><CallIcon color="secondary" /></Tooltip>
            case 2: return <Tooltip title="mobile" placement="right"><VideoCallIcon color="secondary" /></Tooltip>
            case 3: return <Tooltip title="email" placement="right"><EmailIcon color="secondary" /></Tooltip>
            case 4: return <Tooltip title="meeting" placement="right"><RecordVoiceOverIcon color="secondary" /></Tooltip>
            case 5: return <Tooltip title="texting" placement="right"><SmsIcon color="secondary" /></Tooltip>
        }
    }
}

export default FieldIdReportType