import React from 'react'
import clsx from 'clsx'

import { withStyles } from '@material-ui/core/styles'

import {
    getNavSideSchema,
    ShowUserAvatar,
} from '../../../services/NavSideSchema'
import { NavTopSchema } from '../../../services/NavTopSchema'

import AdapterLink from '../../links/modules/AdapterLink'
import ExternalLink from '../../links/modules/ExternalLink'

import NavSideUserBox from './NavSideUserBox'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DotItemIcon from '@material-ui/icons/FiberManualRecord'
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Collapse from '@material-ui/core/Collapse'

const NavSideStyle = theme => {
    return {
        drawer: {
            ...{
                flexShrink: 0,
                whiteSpace: 'nowrap',
                zIndex: 3,
                top: 'auto',
                overflowY: 'hidden',
            },
            ...theme.globals.appNavSideDrawer
        },
        drawerOpen: {
            paddingLeft: 0,
            paddingRight: 0,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            padding: 0,
            overflowX: 'hidden',
            width: theme.spacing(8) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(8) + 1,
            },
        },
        listContainer: {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: ShowUserAvatar ? '85vh' : '94vh',
        },
        closeBtn: theme.globals.appNavSideCloseBtn,
        itemListBlockTitle: theme.globals.appNavSideBlockTitle,
        itemListTitle: theme.globals.appNavSideItemTitle,
        itemListTitleText: theme.globals.appNavSideItemTitle,
        itemList: theme.globals.appNavSideItem,
        dotItemIcon: {fontSize:'40%',marginLeft:6},
        itemIcon: theme.globals.appNavSideItemIcon,
        itemText: theme.globals.appNavSideItemText,
        dotItemIconSelected: {fontSize:'90%',marginLeft:3},
        itemIconSelected: theme.globals.appNavSideItemIconSelected,
        itemTextSelected: theme.globals.appNavSideItemTextSelected,
        itemTextInCollapsedSelected: theme.globals.appNavSideItemTextInCollapsedSelected,
        itemSelected: theme.globals.appNavSideItemSelected,
    }
}

class NavSide extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            schema: [],
            schemaTop: [],
            //open: NavSideStartState === 'close' ? false : true,
            open: this.props.hasOwnProperty('open') ? this.props.open : true,
            itemOpen: {}
        }
        
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
        this.handleDrawerClose = this.handleDrawerClose.bind(this)
        this.handleClickItemOpen = this.handleClickItemOpen.bind(this)
        this.handleClickItemClose = this.handleClickItemClose.bind(this)
        this.renderListItem = this.renderListItem.bind(this)
        this.renderCollapseListItem = this.renderCollapseListItem.bind(this)
        this.renderList = this.renderList.bind(this)
    }

    handleDrawerOpen() {
        this.setState({
            open: true
        })
    }
    handleDrawerClose() {
        if (this.state.itemOpen === true) {
            this.setState({
                open: false,
                itemOpen:false
            })
        }
        else{
            this.setState({
                open: false
            })
        }
    }

    componentDidMount() {
        const schema = getNavSideSchema()
        const schemaTop = NavTopSchema
        this.setState({
            schema: schema,
            schemaTop: schemaTop
        })
    }
    componentDidUpdate(prevProps,prevState) {
        const newSchema = getNavSideSchema()
        const schema = this.state.schema
        if( JSON.stringify(newSchema) !== JSON.stringify(schema) )
            this.setState({
                schema: getNavSideSchema()
            })

        if( prevProps.open !== this.props.open )
            this.setState({
                open: this.props.open
            })
    }

    handleClickItemOpen( item ) {
        this.setState((prevState) => {
            prevState.itemOpen[item] = true
            return prevState
        })
    }
    handleClickItemClose( item ) {
        this.setState((prevState) => {
            prevState.itemOpen[item] = false
            return prevState
        })
    }

    renderListItem( listItem,in_collapsed = false ) {
        const slug = listItem.slug
        const path = listItem.path
        const path_type = listItem.path_type
        const title = listItem.title
        const Icon = listItem.icon
        const Component = listItem.hasOwnProperty('component') ? listItem.component : null

        const currentPath = this.props.currentPath
        const { classes } = this.props
        const itemClass = clsx(classes.itemList,{[classes.itemSelected]:(path === currentPath && !in_collapsed)})
        const dotItemIconClass = clsx(classes.dotItemIcon,{[classes.dotItemIconSelected]:path === currentPath})
        const itemIconClass = clsx(classes.itemIcon,{[classes.itemIconSelected]:path === currentPath})
        const itemTextClass = clsx(
            classes.itemText,
            {[classes.itemTextSelected]:(path === currentPath && !in_collapsed)},
            {[classes.itemTextInCollapsedSelected]:(path === currentPath && in_collapsed)}
        )

        const ListItemComponent = path_type === "external"
            ? ExternalLink
            : AdapterLink

        return Component
        ? <ListItem key={slug} className={itemClass}>
            {Component}
        </ListItem>
        : <ListItem button key={slug} className={itemClass} component={ListItemComponent} to={path}>
                <Tooltip title={title} placement="right">
                    <ListItemIcon className={itemIconClass}>
                        {Icon
                            ? <Icon fontSize="small" />
                            : <DotItemIcon fontSize="small" className={dotItemIconClass} />}
                    </ListItemIcon>
                </Tooltip>
                <ListItemText
                    primary={<Typography className={itemTextClass} variant="body2" >{
                        this.state.open
                            ? title
                            : <span>&nbsp;</span>
                    }</Typography>}
                />
            </ListItem>
    }
    renderCollapseListItem( listItem ) {
        const slug = listItem.slug
        const title = listItem.title
        const Icon = listItem.icon
        const list = listItem.list

        const currentPath = this.props.currentPath
        const { classes } = this.props

        let includesCurrentPath = false
        const ListItems = list.map((item,index) => {
            if( currentPath === item.path ) includesCurrentPath = true
            return this.renderListItem( item,true )
        })

        const itemOpen = this.state.itemOpen[slug] || includesCurrentPath
        const itemClass = clsx(classes.itemList,{[classes.itemSelected]:(itemOpen === true && includesCurrentPath)})
        const itemIconClass = clsx(classes.itemIcon,{[classes.itemIconSelected]:(itemOpen === true && includesCurrentPath)})
        const itemTextClass = clsx(classes.itemText,{[classes.itemTextSelected]:(itemOpen === true && includesCurrentPath)})

        return <React.Fragment key={slug}>
            <ListItem button className={itemClass}
                onClick={() => {
                    if(itemOpen === true) this.handleClickItemClose(slug)
                    else this.handleClickItemOpen(slug)
                }}
            >
                <Tooltip title={this.state.open ? "" : title} placement="right">
                    <ListItemIcon className={itemIconClass}>
                        {Icon
                            ? <Icon fontSize="small" />
                            : null}
                    </ListItemIcon>
                </Tooltip>
                <React.Fragment>
                    <ListItemText
                        primary={<Typography className={itemTextClass} variant="body2" >{
                            this.state.open
                                ? title
                                : <span>&nbsp;</span>
                        }</Typography>}
                    />
                    {this.state.open && itemOpen
                        ? <ExpandMoreIcon className={itemIconClass} fontSize="small" />
                        : <ChevronRightIcon className={itemIconClass} fontSize="small" />}
                </React.Fragment>
            </ListItem>

            <Collapse in={itemOpen} timeout="auto"  >
                <List component="div" disablePadding={true}>
                    {ListItems}
                </List>
            </Collapse>
        </React.Fragment>
    }
    renderList( list ) {
        const { classes } = this.props
        const itemListBlockTitleClass = clsx(classes.itemListBlockTitle)
        const itemListTitleClass = clsx(classes.itemList,classes.itemListTitle)
        const itemListTitleTextClass = clsx(classes.itemListTitleText)

        return <React.Fragment>
            {ShowUserAvatar
                ? <NavSideUserBox showUsername={this.state.open} />
                : null}
            <div className={classes.listContainer}>
                {list.map((block,b) => (
                    <React.Fragment key={block.block}>
                        <List disablePadding={true} className={block.hasOwnProperty('title') ? itemListBlockTitleClass : ""}>
                            {block.hasOwnProperty('title')
                                ? <ListItem className={itemListTitleClass}>
                                    {this.state.open
                                        ? null
                                        : <Tooltip title={this.state.open ? "" : block.title} placement="right">
                                            <ListItemIcon className={classes.itemIcon}>
                                                {this.state.open ? null : <KeyboardArrowDownIcon fontSize="small" />}
                                            </ListItemIcon>
                                        </Tooltip>}
                                    <Typography variant="overline" component="div" className={itemListTitleTextClass}>
                                        {this.state.open ? block.title : <span>&nbsp;</span>}
                                    </Typography>
                                </ListItem>
                                : null}

                            {block.list.map((item,i) => (
                                item.collapse
                                    ? this.renderCollapseListItem( item )
                                    : this.renderListItem( item )
                            ))}
                        </List>
                    </React.Fragment>
                ))}
                {/*CloseBtn
                    ? <React.Fragment>
                        <List className={classes.closeBtn}>
                            <ListItem button className={classes.itemList} key={this.state.open ? 'close' : 'open'} onClick={this.state.open ? this.handleDrawerClose : this.handleDrawerOpen}>
                                <Tooltip title={this.state.open ? "" : "Open"} placement="right">
                                    <ListItemIcon className={classes.itemIcon}>
                                        {this.state.open
                                            ? <ChevronLeftIcon fontSize="small" />
                                            : <ChevronRightIcon fontSize="small" />}
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={<Typography className={classes.itemText} variant="body2" >{
                                        this.state.open
                                            ? 'Close'
                                            : <span>&nbsp;</span>
                                    }</Typography>}
                                />
                            </ListItem>
                        </List>
                    </React.Fragment>
                                : null*/}
            </div>
                
        </React.Fragment>
    }

    render() {
        const schema = this.state.schema
        const schemaTop = this.state.schemaTop
        const { classes } = this.props
        const drawerClass = clsx(
            classes.drawer,
            {
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open
            }
        )
        const variant = this.props.hasOwnProperty('variant')
            ? this.props.variant
            : "permanent"

        return <Drawer id="NavSide" variant={variant}
            open={this.state.open}
            className={drawerClass}
            classes={{paper: drawerClass}}
        >
            {this.renderList( schema )}
            <Hidden smUp>
                <React.Fragment>
                    <Divider />
                    {this.renderList([{
                        block: "navTop",
                        list: schemaTop
                    }])}
                </React.Fragment>
            </Hidden>
        </Drawer>
    }
}

export default withStyles(NavSideStyle)(NavSide)