import React from 'react'

import CIcon from '../@react-fefmwk/custom-icons/modules/CIcon'
import ExternalLink from '../@react-fefmwk/links/modules/ExternalLink'

import { Chip,Box,Tooltip } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import InfoIcon from '@material-ui/icons/Info'
//import OpenInNewIcon from '@material-ui/icons/OpenInNew'
//import StoreIcon from '@material-ui/icons/Store'
//import PeopleIcon from '@material-ui/icons/People'
//import SettingsIcon from '@material-ui/icons/Settings'
//import AssignmentIcon from '@material-ui/icons/Assignment'
//import WorkIcon from '@material-ui/icons/Work'
//import InboxIcon from '@material-ui/icons/Inbox'
//import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'
import {agenciesBranchesFromToken} from '../services/AgencySchema'

function getNavTopSchema() {
    const groups = groupsFromToken()
    const agencies_branches = agenciesBranchesFromToken()

    let NTschema = []

    NTschema.push({
        "slug": "wiki",
        //"icon": InfoIcon,
        "icon": null,
        "title": "WIKI",
        "path": "/wiki",
        "path_type": "internal",
    })
    NTschema.push({
        "slug": "shared-docs",
        //"icon": InfoIcon,
        "icon": null,
        "title": "MODULISTICA",
        "path": "/shared-docs",
        "path_type": "internal",
    })
    NTschema.push({
        "slug": "schede-prod-avvera",
        //"icon": InfoIcon,
        "icon": null,
        "title": "SCHEDE PRODOTTO",
        "path": "https://docs.google.com/spreadsheets/d/19TlJMj5MfIgspp3Y6GvoDfvqzXbLRFUZT4zNG8xJS-o/edit?pli=1#gid=319030136",
        "path_type": "external",
    })
    NTschema.push({
        "slug": "whatsappweb",
        "component": <Box style={{lineHeight:0}}>
            <Tooltip title="Whatsapp web">
                <ExternalLink to="https://web.whatsapp.com/">
                    <CIcon icon="whatsapp" width={18} height={18} />
                </ExternalLink>
            </Tooltip>
        </Box>,
    })
    NTschema.push({
        "slug": "qinetic",
        "component": <Box style={{lineHeight:0}}>
            <Tooltip title="Quinetic - Credem">
                <ExternalLink to="https://crea.avverafinanziamenti.it/smilers">
                    <CIcon icon="credem" width={18} height={18} />
                </ExternalLink>
            </Tooltip>
        </Box>,
    })

    //if( groups.includes(String(4)) )
    /*NTschema.push({
        "slug": "stakeholders",
        "icon": null,
        "title": "Contatti",
        "path": "/stakeholders",
        "path_type": "internal",
    })*/

    /*if( !groups.includes(String(4)) )
        NTschema.push({
            "slug": "practicals",
            "icon": null,
            "title": "Pratiche",
            "path": "/practicals",
            "path_type": "internal",
        })*/

    if( groups.includes(String(1)) )
        NTschema.push({
            "slug": "users",
            "icon": null,
            "title": "Users",
            "path": "/users",
            "path_type": "internal",
        })

    /*NTschema.push({
        "slug": "home",
        "icon": null,
        "title": "Dashboard",
        "path": "/home",
        "path_type": "internal",
    })*/

    NTschema.push({
        "slug": "agency_branch",
        "component": <Chip label={(!agencies_branches.length ? "NESSUNA" : agencies_branches[0].toUpperCase())} size="small" color="primary" />,
    })

    return NTschema
}
export const NavTopSchema = getNavTopSchema()

export const UserDropdownSchema = [
    {
        "slug": "home",
        "icon": HomeIcon,
        "title": "Dashboard",
        "path": "/home",
        "path_type": "internal",
    },
    {
        "slug": "profile",
        "icon": PersonOutlineIcon,
        "title": "Profile",
        "path": "/profile",
        "path_type": "internal",
    },
    /*{
        "slug": "settings",
        "icon": SettingsIcon,
        "title": "Settings",
        "path": "/settings",
        "path_type": "internal",
    },*/
]