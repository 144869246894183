import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import {selectFieldsFromObj} from '../../arrays/api'
import TableOneRowTransposed from '../../tables/modules/TableOneRowTransposed'

const styles = theme => ({
    stepperInfoBox: {
        minHeight:200,
        overflowY:'auto',
        maxHeight: '70vh',
        [theme.breakpoints.down('md')]: {
            maxHeight: 200,
        },
    }
})

class StepperInfo extends React.Component {
    render() {
        const fieldsProps = this.props.fieldsProps ? this.props.fieldsProps : {}
        const info = this.props.info
        const infoFieldsList = this.props.hasOwnProperty('infoFieldsList') && this.props.infoFieldsList
            ? this.props.infoFieldsList
            : []

        let data = Object.assign({},info)
        if(infoFieldsList.length) {
            data = selectFieldsFromObj( data,infoFieldsList )
        }

        const {classes} = this.props

        return <Box className={classes.stepperInfoBox}>
            <TableOneRowTransposed material dense
                hideHeaders
                rowObject={data}
                fieldsProps={fieldsProps}
            />
        </Box>
    }
}

export default withStyles(styles)(StepperInfo)