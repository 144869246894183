import { matchPath } from 'react-router-dom/cjs/react-router-dom'

export function queryStringToObj( querystring ) {
    if( !querystring ) return {}

    // parse query string
    let params = new URLSearchParams( querystring )

    //init final object
    let obj = {}

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {//if param value is multiple (array)
            let values = params.getAll(key)
            values = values.map(v => !isNaN(v) ? Number(v) : v)
            obj[key] = values
        } else {//if param value is simple (number,string,...)
            const value = params.get(key)
            obj[key] = !isNaN(value) ? Number(value) : value
        }
    }

    return obj
}
export function queryHashToStr( hash ) {
    if( !hash ) return ''
    return hash.replace('#','')
}
export function objToQueryString( obj ) {
    var str = []
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(p + "=" + obj[p])
        }
    return str.join("&")
}
// convert a Unicode string to a string in which
// each 16-bit unit occupies only one byte
export function encodeBase64(string) {
    //return btoa(unescape(encodeURIComponent(string)))
    const buff = Buffer.from(string,'utf8')
    return buff.toString('base64')
}
export function decodeBase64(encoded) {
    //return decodeURIComponent(escape(atob(encoded)))
    const buff = Buffer.from(encoded,'base64')
    return buff.toString('utf8')
}
/**
 * La funzione restituisce l'oggetto della rotta (v. react-router-dom)
 *
 * @param {*} url Required. Url to parse.
 * @param {*} rule Required. Route object as of react-router-dom doc. Example: { path: "/prova/:id", exact: false, strict: false }.
 * @returns 
 */
export function matchUrlParams( url,rule ) {
    const _url = String(url)
    return !_url
        ? {}
        : matchPath(_url,rule)
}