import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {atcs_get_customers} from '../../services/BE'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'

import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

class WidgetAtcCustomers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
        }
    }
    
    async updateCustomers() {
        const id_atc = this.props.id_atc
        if( id_atc ) {
            this.setState({
                customers: await atcs_get_customers(id_atc),
            })
        }
    }
    
    async componentDidMount() {
        this.updateCustomers()
    }
    
    async componentDidUpdate(prevProps) {
        const id_atc = this.props.id_atc
        if( id_atc !== prevProps.id_atc )
            this.updateCustomers()
    }

    render() {
        const customers = this.state.customers

        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Clienti"

        const customersRowActions = actionSchema('stakeholders')
        const fieldsProps = tableSchema('stakeholders')

        const cardProps = {}
        cardProps['Content'] = customers.length
            ? <Table material dense
                data={customers}
                fieldsProps={fieldsProps}
                rowActions={customersRowActions}
                showPagination={true}
                defaultPageSize={15}
                rowActionsField="id_stakeholder"
            />
            : <Chip icon={<ErrorIcon />} label="Nessun cliente disponibile" variant="outlined" />

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetAtcCustomers