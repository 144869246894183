import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles,withTheme } from '@material-ui/core/styles'

import {hasOwnPath} from '../../arrays/api'
import {groupsFromToken} from '../../auth/services/token'
import {getThemeLocaleCode} from '../../dates/services/api'
import {EntitySchema} from '../../../services/EntitySchema'
import IFrame from '../../app/modules/IFrame'
//import AdapterLink from '../../links/modules/AdapterLink'
import Modal from '../../modals/modules/Modal'
import ModalDrawer from '../../modals/modules/ModalDrawer'

import { Box, Typography,Chip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
    linkTypography: {
        textDecoration: 'none'
    },
    linkChip: {
        border: 0
    },
    fullBackdrop: {
        MuiBackdrop: {
            root: {
              backgroundColor: 'rgba(0,0,0,0.2)'
            }
          }
    }
})

class EntityRelFieldActions extends React.Component {
    onClick() {
        const onOpen = this.props.hasOwnProperty('onOpen') ? this.props.onOpen : () => true
        onOpen()
    }
    render() {
        const {entity,classes,Trigger,theme,...rest} = this.props
        if( !entity ) return null

        const type = this.props.hasOwnProperty('type') ? this.props.type : 'modal'//modal|drawer

        const entityObj = EntitySchema[entity]
        const title = this.props.hasOwnProperty('title') ? this.props.title : (entityObj.title_singular+" add")

        //check privilegies related to entity "add" or "update"
        const groups = groupsFromToken()
        const entityAddPrivilegies = hasOwnPath(entityObj,'privilegies.add.groups_can') ? entityObj.privilegies.add.groups_can : []
        const entityUpdatePrivilegies = hasOwnPath(entityObj,'privilegies.update.groups_can') ? entityObj.privilegies.update.groups_can : []
        const checkPrivilegies = [
            ...entityAddPrivilegies.filter(g => groups.includes(String(g))),
            ...entityUpdatePrivilegies.filter(g => groups.includes(String(g)))
        ]
        //if no privilegies render null
        if( (entityAddPrivilegies.length > 0 || entityUpdatePrivilegies.length > 0) && checkPrivilegies.length <= 0 )
            return null

        const locale = getThemeLocaleCode()
        const linkText = this.props.hasOwnProperty('linkText') ? this.props.linkText : ((locale === 'it' ? "nuovo " : "add new ") + entityObj.title_singular)

        const ismodalfull = this.props.location.hash.includes('ismodalfull') ? true : false
        const PaperProps = ismodalfull
            ? {style:{boxShadow:theme.shadows[8]}}
            : (this.props.hasOwnProperty('PaperProps')
                ? this.props.PaperProps
                : (type === 'drawer' ? {style:{height:"50vh"}} : {}))
        const modalContentStyle = ismodalfull ? {height:"80vh"} : {}
        const fullScreen = ismodalfull ? false : true
        const screenProps = fullScreen
            ? {fullScreen: true}
            : {fullWidth: true,maxWidth: "xl"}
        const BackdropProps = fullScreen
            ? {}
            : {style:{backgroundColor:'white'}}
        const scrolling = this.props.hasOwnProperty('scrolling') ? this.props.scrolling : 'yes'//iframe can scroll
        const url = this.props.hasOwnProperty('url') ? this.props.url : ''//customize url after "/embed/:entity/"
        const customQueryStrings = this.props.hasOwnProperty('s') ? ('?'+this.props.s) : ''
        const src_url = "/embed/"+entity+"/"+(url ? url : "add")+customQueryStrings+"#ismodalfull"

        const TriggerComponent = !this.props.hasOwnProperty('Trigger')
            ? <Typography variant="caption" color="primary">
                <Chip icon={<AddIcon />}
                    size="small" color="primary" variant="outlined"
                    className={classes.linkChip}
                    label={linkText}
                    clickable
                    onClick={() => this.onClick()}
                />
            </Typography>
            : (React.isValidElement(this.props.Trigger)
                ? React.cloneElement(
                    this.props.Trigger,
                    {
                        onClick: () => this.onClick()
                    }
                )
            : <Trigger color="secondary" onClick={() => this.onClick()} />)

        return <React.Fragment>
            <Box>
                {type === 'drawer'
                    ? <ModalDrawer
                        printCloseBtn
                        title={title}
                        content={<IFrame src={src_url} scrolling={scrolling} />}
                        Trigger={TriggerComponent}
                        tooltipText={linkText}
                        tooltipPlacement="right"
                        PaperProps={PaperProps}
                        {...rest}
                    />
                    : <Modal
                        {...screenProps}
                        //fullScreen
                        //fullWidth
                        //maxWidth="xl"
                        printCloseBtn
                        title={title}
                        contentStyle={modalContentStyle}
                        content={<IFrame src={src_url} scrolling={scrolling} />}
                        Trigger={TriggerComponent}
                        tooltipText={linkText}
                        tooltipPlacement="right"
                        BackdropProps={BackdropProps}
                        disableEscapeKeyDown
                        disableBackdropClick
                        PaperProps={PaperProps}
                        {...rest}
                    />}
            </Box>
        </React.Fragment>
    }
}

export default withStyles(styles)(withRouter(withTheme(EntityRelFieldActions)))