import React from 'react'
import PhotoIcon from '@material-ui/icons/Photo'

class CIcon extends React.Component {
    render() {
        const icon = this.props.icon
        let size = this.props.hasOwnProperty('size')
            ? this.props.size
            : "sm"

        let width = this.props.width
        let height = this.props.width
        size = parseInt(width) > 0 || parseInt(height) > 0 ? "custom" : size

        switch(size) {
            case "xs":
                width = 16
                height = 16
            break
            case "md":
                width = 48
                height = 48
            break
            case "lg":
                width = 144
                height = 144
            break
            case "xl":
                width = 288
                height = 288
            break
            case "sm":
                width = 24
                height = 24
            break
        }

        try {
            const img = require(`../images/${icon}.png`)
            return <img src={img}
                width={width}
                height={height}
            />
        }
        catch (e) {
            return <PhotoIcon />
        }
    }
}

export default CIcon