import React from 'react'
import { withStyles } from '@material-ui/core/styles'

//import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
//import Divider from '@material-ui/core/Divider'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => ({})

function Accordions(props) {
    const [expanded,setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event,isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const ExpandIcon = props.hasOwnProperty('ExpandIcon') ? props.ExpandIcon : ExpandMoreIcon

    const accordions = props.list
    if( !accordions.length ) return null

    const AccordionComps = accordions.map((accordion,a) => {
        const key="accordion-panel-"+a
        const id="panel"+a
        const ariaControls="panel"+a
        const title = accordion.hasOwnProperty('title') ? accordion.title : ''
        const titleDet = accordion.hasOwnProperty('titleDet') ? accordion.titleDet : ''
        const headerGrid = accordion.hasOwnProperty('headerGrid')
            ? accordion.headerGrid
            : <React.Fragment>
                <Grid item xs="auto">{title}</Grid>
                <Grid item xs>{titleDet ? titleDet : null}</Grid>
            </React.Fragment>
        const disabled = accordion.hasOwnProperty('disabled') ? accordion.disabled : false
        const content = accordion.hasOwnProperty('content') ? accordion.content : <React.Fragment></React.Fragment>
        const contentStyle = accordion.hasOwnProperty('contentStyle') ? accordion.contentStyle : {}
        const startOpen = accordion.startOpen
        const open = (expanded === false && startOpen === true) ? startOpen : expanded === id
        return <Accordion key={key} expanded={open} onChange={handleChange(id)} disabled={disabled}>
            <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls={ariaControls+"-content"}
                id={id+"-header"}
            >
            <Grid container alignItems="center" spacing={2}>
                {headerGrid}
            </Grid>
            </AccordionSummary>
            <AccordionDetails style={{...contentStyle,...{display:"block"}}}>
                {content}
            </AccordionDetails>
        </Accordion>
    })
  
    return <React.Fragment>
        {AccordionComps}
    </React.Fragment>
}
export default withStyles(styles)(Accordions)