import React from 'react'

import { EntitySchema } from '../services/EntitySchema'
import {entity_get} from '../@react-fefmwk/entity/services/api'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ExternalLink from '../@react-fefmwk/links/modules/ExternalLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import WidgetCompany from './widgets/WidgetCompany'
import WidgetCompanyAddresses from './widgets/WidgetCompanyAddresses'
import WidgetCompanyContacts from './widgets/WidgetCompanyContacts'
import WidgetInsuranceQuotations from './widgets/WidgetInsuranceQuotations'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'

class CardInsurance extends React.Component {
    constructor(props) {
        super(props)

        const entity = 'insurances'
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        this.state = {
            entity: entity,
            id_field: id_field,
            id_entity: this.props.id_entity,
            insurance: {},
        }
    }
    async componentDidMount() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        this.setState({
            insurance: await entity_get(entity,id_entity),
        })
    }
    render() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        const insurance = this.state.insurance
        const id_company = insurance.hasOwnProperty("id_company") ? insurance.id_company : 0
        if( !id_company ) return <LoaderSingleComponent wifth="100%" />
console.log(insurance)
        const link_webportal = insurance.hasOwnProperty("link_webportal") ? insurance.link_webportal : ""
        const insurance_name = insurance.hasOwnProperty("insurance") ? insurance.insurance : ""

        const groups = groupsFromToken()
        const updateBtn = groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5))
            ? (this.props.hasOwnProperty('deleteBtn')
                ? this.props.deleteBtn
                : true)
            : false
        const deleteBtn = groups.includes(String(4))
            ? false
            : (this.props.hasOwnProperty('deleteBtn')
                ? this.props.deleteBtn
                : true)

        return <Tabber basicname="stakeholdercard"
            tabs={[
                {
                    slug: "info",
                    label: "info",
                    content: <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <WidgetCompany
                                id_company={id_company}
                                //id_insurance={id_entity}
                                fields={['id_company','company_name','vat_number','cf']}
                                dashboardcardTitle="Anagrafica"
                                updateBtn={updateBtn}
                                deleteBtn={deleteBtn}
                                otherActions={[
                                    link_webportal
                                        ? <Button
                                            variant="text" color="primary" size="small"
                                            startIcon={<OpenInNewIcon fontSize="small" />}
                                            component={ExternalLink}
                                            to={link_webportal}
                                            target="_blank"
                                        >portale {insurance_name}</Button>
                                        : null
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/*<ViewEntity dense
                                entity={entity}
                                id_entity={id_entity}
                                //cardTitle={dashboardcardTitle}
                            />
                            <br />*/}
                            <WidgetCompanyAddresses
                                id_company={id_company}
                                dashboardcardTitle="Indirizzi"
                            />
                            <br />
                            <WidgetCompanyContacts
                                id_company={id_company}
                                dashboardcardTitle="Altri contatti"
                            />
                        </Grid>
                    </Grid>
                },
                {
                    slug: "quotations",
                    label: "quotazioni",
                    content: <React.Fragment>
                        <WidgetInsuranceQuotations
                            id_insurance={id_entity}
                            otherActions={[
                                link_webportal
                                    ? <Button
                                        variant="text" color="primary" size="small"
                                        startIcon={<OpenInNewIcon fontSize="small" />}
                                        component={ExternalLink}
                                        to={link_webportal}
                                        target="_blank"
                                    >portale {insurance_name}</Button>
                                    : null
                            ]}
                        />
                    </React.Fragment>
                },
            ]}
        />
    }
}

export default CardInsurance