import React from 'react'
import Content from './Content'

import { Container,Typography } from '@material-ui/core'

class Page404 extends React.Component {
    render() {
        return <Content>
            <Container style={{textAlign:"center",marginTop:"10%"}}>
                <Typography component="h1" variant="h1">404</Typography>
                <Typography component="h2" variant="overline">Not found</Typography>
                <Typography component="p" variant="caption">The resource cannot be found on this server</Typography>
            </Container>
        </Content>
    }
}

export default Page404