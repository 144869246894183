
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'

import Grid from '@material-ui/core/Grid'
import { entity_get } from '../../@react-fefmwk/entity/services/api'

const styles = theme => ({})

class StakeholderData extends React.Component {
    constructor(props) {
        super(props)

        const formikProps = this.props.formikProps

        this.state = {
            id_people: formikProps.values.id_people,
            id_company: formikProps.values.id_company,
        }
    }

    async componentDidMount() {
        const id_people = this.state.id_people
        const person = id_people
            ? await entity_get('people',id_people)
            : 0

        const id_company = this.state.id_company
        const company = id_company
            ? await entity_get('companies',id_company)
            : 0

        const formikProps = this.props.formikProps
        formikProps.setValues({...formikProps.values,...person,...company})
    }

    render() {
        let formikProps = this.props.formikProps
        const stakeholder_type = formikProps.values.stakeholder_type
        const entity = stakeholder_type === 'company'
            ? 'companies'
            : 'people'

        return <React.Fragment>
            <Grid container spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    formikProps={formikProps}
                />
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(StakeholderData)