import React from 'react'
import { withTheme } from '@material-ui/styles'

import Chip from '@material-ui/core/Chip'

class FieldMultiLineCell extends React.Component {
    render() {
        const value = this.props.value
        const values = value ? value.split(",") : []

        const variant = this.props.hasOwnProperty('outlined') ? this.props.variant : 'outlined'
        const size = this.props.hasOwnProperty('size') ? this.props.size : 'small'
        const color = this.props.hasOwnProperty('color') ? this.props.color : 'secondary'

        const theme = this.props.theme
        const style = {
            marginRight: theme.spacing(1)
        }

        return <React.Fragment>
            {values.map(
                (value, idx) => <Chip key={idx} label={value} variant={variant} size={size} color={color} style={style} />
            )}
        </React.Fragment>
    }
}

export default withTheme(FieldMultiLineCell)