import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import {displaynameFromToken} from '../services/token'

const UserAvatarStyles = theme => {
    return {
        root: theme.globals.appUserAvatar
    }
}

function stringAvatar(name) {
    let avatarStr = name.includes(' ')
        ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        : name.slice(0,2)
    return {
        children: avatarStr.toUpperCase(),
    }
}

function UserAvatar(props) {
    const displayname = props.hasOwnProperty('username')
        ? props.username
        : displaynameFromToken()
    const {classes} = props

    const {showUsername,...rest} = props

    return <React.Fragment>
        <Avatar className={classes.appUserAvatar} 
            {...rest}
            {...stringAvatar(displayname)}
        />
        {showUsername
            ? <Typography variant="body2" component="span">
                &nbsp;&nbsp;{displayname}
            </Typography>
            : null}
    </React.Fragment>
}

export default withStyles(UserAvatarStyles)(UserAvatar)