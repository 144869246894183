import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {companies_get_addresses} from '../../services/BE'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import LocationOnIcon from '@material-ui/icons/LocationOn'

class WidgetCompanyAddresses extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            addresses: [],
            loading: false
        }
    }
    
    async updateAddresses() {
        this.setState({
            addresses: [],
            loading: true
        })
        const id_company = this.props.id_company
        if( id_company )
            this.setState({
                addresses: await companies_get_addresses(id_company),
                loading: false
            })
    }

    async componentDidMount() {
        this.updateAddresses()
    }
    
    async componentDidUpdate(prevProps) {
        const id_company = this.props.id_company
        const refreshContent = this.props.refreshContent
        if( id_company !== prevProps.id_company || (!refreshContent && refreshContent !== prevProps.refreshContent) )
            this.updateAddresses()
    }

    render() {
        const id_company = this.props.id_company
        const loading = this.props.loading
        const addresses = this.state.addresses

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const TopActions = this.props.hasOwnProperty('TopActions')
            ? this.props.TopActions
            : null
        const otherActions = this.props.hasOwnProperty('otherActions')
            ? this.props.otherActions
            : []
        const noRowActions = this.props.hasOwnProperty('noRowActions')
            ? this.props.noRowActions
            : false
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Addresses"

        const fieldsProps = tableSchema('addresses')
        let addressesRowActions = noRowActions ? [] : actionSchema('addresses')
        if( addressesRowActions.length ) {
            addressesRowActions.map((act) => {
                if( act.hasOwnProperty('action') && act.action === 'edit' && act.hasOwnProperty('link') ) {
                    act.link += '?id_company'
                }
            })
        }

        let cardProps = {...this.props}
        cardProps['Content'] = addresses.length
            ? (loading
                ? <LoaderSingleComponent width="100%" />
                : <Table material dense
                    //hideHeaders
                    filterable={false}
                    defaultPageSize="100"
                    data={addresses}
                    fieldsProps={fieldsProps}
                    rowActions={addressesRowActions}
                    rowActionsField="id_address"
                />)
            : <Chip icon={<ErrorIcon />} label="Nessun indirizzo trovato" variant="outlined" />

        if( dashboardcardActions )
            cardProps['TopActions'] = TopActions
                ? TopActions
                : <React.Fragment>
                    <Button
                        variant="text" color="primary" size="small"
                        startIcon={<LocationOnIcon fontSize="small" />}
                        component={AdapterLink}
                        to={"/addresses/add?id_address_use=4&housing_condition=affitto&id_company="+id_company}
                    >Nuovo</Button>&nbsp;&nbsp;
                    {[...otherActions]}
                </React.Fragment>

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetCompanyAddresses