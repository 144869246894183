import React from 'react'
import SnackbarMessage from './SnackbarMessage'

class ErrorMsgApp extends React.Component  {
    render() {
      const message = this.props.message ? this.props.message : 'Something went wrong'
      return <SnackbarMessage
          open={true}
          message={message}
          severity={'error'}
          autoHideDuration={120000}
      />
    }
}

export default ErrorMsgApp