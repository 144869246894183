import React from 'react'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityTitle from '../@react-fefmwk/entity/modules/EntityTitle'

import CardInsurance from '../modules/CardInsurance'

class CardInsurancePage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        return <Content title={title} toolbar={<EntityTitle entity="insurances" id_entity={id_entity} />}>
            <CardInsurance id_entity={id_entity} />
        </Content>
    }
}

export default CardInsurancePage