import React from 'react'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
//import {agenciesBranchesFromToken} from '../services/AgencySchema'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'

import StatsAgentsForm from './StatsAgentsForm'
import MyTasks from './MyTasks'
import MyRenewals from './MyRenewals'
import MyAssignments from './MyAssignments'
import MyReports from './MyReports'
import MyStakeholders from './MyStakeholders'
import MyPracticals from './MyPracticals'

class Dashboard extends React.Component {
    render() {
        const id_user = this.props.hasOwnProperty('id_user') && this.props.id_user
            ? this.props.id_user
            : idFromToken()
        const branches = this.props.hasOwnProperty('branches') && this.props.branches
            ? this.props.branches
            : []//agenciesBranchesFromToken()

        return <React.Fragment>
            <Tabber basicname="dashboard"
                tabs={[
                    {
                        slug: "overview",
                        label: "Overview",
                        content: <StatsAgentsForm users_ids={[id_user]} branches={branches} />
                    },
                    {
                        slug: "my-tasks",
                        label: "Task",
                        content: <MyTasks id_user={id_user} />
                    },
                    {
                        slug: "my-renewals",
                        label: "Rinnovi",
                        content: <MyRenewals id_user={id_user} />
                    },
                    {
                        slug: "my-assignments",
                        label: "Richieste",
                        content: <MyAssignments id_user={id_user} />
                    },
                    {
                        slug: "my-reports",
                        label: "Report",
                        content: <MyReports id_user={id_user} />
                    },
                    {
                        slug: "my-practicals",
                        label: "Pratiche",
                        content: <MyPracticals id_user={id_user} />
                    },
                    {
                        slug: "my-stakeholders",
                        label: "Contatti",
                        content: <MyStakeholders id_user={id_user} />
                    },
                ]}
            />
        </React.Fragment>
    }
}

export default Dashboard