import React from 'react'

import {
    users_get_practicals,
    users_get_practicals_stats
} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'

import BlockIcon from '@material-ui/icons/Block'
import AddIcon from '@material-ui/icons/Add'

import Table from '../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import ScoreCard from '../@react-fefmwk/cards/modules/ScoreCard'

class MyPracticals extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            practicals_stats: {},
            practicals: [],
            refresh: false,
            closed: false,
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        const practicals_stats = await users_get_practicals_stats(id_user)
        const practicals = await users_get_practicals(id_user)

        this.setState({
            practicals_stats: practicals_stats,
            practicals: practicals
        })
    }

    async componentDidUpdate(prevProps,prevState) {
        const id_user = this.state.id_user
        const closed = this.state.closed
        if( closed !== prevState.closed ) {
            this.setState({
                practicals_stats: await users_get_practicals_stats(id_user,closed),
                practicals: await users_get_practicals(id_user,closed),
                refresh: false
            })
        }
    }

    handleChange(e,filter) {
        e.persist()

        this.setState({
            refresh: true,
            closed: filter === 'closed' ? e.target.checked : this.state.closed,
        })
    }

    render() {
        const practicals_stats = this.state.practicals_stats
        const practicals = this.state.practicals

        const entity = "practicals"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        const refresh = this.state.refresh
        const closed = this.state.closed

        const practicals_n = practicals_stats.hasOwnProperty('total') ? practicals_stats.total : 0
        const practicals_by_state = practicals_stats.hasOwnProperty('by_state') ? practicals_stats.by_state : []
        const practicals_by_product = practicals_stats.hasOwnProperty('by_product') ? practicals_stats.by_product : []

        return <React.Fragment>
            <Box mb={2}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs="auto">
                        <Button component={AdapterLink} to="/practicals/add" variant="contained" size="small" color="secondary" startIcon={<AddIcon />}>
                            pratica
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={closed} onChange={(e) => this.handleChange(e,'closed')} /> includi perfezionate
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    {!refresh
                        ? <Grid item xs>
                            <ScoreCard title="# pratiche" value={practicals_n} fullWidth />
                        </Grid>
                        : null}
                    {practicals_by_state.length && !refresh
                        ? <React.Fragment>
                            {practicals_by_state.map((row,i) => {
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+row.practical_state} value={row.practicals_n} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                    {practicals_by_product.length && !refresh
                        ? <React.Fragment>
                            {practicals_by_product.map((row,i) => {
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+row.product_code} value={row.practicals_n} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                </Grid>
            </Box>
            {practicals.length
                ? <Paper>
                    {!refresh
                        ? <Table material dense highlight
                            data={practicals}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                        : <LoaderSingleComponent width="100%" />}
                </Paper>
                : <Chip icon={<BlockIcon />} label="Nessuna pratica assegnata" variant="outlined" />}
        </React.Fragment>
    }
}

export default MyPracticals