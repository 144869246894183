import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { practicals_list_originations } from '../../services/BE'
import { entity_fields_info,entity_get } from '../../@react-fefmwk/entity/services/api'
import { helpersFormSchema } from '../../services/EntityHelpersFormSchema'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import WidgetLeadPreview from '../widgets/WidgetLeadPreview'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import FieldRadios from '../../@react-fefmwk/form/modules/FieldRadios'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import DoneIcon from '@material-ui/icons/Done'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'

const styles = theme => ({})

class Origination extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals_fields_info: {},
            practicals_originations: [],
            lead: {},
            stakeholder: {},
        }
    }
    async componentDidMount() {
        let practicals_originations = await practicals_list_originations()

        let practicals_fields_info = await entity_fields_info('practicals')
        if( Object.keys(practicals_fields_info).length ) {
            practicals_fields_info.id_lead.nice_name = 'cerca lead...'
        }

        const formikProps = this.props.formikProps
        const id_stakeholder = formikProps.values.id_stakeholder
        let stakeholder = {}
        if( id_stakeholder ) {
            //get stakeholder
            stakeholder = await this.getStakeholder( id_stakeholder )
            //do not show from lead origination
            /*practicals_originations = practicals_originations.filter((origination) => { 
                return parseInt(origination.id_practical_origination) !== 1
            })*/
        }

        this.setState({
            practicals_fields_info: {id_lead: practicals_fields_info.id_lead},
            practicals_originations: practicals_originations,
            stakeholder: stakeholder
        })
    }
    /*async componentDidUpdate(prevProps) {
        const formikProps = this.props.formikProps
        const id_practical_origination = formikProps.values.id_practical_origination
        const id_lead = formikProps.values.id_lead
        if( id_practical_origination !== 1 && prevProps.formikProps.values.id_practical_origination !== id_practical_origination ) {
            formikProps.setFieldValue('id_lead','')
            formikProps.setFieldValue('id_stakeholder','')
            this.setState({
                lead: {},
                stakeholder: {},
            })
        }
        else {
            if( id_lead && id_lead !== 1 && prevProps.formikProps.values.id_lead !== id_lead ) {
                const lead = await entity_get('leads',id_lead)
                if( Object.keys(lead).length && lead.hasOwnProperty('id_stakeholder') ) {
                    const stakeholder = await entity_get('stakeholders',lead.id_stakeholder)
                    if( Object.keys(stakeholder).length && stakeholder.hasOwnProperty('id_stakeholder') ) {
                        formikProps.setFieldValue('id_stakeholder',stakeholder.id_stakeholder)
                        this.setState({
                            lead: lead,
                            stakeholder: stakeholder,
                        })
                    }
                }
            }
        }
    }*/
    async componentDidUpdate(prevProps) {
        const formikProps = this.props.formikProps

        const id_practical_origination = formikProps.values.id_practical_origination
        const prev_id_practical_origination = prevProps.formikProps.values.id_practical_origination

        const id_lead = formikProps.values.id_lead
        const prev_id_lead = prevProps.formikProps.values.id_lead

        //from lead
        if( parseInt(id_practical_origination) === 1 && id_lead && prev_id_lead !== id_lead ) {
            //reset id_stakeholder
            //formikProps.setFieldValue('id_stakeholder','')

            //get lead
            const lead = await entity_get('leads',id_lead)
            if( Object.keys(lead).length && lead.hasOwnProperty('id_stakeholder') ) {
                formikProps.setFieldValue('id_stakeholder',lead.id_stakeholder)
                this.setState({
                    lead: lead
                })
            }
        }

        //other originations
        if( parseInt(id_practical_origination) !== 1 && parseInt(prev_id_practical_origination) === 1 ) {
            formikProps.setFieldValue('id_lead','')
            formikProps.setFieldValue('id_stakeholder','')
            this.setState({
                lead: {},
            })
        }

        //get stakeholder
        const id_stakeholder = formikProps.values.id_stakeholder
        const prev_id_stakeholder = prevProps.formikProps.values.id_stakeholder
        if( id_stakeholder && prev_id_stakeholder !== id_stakeholder ) {
            const stakeholder = await this.getStakeholder( id_stakeholder )
            this.setState({
                stakeholder: stakeholder
            })
        }
    }
    async getStakeholder( id_stakeholder ) {
        return await entity_get('stakeholders',id_stakeholder)
    }

    render() {
        const formikProps = this.props.formikProps
        //const {classes} = this.props

        let practicals_fields_info = this.state.practicals_fields_info
        if( Object.keys(practicals_fields_info).length === 0 )
            return <LoaderSingleComponent width="100%" />

        let helpersTextSchema = helpersFormSchema('practicals')

        const id_practical_origination = formikProps.values.id_practical_origination
        const id_stakeholder = formikProps.values.id_stakeholder

        const practicals_originations = this.state.practicals_originations
        const stakeholder = this.state.stakeholder
        const lead = this.state.lead
        const id_lead = formikProps.values.id_lead

        return <React.Fragment>
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
            >
                <Grid item xs={12} md={2}>
                    <FieldRadios
                        title='Sorgenti'
                        items={practicals_originations}
                        name='id_practical_origination'
                        fieldId='id_practical_origination'
                        fieldName='practical_origination'
                        value={String(formikProps.values['id_practical_origination'])}
                        onChange={formikProps.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    {parseInt(id_practical_origination) === 1
                        ? <React.Fragment>
                            <EntityFormInputs
                                entity={'practicals'}
                                entityFieldsInfo={practicals_fields_info}
                                entityHelpersSchema={helpersTextSchema}
                                formikProps={formikProps}
                            />
                        </React.Fragment>
                        : null}
                    {Object.keys(stakeholder).length
                        ? <React.Fragment>
                            <Chip label={stakeholder.stakeholder} icon={<DoneIcon />} />
                            <Box mt={2}>
                                <ViewEntity entity="stakeholders" id_entity={id_stakeholder} />
                            </Box>
                        </React.Fragment>
                        : null}
                </Grid>
                <Grid item xs={12} md={7}>
                    {id_lead && Object.keys(lead).length
                        ? <React.Fragment>
                            <WidgetLeadPreview
                                body={lead.lead_body}
                                date={lead.lead_datetime}
                                subject={lead.lead_subject}
                                from={lead.lead_from}
                                to={lead.lead_to}
                            />
                        </React.Fragment>
                        : null}
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(Origination)