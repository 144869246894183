import React from 'react'

import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import { tableSchema } from '../../services/EntityTableSchema'
import { actionSchema } from '../../services/EntityRowActionsSchema'
import { stakeholders_get_practicals_outers } from '../../services/BE'

class WidgetStakeholderPracticalsOuters extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            entity: "practicals_outers",
            id_field: "id_practical_outer",
            path: this.props.path,
            id_stakeholder: this.props.id_stakeholder,
            practicals_outers: [],
        }
    }
    
    async getPracticalsOuters() {
        const id_stakeholder = this.props.id_stakeholder
        this.setState({
            practicals_outers: await stakeholders_get_practicals_outers( id_stakeholder ),
        })
    }
    
    async componentDidMount() {
        this.getPracticalsOuters()
    }

    async componentDidUpdate(prevProps) {
        const id_stakeholder = this.props.id_stakeholder
        if( id_stakeholder !== prevProps.id_stakeholder )
            this.getPracticalsOuters()
    }

    render() {
        const entity = this.state.entity
        const id_field = this.state.id_field
        const practicals_outers = this.state.practicals_outers

        const tableRowActions = this.props.hasOwnProperty('tableRowActions')
            ? this.props.tableRowActions
            : true
        const rowActions = tableRowActions
            ? actionSchema(entity)
            : {}

        let fieldsProps = tableSchema(entity)
        if( !tableRowActions )
            fieldsProps = {
                ...fieldsProps,
                ...{id_practical_outer:{show:false}}
            }
    
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Pratiche altri istituti"

        return <DashboardCard title={dashboardcardTitle}
            Content={practicals_outers.length
                ? <Table material highlight dense
                    data={practicals_outers}
                    fieldsProps={fieldsProps}
                    rowActions={rowActions}
                    rowActionsField={id_field}
                    showPagination={true}
                    filterable={practicals_outers.length > 5 ? true : false}
                    sortable={true}
                />
                : <Chip icon={<ErrorIcon />} label="Nessuna pratica trovata" variant="outlined" />}
        />
    }
}

export default WidgetStakeholderPracticalsOuters