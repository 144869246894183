import React from 'react'

import Modal from '../../@react-fefmwk/modals/modules/Modal'
import FieldTextarea from '../../@react-fefmwk/form/modules/FieldTextarea'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import BlockIcon from '@material-ui/icons/Block'

class FieldLongText extends React.Component {
    render() {
        const title = this.props.title
        const text = this.props.text

        const maxChars = this.props.hasOwnProperty('maxChars') ? this.props.maxChars : 50

        const ifEmpty = this.props.hasOwnProperty('ifEmpty')
            ? this.props.ifEmpty
            : <BlockIcon size="small" style={{color:'lightgrey'}} />

        return text && text.length
            ? <Grid container alignItems="center" spacing={0} wrap="nowrap">
                {text.length > maxChars
                    ? <Grid item xs="auto">
                        <Modal
                            fullWidth
                            maxWidth="md"
                            printCloseBtn
                            title={title}
                            tooltipText={text}
                            content={<FieldTextarea
                                title={title}
                                type="string"
                                value={text}
                                disabled={true}
                                variant="outlined"
                            />}
                            Trigger={MoreHorizIcon}
                        />
                        &nbsp;
                    </Grid>
                    : null}
                <Grid item xs>
                    <Box style={{fontSize: "90%"}}>{text.slice(0,maxChars)} {text.length > maxChars ? " ..." : ""}</Box>
                </Grid>
            </Grid>
            : ifEmpty
    }
}

export default FieldLongText