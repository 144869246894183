import React from 'react'

import {entity_get} from '../@react-fefmwk/entity/services/api'
import {idFromToken,groupsFromToken} from '../@react-fefmwk/auth/services/token'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AssignmentIcon from '@material-ui/icons/Assignment'

class CardReport extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            report: {},
        }
    }

    async componentDidMount() {
        const id_entity = this.props.id_entity
        this.setState({
            report: await entity_get('reports',id_entity),
        })
    }

    render() {
        const id_entity = this.props.id_entity
        const report = this.state.report
        const groups = groupsFromToken()

        const id_stakeholder = report.hasOwnProperty('id_report_stakeholder') ? report.id_report_stakeholder : 0
        const target = report.hasOwnProperty('report_target') ? report.report_target : ''
        const id_practical = report.hasOwnProperty('id_practical') ? report.id_practical : 0
        const id_lead = report.hasOwnProperty('id_lead') ? report.id_lead : 0
        const id_task = report.hasOwnProperty('id_activity') ? report.id_activity : 0
        const id_user = report.hasOwnProperty('id_user') ? report.id_user : 0

        const id_current_user = idFromToken()
        const canBeUpdated = (!groups.includes(String(4)) || parseInt(id_current_user) === parseInt(id_user) ) ? true : false

        return <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                {id_stakeholder
                    ? <React.Fragment>
                        <ViewEntity
                            cardTitle={"Contatto / cliente #"+id_stakeholder}
                            entity={'stakeholders'}
                            id_entity={id_stakeholder}
                            fields={[
                                'stakeholder','main_mobile',
                                'company','vat_number',
                            ]}
                            TopActions={<React.Fragment>
                                <Button variant="text" size="small" color="primary"
                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder}
                                >Visualizza</Button>
                                <Button variant="text" size="small" color="primary"
                                    startIcon={<CloudUploadIcon />}
                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#documents'}
                                >Documenti</Button>
                                <Button variant="text" size="small" color="primary"
                                    startIcon={<AssignmentIcon />}
                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#activities'}
                                >Attività</Button>
                            </React.Fragment>}
                        />
                        <br />
                    </React.Fragment>
                    : null}
                <ViewEntity
                    cardTitle={"Report #"+id_entity}
                    entity={'reports'}
                    id_entity={id_entity}
                    fields={[
                        'report_type','report_author','task_receiver',
                        'report_target','report_feedback','report_date','report_title','report_content',
                        'id_practical','id_lead','id_activity','id_user',
                    ]}
                    TopActions={<React.Fragment>
                        <Button variant="text" size="small" color="primary" disabled={!canBeUpdated}
                            component={AdapterLink} to={'/reports/update/'+id_entity}
                        >Modifica</Button>
                        <Button variant="text" size="small" color="primary" disabled={!canBeUpdated}
                            component={AdapterLink} to={'/reports/delete/'+id_entity}
                        >Elimina</Button>
                    </React.Fragment>}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {target === 'task' && parseInt(id_task) > 0
                    ? <ViewEntity
                        cardTitle={"Task #"+id_task}
                        entity={'tasks'}
                        id_entity={id_task}
                        fields={[
                            'activity_order',
                            'activity_instruction',
                            'id_agent','id_user',
                            'activity_target',
                            'id_practical','id_lead',
                            'activity_state',
                            'id_report','closed_at',
                            'active_at',
                            'created_at','updated_at',
                        ]}
                    />
                    : null}
                {target === 'practical' && parseInt(id_practical) > 0
                    ? <ViewEntity
                        cardTitle={"Pratica #"+id_practical}
                        entity={'practicals'}
                        id_entity={id_practical}
                        fields={[
                            'id_practical','product','stakeholder','practical_state',
                            'agent','origination','atc',
                            'load_date','effective_date','renewal_date',
                            'disbursed_amount','installments_n','installment_amount','compounding_amount',
                            'commission','tan','taeg',
                            'estinzione','installments_n','installment_amount','commission',
                        ]}
                        TopActions={<React.Fragment>
                            <Button variant="text" size="small" color="primary"
                                component={AdapterLink} to={'/practicals/card/'+id_practical}
                            >Visualizza</Button>
                            <Button variant="text" size="small" color="primary"
                                startIcon={<CloudUploadIcon />}
                                component={AdapterLink} to={'/practicals/card/'+id_practical+'#documents'}
                            >Documenti</Button>
                            <Button variant="text" size="small" color="primary"
                                startIcon={<AssignmentIcon />}
                                component={AdapterLink} to={'/practicals/card/'+id_practical+'#history'}
                            >Attività</Button>
                        </React.Fragment>}
                    />
                    : null}
                {target === 'lead' && parseInt(id_lead) > 0
                    ? <ViewEntity
                        cardTitle={"Lead #"+id_lead}
                        entity={'leads'}
                        id_entity={id_lead}
                        fields={[
                            'lead_channel','lead_subject','lead_date','lead_body','id_agent',
                        ]}
                    />
                    : null}
            </Grid>
        </Grid>
    }
}

export default CardReport