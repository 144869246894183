import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import {agenciesBranchesFromToken} from '../services/AgencySchema'
import StatsAgentsForm from '../modules/StatsAgentsForm'
import { Chip } from '@material-ui/core'

class MyBranchAgentsPage extends React.Component {
    render() {
        const title = this.props.route.title
        const branches = this.props.hasOwnProperty('branches') && this.props.branches
            ? this.props.branches
            : agenciesBranchesFromToken()

        return <Content title={title}>
            {!branches.length
                ? <Chip label="Non ci sono sedi accessibili per l'utente collegato" variant="outlined" />
                : <StatsAgentsForm branches={branches} stats={['metrics_by_branch','metrics_by_agent']} />}
        </Content>
    }
}

export default MyBranchAgentsPage