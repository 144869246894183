import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Box } from '@material-ui/core'

const DashboardCardStyle = theme => ({
    dCardTitleContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    dCardTitle: {
        margin:0
    },
    dCardTopActionsLeft: {
        textAlign:"left"
    },
    dCardTopActionsRight: {
        textAlign:"right"
    },
    dCardContent: {
        overflow:'auto',
        [theme.breakpoints.up('sm')]: {
            maxHeight:"70vh",
        },
    },
})

class DashboardCard extends React.Component {
    render() {
        const title = this.props.title
        const noTitle = this.props.hasOwnProperty('noTitle')
        const noCard = this.props.hasOwnProperty('noCard')

        const Content = this.props.hasOwnProperty('Content') !== null && React.isValidElement(this.props.Content)
            ? React.cloneElement(this.props.Content)
            : null

        const Actions = this.props.hasOwnProperty('Actions') && React.isValidElement(this.props.Actions)
            ? React.cloneElement(this.props.Actions)
            : null

        const TopActions = this.props.hasOwnProperty('TopActions') && React.isValidElement(this.props.TopActions)
            ? React.cloneElement(this.props.TopActions)
            : null

        const { classes } = this.props

        let cardProps = {}
        if( noCard ) cardProps['elevation'] = 0
        cardProps = {
            ...this.props.hasOwnProperty("cardProps") ? this.props.cardProps : {},
            ...cardProps
        }

        const titleStyle = this.props.hasOwnProperty('titleStyle') ? this.props.titleStyle : {}
        const actionsStyle = this.props.hasOwnProperty('actionsStyle') ? this.props.actionsStyle : {}
        const contentStyle = this.props.hasOwnProperty('contentStyle') ? this.props.contentStyle : {}
        const height = this.props.hasOwnProperty('height') ? this.props.height : false
        if( height ) contentStyle['height'] = height

        return <Card {...cardProps}>
            {!noTitle || TopActions !== null
                ? <React.Fragment>
                    <CardContent className={classes.dCardTitleContainer} style={titleStyle}>
                        <Grid container spacing={0} alignItems="center">
                            {noTitle
                                ? null
                                : <Grid item xs={12} md>
                                    <Typography gutterBottom variant="overline" component="div" className={classes.dCardTitle}>
                                        {title}
                                    </Typography>
                                </Grid>}
                            {TopActions === null
                                ? null
                                : <Grid item xs={12} md className={noTitle ? classes.dCardTopActionsLeft : classes.dCardTopActionsRight}>
                                    <Box pt={1} pb={1}>{TopActions}</Box>
                                </Grid>}
                        </Grid>
                    </CardContent>
                    <Divider />
                </React.Fragment>
                : null}
            <CardContent className={classes.dCardContent} style={contentStyle}>
                {Content}
            </CardContent>
            {Actions === null
                ? null
                : <React.Fragment>
                    <Divider />
                    <CardActions style={actionsStyle}>
                        {Actions}
                    </CardActions>
                </React.Fragment>}
        </Card>
    }

}

export default withStyles(DashboardCardStyle)(DashboardCard)