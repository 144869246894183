import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {leads_get_reports} from '../../services/BE'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'

class WidgetLeadReports extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reports: [],
        }
    }
    
    async updateReports() {
        const id_lead = this.props.id_lead
        if( id_lead )
            this.setState({
                reports: await leads_get_reports(id_lead),
            })
    }

    async componentDidMount() {
        this.updateReports()
    }
    
    async componentDidUpdate(prevProps) {
        const id_lead = this.props.id_lead
        if( id_lead !== prevProps.id_lead )
            this.updateReports()
    }

    render() {
        const id_lead = this.props.id_lead
        const reports = this.state.reports

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Reports"

        const tableRowActions = this.props.hasOwnProperty('tableRowActions')
            ? this.props.tableRowActions
            : true
        const rowActions = tableRowActions
            ? actionSchema('reports')
            : {}

        let fieldsProps = tableSchema('reports')
        if( !tableRowActions )
            fieldsProps = {
                ...fieldsProps,
                ...{id_report:{show:false}}
            }

        const cardProps = {}
        cardProps['Content'] = reports.length
            ? <Table material dense
                hideHeaders
                defaultPageSize="100"
                data={reports}
                fieldsProps={fieldsProps}
                rowActions={rowActions}
                rowActionsField="id_report"
            />
            : <Chip icon={<ErrorIcon />} label="Nessun report trovato" variant="outlined" />

        if( dashboardcardActions )
            cardProps['TopActions'] = <Button
                variant="text" color="primary" size="small"
                startIcon={<AddIcon fontSize="small" />}
                component={AdapterLink}
                to={"/reports/add?id_lead="+id_lead}
            >Nuovo</Button>

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetLeadReports