import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { withStyles,withTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import {setSeries} from '../services/api'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'

import highChartsDefaults from './defaults'

const style = theme => ({})
/*
<HighchartsReact highcharts={Highcharts} options={getOptions('line')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('spline')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('area')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('areaspline')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('column')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('bar')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('pie')} />
<HighchartsReact highcharts={Highcharts} options={getOptions('scatter')} />
*/
class HChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            options: {},
        }
    }
    componentDidMount() {
        this.setOptions()
    }
    componentDidUpdate(prevProps,prevState) {
        if( prevProps !== this.props )
            this.setOptions()
    }
    setOptions() {
        const data = this.props.hasOwnProperty('data') ? this.props.data : []
        if(!data.length) return {}

        const type = this.props.hasOwnProperty('type')
            ? this.props.type
            : 'line'
        const data_type = this.props.hasOwnProperty('data_type')
            ? this.props.data_type
            : ''
        const name = this.props.hasOwnProperty('name')
            ? this.props.name
            : ''
        const visible = this.props.hasOwnProperty('visible')
            ? this.props.visible
            : ''
        /*const index = this.props.hasOwnProperty('index')
            ? this.props.index
            : ''
        const zIndex = this.props.hasOwnProperty('zIndex')
            ? this.props.zIndex
            : ''
        const yAxisData = this.props.hasOwnProperty('yAxisData')
            ? this.props.yAxisData
            : ''*/

        const dataObj = setSeries(data,{
            type: type,
            data_type: data_type,
            name: name,
            visible: visible,
            xField: '',
        })

        const {theme} = this.props
        const colors = [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.primary[200],
            theme.palette.secondary[200],
            theme.palette.primary[700],
            theme.palette.secondary[700],
            theme.palette.primary[900],
            theme.palette.secondary[900],
        ]
        const defaults = highChartsDefaults
        let options = {
            ...defaults,
            ...{colors: colors},
            ...{
                chart: {
                    ...defaults.chart,
                    ...this.props.hasOwnProperty('chart') ? this.props.chart : {},
                    ...{type: type}
                },
                title: {
                    ...defaults.title,
                    ...{
                        text: this.props.hasOwnProperty('title') ? this.props.title : ''
                    }
                },
                subtitle: {
                    ...defaults.subtitle,
                    ...{
                        text: this.props.hasOwnProperty('subtitle') ? this.props.subtitle : ''
                    }
                },
                xAxis: {
                    ...defaults.xAxis,
                    ...this.props.hasOwnProperty('xAxis') ? this.props.xAxis : {}
                },
                yAxis: {
                    ...defaults.yAxis,
                    ...this.props.hasOwnProperty('yAxis') ? this.props.yAxis : {}
                },
                tooltip: {
                    ...defaults.tooltip,
                    ...this.props.hasOwnProperty('tooltip') ? this.props.tooltip : {}
                },
                plotOptions: {
                    ...defaults.plotOptions,
                    ...this.props.hasOwnProperty('plotOptions') ? this.props.plotOptions : {}
                },
                legend: {
                    ...defaults.legend,
                    ...this.props.hasOwnProperty('legend') ? this.props.legend : {}
                },
                series: dataObj.series
            }
        }

        if( dataObj.hasOwnProperty('categories') && dataObj.categories.length )
            options.xAxis.categories = dataObj.categories

        this.setState({
            options: options
        })

        return options
    }

    render() {
        const options = this.state.options
        if( !Object.keys(options).length )
            return <LoaderSingleComponent width="100%" />

        return <Box p={1}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Box>
    }

}

export default withStyles(style)(withTheme(HChart))