import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

const style = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    radio: {
        '&$checked': {
            color: theme.palette.error[500]
        }
    },
    checked: {},
})

class FieldRadios extends React.Component {

    render() {
        const name = this.props.name
        if( !this.props.hasOwnProperty("items") || !this.props.items.length )
            return <Chip label={"No "+name+" found"} variant="outlined" style={{marginTop:20}}/>
        
        const { classes } = this.props

        const title = this.props.title ? this.props.title : ""
        const id = this.props.id ? this.props.id : ""
        const fieldId = this.props.fieldId
        const fieldName = this.props.fieldName
        const error = this.props.error ? 'true' : ''
        const value = this.props.value
        const disabled = this.props.disabled
        const items = this.props.items
        const row = this.props.hasOwnProperty('row') ? this.props.row : false
        const radios = items.map((item, i) => <FormControlLabel key={i}
                value={String(item[fieldId])}
                control={<Radio classes={error ? {root: classes.radio,checked: classes.checked} : {}} />}
                label={item[fieldName]}
            />)

        return <FormControl className={classes.formControl}>
            <FormLabel className="MuiInputLabel-shrink">{title}</FormLabel>
            <RadioGroup row={row}
                id={id}
                name={name}
                error={error}
                value={value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                disabled={disabled}
            >
                {radios}
            </RadioGroup>
        </FormControl>
    }
}

export default withStyles(style)(FieldRadios)