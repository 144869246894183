import React from 'react'

import FieldText from './FieldText'

class FieldNumber extends React.Component {

    render() {
        let {type,InputLabelProps,...rest} = this.props
        type = "number"
        InputLabelProps = {
            ...InputLabelProps,
            ...{shrink:true}
        }

        return <React.Fragment>
            <FieldText  {...rest} type={type} InputLabelProps={InputLabelProps} />
        </React.Fragment>
    }
}

export default FieldNumber