import React from 'react'
import { Redirect,withRouter } from "react-router-dom"

class RedirectBack extends React.Component  {
    render() {
        const { history } = this.props
        return history.goBack() && {}.toString.call(history.goBack()) === '[object Function]'
            ? <Redirect to={history.goBack()} />
            : null
    }
}

export default withRouter(RedirectBack)