import React from 'react'

import {
    entity_create,
    entity_update
} from '../@react-fefmwk/entity/services/api'
import {nowString} from '../@react-fefmwk/dates/services/api'
import {idFromToken} from '../@react-fefmwk/auth/services/token'

import TargetSelection from '../modules/add-task-stepper/TargetSelection'
import TaskData from '../modules/add-task-stepper/TaskData'
import Checkout from '../modules/add-task-stepper/Checkout'

export const AddTaskStepperSchema = [
    {
        "name": "target-selection",
        "icon": false,
        "title": "Target",
        "description": "Scegli il target del task",
        "component": (formikProps) => <TargetSelection formikProps={formikProps} />,
    },
    {
        "name": "task_data",
        "icon": false,
        "title": "Task data ",
        "description": "Compila il form sottostante",
        "component": (formikProps) => <TaskData formikProps={formikProps} />,
    },
    {
        "name": "checkout",
        "icon": false,
        "title": "Checkout",
        "description": "Controlla i dati inseriti",
        "component": (formikProps) => <Checkout formikProps={formikProps} />,
    },
]

export const AddTaskStepperFormSchema = {
    id_task: 0,
    id_task_target: '',
    id_lead: 0,
    id_stakeholder: 0,
    id_practical: 0,
    id_activity_order: '',
    id_agent: '',
    activity_instruction: '',
    active_at: nowString(),
    created_at: nowString(),
    id_user: parseInt(idFromToken()),
}

export const AddTaskStepperValidationSchema = [
    (values) => {
        let errors = {}
        const id_task_target = values.id_task_target
        const id_lead = values.id_lead
        const id_stakeholder = values.id_stakeholder
        const id_practical = values.id_practical

        if( !id_task_target )
            errors['id_task_target'] = 'Scegli un target valido'
        
        if( !id_lead && !id_stakeholder && !id_practical )
            errors['id_task_target'] = 'Scegli un valido lead o pratica o cliente esistente'

        return errors
    },
    (values) => {
        let errors = {}
        const id_activity_order = values.id_activity_order
        const id_task_target = values.id_task_target
        const id_agent = values.id_agent
        const activity_instruction = values.activity_instruction
        const event = values.hasOwnProperty('event')

        if( !id_activity_order ) errors['id_activity_order'] = 'Scegli un ordine valido'
        if( !id_task_target ) errors['id_task_target'] = 'Scegli un tipo di task valido'
        if( !id_agent && !event ) errors['id_agent'] = 'Scegli un collaboratore Che Quinto'
        if( !activity_instruction ) errors['activity_instruction'] = 'Inserisci le istruzioni del task'

        return errors
    },
]

export const AddTaskStepperSubmitSchema = [
    {
        "enableSubmit": false,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            return true
        }
    },
    {
        "enableSubmit": true,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            const id = prevState.id
            if( id === "add-task" ) {
                const inserted_task = await entity_create( 'tasks',values )
                const id_task = inserted_task['last_id']
                actions.setFieldValue("id_task",id_task)
                return id_task
            }
            else {
                const id_task = prevState.form.id_task
                if(id_task) {
                    const updated_task = await entity_update( 'tasks',id_task,values )
                    return updated_task
                }
            }
        }
    },
]