import React from 'react'

import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'
import TableOneRowTransposed from '../../tables/modules/TableOneRowTransposed'

import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

import {selectFieldsFromObj} from '../../arrays/api'
import {
    entity_get,
    entity_fields_info,
    mergeFieldsPropsAndFieldsInfo
} from '../services/api'
import { EntitySchema } from '../../../services/EntitySchema'
import { tableSchema } from '../../../services/EntityTableSchema'
import { fieldsViewSchema } from '../../../services/EntityFieldsSchema'

import DashboardCard from '../../cards/modules/DashboardCard'

class ViewEntity extends React.Component{
    constructor(props) {
        super(props)

        const id_entity = this.props.id_entity
        const entity = this.props.entity
        const entityObj = EntitySchema[entity]
        this.state = {
            entity: entity,
            entityObj: entityObj,
            id_entity: id_entity,
            data: [],
            fields_info: {},
        }
    }

    async componentDidMount() {
        this.setState({
            data: await entity_get(this.state.entity,this.state.id_entity),
            fields_info: await entity_fields_info(this.state.entity),
        })
    }

    async componentDidUpdate(prevProps) {
        const entity = this.props.entity
        const id_entity = this.props.id_entity

        if( entity !== prevProps.entity ) {
            this.setState({
                fields_info: await entity_fields_info(entity),
            })
        }

        if( id_entity !== prevProps.id_entity ){
            const data = await entity_get(entity,id_entity)
            this.setState({
                data: data,
            })
        }

        const onGetData = this.props.hasOwnProperty('onGetData')
            ? this.props.onGetData
            : () => {}
        onGetData(this.state.data)
    }

    render() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        const entityObj = this.state.entityObj
        if( !entity || !entityObj || !Object.keys(entityObj).length )
            return <Chip icon={<ErrorIcon />} label="Unmanaged entity" variant="outlined" />

        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : fieldsViewSchema(entity)
        let data = this.state.data
        if(fields.length) {
            data = selectFieldsFromObj( data,fields )
        }
        if( !Object.keys(data).length ) return <LoaderSingleComponent width="100%" />

        let fieldsProps = tableSchema(entity)
        const fields_info = this.state.fields_info
        if( Object.keys(fields_info).length ) {
            //merge fields_info e fieldsProps
            fieldsProps = mergeFieldsPropsAndFieldsInfo(fieldsProps,fields_info)
        }

        const actions = this.props.actions ? this.props.actions : null
        const TopActions = this.props.TopActions ? this.props.TopActions : null

        const dense = {dense: this.props.dense}
        const noTitle = this.props.hasOwnProperty('noTitle')
        const noCard = this.props.hasOwnProperty('noCard')
        const cardTitle = this.props.hasOwnProperty('cardTitle')
            ? this.props.cardTitle
            : entity+" #"+id_entity

        let cardProps = {}
        if( noTitle ) cardProps['noTitle'] = true
        if( noCard ) cardProps['noCard'] = true
        cardProps['Content'] = <TableOneRowTransposed
            material
            {...dense}
            hideHeaders
            rowObject={data}
            fieldsProps={fieldsProps}
        />
        if( TopActions ) cardProps['TopActions'] = TopActions
        if( actions ) cardProps['Actions'] = actions

        return <React.Fragment>
            <DashboardCard title={cardTitle} {...cardProps} />
        </React.Fragment>
    }
}

export default ViewEntity