import React, { Component } from 'react'
import { JSONToCSV } from '../../tables/services/api'
import IconButton from '@material-ui/core/IconButton'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'

class ExportCsvButton extends Component  {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data, //json
            title: this.props.title, //filename
            headers: this.props.headers, //true or false
            separator: this.props.separator, //, or ;
            decimalSeparator: this.props.decimalSeparator // . or ,
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props)
            this.setState({data: this.props.data})
    }

    render() {
        const Component = this.props.component
            ? this.props.component
            : IconButton

        return <Component
            {...this.props}
            onClick={() => JSONToCSV(
                this.state.data,
                this.state.title,
                this.state.headers,
                this.state.separator,
                this.state.decimalSeparator
                )}
        >
            <DescriptionOutlinedIcon />
        </Component>
    }
}

export default ExportCsvButton