import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AdapterLink from '../../links/modules/AdapterLink'

import UserAvatar from '../../auth/modules/UserAvatar'
import {usernameFromToken} from '../../auth/services/token'

const NavSideUserBoxStyles = theme => {
    return {
        navSideUserBoxContainerClass: theme.globals.navSideUserBoxContainerClass,
        navSideUserBox: theme.globals.navSideUserBox,
        navSideUserBoxNoUsername: theme.globals.navSideUserBoxNoUsername,
        navSideUserText: theme.globals.navSideUserText,
    }
}

function NavSideUserBox(props) {
    const showUsername = props.showUsername
    const username = usernameFromToken()
    const {classes} = props
    const navSideUserBoxClass = showUsername ? classes.navSideUserBox : classes.navSideUserBoxNoUsername

    return <Box className={classes.navSideUserBoxContainerClass}>
        <Box className={navSideUserBoxClass}>
        {showUsername
            ? <Grid container direction="row"
                spacing={1}
                alignItems="center"
                justify="flex-start"
            >
                <Grid item>
                    <UserAvatar component={AdapterLink} to={"/profile"} />
                </Grid>
                <Grid item>
                    <Typography variant="body2" component={AdapterLink} to={"/profile"} className={classes.navSideUserText} >
                        {username}
                    </Typography>
                </Grid>
            </Grid>
            : <UserAvatar component={AdapterLink} to={"/profile"} />}
    </Box>
    </Box>
}

export default withStyles(NavSideUserBoxStyles)(NavSideUserBox)