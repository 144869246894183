import React from 'react'

import WidgetCompany from './widgets/WidgetCompany'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'

class CardCompany extends React.Component {
    render() {
        const id_entity = this.props.id_entity
        const groups = groupsFromToken()
        const deleteBtn = groups.includes(String(4))
            ? false
            : (this.props.hasOwnProperty('deleteBtn')
                ? this.props.deleteBtn
                : true)

        return <WidgetCompany
            id_company={id_entity}
            dashboardcardTitle="Anagrafica"
            deleteBtn={deleteBtn}
        />
    }
}

export default CardCompany