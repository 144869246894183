import React from 'react'

import ViewEntity from './ViewEntity'
import Modal from '../../modals/modules/Modal'

class FieldEntityView extends React.Component {
    render() {
        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const defaultValue = this.props.hasOwnProperty('default')
            ? this.props.defaultValue
            : "-"
        if( !entity || !id_entity || id_entity <= 0 ) return defaultValue

        const title = this.props.title
        const icon = this.props.icon
        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : []
        const viewEntityActions = this.props.hasOwnProperty('actions') ? this.props.actions : null
        const tooltipText = this.props.tooltipText
        const tooltipPlacement = this.props.tooltipPlacement

        return <Modal
            fullWidth
            maxWidth="md"
            printCloseBtn
            title={title}
            content={<ViewEntity dense noTitle noCard
                entity={entity} id_entity={id_entity} fields={fields}
                actions={viewEntityActions}
            />}
            Trigger={icon}
            tooltipText={tooltipText}
            tooltipPlacement={tooltipPlacement}
        />
    }
}

export default FieldEntityView