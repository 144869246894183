import React from 'react'
import { withTheme } from '@material-ui/styles'

import Chip from '@material-ui/core/Chip'

class FieldLeadState extends React.Component {
    chooseStyle(state) {
        const theme = this.props.theme

        switch( state ) {
            case "managed":
                return {
                    color: "white",
                    backgroundColor: theme.palette.success[800]
                }

            case "assigned":
                return {
                    color: "white",
                    backgroundColor: theme.palette.success.main
                }

            default:
                return {
                    color: "white",
                    backgroundColor: theme.palette.warning.main
                }
        }
    }
    render() {
        const state = this.props.state
        const style = this.chooseStyle(state)

        return <Chip label={state} size="small" style={style} />
    }
}

export default withTheme(FieldLeadState)