import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import AddReport from '../modules/AddReport'

const CloseTaskPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id
    const currentUrl = props.route.location.pathname

    const precompiledData = {id_activity: id_entity}

    return <Content title={title}>
        <AddReport id_entity={0} url={currentUrl} precompiledData={precompiledData} />
    </Content>
}

export default CloseTaskPage