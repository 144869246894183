import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InboxIcon from '@material-ui/icons/Inbox'

class FieldIdLead extends React.Component {
    render() {
        const id_lead = this.props.id_lead
        const title = this.props.title ? this.props.title : "Scheda lead"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda lead"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        const id_activity = this.props.id_activity
        const activity_state = this.props.activity_state
        return  !id_lead || id_lead <= 0 || id_lead === '-'
            ? null
            : (id_lead > 0 && activity_state === 'closed'
                ? <IconButton disabled={true} style={{margin:0,padding:0}}>
                    <InboxIcon size="small" />
                </IconButton>
                : <FieldEntityView title={title} icon={InboxIcon}
                    id_entity={id_lead} entity="leads"
                    fields={[
                        'id_lead',
                        'lead_datetime',
                        'lead_subject',
                        'lead_body',
                        'id_agent',
                        'id_stakeholder'
                    ]}
                    actions={<React.Fragment>
                        <Button variant="contained" size="small" color="primary"
                            component={AdapterLink} to={'/leads/card/'+id_lead} target="_blank"
                        >Visualizza</Button>
                        {id_activity
                            ? <Button size="small"
                                variant="contained" color="primary"
                                component={AdapterLink} to={'/leads/manage/'+id_lead+'?id_activity='+id_activity+'&activity_state='+activity_state} target="_blank"
                            >Gestisci</Button>
                            : null}
                    </React.Fragment>}
                    tooltipText={tooltipText}
                    tooltipPlacement={tooltipPlacement}
                />)
    }
}

export default FieldIdLead