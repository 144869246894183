import React from 'react'

import {branches_list_practicals,branches_list_practicals_outers} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import Table from '../@react-fefmwk/tables/modules/Table'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import BlockIcon from '@material-ui/icons/Block'

class MyBranchPracticals extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals: [],
            refresh: false,
            closed: false,
        }
    }

    async componentDidMount() {
        const branches = this.props.branches
        const outers = this.props.outers
        const closed = this.state.closed
        const practicals = await this.getPracticals(branches,outers,closed)

        this.setState({
            practicals: practicals
        })
    }
    async componentDidUpdate(prevProps,prevState) {
        const branches = this.props.branches
        const outers = this.props.outers
        const closed = this.state.closed
        if( closed !== prevState.closed ) {
            const practicals = await this.getPracticals(branches,outers,closed)
            this.setState({
                practicals: practicals,
                refresh: false
            })
        }
    }
    async getPracticals(branches,outers,closed) {
        return outers
            ? await branches_list_practicals_outers(branches)
            : await branches_list_practicals(branches,closed)
    }

    handleChange(e,filter) {
        e.persist()

        this.setState({
            refresh: true,
            closed: filter === 'closed' ? e.target.checked : this.state.closed,
        })
    }

    render() {
        const practicals = this.state.practicals
        const outers = this.props.outers

        const entity = outers ? "practicals_outers" : "practicals"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        const refresh = this.state.refresh
        const closed = this.state.closed

        return <React.Fragment>
            {outers
                ? null
                : <Box mb={2}>
                    <Switch checked={closed} onChange={(e) => this.handleChange(e,'closed')} /> includi perfezionate
                </Box>}
            {practicals.length
                ? <Paper>
                    {!refresh
                        ? <Table material dense highlight
                            data={practicals}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                        : <LoaderSingleComponent />}
                </Paper>
                : <Chip icon={<BlockIcon />} label="Nessuna pratica assegnata" variant="outlined" />}
        </React.Fragment>
    }
}

export default MyBranchPracticals