import React from 'react'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import ExportCsvButton from '../@react-fefmwk/tables/modules/ExportCsvButton'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ContactsIcon from '@material-ui/icons/Contacts'
import EventIcon from '@material-ui/icons/Event'
import PaymentIcon from '@material-ui/icons/Payment'

import SyncLeadsBtn from '../modules/SyncLeadsBtn'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'
import {agencySchema} from '../services/AgencySchema'

export function addBtn( entity,path,s='',options = {} ) {
    const BtnComp = options.hasOwnProperty('component') ? options.component : Fab
    const color = options.hasOwnProperty('color') ? options.color : "primary"
    const size = options.hasOwnProperty('size') ? options.size : "small"
    const title = options.hasOwnProperty('title') ? options.title : ("Add new "+entity)
    const style = options.hasOwnProperty('style') ? options.style : {}
    return <React.Fragment>
        <Tooltip title={title}>
            <BtnComp component={AdapterLink} color={color} size={size} to={path + "/add" + s} style={style}>
                <AddIcon />
            </BtnComp>
        </Tooltip>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function exportCSVBtn( entity,path,data ) {
    const groups = groupsFromToken()
    return data === undefined || !data.length || groups.includes(String(4))
        ? null
        : <React.Fragment>
            <Tooltip title={"Download CSV "+entity}>
                <ExportCsvButton data={data} component={Fab} color="primary" size="small" />
            </Tooltip>
            &nbsp;&nbsp;
        </React.Fragment>
}
export function importCSVBtn( entity,path ) {
    return <React.Fragment>
        <Tooltip title={"Upload CSV "+entity}>
            <Fab component={AdapterLink} color="primary" size="small" to={path + "/upload-csv"}>
                <PublishIcon />
            </Fab>
        </Tooltip>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function addAddress() {
    return <React.Fragment>
        <Tooltip title={"Add new address"}>
            <Fab component={AdapterLink} color="primary" size="small" to={"/addresses/add"}>
                <LocationOnIcon />
            </Fab>
        </Tooltip>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function addContact() {
    return <React.Fragment>
        <Tooltip title={"Add new contact"}>
            <Fab component={AdapterLink} color="primary" size="small" to={"/contacts/add"}>
                <ContactsIcon />
            </Fab>
        </Tooltip>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function syncLeads() {
    return <SyncLeadsBtn />
}
export function toInsurancesQuotations() {
    return <React.Fragment>
        <Tooltip title={"Quotazioni assicurative"}>
            <Fab component={AdapterLink} color="primary" size="small" to={"/insurances_quotations"}>
                <PaymentIcon />
            </Fab>
        </Tooltip>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function toPracticalsEvents() {
    const groups = groupsFromToken()
    return (groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)))
        ? <React.Fragment>
            <Fab title={"Eventi pratiche"} component={AdapterLink} color="primary" size="small" to={"/practicals_histories"} target="_blank">
                <EventIcon />
            </Fab>
            &nbsp;&nbsp;
        </React.Fragment>
        : null
}

export function toolbarSchema( entity,path,data ) {
    const agencyObj = agencySchema()
    const groups = groupsFromToken()
    switch (entity) {
        case 'people':
            return [
                exportCSVBtn( entity,path,data ),
                //importCSVBtn( entity,path ),
                addBtn(entity,path),
                addAddress(),
                addContact(),
            ]
        case 'customers':
            return [
                exportCSVBtn( entity,path,data ),
            ]
        case 'practicals':
            return [
                exportCSVBtn( entity,path,data ),
                //importCSVBtn( entity,path ),
                addBtn(entity,path),
                toPracticalsEvents()
            ]
        case 'practicals_products':
        case 'practicals_histories':
            return [
                exportCSVBtn( entity,path,data ),
            ]
        case 'leads':
            const leads_fetch = agencyObj.hasOwnProperty('leads_fetch')
                ? agencyObj.leads_fetch
                : false
            return [
                exportCSVBtn( entity,path,data ),
                leads_fetch ? syncLeads() : null,
            ]
        case 'leads_channels':
            return [
                exportCSVBtn( entity,path,data ),
                groups.includes(String(1)) || groups.includes(String(2))
                    ? addBtn(entity,path)
                    : null,
            ]
        case 'atcs':
            return [
                exportCSVBtn( entity,path,data ),
                addBtn(entity,path),
                toInsurancesQuotations(),
            ]
        case 'atcs':
        case 'insurances':
            return [
                exportCSVBtn( entity,path,data ),
                groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5))
                    ? addBtn(entity,path)
                    : null,
                toInsurancesQuotations(),
            ]
        default:
            return [
                exportCSVBtn( entity,path,data ),
                //importCSVBtn( entity,path ),
                addBtn(entity,path),
            ]
    }
}