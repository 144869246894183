import React from 'react'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import {
    stringToDate,
    getThemeLocale,
    getDbDateFormat,
    getThemeDateFormat
} from '../../dates/services/api'

import FormHelperText from '@material-ui/core/FormHelperText'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

class FieldDate extends React.Component {
    formatDate(dt,dbFormat,displayFormat) {
        if(!dt) return null
        else {
            let converted = 0
            converted = stringToDate(dt,displayFormat)
            if( !Date.parse(converted) ) {
                converted = stringToDate(dt,dbFormat)
            }
            return converted
        }
    }
    setValueDate(dateString,dbFormat,displayFormat) {
        if(!dateString) return null
        let converted = stringToDate(dateString,displayFormat)
        if( Date.parse(converted) ) {
            return format(converted,dbFormat)
        }
        return dateString
    }
    render() {
        const formikProps = this.props.formikProps

        const locale = getThemeLocale()
        const dateFormat = getDbDateFormat()
        const dateFormatDisplay = this.props.hasOwnProperty('format')
            ? this.props.format
            : getThemeDateFormat()

        const disableToolbar = this.props.disableToolbar ? this.props.disableToolbar : true
        const variant = this.props.variant ? this.props.variant : ""
        let value = this.props.value === null ? '' : this.props.value
        const helperText = this.props.helperText

        value = this.formatDate(value,dateFormat,dateFormatDisplay)

        return <MuiPickersUtilsProvider utils={DateFnsUtils} locale={Object.keys(locale).length ? locale : null}>
            <KeyboardDatePicker
                disableToolbar={disableToolbar}
                variant={variant}
                format={dateFormatDisplay}
                margin="normal"
                id={this.props.id}
                name={this.props.name}
                error={this.props.error}
                disabled={this.props.disabled}
                label={this.props.label}
                placeholder={this.props.placeholder}
                value={value}
                onChange={(_,dateString) => {
                    dateString = this.setValueDate(dateString,dateFormat,dateFormatDisplay)
                    formikProps.setFieldValue(this.props.name,dateString)
                    formikProps.setFieldTouched(this.props.name,true,true)
                }}
                onBlur={this.props.onBlur}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                inputProps={this.props.inputProps}
            />
            <FormHelperText component="div">{helperText}</FormHelperText>
        </MuiPickersUtilsProvider>
    }
}

export default FieldDate