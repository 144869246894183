import React from "react"

import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExternalLink from '../../links/modules/ExternalLink'
import AdapterLink from '../../links/modules/AdapterLink'
import OpenIcon from '@material-ui/icons/OpenInNew'

class TableActions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.id,
            rowActions: this.props.rowActions,
            currentId: null,
            anchorEl: null,
            open: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.menuItems = this.menuItems.bind(this)
    }
    handleClose() {
        this.setState({currentId:null,anchorEl:null,open:false})
    }
    handleClick(e,id) {
        this.setState({currentId:id,anchorEl:e.currentTarget,open:true})
    }
    menuItems() {
        const menuItemStyle={backgroundColor:'white'}

        const rowActions = this.props.rowActions
        const copyRowActions = [...rowActions]//make a copy without reference

        let menuItems = []
        copyRowActions.map((rowAction,r) => {
            const copyrowAction = Object.assign({},rowAction)//make a copy without reference
            const action = copyrowAction.action
            const header = copyrowAction.header
            const Icon = copyrowAction.icon ? copyrowAction.icon : OpenIcon
            const link = copyrowAction.link
            const linktype = copyrowAction.linktype
            const target = copyrowAction.target
            const disabled = copyrowAction.disabled

            const Component = linktype === 'external' ? ExternalLink : AdapterLink

            menuItems.push(<MenuItem key={action+"-"+r} component={Component} to={link} target={target} style={menuItemStyle} disabled={disabled} onClick={() => this.handleClose()}>
                <ListItemIcon><Icon fontSize="small" /></ListItemIcon>
                <ListItemText>{header}</ListItemText>
            </MenuItem>)

            return true
        })

        return menuItems
    }
    render() {
        const id = this.props.id
        const open = this.state.open
        const anchorEl = this.state.anchorEl
        const menuItems = this.menuItems()
        const copyMenuItems = [...menuItems]

        const key = "table-action-"+id
        const buttonId = key+"-button"
        const menuId = key+"-menu"

        return <React.Fragment key={key}>
            {id === 0
                ? null
                : <React.Fragment>
                    <IconButton id={buttonId} style={{padding:0}}
                        onClick={(e) => this.handleClick(e,id)}
                        aria-controls={menuId}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu open={open}
                        id={menuId}
                        anchorEl={anchorEl}
                        onClose={() => this.handleClose()}
                    >
                        {copyMenuItems}
                    </Menu>
                </React.Fragment>}
        </React.Fragment>
    }
}

export default TableActions