import React from 'react'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import PostponeTask from '../modules/PostponeTask'

class PostponeTaskPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        const queryString = this.props.route.location.search
        let queryStringObj = {}
        if( queryString ) queryStringObj = queryStringToObj(queryString)
    
        return <Content title={title}>
            <PostponeTask id_entity={id_entity} precompiledData={queryStringObj} />
        </Content>
    }
}

export default PostponeTaskPage