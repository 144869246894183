import { getEP,doCall } from '../../requests/api'
import { headerTokenKey,getToken } from '../../auth/services/token'
import {idFromToken,groupsFromToken} from '../../auth/services/token'

export function clear_nulls( data ) {
    Object.keys(data).forEach(field => {
        data[field] = data[field] === null ? "" : data[field]
    })
    return data
}
export function apply_defaults( fields_info ) {
    let form = {}
    Object.keys(fields_info).forEach(field => {
        if( fields_info[field].hasOwnProperty('default') )
            form[field] = fields_info[field].default
                ? fields_info[field].default
                : ""
    })
    return form
}

export async function entity_q( entity,Q,options = {} ) {
    const func = entity + "_q"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            Q
        })
    },'json.array',options)
}
export async function entity_q_timeinfo( entity,Q,options = {} ) {
    const func = entity + "_q_timeinfo"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            Q
        })
    },'json.array',options)
}

export async function entity_list( entity,options = {} ) {
    const func = entity + "_list"
    let callUrl = getEP( func )
    if( !callUrl ) return []

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',options)
}
export async function entity_list_timeinfo( entity,cf = '',date = '',options = {} ) {
    const func = entity + "_list_timeinfo"
    let callUrl = getEP( func )
    if( !callUrl ) return []

    if( cf ) callUrl += '?cf='+cf
    if( date ) callUrl += '&date='+date

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',options)
}
export async function entity_get( entity,id_entity,fields = '',options = {} ) {
    const func = entity + "_get"
    let callUrl = getEP( func )
    if( !callUrl ) return []

    callUrl = callUrl+'/'+id_entity
    if( fields ) callUrl += '?fields='+fields

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',options)
}
export async function entity_get_timeinfo( entity,id_entity,cf = '',options = {} ) {
    const func = entity + "_get_timeinfo"
    let callUrl = getEP( func )
    if( !callUrl ) return []

    callUrl = callUrl+'/'+id_entity+'/timeinfo'
    if( cf ) callUrl += '?cf='+cf

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',options)
}

export async function entity_create( entity,data,referenced = false,options = {} ) {
    const func = entity + "_create"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            data,referenced
        })
    },'json.object',options)
}

export async function entity_insert_timeinfo( entity,data,referenced = false,options = {} ) {
    const func = entity + "_insert_timeinfo"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            data,referenced
        })
    },'numeric',options)
}

export async function entity_update( entity,id_entity,data,referenced = false,options = {} ) {
    const func = entity + "_update"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    callUrl = callUrl + '/' + id_entity

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            data,referenced
        })
    },'numeric',options)
}

export async function entity_delete( entity,id_entity,options = {} ) {
    const func = entity + "_delete"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    callUrl = callUrl + '/' + id_entity

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken(),
        }
    },'numeric',options)
}
export async function entity_delete_info( entity,id_entity,cf,options = {} ) {
    const func = entity + "_delete_info"
    let callUrl = getEP( func )
    if( !callUrl ) return false

    callUrl = callUrl + '/' + id_entity + '/info?cf=' + cf

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken(),
        }
    },'numeric',options)
}

export async function entity_fields_info( entity,options = {} ) {
    const func = entity + "_fields"
    let callUrl = getEP( func )
    if( !callUrl ) return {}

    callUrl = callUrl

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken(),
        }
    },'json.object',options)
}
export async function entity_fields_cfstime( entity,options = {} ) {
    const func = entity + "_fields_cfstime"
    let callUrl = getEP( func )
    if( !callUrl ) return {}

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken(),
        }
    },'json.object',options)
}
export async function cfs_list( entity,options = {} ) {
    let callUrl = getEP( "cfs_list" )
    callUrl = callUrl+'?entity='+entity

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',options)
}
export async function cfstime_list( entity,options = {} ) {
    let callUrl = getEP( "cfstime_list" )
    callUrl = callUrl+'?entity='+entity

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array',options)
}
export async function row_get( db,table,id_field,id_row,options = {} ) {
    let callUrl = getEP( "row_get" )
    callUrl = callUrl+'?db='+db+'&table='+table+'&id_field='+id_field+'&id_row='+id_row

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object',options)
}
export async function row_insert( data,db,table,options = {} ) {
    let callUrl = getEP( "row_insert" )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            data,db,table
        })
    },'numeric',options)
}
export async function row_update( data,db,table,id_field,id_row,options = {} ) {
    let callUrl = getEP( "row_update" )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            data,db,table,id_field,id_row
        })
    },'numeric',options)
}
export async function row_delete( db,table,id_field,id_row,options = {} ) {
    let callUrl = getEP( "row_delete" )
    callUrl = callUrl+'?db='+db+'&table='+table+'&id_field='+id_field+'&id_row='+id_row

   return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'numeric',options)
}
export function mergeFieldsPropsAndFieldsInfo(fieldsProps,fields_info) {
    if( Object.keys(fields_info).length ) {
        for (const fld in fields_info) {
            fieldsProps[fld] = {
                ...fields_info[fld],
                ...(fieldsProps.hasOwnProperty(fld) ? fieldsProps[fld] : {})
            }
        }
    }

    return fieldsProps
}

export function checkPrivilegies( entityObj,privilege ) {
    const groups_current_user = groupsFromToken()
    const id_current_user = idFromToken()

    const privilegies = entityObj.hasOwnProperty('privilegies') ? entityObj.privilegies : {}
    if( Object.keys(privilegies).length ) {

        const privilegeObj = privilegies.hasOwnProperty(privilege) ? privilegies[privilege] : {}
        if( Object.keys(privilegeObj).length ) {
            const users_can = privilegeObj.hasOwnProperty('users_can') ? privilegeObj.users_can : []
            const groups_can = privilegeObj.hasOwnProperty('groups_can') ? privilegeObj.groups_can : []
        
            const checkGroups = groups_current_user.filter(id_group => groups_can.includes(parseInt(id_group)))
            if( users_can.length && !users_can.includes( parseInt(id_current_user) ) ) return false
            if( groups_can.length && !checkGroups.length ) return false
        }

    }

    return true
}
