import React from 'react'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'
import DashboardCard from '../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'

import WidgetPracticalFiles from './widgets/WidgetPracticalFiles'
import WidgetStakeholderFiles from './widgets/WidgetStakeholderFiles'

import { EntitySchema } from '../services/EntitySchema'
import { tableSchema } from '../services/EntityTableSchema'
import {entity_get} from '../@react-fefmwk/entity/services/api'
import {
    practicals_get_history
} from '../services/BE'
import {actionSchema} from '../services/EntityRowActionsSchema'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'
//import DeleteIcon from '@material-ui/icons/Delete'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { groupsFromToken } from '../@react-fefmwk/auth/services/token'

class CardPractical extends React.Component {
    constructor(props) {
        super(props)

        const entity = 'practicals'
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field
        //const entity_priv = entityObj.privilegies

        this.state = {
            entity: entity,
            id_field: id_field,
            id_entity: this.props.id_entity,
            //entity_priv: entity_priv,
            practical: {},
            practical_history: [],
        }
    }

    async componentDidMount() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity

        this.setState({
            practical: await entity_get(entity,id_entity),
            practical_history: await practicals_get_history(id_entity),
        })
    }

    render() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        //const entity_priv = this.state.entity_priv
        const practical = this.state.practical
        if( !Object.keys(practical).length ) return <LoaderSingleComponent width="100%" />

        const groups = groupsFromToken()
        const isManager = [1,2,5].filter(v => groups.includes(String(v))).length ? true : false

        const renewal_date = practical.renewal_date+" 00:00:00"
        const id_atc = practical.hasOwnProperty("id_atc") ? practical.id_atc : 0
        const id_stakeholder = practical.hasOwnProperty("id_stakeholder") ? practical.id_stakeholder : 0
        const is_obscured = practical.hasOwnProperty("is_obscured") ? practical.is_obscured : false
        const priority = practical.hasOwnProperty("priority")
            ? (practical.priority===null ? 0 : practical.priority)
            : 0

        const can_update = isManager ? true : ((priority >= 4 || is_obscured) ? false : true)
        const can_delete = isManager ? true : false

        const practical_history = this.state.practical_history
        const practical_historyFieldsProps = tableSchema('activities')
        const practical_historyRowActions = actionSchema('activities')

        return <Tabber basicname="practicalcard"
            tabs={[
                {
                    slug: "info",
                    label: "info",
                    content: <React.Fragment>
                        {is_obscured
                            ? <Box mb={1}><Chip label="Non hai i privilegi per gestire gli eventi di questa pratica" variant="outlined" /></Box>
                            : null}
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <ViewEntity dense noCard
                                    entity={entity}
                                    id_entity={id_entity}
                                    fields={[
                                        //'id_practical',
                                        'practical_code',
                                        'product',
                                        //'id_stakeholder',
                                        'stakeholder',
                                        'practical_state',
                                        'agent','agencies_branches',
                                        'notes',
                                        'origination',
                                        //'id_lender',
                                        'lender',
                                        //'id_atc',
                                        'atc','insurance_quotation',
                                        'load_date','effective_date','renewal_date','renewal_date_second',
                                        'disbursed_amount','installments_n','installment_amount','compounding_amount',
                                        'commission','tan','taeg',
                                        'estinzione'
                                    ]}
                                    TopActions={is_obscured
                                        ? {}
                                        : <React.Fragment>
                                            <Button variant="text" size="small" color="primary"
                                                startIcon={<CloudUploadIcon />}
                                                component={AdapterLink} to={'/practicals/card/'+id_entity+'#documents'}
                                            >Documenti</Button>&nbsp;&nbsp;
                                            <Button variant="text" size="small" color="primary"
                                                startIcon={<AddIcon />}
                                                component={AdapterLink} to={'/tasks/add?id_practical='+id_entity}
                                            >Task</Button>&nbsp;&nbsp;
                                            <React.Fragment>
                                                <Button variant="text" size="small"
                                                    disabled={!can_update}
                                                    component={AdapterLink} to={'/practicals/update/'+id_entity}
                                                >Modifica</Button>&nbsp;&nbsp;
                                            </React.Fragment>
                                            <React.Fragment>
                                                <Button variant="text" size="small"
                                                    disabled={!can_delete}
                                                    //startIcon={<DeleteIcon />}
                                                    component={AdapterLink} to={'/practicals/delete/'+id_entity}
                                                >Elimina</Button>
                                            </React.Fragment>
                                        </React.Fragment>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {id_atc
                                    ? <Box mb={2}>
                                        <ViewEntity dense
                                            entity="atcs"
                                            id_entity={id_atc}
                                            //cardTitle={dashboardcardTitle}
                                            TopActions={<React.Fragment>
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/atcs/card/"+id_atc}
                                                >Visualizza</Button>&nbsp;&nbsp;
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/atcs/card/"+id_atc+"#quotations"}
                                                >Quotazioni</Button>&nbsp;&nbsp;
                                                <Button
                                                    variant="text" size="small"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/atcs/update/"+id_atc}
                                                >Modifica</Button>
                                            </React.Fragment>}
                                        />
                                    </Box>
                                    : null}
                                {id_stakeholder
                                    ? <Box mb={2}>
                                        <ViewEntity dense
                                            entity="stakeholders"
                                            id_entity={id_stakeholder}
                                            //cardTitle={dashboardcardTitle}
                                            fields={[
                                                'id_stakeholder','stakeholder',
                                                'main_mobile','vat_number','cf',
                                                'practicals_n','last_practical_at',
                                                'recent_agents','agencies_branches','products'
                                            ]}
                                            TopActions={<React.Fragment>
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/stakeholders/card/"+id_stakeholder}
                                                >Visualizza</Button>&nbsp;&nbsp;
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/stakeholders/card/"+id_stakeholder+"#practicals"}
                                                >Pratiche</Button>
                                            </React.Fragment>}
                                        />
                                    </Box>
                                    : null}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                },
                {
                    slug: "history",
                    label: "eventi",
                    content: is_obscured
                        ? <Chip label="Non hai i privilegi per gestire gli eventi di questa pratica" variant="outlined" />
                        : <DashboardCard title="Eventi della pratica"
                            Content={practical_history.length
                                ? <Table material
                                    hideHeaders
                                    defaultPageSize="100"
                                    data={practical_history}
                                    fieldsProps={{
                                        ...practical_historyFieldsProps,
                                        ...{id_practical:{show:false}}
                                    }}
                                    rowActions={practical_historyRowActions}
                                    rowActionsField="id_activity"
                                />
                                : <Chip icon={<ErrorIcon />} label="No history found" variant="outlined" />}
                            TopActions={<React.Fragment>
                                <Button variant="text" size="small" color="primary"
                                    startIcon={<AddIcon />}
                                    component={AdapterLink} to={'/tasks/add?id_practical='+id_entity}
                                >Task</Button>
                                <Button size="small" variant="text" color="primary"
                                    startIcon={<AddIcon />}
                                    component={AdapterLink} to={'/reports/add?id_practical='+id_entity}
                                >Report</Button>
                                <Button size="small" variant="text" color="primary"
                                    startIcon={<AddIcon />}
                                    component={AdapterLink} to={'/tasks/add?id_practical='+id_entity+'&id_activity_order=23&active_at='+renewal_date}
                                >Memo rinnovo</Button>
                                <Button size="small" variant="text" color="primary"
                                    startIcon={<AddIcon />}
                                    component={AdapterLink} to={'/tasks/add?id_practical='+id_entity+'&event'}
                                >evento</Button>
                            </React.Fragment>}
                        />
                },
                {
                    slug: "documents",
                    label: "documenti",
                    content: is_obscured
                        ? <Chip label="Non hai i privilegi per gestire i documenti di questa pratica" variant="outlined" />
                        : <React.Fragment>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography component="h4" variant="subtitle2">Documenti della pratica</Typography>
                                    <br />
                                    <WidgetPracticalFiles id_practical={id_entity} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography component="h4" variant="subtitle2">Documenti del contatto</Typography>
                                    <br />
                                    <WidgetStakeholderFiles id_stakeholder={id_stakeholder} />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                },
            ]}
        />
    }
}

export default CardPractical