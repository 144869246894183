import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {atcs_get_practicals} from '../../services/BE'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'

import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

class WidgetAtcPracticals extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals: [],
        }
    }
    
    async updatePracticals() {
        const id_atc = this.props.id_atc
        if( id_atc ) {
            this.setState({
                practicals: await atcs_get_practicals(id_atc),
            })
        }
    }
    
    async componentDidMount() {
        this.updatePracticals()
    }
    
    async componentDidUpdate(prevProps) {
        const id_atc = this.props.id_atc
        if( id_atc !== prevProps.id_atc )
            this.updatePracticals()
    }

    render() {
        const practicals = this.state.practicals

        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Practicals"

        const practicalsRowActions = actionSchema('practicals')
        const fieldsProps = tableSchema('practicals')

        const cardProps = {}
        cardProps['Content'] = practicals.length
            ? <Table material dense
                data={practicals}
                fieldsProps={fieldsProps}
                rowActions={practicalsRowActions}
                rowActionsField="id_practical"
                defaultPageSize={15}
                showPagination={true}
            />
            : <Chip icon={<ErrorIcon />} label="Nessuna pratica disponibile" variant="outlined" />

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetAtcPracticals