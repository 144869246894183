import React from 'react'

import SnackbarMessage from '../../messages/modules/SnackbarMessage'
import ViewEntity from './ViewEntity'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'

import { entity_get,entity_delete } from '../services/api'
import { EntitySchema } from '../../../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorIcon from '@material-ui/icons/Error'


class DeleteEntity extends React.Component{

    constructor(props) {
        super(props)

        const id_entity = this.props.id_entity
        const entity = this.props.entity
        const entityObj = EntitySchema[entity]
        this.state = {
            entity: entity,
            entityObj: entityObj,
            id_entity: id_entity,
            data: [],
            submit: false,
            result: false,
            data: {},
            hideForm: false,
            hideFormMsg: "Rendering form is not allowed",
            withViewState: true
        }

        this.onClick = this.onClick.bind(this)
    }

    async componentDidMount() {
        const id_entity = this.state.id_entity
        const entity = this.state.entity
        const data = await entity_get(entity,id_entity)
        const onGetData = this.props.hasOwnProperty('onGetData')
            ? this.props.onGetData
            : () => {}
        this.setState({
            ...{data: data},
            ...onGetData(entity,id_entity,data)})
    }

    onClick() {
        this.deleteEntity()
    }
    
    async deleteEntity() {
        const deletion = await entity_delete(this.state.entity,this.state.id_entity)
        this.setState({
            submit: true,
            result: deletion
        })
    }

    render() {
        const hideForm = this.state.hideForm
        const hideFormMsg = this.state.hideFormMsg
        if( hideForm ) return <Chip label={hideFormMsg} />

        const data = this.state.data
        if( !Object.keys(data).length ) return <LoaderSingleComponent width="100%" />

        const submit = this.state.submit
        const result = this.state.result
        const entity = this.state.entity
        const entityObj = this.state.entityObj
        if( !entity || !entityObj || !Object.keys(entityObj).length )
            return <Chip icon={<ErrorIcon />} label="Unmanaged entity" variant="outlined" />

        const id_entity = this.state.id_entity

        //eventually handled from outside to control ViewEntity
        //similar to withView prop
        const withViewState = this.state.withViewState

        const onDeletionOk_f = this.props.hasOwnProperty('onDeletionOk')
            ? this.props.onDeletionOk
            : () => true

        const onDeletionOk = (submit && result)
            ? onDeletionOk_f
            : () => true

        const withView = this.props.hasOwnProperty('withView') ? this.props.withView : true
        const withBtnSubmit = this.props.hasOwnProperty('withBtnSubmit') ? this.props.withBtnSubmit : true
        const submitDisabled = this.props.hasOwnProperty('submitDisabled') ? this.props.submitDisabled : false
        const textBtnSubmit = this.props.hasOwnProperty('textBtnSubmit') ? this.props.textBtnSubmit : 'Delete'
        const txtOnDeletionOk = this.props.hasOwnProperty('txtOnDeletionOk') ? this.props.txtOnDeletionOk : 'Item correctly deleted'
        const txtOnDeletionError = this.props.hasOwnProperty('txtOnDeletionError') ? this.props.txtOnDeletionError : 'Item not correctly deleted'

        return <React.Fragment>
            {withView && withViewState
                ? <React.Fragment>
                    <Box mb={2}>
                        <ViewEntity
                            entity={entity}
                            id_entity={id_entity}
                        />
                    </Box>
                </React.Fragment>
                : null}
            {withBtnSubmit
                ? <Button variant="contained" color="primary" size="small"
                    startIcon={<DeleteIcon fontSize="small" />}
                    disabled={submitDisabled}
                    onClick={this.onClick}
                >{textBtnSubmit}</Button>
                : null}
            {submit && result
                ? <SnackbarMessage
                    open={true}
                    message={txtOnDeletionOk}
                    severity={"success"}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.setState({
                            submit: false
                        })
                        onDeletionOk()
                    }}
                />
                : null
            }
            {submit && !result
                ? <SnackbarMessage
                    open={true}
                    message={txtOnDeletionError}
                    severity={"error"}
                />
                : null
            }
        </React.Fragment>
    }
}

export default DeleteEntity