import React from 'react'

import {
    users_get_renewals,
    users_get_renewals_stats
} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
//import { actionSchema } from '../services/EntityRowActionsSchema'
//import { EntitySchema } from '../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import Table from '../@react-fefmwk/tables/modules/Table'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import ScoreCard from '../@react-fefmwk/cards/modules/ScoreCard'

import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'

class MyRenewals extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            renewals: [],
            renewals_stats: {},
            refresh: false,
            closed: false,
            next_month: true,
            feedback_no: false,
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        const closed = this.state.closed
        const next_month = this.state.next_month
        const feedback_no = this.state.feedback_no
        const renewals_stats = await users_get_renewals_stats(id_user,closed,false,next_month,feedback_no)
        const renewals = await users_get_renewals(id_user,closed,false,next_month,feedback_no)

        this.setState({
            renewals_stats: renewals_stats,
            renewals: renewals
        })
    }

    async componentDidUpdate(prevProps,prevState) {
        const id_user = this.state.id_user
        const closed = this.state.closed
        const next_month = this.state.next_month
        const feedback_no = this.state.feedback_no
        if( closed !== prevState.closed || next_month !== prevState.next_month || feedback_no !== prevState.feedback_no ) {
            this.setState({
                renewals_stats: await users_get_renewals_stats(id_user,closed,false,next_month,feedback_no),
                renewals: await users_get_renewals(id_user,closed,false,next_month,feedback_no),
                refresh: false
            })
        }
    }

    handleChange(e,filter) {
        e.persist()

        const closed = filter === 'closed' ? true : false
        const next_month = filter === 'next_month' ? true : false
        const feedback_no = filter === 'feedback_no' ? true : false
        this.setState({
            refresh: true,
            closed: closed ? e.target.checked : (feedback_no ? false : this.state.closed),
            next_month: next_month ? e.target.checked : this.state.next_month,
            feedback_no: feedback_no ? e.target.checked : (closed ? false : this.state.feedback_no),
        })
    }

    render() {
        const renewals_stats = this.state.renewals_stats
        const renewals = this.state.renewals

        const entity = "tasks"
        //const entityObj = EntitySchema[entity]
        //const path = entityObj.rootpath
        //const id_field = entityObj.id_field
        const fieldsProps = {
            ...tableSchema(entity),
            ...{agent: {show: false}}
        }
        //const rowActions = actionSchema(entity,path)

        const refresh = this.state.refresh
        const closed = this.state.closed
        const next_month = this.state.next_month
        const feedback_no = this.state.feedback_no

        const stats_label = {
            managed: "in agenda",
            total: "rinnovi totali",
            unmanaged: "non in agenda"
        }

        return <React.Fragment>
            <Box mb={2}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs="auto">
                        <Switch checked={closed} onChange={(e) => this.handleChange(e,'closed')} /> solo rinnovi chiusi
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={next_month} onChange={(e) => this.handleChange(e,'next_month')} /> includi mese successivo
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={feedback_no} onChange={(e) => this.handleChange(e,'feedback_no')} /> con feedback negativo
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    {Object.keys(renewals_stats).length && !refresh
                        ? <React.Fragment>
                            {Object.keys(renewals_stats).map((key,i) => {
                                const label = stats_label.hasOwnProperty(key) ? stats_label[key] : key
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+label} value={renewals_stats[key]} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                </Grid>
            </Box>
            {renewals.length
                ? <Paper>
                    {!refresh
                        ? <Table material dense highlight
                            data={renewals}
                            fieldsProps={fieldsProps}
                            //rowActions={rowActions}
                            //rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                        : <LoaderSingleComponent width="100%" />}
                </Paper>
                : <Chip icon={<SentimentSatisfiedIcon />} label="Nessun rinnovo da effettuare" variant="outlined" />}
        </React.Fragment>
    }
}

export default MyRenewals