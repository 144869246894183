import React from 'react'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import StatsAgentsForm from '../modules/StatsAgentsForm'

import { Chip } from '@material-ui/core'

class CardAgencyBranchPage extends React.Component {
    render() {
        const title = this.props.route.title
        const branches = this.props.route.computedMatch.params.slug

        return <Content title={title} toolbar={<Chip label={branches} variant="outlined" size="small" />}>
            <StatsAgentsForm branches={branches} />
        </Content>
    }
}

export default CardAgencyBranchPage