import LoginPage from '../@react-fefmwk/theme-structure/modules/layouts/LoginPage'
import StandardPage from '../@react-fefmwk/theme-structure/modules/layouts/StandardPage'
//import StandardPageNoHeader from '../@react-fefmwk/theme-structure/modules/layouts/StandardPageNoHeader'
import StandardPageNoNavSideMenu from '../@react-fefmwk/theme-structure/modules/layouts/StandardPageNoNavSideMenu'
import FullWidthPage from '../@react-fefmwk/theme-structure/modules/layouts/FullWidthPage'
import EmbedPage from '../@react-fefmwk/theme-structure/modules/layouts/EmbedPage'

import Page404 from '../@react-fefmwk/theme-structure/modules/404'
import Page401 from '../@react-fefmwk/theme-structure/modules/401'
import EntityPage from '../@react-fefmwk/entity/modules/pages/EntityPage'
import EntityPageView from '../@react-fefmwk/entity/modules/pages/EntityPageView'
import EntityPageForm from '../@react-fefmwk/entity/modules/pages/EntityPageForm'
//import EntityPageUploadCSV from '../@react-fefmwk/entity/modules/pages/EntityPageUploadCSV'
import EntityPageDelete from '../@react-fefmwk/entity/modules/pages/EntityPageDelete'
//import EntityPageTimeinfoForm from '../@react-fefmwk/entity/modules/pages/EntityPageTimeinfoForm'
//import EntityPageTimeinfoDelete from '../@react-fefmwk/entity/modules/pages/EntityPageTimeinfoDelete'

import Login from '../pages/Login'
import ProfilePage from '../pages/ProfilePage'
import Settings from '../pages/Settings'

import DashboardPage from '../pages/DashboardPage'
import DashboardAgentsPage from '../pages/DashboardAgentsPage'
import DashboardAgenciesBranchesPage from '../pages/DashboardAgenciesBranchesPage'
/*import DashboardCRM from '../pages/DashboardCRM'
import DashboardLeads from '../pages/DashboardLeads'
import DashboardPracticals from '../pages/DashboardPracticals'
import DashboardCalendar from '../pages/DashboardCalendar'
import DashboardAssignments from '../pages/DashboardAssignments'
import DashboardTasks from '../pages/DashboardTasks'*/
import AddCompanyPage from '../pages/AddCompanyPage'
import AddAddressPage from '../pages/AddAddressPage'
import UpdateAddressPage from '../pages/UpdateAddressPage'
import AddContactPage from '../pages/AddContactPage'
import UpdateContactPage from '../pages/UpdateContactPage'
import CardPeoplePage from '../pages/CardPeoplePage'
import CardAtcPage from '../pages/CardAtcPage'
import CardInsurancePage from '../pages/CardInsurancePage'
import CardStakeholderPage from '../pages/CardStakeholderPage'
import CardAgentPage from '../pages/CardAgentPage'
import CardAgencyBranchPage from '../pages/CardAgencyBranchPage'
import CardPracticalPage from '../pages/CardPracticalPage'
import CardPracticalOuterPage from '../pages/CardPracticalOuterPage'
import CardLeadPage from '../pages/CardLeadPage'
import AddStakeholderPage from '../pages/AddStakeholderPage'
import ManageLeadPage from '../pages/ManageLeadPage'
import AssignLeadPage from '../pages/AssignLeadPage'
import AddPracticalPage from '../pages/AddPracticalPage'
import UpdatePracticalPage from '../pages/UpdatePracticalPage'
import AddReportPage from '../pages/AddReportPage'
import UpdateReportPage from '../pages/UpdateReportPage'
import DeleteReportPage from '../pages/DeleteReportPage'
import CardReportPage from '../pages/CardReportPage'
import AddTaskPage from '../pages/AddTaskPage'
//import UpdateTaskPage from '../pages/UpdateTaskPage'
import PostponeTaskPage from '../pages/PostponeTaskPage'
import CardTaskPage from '../pages/CardTaskPage'
import CloseTaskPage from '../pages/CloseTaskPage'
import MyBranchPage from '../pages/MyBranchPage'
import MyBranchStakeholdersPage from '../pages/MyBranchStakeholdersPage'
import MyBranchPracticalsPage from '../pages/MyBranchPracticalsPage'
import MyBranchAgentsPage from '../pages/MyBranchAgentsPage'
import MyTasksPage from '../pages/MyTasksPage'
import MyLeadsPage from '../pages/MyLeadsPage'
import MyReportsPage from '../pages/MyReportsPage'
import MyStakeholdersPage from '../pages/MyStakeholdersPage'
import MyAssignmentsPage from '../pages/MyAssignmentsPage'
import MyPracticalsPage from '../pages/MyPracticalsPage'
import PracticalsCommissionsPage from '../pages/PracticalsCommissionsPage'
import UpdatePracticalCommissionPage from '../pages/UpdatePracticalCommissionPage'
import WikiPage from '../pages/WikiPage'
import SharedDocsPage from '../pages/SharedDocsPage'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'

const groups = groupsFromToken()
const LayoutPage = groups.includes(String(4))
    ? StandardPageNoNavSideMenu
    : StandardPage

export const AppSchema = {
    "specific": {
        "matchRules": ["/",],
        "layout": LoginPage,
        "routes": [
            {
                "slug": "login",
                "visibility": "public",
                "path": "/",
                "title": "Login",
                "component": Login,
                "users_can": [],
                "groups_can": [],
            },
        ],
    },
    "embed": {
        "matchRules": [
            "/embed/practicals/update/commission/:id",
            "/embed/:entity/add",
            "/embed/:entity/update/:id",
        ],
        "layout": EmbedPage,
        "routes": [
            {
                "slug": "embed-practicals-update-commission-id",
                "visibility": "private",
                "path": "/embed/practicals/update/commission/:id",
                "component": UpdatePracticalCommissionPage,
                "users_can": [],
                "groups_can": [1,2],
            },
            {
                "slug": "embed-companies-add",
                "visibility": "private",
                "path": "/embed/companies/add",
                "component": AddCompanyPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "embed-addresses-add",
                "visibility": "private",
                "path": "/embed/addresses/add",
                "component": AddAddressPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "embed-contacts-add",
                "visibility": "private",
                "path": "/embed/contacts/add",
                "component": AddContactPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "embed-entity-add",
                "visibility": "private",
                "path": "/embed/:entity/add",
                "component": EntityPageForm,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "entity-update-id",
                "visibility": "private",
                "path": "/embed/:entity/update/:id",
                "component": EntityPageForm,
                "users_can": [],
                "groups_can": [],
                "props": {
                    onGetData: (entity,id_entity,data) => {
                        if(data.hasOwnProperty('is_obscured') && data.is_obscured !== false)
                            return {hideForm: true,hideFormMsg:"Non hai i permessi per gestire il form"}
                    }
                },
            },
        ],
    },
    "fullwidthpage": {
        "matchRules": [
            "/wiki","/shared-docs",
            "/practicals_histories",
            "/insurances_quotations",
            "/agencies-branch/card/:slug",
            "/:entity/add","/:entity/view/:id","/:entity/update/:id","/:entity/delete/:id","/:entity/upload-csv","/:entity/card/:id",
            "/:entity/manage/:id",
            "/:entity/close/:id","/:entity/assign/:id",
            "/tasks/postpone/:id",
            "/:entity/:entity_det/add","/:entity/:entity_det/view/:id","/:entity/:entity_det/update/:id","/:entity/:entity_det/delete/:id","/:entity/:entity_det/upload-csv","/:entity/:entity_det/card/:id",
        ],
        "layout": FullWidthPage,
        "routes": [
            {
                "slug": "wiki",
                "visibility": "private",
                "path": "/wiki",
                "title": "Wiki",
                "component": WikiPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "shared-docs",
                "visibility": "private",
                "path": "/shared-docs",
                "title": "Modulistica",
                "component": SharedDocsPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "practicals-histories",
                "visibility": "private",
                "path": "/practicals_histories",
                "title": "Eventi pratiche",
                "component": EntityPage,
                "props": {"entity":"practicals_histories"},
                "users_can": [],
                "groups_can": [1,2,5],
            },
            {
                "slug": "people-card-id",
                "visibility": "private",
                "path": "/people/card/:id",
                "title": "Scheda persona",
                "component": CardPeoplePage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "atc-card-id",
                "visibility": "private",
                "path": "/atcs/card/:id",
                "title": "Scheda ATC",
                "component": CardAtcPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "insurance-id",
                "visibility": "private",
                "path": "/insurances/card/:id",
                "title": "Scheda assicurazione",
                "component": CardInsurancePage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "insurances-quotations",
                "visibility": "private",
                "path": "/insurances_quotations",
                "title": "Quotazioni assicurative",
                "component": EntityPage,
                "props": {"entity":"insurances_quotations"},
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "stakeholders-card-id",
                "visibility": "private",
                "path": "/stakeholders/card/:id",
                "title": "Scheda contatto",
                "component": CardStakeholderPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "customers-card-id",
                "visibility": "private",
                "path": "/customers/card/:id",
                "title": "Scheda contatto",
                "component": CardStakeholderPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "agents-card-id",
                "visibility": "private",
                "path": "/agents/card/:id",
                "title": "Panoramica collaboratore",
                "component": CardAgentPage,
                "users_can": [],
                "groups_can": [1,2,6],
            },
            {
                "slug": "agencies-branch-card-slug",
                "visibility": "private",
                "path": "/agencies-branch/card/:slug",
                "title": "Scheda sede",
                "component": CardAgencyBranchPage,
                "users_can": [],
                "groups_can": [1,2,],
            },
            {
                "slug": "companies-add",
                "visibility": "private",
                "path": "/companies/add",
                "title": "Nuova azienda",
                "component": AddCompanyPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "addresses-add",
                "visibility": "private",
                "path": "/addresses/add",
                "title": "Nuovo indirizzo",
                "component": AddAddressPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "addresses-update-id",
                "visibility": "private",
                "path": "/addresses/update/:id",
                "title": "Modifica indirizzo",
                "component": UpdateAddressPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "contacts-add",
                "visibility": "private",
                "path": "/contacts/add",
                "title": "Nuovo recapito",
                "component": AddContactPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "contacts-update-id",
                "visibility": "private",
                "path": "/contacts/update/:id",
                "title": "Modifica recapito",
                "component": UpdateContactPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "stakeholders-add",
                "visibility": "private",
                "path": "/stakeholders/add",
                "title": "Nuovo contatto",
                "component": AddStakeholderPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "customers-add",
                "visibility": "private",
                "path": "/customers/add",
                "title": "Nuovo cliente",
                "component": AddStakeholderPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "practicals-card-id",
                "visibility": "private",
                "path": "/practicals/card/:id",
                "title": "Scheda pratica",
                "component": CardPracticalPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "practicals-outers-card-id",
                "visibility": "private",
                "path": "/practicals_outers/card/:id",
                "title": "Scheda pratica esterna",
                "component": CardPracticalOuterPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "practicals-add",
                "visibility": "private",
                "path": "/practicals/add",
                "title": "Nuova pratica",
                "component": AddPracticalPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "practicals-update-id",
                "visibility": "private",
                "path": "/practicals/update/:id",
                "title": "Modifica pratica",
                "component": UpdatePracticalPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "leads-card-id",
                "visibility": "private",
                "path": "/leads/card/:id",
                "title": "Scheda lead",
                "component": CardLeadPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "leads-manage-id",
                "visibility": "private",
                "path": "/leads/manage/:id",
                "title": "Gestione lead",
                "component": ManageLeadPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "leads-assign-id",
                "visibility": "private",
                "path": "/leads/assign/:id",
                "title": "Assegna lead",
                "component": AssignLeadPage,
                "users_can": [],
                "groups_can": [1,2,8],
            },
            {
                "slug": "reports-add",
                "visibility": "private",
                "path": "/reports/add",
                "title": "Nuovo report",
                "component": AddReportPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "reports-update-id",
                "visibility": "private",
                "path": "/reports/update/:id",
                "title": "Modifica report",
                "component": UpdateReportPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "reports-delete-id",
                "visibility": "private",
                "path": "/reports/delete/:id",
                "title": "Elimina report",
                "component": DeleteReportPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "reports-card-id",
                "visibility": "private",
                "path": "/reports/card/:id",
                "title": "Scheda report",
                "component": CardReportPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "tasks-add",
                "visibility": "private",
                "path": "/tasks/add",
                "title": "Nuovo task",
                "component": AddTaskPage,
                "users_can": [],
                "groups_can": [],
            },
            /*{
                "slug": "tasks-update-id",
                "visibility": "private",
                "path": "/tasks/update/:id",
                "title": "Modifica task",
                "component": UpdateTaskPage,
                "users_can": [],
                "groups_can": [1],
            },*/
            {
                "slug": "tasks-postpone-id",
                "visibility": "private",
                "path": "/tasks/postpone/:id",
                "title": "Rimanda task",
                "component": PostponeTaskPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "assignments-card-id",
                "visibility": "private",
                "path": "/assignments/card/:id",
                "title": "Scheda richiesta",
                "component": CardTaskPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "tasks-card-id",
                "visibility": "private",
                "path": "/tasks/card/:id",
                "title": "Scheda task",
                "component": CardTaskPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "tasks-close-id",
                "visibility": "private",
                "path": "/tasks/close/:id",
                "title": "Chiudi task",
                "component": CloseTaskPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "activity-card-id",
                "visibility": "private",
                "path": "/activities/card/:id",
                "title": "Scheda attività",
                "component": CardTaskPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "activity-close-id",
                "visibility": "private",
                "path": "/activities/close/:id",
                "title": "Chiudi attività",
                "component": CloseTaskPage,
                "users_can": [],
                "groups_can": [1,2,5],
            },
            {
                "slug": "entity-view-id",
                "visibility": "private",
                "path": "/:entity/view/:id",
                "component": EntityPageView,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "entity-add",
                "visibility": "private",
                "path": "/:entity/add",
                "component": EntityPageForm,
                "users_can": [],
                "groups_can": [],
            },
            /*{
                "slug": "entity-upload-csv",
                "visibility": "private",
                "path": "/:app/:entity/upload-csv",
                "component": EntityPageUploadCSV,
            },*/
            {
                "slug": "entity-update-id",
                "visibility": "private",
                "path": "/:entity/update/:id",
                "component": EntityPageForm,
                "users_can": [],
                "groups_can": [],
                "props": {
                    onGetData: (entity,id_entity,data) => {
                        if(data.hasOwnProperty('is_obscured') && data.is_obscured !== false)
                            return {hideForm: true,hideFormMsg:"Non hai i permessi per gestire il form"}
                    }
                },
            },
            {
                "slug": "entity-delete-id",
                "visibility": "private",
                "path": "/:entity/delete/:id",
                "component": EntityPageDelete,
                "users_can": [],
                "groups_can": [],
                "props": {
                    onGetData: (entity,id_entity,data) => {
                        if(data.hasOwnProperty('is_obscured') && data.is_obscured !== false)
                            return {hideForm: true,hideFormMsg:"Non hai i permessi per gestire il form"}
                    }
                },
            },
        ],
    },
    "page": {
        "matchRules": [
            "/my-branch/stakeholders",
            "/my-branch/practicals",
            "/my-branch/practicals_outers",
            "/my-branch/agents",
            "/practicals/commissions",
            "/:entity",
            "/:entity/dashboard"
        ],
        "layout": LayoutPage,
        "routes": [
            {
                "slug": "home",
                "visibility": "private",
                "path": "/home",
                "title": "Dashboard personale",
                "component": DashboardPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "profile",
                "visibility": "private",
                "path": "/profile",
                "title": "Profilo",
                "component": ProfilePage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "settings",
                "visibility": "private",
                "path": "/settings",
                "title": "Impostazioni",
                "component": Settings,
                "users_can": [],
                "groups_can": [1],
            },
            {
                "slug": "my-tasks",
                "visibility": "private",
                "path": "/my-tasks",
                "title": "I miei task",
                "component": MyTasksPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "my-reports",
                "visibility": "private",
                "path": "/my-reports",
                "title": "I miei report",
                "component": MyReportsPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "my-stakeholders",
                "visibility": "private",
                "path": "/my-stakeholders",
                "title": "I miei contatti",
                "component": MyStakeholdersPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "my-assignments",
                "visibility": "private",
                "path": "/my-assignments",
                "title": "Le mie richieste",
                "component": MyAssignmentsPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "my-practicals",
                "visibility": "private",
                "path": "/my-practicals",
                "title": "Le mie pratiche",
                "component": MyPracticalsPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "my-leads",
                "visibility": "private",
                "path": "/my-leads",
                "title": "I miei lead",
                "component": MyLeadsPage,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "my-branch",
                "visibility": "private",
                "path": "/my-branch",
                "title": "Overview sede",
                "component": MyBranchPage,
                "users_can": [],
                "groups_can": [1,6],
            },
            {
                "slug": "my-branch-stakeholders",
                "visibility": "private",
                "path": "/my-branch/stakeholders",
                "title": "Contatti sede",
                "component": MyBranchStakeholdersPage,
                "users_can": [],
                "groups_can": [1,6,7],
            },
            {
                "slug": "my-branch-practicals-inner",
                "visibility": "private",
                "path": "/my-branch/practicals",
                "title": "Pratiche sede",
                "component": MyBranchPracticalsPage,
                "users_can": [],
                "groups_can": [1,6,7],
            },
            {
                "slug": "my-branch-practicals-outers",
                "visibility": "private",
                "path": "/my-branch/practicals_outers",
                "title": "Pratiche altri istituti",
                "component": MyBranchPracticalsPage,
                "props": {"outers":true},
                "users_can": [],
                "groups_can": [1,6,7],
            },
            {
                "slug": "my-branch-agents",
                "visibility": "private",
                "path": "/my-branch/agents",
                "title": "Collaboratori sede",
                "component": MyBranchAgentsPage,
                "users_can": [],
                "groups_can": [1,6],
            },
            {
                "slug": "agencies-branch-dashboard",
                "visibility": "private",
                "path": "/agencies-branch/dashboard",
                "title": "Panoramica sedi",
                "component": DashboardAgenciesBranchesPage,
                "users_can": [],
                "groups_can": [1,2,],
            },
            {
                "slug": "agents-dashboard",
                "visibility": "private",
                "path": "/agents/dashboard",
                "title": "Panoramica collaboratori",
                "component": DashboardAgentsPage,
                "users_can": [],
                "groups_can": [1,2,],
            },
            {
                "slug": "practicals-commissions",
                "visibility": "private",
                "path": "/practicals/commissions",
                "title": "Provvigioni",
                "component": PracticalsCommissionsPage,
                "users_can": [],
                "groups_can": [1,2,],
            },
            {
                "slug": "entity-page",
                "visibility": "private",
                "path": "/:entity",
                "component": EntityPage,
                "users_can": [],
                "groups_can": [],
            },
            /*{
                "slug": "crm-dashboard",
                "visibility": "private",
                "path": "/crm/dashboard",
                "title": "CRM overview",
                "component": DashboardCRM,
                "users_can": [],
                "groups_can": [],
            },*/
            /*{
                "slug": "leads-dashboard",
                "visibility": "private",
                "path": "/leads/dashboard",
                "title": "Leads overview",
                "component": DashboardLeads,
                "users_can": [],
                "groups_can": [],
            },*/
            /*{
                "slug": "practicals-dashboard",
                "visibility": "private",
                "path": "/practicals/dashboard",
                "title": "Practicals overview",
                "component": DashboardPracticals,
                "users_can": [],
                "groups_can": [],
            },*/
            /*{
                "slug": "calendar",
                "visibility": "private",
                "path": "/calendar",
                "title": "Calendar",
                "component": DashboardCalendar,
                "users_can": [],
                "groups_can": [],
            },*/
            /*{
                "slug": "assignments-dashboard",
                "visibility": "private",
                "path": "/assignments/dashboard",
                "title": "Assignments overview",
                "component": DashboardAssignments,
                "users_can": [],
                "groups_can": [],
            },*/
            /*{
                "slug": "tasks-dashboard",
                "visibility": "private",
                "path": "/tasks/dashboard",
                "title": "Tasks overview",
                "component": DashboardTasks,
                "users_can": [],
                "groups_can": [],
            },*/
        ],
    },
    "errors": {
        "matchRules": ["/error/401","*"],
        "layout": StandardPageNoNavSideMenu,
        "routes": [
            {
                "slug": "401",
                "visibility": "public",
                "path": "/error/401",
                "title": "401",
                "component": Page401,
                "users_can": [],
                "groups_can": [],
            },
            {
                "slug": "404",
                "visibility": "public",
                "path": "*",
                "title": "404",
                "component": Page404,
                "users_can": [],
                "groups_can": [],
            },
        ],
    },
}