import React from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

const DividerAdvanced = (props) => {
    const children = props.hasOwnProperty('children') ? props.children : false
    if( children === false )
        return <Divider />

    const spacing = props.spacing
    const mt = props.mt
    const mb = props.mb
    const align = props.hasOwnProperty('align') ? props.align : "left"

    return <Box mt={mt} mb={mb}>
        <Grid container spacing={spacing} alignItems="center">
            {align === 'left' ? null : <Grid item xs><Divider /></Grid>}
            <Grid item xs="auto">{children}</Grid>
            {align === 'right' ? null : <Grid item xs><Divider /></Grid>}
        </Grid>
    </Box>
}

export default DividerAdvanced