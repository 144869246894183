import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

import AddCompany from '../modules/AddCompany'

const AddCompanyPage = (props) => {
    const title = props.route.title
    const queryStrings = props.route.location.search

    const queryStringsObj = queryStringToObj(queryStrings)

    return <Content title={title}>
        <AddCompany initialValues={queryStringsObj}/>
    </Content>
}

export default AddCompanyPage