import React from 'react'
import { withTheme } from '@material-ui/styles'

import Chip from '@material-ui/core/Chip'

class FieldPracticalState extends React.Component {
    chooseStyle(state) {
        const theme = this.props.theme

        switch( state ) {
            case "Estinta":
            case "Archiviata":
                return {
                    color: "white",
                    backgroundColor: theme.palette.success[900]
                }
            case "Erogata":
                return {
                    color: "white",
                    backgroundColor: theme.palette.success.main
                }

            case "Stornata":
            case "Ritirata":
            case "Sospesa":
            case "Rifiutata":
            case "Estinta anticipatamente":
                return {
                    color: "white",
                    backgroundColor: theme.palette.error.main
                }

            case "Preventivata":
                return {
                    color: "white",
                    backgroundColor: theme.palette.info[200]
                }

            default:
                return {
                    color: "white",
                    backgroundColor: theme.palette.warning.main
                }
        }
    }
    render() {
        const state = this.props.state
        const style = this.chooseStyle(state)

        return <Chip label={state} size="small" style={style} />
    }
}

export default withTheme(FieldPracticalState)