import React from 'react'
import FieldSelect from './FieldSelect'

class FieldBoolean extends React.Component {

    render() {
        let {name,...rest} = this.props

        const fieldName = 'label'
        const labelTrue = this.props.hasOwnProperty('labelTrue') ? this.props.labelTrue : 'Yes'
        const labelFalse = this.props.hasOwnProperty('labelFalse') ? this.props.labelFalse : 'No'

        let rowTrue = {}
        rowTrue[name] = 1
        rowTrue[fieldName] = labelTrue

        let rowFalse = {}
        rowFalse[name] = 0
        rowFalse[fieldName] = labelFalse


        let items = []
        items.push(rowTrue)
        items.push(rowFalse)

        return <React.Fragment>
            <FieldSelect
                {...rest}
                name={name}
                fieldId={name}
                fieldName={fieldName}
                items={items}
            />
        </React.Fragment>
    }
}

export default FieldBoolean