import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'

import PhoneIcon from '@material-ui/icons/Phone'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import EmailIcon from '@material-ui/icons/Email'
import PrintIcon from '@material-ui/icons/Print'
import ContactsIcon from '@material-ui/icons/Contacts'

class FieldIdContactType extends React.Component {
    render() {
        const id_contact_type = this.props.id_contact_type
        switch( id_contact_type ) {
            default: return <ContactsIcon color="secondary" />
            case 1: return <Tooltip title="phone" placement="right"><PhoneIcon color="secondary" /></Tooltip>
            case 2: return <Tooltip title="mobile" placement="right"><PhoneAndroidIcon color="secondary" /></Tooltip>
            case 3: return <Tooltip title="email" placement="right"><EmailIcon color="secondary" /></Tooltip>
            case 4: return <Tooltip title="fax" placement="right"><PrintIcon color="secondary" /></Tooltip>
        }
    }
}

export default FieldIdContactType