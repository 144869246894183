import React from 'react'

import { basename,extension } from '../../@react-fefmwk/requests/api'
import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Tooltip from '@material-ui/core/Tooltip'

class FieldExternalUrl extends React.Component {
    render() {
        const url = this.props.url
        if( !url )
            return "-"

        const _extension = extension(url)
        let Icon = null
        let target = null
        switch(_extension) {
            case "xlsx":case "xls":
            case "docx":case "doc":
            case "pptx":case "ppt":
            case "zip":case "rar":
                Icon = ArrowDownwardIcon
                target = '_self'
            break
            default:
                Icon = OpenInNewIcon
                target = '_blank'
            break
        }
        const title = this.props.title ? this.props.title : "visualizza o scarica"
        return <React.Fragment>
            <ExternalLink to={url} target={target}>
                <Tooltip title={title} placement="right">
                    <Icon color="secondary" />
                </Tooltip>
            </ExternalLink>
        </React.Fragment>
    }
}

export default FieldExternalUrl