import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {entity_q} from '../../@react-fefmwk/entity/services/api'

import Table from '../../@react-fefmwk/tables/modules/Table'

import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

class WidgetCompaniesList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            companies: [],
        }
    }
    
    async listCompanies() {
        const fields = this.props.hasOwnProperty('fields')
            ? this.props.fields
            : []
        this.setState({
            companies: await entity_q('companies',{
                fields: fields
            }),
        })
    }
    
    async componentDidMount() {
        this.listCompanies()
    }
    
    /*async componentDidUpdate(prevProps) {
        const id_atc = this.props.id_atc
        if( id_atc !== prevProps.id_atc )
            this.listCompanies()
    }*/

    render() {
        const companies = this.state.companies

        const showPagination = this.props.hasOwnProperty('showPagination')
            ? this.props.showPagination
            : true
        const defaultPageSize = this.props.hasOwnProperty('defaultPageSize')
            ? this.props.defaultPageSize
            : 10
        const noRowActions = this.props.hasOwnProperty('noRowActions')
            ? this.props.noRowActions
            : true
        const companiesRowActions = noRowActions ? [] : actionSchema('companies')
        const fieldsProps = tableSchema('companies')

        return <React.Fragment>
            {companies.length
                ? <Table material dense
                    data={companies}
                    fieldsProps={fieldsProps}
                    rowActions={companiesRowActions}
                    showPagination={showPagination}
                    defaultPageSize={defaultPageSize}
                    rowActionsField="id_company"
                />
                : <Chip icon={<ErrorIcon />} label="Nessuna azienda disponibile" variant="outlined" />}
        </React.Fragment>
    }
}

export default WidgetCompaniesList