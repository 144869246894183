import React from 'react'

//import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
//import Button from '@material-ui/core/Button'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

class FieldIdReport extends React.Component {
    render() {
        const id_report = this.props.id_report
        const title = this.props.title ? this.props.title : "Scheda report"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda report"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_report || id_report <= 0 || id_report === '-'
            ? null
            : <FieldEntityView title={title} icon={InsertDriveFileIcon}
                id_entity={id_report} entity="reports"
                fields={[
                    'id_report','report_type','report_stakeholder',
                    'report_target','report_feedback','report_date','report_title','report_content',
                    'id_practical','id_lead','id_activity','id_report_stakeholder','id_user',
                ]}
                /*actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/activities/view/'+id_activity} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}*/
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdReport