import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityFormSwitchLegalIdentity from '../@react-fefmwk/entity/modules/EntityFormSwitchLegalIdentity'

const UpdateContactPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id
    const queryStrings = props.route.location.search

    const onGetData = (entity,id_entity,data) => {
        if(data.hasOwnProperty('is_obscured') && data.is_obscured !== false)
            return {hideForm: true,hideFormMsg:"Non hai i permessi per gestire il form"}
    }

    return <Content title={title}>
        <EntityFormSwitchLegalIdentity entity="contacts" id_entity={id_entity} queryStrings={queryStrings} disableSwitch={true} onGetData={onGetData} />
    </Content>
}

export default UpdateContactPage