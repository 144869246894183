import React from 'react'
import { Formik,Form } from 'formik'
import { withStyles,styled } from '@material-ui/core/styles'

import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'

import StepperInfo from './StepperInfo'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import InfoIcon from '@material-ui/icons/InfoOutlined'

const styles = theme => ({
    stepperButtons: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        //justifyContent: 'center',
        paddingTop: theme.spacing(2.5),
        paddingLeft: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            paddingLeft: 0,
        },
        //paddingBottom: 21,
    },
    stepHeader: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    stepBody: {

    },
    stepTitle: {
        paddingRight: theme.spacing(2)
    },
    stepDescription: {
        color: theme.palette.grey[700],
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
})

const StepLabelPro = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepLabel-labelContainer': {
        [theme.breakpoints.down('sm')]: {
            display:'none'
        },
    }
}))

class StepperForm extends React.Component {
    constructor(props) {
        super(props)

        const id = this.props.id
            ? this.props.id
            : (Math.random().toString(5).substring(2,5) + Math.random().toString(5).substring(2,5))

        const infoFieldsProps = this.props.infoFieldsProps
        const stepperSchema = this.props.stepperSchema
        const formSchema = this.props.formSchema
        const infoFieldsList = this.props.infoFieldsList

        let steps = []
        let titles = []
        let descriptions = []
        let nextBtnText = []
        let backBtnText = []
        stepperSchema.map((step,i) => {
            steps[i] = step.hasOwnProperty("name") ? step.name : ''
            titles[i] = step.hasOwnProperty("title") ? step.title : ''
            descriptions[i] = step.hasOwnProperty("description") ? step.description : ''
            nextBtnText[i] = step.hasOwnProperty("nextBtnText") ? step.nextBtnText : 'next'
            backBtnText[i] = step.hasOwnProperty("backBtnText") ? step.backBtnText : 'back'
            return true
        })

        let activeStep = this.props.activeStep
        const lastStep = steps.length - 1
        const firstStep = 0

        activeStep = activeStep > lastStep ? lastStep : activeStep

        /**
         * Validation policy:
         * 1. stepperValidationSchema
         * Each step validation function, defined in services, is passed to validate Formik prop.
         * validationSchema prop must be provided.
         * 2. YupSchema
         * A Yup schema can be defined in services and passed to StepperForm with validationYupSchema prop.
         * validationYupSchema prop must be provided.
         * */
        const validationMode = this.props.hasOwnProperty('validationYupSchema')
            ? 'yup'
            : 'validate'

        this.state = {
            id: id,
            stepperSchema: stepperSchema,
            lastStep: lastStep,
            firstStep: firstStep,
            steps: steps,
            titles: titles,
            descriptions: descriptions,
            nextBtnText: nextBtnText,
            backBtnText: backBtnText,
            infoFieldsProps: infoFieldsProps,
            form: formSchema,
            infoFieldsList: infoFieldsList,
            activeStep: activeStep,
            validationMode: validationMode,
        }

        this._next = this._next.bind(this)
        this._prev = this._prev.bind(this)
        this._reset = this._reset.bind(this)

        this.setActiveStep = this.setActiveStep.bind(this)
        this.renderStep = this.renderStep.bind(this)
        this.validateStep = this.validateStep.bind(this)
        this.submitStep = this.submitStep.bind(this)

        this.renderHeader = this.renderHeader.bind(this)
    }

    componentDidMount() {
        const formSchema = this.props.formSchema
        const activeStep = this.state.activeStep

        this.setState({
            form: formSchema,
            activeStep: activeStep,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const formSchema = this.props.formSchema
            const activeStep = this.state.activeStep

            this.setState({
                form: formSchema,
                activeStep: activeStep,
            })
        }
    }

    _next() {
        this.setActiveStep(1)
    }
    
    _prev() {
        this.setActiveStep(-1)
    }

    _reset() {
        this.setActiveStep(1)
    }

    setActiveStep(i=0) {
        this.setState({
            activeStep: this.state.activeStep + i,
        })
    }

    renderStep(step_n,formikProps) {
        const Step = this.state.stepperSchema[step_n].component
        return <Step {...formikProps} />
    }

    validateStep(step_n,values) {
        const validationMode = this.state.validationMode
        if( validationMode === 'validate' ) {
            const validationSchema = this.props.validationSchema

            const validate = validationSchema.hasOwnProperty(step_n)
                ? validationSchema[step_n]
                : false
    
            return validate ? validate(values) : {}
        }
    }

    async submitStep(step_n,values,actions) {
        let next = false

        const submitSchema = this.props.submitSchema

        const stepSubmitSchema = submitSchema.hasOwnProperty(step_n)
            ? submitSchema[step_n]
            : false

        if( !stepSubmitSchema )
            next = true
        else {
            const submit = stepSubmitSchema.hasOwnProperty("submit")
                ? stepSubmitSchema["submit"]
                : false
            const updateState = stepSubmitSchema.hasOwnProperty("updateState")
                ? stepSubmitSchema["updateState"]
                : false

            const submit_return = submit
                ? await submit(this.state,values,actions)
                : false

            if( submit_return ) {
                next = true
                if( updateState && submit && submit_return )
                    this.setState(submit_return)
            }
        }

        if(next) this._next()
        return next
    }

    renderHeader( titles = [] ) {
        const id = this.state.id

        if( !titles.length ) return <LoaderSingleComponent width="100%"/>

        return titles.map((title,i) =>
            <Step key={"step-"+id+"-"+i}>
                <StepLabelPro>{title}</StepLabelPro>
            </Step>)
    }

    render() {
        const infoFieldsList = this.state.infoFieldsList
        const infoFieldsProps = this.state.infoFieldsProps

        const id = this.state.id
        const activeStep = this.state.activeStep
        const lastStep = this.state.lastStep
        const firstStep = this.state.firstStep
        const steps = this.state.steps
        const stepsTitles = this.state.titles
        const form = this.state.form
        const stepTitle = this.state.titles[activeStep]
        const stepDescription = this.state.descriptions[activeStep]
        const nextBtnText = this.state.nextBtnText[activeStep]
        const backBtnText = this.state.backBtnText[activeStep]
        const disableBackAtLastStep = this.props.hasOwnProperty('disableBackAtLastStep')
        const disableBackAtSteps = this.props.hasOwnProperty('disableBackAtSteps')
            ? this.props.disableBackAtSteps
            : []

        const validationMode = this.state.validationMode
        const validationYupSchema = this.props.validationYupSchema

        const { classes } = this.props

        const submitSchema = this.props.submitSchema
        const enableSubmit = submitSchema.hasOwnProperty(activeStep) && submitSchema[activeStep]
            ? submitSchema[activeStep].enableSubmit
            : false

        return <div id={"stepper-"+id}>
            {activeStep === steps.length
                ? <React.Fragment>
                    <p>All steps completed</p>
                    <Button onClick={this._reset}>Reset</Button>
                </React.Fragment>
                : <React.Fragment>
                    <Formik
                        initialValues={form}
                        validationSchema={validationMode === 'yup' ? validationYupSchema : null}
                        validate={validationMode === 'validate'
                            ? (values) => {
                                const errors = this.validateStep(activeStep,values)
                                return errors
                            }
                            : null
                        }
                        onSubmit={(values,actions) => {
                            this.submitStep(activeStep,values,actions)
                        }}
                        render={formikProps => {
                            const infoObj = formikProps.values
                            return <Form id={"stepper-form-"+id}>
                                <Paper>
                                    <Grid container spacing={0}
                                        alignItems="flex-start"
                                        justify="flex-start"
                                        direction="row"
                                    >
                                        <Grid item xs={12} md={3} lg={2} xl={1}>
                                            <Box className={classes.stepperButtons}>
                                                {activeStep === lastStep && disableBackAtLastStep
                                                    ? <Button size="small"
                                                        disabled={true}
                                                        onClick={this._prev}
                                                    >Complete</Button>
                                                    : <React.Fragment>
                                                        <Button size="small"
                                                            disabled={(activeStep === firstStep || (activeStep === lastStep && disableBackAtLastStep) || disableBackAtSteps.includes(activeStep))}
                                                            onClick={this._prev}
                                                        >{backBtnText}</Button>
                                                        {activeStep !== lastStep
                                                            ? <Button size="small" variant="contained" color="primary"
                                                                onClick={(e) => {
                                                                    if(enableSubmit)
                                                                        formikProps.handleSubmit(e)
                                                                    else
                                                                        this.submitStep(activeStep,formikProps.values,formikProps)
                                                                }}
                                                                disabled={!formikProps.isValid}
                                                            >{nextBtnText}</Button>
                                                            : null}
                                                    </React.Fragment>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={9} lg={10} xl={11}>
                                            <Stepper activeStep={activeStep} style={{backgroundColor:'transparent'}}>
                                                {this.renderHeader(stepsTitles)}
                                            </Stepper>
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <br />

                                <Container maxWidth="xl">
                                    <Grid container spacing={5} direction="row">
                                        {activeStep > 0
                                            ? <Grid item xs={12} md={3}>
                                                <Typography gutterBottom variant="subtitle1" component="div">Collected info</Typography>
                                                <br />
                                                <Box>
                                                    <StepperInfo
                                                        info={infoObj}
                                                        infoFieldsList={infoFieldsList}
                                                        fieldsProps={infoFieldsProps}
                                                    />
                                                </Box>
                                            </Grid>
                                            : null}
                                        <Grid item xs={12} md={activeStep > 0 ? 9 : 12}>
                                            <Box className={classes.stepHeader}>
                                                <Typography gutterBottom className={classes.stepTitle} variant="subtitle1" component="div">{stepTitle}</Typography>
                                                <Typography gutterBottom className={classes.stepDescription} variant="body2" component="div">
                                                    <InfoIcon fontSize="small" />&nbsp;{stepDescription}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.stepBody}>
                                                {this.renderStep(activeStep,formikProps)}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Form>
                        }}
                    />
                </React.Fragment>
            }
        </div>
    }
    
}

export default withStyles(styles)(StepperForm)