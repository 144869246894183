import React from 'react'

import {HookRoutePrepend,HookRouteAppend} from '../../../services/Hooks'

class AppRoute extends React.Component {
    render() {
        return <React.Fragment>
            <HookRoutePrepend {...this.props} />
            {this.props.children}
            <HookRouteAppend {...this.props} />
        </React.Fragment>
    }
}

export default AppRoute