import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import DeleteEntity from '../@react-fefmwk/entity/modules/DeleteEntity'
import EntityTitle from '../@react-fefmwk/entity/modules/EntityTitle'
import { idFromToken,groupsFromToken } from '../@react-fefmwk/auth/services/token'
import { Chip } from '@material-ui/core'

class DeleteReportPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            allow_deletion: true,
            refresh: false,
        }
    }

    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id
        const entity = "reports"
        const allow_deletion = this.state.allow_deletion
        const refresh = this.state.refresh

        const current_user_id = idFromToken()
        const groups = groupsFromToken()

        return <Content title={title} toolbar={<EntityTitle entity={entity} id_entity={id_entity} />}>
            {!allow_deletion ? <Chip label="Non hai i privilegi per eliminare questo report" /> : null}
            <DeleteEntity
                entity={entity}
                id_entity={id_entity}
                withBtnSubmit={allow_deletion}
                onGetData={(entity,id_entity,data) => {
                    const author_id = data.hasOwnProperty('id_user') ? data.id_user : 0
                    if( (!author_id || parseInt(author_id) !== parseInt(current_user_id)) && !groups.includes(String(1)) ) {
                        //this causes a warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                        this.setState({
                            allow_deletion: false
                        })
                        return {//retur new state for DeleteEntity component
                            withViewState: false
                        }
                    }
                }}
            />
        </Content>
    }
}

export default DeleteReportPage