import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

class WidgetPerson extends React.Component {
    render() {
        const id_people = this.props.id_people
        if( !id_people ) return <Chip icon={<ErrorIcon />} label="No data available" variant="outlined" />

        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : []

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Anagrafica"

        const deleteBtn = this.props.hasOwnProperty('deleteBtn')
            ? this.props.deleteBtn
            : true

        let actions = null
        if( dashboardcardActions )
            actions = <React.Fragment>
                <Button
                    variant="text" size="small"
                    startIcon={<EditIcon fontSize="small" />}
                    component={AdapterLink}
                    to={"/people/update/"+id_people}
                >Modifica</Button>
                {deleteBtn
                    ? <Button variant="text" size="small"
                        startIcon={<DeleteIcon fontSize="small" />}
                        to={"/people/delete/"+id_people}
                        component={AdapterLink}
                    >Elimina</Button>
                    : null}
            </React.Fragment>

        return <ViewEntity dense
            entity={"people"}
            id_entity={id_people}
            fields={fields}
            TopActions={actions}
            cardTitle={dashboardcardTitle}
        />
    }
}

export default WidgetPerson