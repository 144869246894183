import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {leads_get_tasks} from '../../services/BE'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'

class WidgetLeadTasks extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tasks: [],
        }
    }
    
    async updateTasks() {
        const id_lead = this.props.id_lead
        if( id_lead )
            this.setState({
                tasks: await leads_get_tasks(id_lead),
            })
    }

    async componentDidMount() {
        this.updateTasks()
    }
    
    async componentDidUpdate(prevProps) {
        const id_lead = this.props.id_lead
        if( id_lead !== prevProps.id_lead )
            this.updateTasks()
    }

    render() {
        const id_lead = this.props.id_lead
        const tasks = this.state.tasks

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Tasks"

        const tableRowActions = this.props.hasOwnProperty('tableRowActions')
            ? this.props.tableRowActions
            : true
        const rowActions = tableRowActions
            ? actionSchema('activities')
            : {}

        let fieldsProps = tableSchema('activities')
        if( !tableRowActions )
            fieldsProps = {
                ...fieldsProps,
                ...{id_activity:{show:false}}
            }

        const cardProps = {}
        cardProps['Content'] = tasks.length
            ? <Table material dense
                hideHeaders
                defaultPageSize="100"
                data={tasks}
                fieldsProps={fieldsProps}
                rowActions={rowActions}
                rowActionsField="id_activity"
            />
            : <Chip icon={<ErrorIcon />} label="Nessun task trovato" variant="outlined" />

        if( dashboardcardActions )
            cardProps['TopActions'] = <Button
                variant="text" color="primary" size="small"
                startIcon={<AddIcon fontSize="small" />}
                component={AdapterLink}
                to={"/tasks/add?id_lead="+id_lead}
            >Nuovo</Button>

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetLeadTasks