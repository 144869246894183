import React from 'react'

import {EntitySchema} from '../../../services/EntitySchema'
import {helpersFormSchema} from '../../../services/EntityHelpersFormSchema'

import {hasOwnPath} from '../../arrays/api'
import {
    entity_fields_info,
} from '../services/api'
import EntityFormInput from './EntityFormInput'

import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'
import Grid from '@material-ui/core/Grid'

class EntityFormInputs extends React.Component {
    constructor(props) {
        super(props)

        const entity = props.entity

        this.state = {
            inputs_props: props.entityInputProps ? props.entityInputProps : {},
            grid_item_props: props.gridItemProps ? props.gridItemProps : {},
            fields_order: props.fieldsOrder ? props.fieldsOrder : [],
            fields_info: props.entityFieldsInfo ? props.entityFieldsInfo : {},
            helpersTextSchema: props.entityHelpersSchema ? props.entityHelpersSchema : helpersFormSchema(entity),
        }
    }

    async componentDidMount() {
        const entity = this.props.entity
        let fields_info = this.state.fields_info

        if( Object.keys(fields_info).length === 0 ) {
            fields_info = await entity_fields_info(entity)
            this.setState({
                fields_info: fields_info
            })
        }
    }
    componentDidUpdate(prevProps) {
        if( prevProps.entityFieldsInfo !== this.props.entityFieldsInfo )
            this.setState({
                fields_info: this.props.entityFieldsInfo ? this.props.entityFieldsInfo : {},
            })
    }

    render() {
        const fields_info = this.state.fields_info
        if( !fields_info ) return <LoaderSingleComponent width="100%" />

        const inputs_props = this.state.inputs_props
        const grid_item_props = this.state.grid_item_props

        const entity = this.props.entity
        const entitySchema = EntitySchema.hasOwnProperty(entity) ? EntitySchema[entity] : {}
        const helperTextSchema = this.state.helpersTextSchema
        const id_field = entitySchema.id_field

        const formikProps = this.props.formikProps

        //apply custom fields order if is set
        const fields_order = this.state.fields_order
        const fields_info_keys = Object.keys(fields_info)
        let formKeys = fields_info_keys
        if( fields_order.length ) {
            formKeys = fields_order
            formKeys = fields_order.filter(value => fields_info_keys.includes(value))
        }

        //check if if field must be printed
        const print_field_id = this.props.hasOwnProperty('print_field_id')
            ? this.props.print_field_id
            : false
        if(!print_field_id && formKeys.indexOf(id_field) !== -1)
            formKeys.splice(formKeys.indexOf(id_field),1)

        var fieldsArray = []
        for (var i = 0; i < formKeys.length; i++) {
            let field = formKeys[i]
            let inputType = hasOwnPath(fields_info,field+'.input_type') ? fields_info[field].input_type : 'text'
            let disabled = hasOwnPath(fields_info,field+'.disabled') ? fields_info[field].disabled : false
            let niceName = ( !hasOwnPath(fields_info,field+'.nice_name') || fields_info[field].nice_name === null )
                ? field
                : (fields_info[field].nice_name ? fields_info[field].nice_name : field)
            if( niceName === field ) {
                niceName = niceName.replaceAll("_"," ").replaceAll("-"," ")
                niceName = niceName.charAt(0).toUpperCase() + niceName.slice(1)
            }
            let placeholder = hasOwnPath(fields_info,field+'.placeholder') ? fields_info[field].placeholder : ''
            let inputOptions = "input_options" in fields_info[field] ? fields_info[field].input_options : {}

            const helperText = helperTextSchema.hasOwnProperty(field) ? helperTextSchema[field] : ""
            const inputProps = inputs_props.hasOwnProperty(field) ? inputs_props[field] : {}
            const gridItemProps = {
                ...fields_info[field].hasOwnProperty('grid_item') ? fields_info[field].grid_item : {},
                ...grid_item_props.hasOwnProperty(field) ? grid_item_props[field] : {}
            }

            let style = {}
            if( inputType === "hidden" ) style = {padding: 0}

            const defaultVal = fields_info[field].default ? fields_info[field].default : null
            const ref_entity = fields_info[field].hasOwnProperty('ref_entity') ? fields_info[field].ref_entity : null
            const onRefreshForm = this.props.onRefreshForm

            const Input = <Grid key={i} item style={style} {...gridItemProps}>
                <EntityFormInput
                    field={field}
                    inputType={inputType}
                    formikProps={formikProps}
                    niceName={niceName}
                    placeholder={placeholder}
                    helperText={helperText}
                    inputOptions={inputOptions}
                    disabled={disabled}
                    default={defaultVal}
                    inputProps={inputProps}
                    ref_entity={ref_entity}
                    onRefreshForm={onRefreshForm}
                />
            </Grid>

            fieldsArray.push( Input )
        }

        return fieldsArray
    }
}

export default EntityFormInputs