import React from 'react'
import clsx from 'clsx'
import { withStyles,withTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import {isLoggedIn} from '../../auth/services/api'

import AdapterLink from '../../links/modules/AdapterLink'
import ExternalLink from '../../links/modules/ExternalLink'
import { NavTopSchema } from '../../../services/NavTopSchema'

import { currentUrl } from '../../requests/api'

import UserDropdown from '../../auth/modules/UserDropdown'

const style = theme => ({
    appNavTopItem: theme.globals.appNavTopItem,
    appNavTopItemIcon: theme.globals.appNavTopItemIcon,
    appNavTopItemText: theme.globals.appNavTopItemText,
    appNavTopItemIconSelected: theme.globals.appNavTopItemIconSelected,
    appNavTopItemTextSelected: theme.globals.appNavTopItemTextSelected,
})

class NavTop extends React.Component {
    renderListItem( listItem,currentPath ) {
        const slug = listItem.slug
        const path = listItem.path
        const path_type = listItem.path_type
        const title = listItem.title
        const Icon = listItem.icon
        const Component = listItem.hasOwnProperty('component') ? listItem.component : null

        const { classes } = this.props

        const Link = path_type === "external" ? ExternalLink : AdapterLink

        const appNavTopItemIconClass = clsx(classes.appNavTopItemIcon,{[classes.appNavTopItemIconSelected]:path === currentPath})
        const appNavTopItemTextClass = clsx(classes.appNavTopItemText,{[classes.appNavTopItemTextSelected]:path === currentPath})

        return Component
            ? <React.Fragment key={slug} >
                <Box className={classes.appNavTopItem}>
                    {Component}
                </Box>
            </React.Fragment>
            : <Link key={slug} to={path} className={classes.appNavTopItem}>
                <Grid container alignItems="center">
                    <Grid item>
                        {Icon ? <Icon className={appNavTopItemIconClass} fontSize="small" /> : null}
                    </Grid>
                    <Grid item className={appNavTopItemTextClass}>
                        &nbsp;&nbsp;{title}
                    </Grid>
                </Grid>
            </Link>
    }
    renderList( list ) {
        const currentPath = currentUrl()

        return <React.Fragment>
            {list.map((item,i) => (
                this.renderListItem( item,currentPath )
            ))}
        </React.Fragment>
    }
    render() {
        const { theme } = this.props
        const loggedIn = isLoggedIn()

        const showUserDivider = this.props.showUserDivider
        const showUsername = this.props.hasOwnProperty('showUsername')
            ? this.props.showUsername
            : false

        return <React.Fragment>
            <Hidden xsDown>
                {this.renderList( NavTopSchema )}
            </Hidden>
            {loggedIn
                ? <React.Fragment>
                    {showUserDivider
                        ? <Divider orientation="vertical" flexItem style={theme.globals.appNavTopItemDivider} />
                        : null}
                    <UserDropdown showUsername={showUsername} style={{marginLeft: showUserDivider ? 0 : theme.globals.appNavTopItemDivider.marginLeft}} />
                </React.Fragment>
                : null}
            
        </React.Fragment>
    }
}

export default withStyles(style)(withTheme(NavTop))