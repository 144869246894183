import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import BusinessIcon from '@material-ui/icons/Business'

class FieldIdAtc extends React.Component {
    render() {
        const id_atc = this.props.id_atc
        const title = this.props.title ? this.props.title : "Scheda ATC"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda ATC"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_atc || id_atc <= 0 || id_atc === '-'
            ? null
            : <FieldEntityView title={title} icon={BusinessIcon}
                id_entity={id_atc} entity="atcs"
                fields={['id_atc','atc','atc_type',]}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/atcs/card/'+id_atc} target="_blank"
                    >Visualizza</Button>
                    <Button variant="text" size="small"
                        component={AdapterLink} to={'/atcs/card/'+id_atc+'#customers'} target="_blank"
                    >Clienti</Button>
                    <Button variant="text" size="small"
                        component={AdapterLink} to={'/atcs/card/'+id_atc+'#quotations'} target="_blank"
                    >Quotazioni</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdAtc