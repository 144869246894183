import React from 'react'

import {entity_get} from '../@react-fefmwk/entity/services/api'
import {idFromToken,groupsFromToken} from '../@react-fefmwk/auth/services/token'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'

import WidgetTaskReports from '../modules/widgets/WidgetTaskReports'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AssignmentIcon from '@material-ui/icons/Assignment'

class CardTask extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            task: {},
        }
    }

    async componentDidMount() {
        const id_entity = this.props.id_entity
        this.setState({
            task: await entity_get('activities',id_entity),
        })
    }

    render() {
        const id_entity = this.props.id_entity
        const task = this.state.task
        const groups = groupsFromToken()

        const id_stakeholder = task.hasOwnProperty('id_activity_stakeholder') ? task.id_activity_stakeholder : 0
        const target = task.hasOwnProperty('activity_target') ? task.activity_target : ''
        const id_practical = task.hasOwnProperty('id_practical') ? task.id_practical : 0
        const id_lead = task.hasOwnProperty('id_lead') ? task.id_lead : 0
        const id_agent = task.hasOwnProperty('id_agent') ? task.id_agent : 0

        const state = task.hasOwnProperty('activity_state') ? task.activity_state : ''
        const isClosed = state === 'closed' ? true : false
        const id_current_user = idFromToken()
        const canBeClosed = !isClosed && (!groups.includes(String(4)) || parseInt(id_current_user) === parseInt(id_agent) ) ? true : false

        return <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                {id_stakeholder
                    ? <React.Fragment>
                        <ViewEntity
                            cardTitle={"Contatto / cliente #"+id_stakeholder}
                            entity={'stakeholders'}
                            id_entity={id_stakeholder}
                            fields={[
                                'stakeholder','main_mobile',
                                'company','vat_number',
                            ]}
                            TopActions={<React.Fragment>
                                <Button variant="text" size="small"
                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder}
                                >Visualizza</Button>
                                <Button variant="text" size="small"
                                    startIcon={<CloudUploadIcon />}
                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#documents'}
                                >Documenti</Button>
                                <Button variant="text" size="small"
                                    startIcon={<AssignmentIcon />}
                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#activities'}
                                >Attività</Button>
                            </React.Fragment>}
                        />
                        <br />
                    </React.Fragment>
                    : null}
                <ViewEntity
                    cardTitle={"Task #"+id_entity}
                    entity={'activities'}
                    id_entity={id_entity}
                    fields={[
                        'activity_order',
                        'activity_instruction',
                        'id_agent','id_user',
                        'activity_target',
                        'id_practical','id_lead',
                        'activity_state',
                        'id_report','closed_at',
                        'active_at',
                        'created_at','updated_at',
                    ]}
                    TopActions={<React.Fragment>
                        <Button variant="contained" size="small" color="primary" disabled={!canBeClosed}
                            component={AdapterLink} to={'/tasks/close/'+id_entity}
                        >Chiudi</Button>
                    </React.Fragment>}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <WidgetTaskReports id_activity={id_entity} dashboardcardTitle="Tutti i report" fields="id_report,report_type,report_author,task_receiver,report_content,report_feedback" />
                <br />
                {target === 'practical' && parseInt(id_practical) > 0
                    ? <ViewEntity
                        cardTitle={"Pratica #"+id_practical}
                        entity={'practicals'}
                        id_entity={id_practical}
                        fields={[
                            'id_practical','product','stakeholder','practical_state',
                            'agent','origination','atc',
                            'load_date','effective_date','renewal_date',
                            'disbursed_amount','installments_n','installment_amount','compounding_amount',
                            'commission','tan','taeg',
                            'estinzione','installments_n','installment_amount','commission',
                        ]}
                        TopActions={<React.Fragment>
                            <Button variant="text" size="small"
                                component={AdapterLink} to={'/practicals/card/'+id_practical}
                            >Visualizza</Button>
                            <Button variant="text" size="small"
                                startIcon={<CloudUploadIcon />}
                                component={AdapterLink} to={'/practicals/card/'+id_practical+'#documents'}
                            >Documenti</Button>
                            <Button variant="text" size="small"
                                startIcon={<AssignmentIcon />}
                                component={AdapterLink} to={'/practicals/card/'+id_practical+'#history'}
                            >Attività</Button>
                        </React.Fragment>}
                    />
                    : null}
                {target === 'lead' && parseInt(id_lead) > 0
                    ? <ViewEntity
                        cardTitle={"Lead #"+id_lead}
                        entity={'leads'}
                        id_entity={id_lead}
                        fields={[
                            'lead_channel','lead_subject','lead_date','lead_body','id_agent',
                        ]}
                    />
                    : null}
            </Grid>
        </Grid>
    }
}

export default CardTask