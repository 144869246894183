import React from 'react'
import Content from '../../../theme-structure/modules/Content'
import {EntitySchema} from '../../../../services/EntitySchema'
import EntityForm from '../EntityForm'
import EntityTitle from '../EntityTitle'

import {checkPrivilegies} from '../../services/api'
import Page404 from '../../../theme-structure/modules/404'
import Page401 from '../../../theme-structure/modules/401'

const EntityPageForm = (props) => {
    const id_entity = props.route.hasOwnProperty('id_entity')
        ? props.route.id_entity
        : props.route.computedMatch.params.id
    const entity = props.route.hasOwnProperty('entity')
        ? props.route.entity
        : props.route.computedMatch.params.entity

    const entityObj = EntitySchema[entity]
    if( !entityObj || !Object.keys(entityObj).length )
        return <Page404 />
    const privilege = id_entity ? "update" : "add"
    if( !checkPrivilegies( entityObj,privilege ) )
        return <Page401 />

    const queryStrings = props.route.location.search

    let title = 'Unknown entity'
    if( entityObj && Object.keys(entityObj).length ) {
        title = props.route.hasOwnProperty("title") && props.route.title
            ? props.route.title
            : (entityObj.title_singular+(id_entity ? " update" : " add"))
    }

    const embed = props.route.path.includes('/embed/') ? true : false
    const BoxContent = embed
        ? React.Fragment
        : Content
    const boxContentProps = embed
        ? {}
        : {
            title: title,
            toolbar: <EntityTitle entity={entity} id_entity={id_entity} />
        }
    const onGetData = props.route.hasOwnProperty('onGetData') ? props.route.onGetData : () => {}

    return <BoxContent {...boxContentProps}>
        <EntityForm 
            entity={entity}
            id_entity={id_entity}
            queryStrings={queryStrings}
            onGetData={onGetData}
        />
    </BoxContent>
}

export default EntityPageForm