import theme from '../../../theme/theme'

export function today( date = '' ) {
    return date ? Date.now(date) : Date.now()
}
export function todayString( date = '' ) {
    var todayDate = date ? new Date(date) : new Date()
    var todayD = String(
        todayDate.getFullYear()+'-'+
        ("0" + (todayDate.getMonth() + 1)).slice(-2)+'-'+
        ("0" + todayDate.getDate()).slice(-2)
    )
    return todayD
}
export function now( now = '' ) {
    return now ? Date.now(now) : Date.now()
}
export function nowString( now = '' ) {
    var nowDate = now ? new Date(now) : new Date()
    var nowD = String(
        nowDate.getFullYear()+'-'+
        ("0" + (nowDate.getMonth() + 1)).slice(-2)+'-'+
        ("0" + nowDate.getDate()).slice(-2)+" "+
        ("0" + nowDate.getHours()).slice(-2)+":"+
        ("0" + nowDate.getMinutes()).slice(-2)+":"+
        ("0" + nowDate.getSeconds()).slice(-2)
    )
    return nowD
}
export function stringToDate( dateString,format = "" ) {
    let dateParts = []
    let year = ""
    let month = ""
    let day = ""
    switch( format ) {
        case "dd/MM/yyyy":
            dateParts = dateString.split("/")
            return new Date(+dateParts[2],dateParts[1] - 1,+dateParts[0])
        case "dd-MM-yyyy":
            dateParts = dateString.split("-")
            return new Date(+dateParts[2],dateParts[1] - 1,+dateParts[0])
        case "YYYYMMDD":
        case "yyyymmdd":
            year = String(dateString).substring(0,4)
            month = String(dateString).substring(4,6)
            day = String(dateString).substring(6,8)
            return new Date(year,parseInt(month) - 1,day)
        case "DDMMYYYY":
        case "ddmmyyyy":
            year = String(dateString).substring(4,8)
            month = String(dateString).substring(2,4)
            day = String(dateString).substring(0,2)
            return new Date(year,parseInt(month) - 1,day)
        default:
            return new Date(dateString)
    }
}
export function stringToDateTime( datetimeString,format = "" ) {
    let dateTimeParts = []
    let dateParts = []
    let timeParts = []
    switch( format ) {
        case "dd/MM/yyyy hh:mm:ss":
            dateTimeParts = datetimeString.split(" ")
            dateParts = dateTimeParts[0].split("/")
            timeParts = dateTimeParts[1] ? dateTimeParts[1].split(":") : ["00","00","00"]
            return new Date(+dateParts[2],dateParts[1] - 1,+dateParts[0],+timeParts[0],+timeParts[1],+timeParts[2])
        case "dd-MM-yyyy hh:mm:ss":
            dateTimeParts = datetimeString.split(" ")
            dateParts = dateTimeParts[0].split("-")
            timeParts = dateTimeParts[1] ? dateTimeParts[1].split(":") : ["00","00","00"]
            return new Date(+dateParts[2],dateParts[1] - 1,+dateParts[0],+timeParts[0],+timeParts[1],+timeParts[2])
        default:
            return new Date(datetimeString)
    }
}
export function getThemeLocale() {
    return theme.hasOwnProperty('locale')
        ? theme.locale
        : {}
}
export function getThemeLocaleCode() {
    return theme.hasOwnProperty('locale')
        ? theme.locale.code
        : "en-US"
}
export function getDbDateFormat( time = false ) {
    return time === true ? 'yyyy-MM-dd hh:mm:ss' : 'yyyy-MM-dd'
}
export function getThemeDateFormat( time = false ) {
    const locale = getThemeLocaleCode()
    return locale === 'it'
        ? (time === true ? 'dd/MM/yyyy hh:mm:ss' : 'dd/MM/yyyy')
        : getDbDateFormat(time)
}
export function formatDate( datetime = '',options = {} ) {
    const locale = getThemeLocaleCode()
    const dtime = datetime ? new Date(datetime) : new Date()
    const opt = Object.keys(options).length
        ? options
        : {
            dateStyle: "long",
            timeStyle: "short",
            //weekday: "long",
            //year: "numeric",
            //month: "numeric",
            //day: "numeric",
            //hour: "numeric",
            //minute: "numeric",
            //second: "numeric",
        }
    const {timeStyle,...rest} = opt
    const date = dtime.toLocaleDateString(
        locale,
        rest
    )
    const time = timeStyle
        ? dtime.toLocaleTimeString(
            locale,
            {timeStyle: timeStyle}
        )
        : ""
    return date + (time ? (" "+time) : "")
}
export function msToTime(s,withMs = false) {
    // Pad to 2 or 3 digits, default is 2
    function pad(n, z) {
        z = z || 2
        return ('00' + n).slice(-z)
    }

    var ms = s % 1000
    s = (s - ms) / 1000
    var secs = s % 60
    s = (s - secs) / 60
    var mins = s % 60
    var hrs = (s - mins) / 60

    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + (withMs ? ('.' + pad(ms, 3)) : '')
}
export function isValidDate( dateString ) {
    const minD = new Date('1971-01-01')
    const d = new Date(dateString)
    return (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d) || d < minD)
        ? false
        : true
}