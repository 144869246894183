import React from 'react'
import { toolbarTimeinfoSchema } from '../../../../services/EntityTimeinfoToolbarSchema'

class TimeinfoToolbar extends React.Component {
    constructor(props) {
        super(props)

        this.renderToolbar = this.renderToolbar.bind(this)
    }

    renderToolbar( list ) {
        const entity = this.props.entity

        return <React.Fragment>
            {list.map((btn,b) => (
                <React.Fragment key={entity+'-timeinfo-btn-'+b}>
                    {btn}
                </React.Fragment>
            ))}
        </React.Fragment>
    }

    render() {
        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const cftime = this.props.cftime
        const data = this.props.data
        const s = this.props.s
        const toolbar = toolbarTimeinfoSchema( entity,id_entity,cftime,s,data )

        return <React.Fragment>
            {this.renderToolbar( toolbar )}
            <br />
            <br />
        </React.Fragment>
    }
}

export default TimeinfoToolbar