import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import { ThemeProvider } from '@material-ui/styles'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

import theme from '../../../theme/theme'
import '../../../theme/theme.css'

import Page401 from '../../theme-structure/modules/401'

import {idFromToken,groupsFromToken} from '../../auth/services/token'
import {AppSchema} from '../../../services/AppSchema'

function privilegiesRoute( route ) {
    const groups_current_user = groupsFromToken()
    const id_current_user = idFromToken()
    const users_can = route.hasOwnProperty('users_can') ? route.users_can : []
    const groups_can = route.hasOwnProperty('groups_can') ? route.groups_can : []
    const checkGroups = groups_current_user.filter(id_group => groups_can.includes(parseInt(id_group)))
    if( users_can.length && !users_can.includes( parseInt(id_current_user) ) ) return false
    if( groups_can.length && !checkGroups.length ) return false

    return true
}

function renderRoute( route ) {
    const routeCan = privilegiesRoute( route )
    if( !routeCan ) {
        route.component = Page401
    }

    let routeProps = {
        key: route.slug,
        visibility: route.visibility,
        path: route.path,
        title: route.title,
        component: route.component,
    }

    const componentProps = route.hasOwnProperty("props") ? {...route.props} : {}
    routeProps = {...routeProps,...componentProps}

    return route.visibility === "public"
        ? <PublicRoute exact {...routeProps} />
        : <PrivateRoute exact {...routeProps} />
}

class AppRoutes extends React.Component {
    render() {
        const routesSchema = Object.entries(AppSchema)
        return <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    {routesSchema.map((layout,b) => {
                        //const layout_slug = layout[0]
                        const matchRules = layout[1].matchRules
                        const Layout = layout[1].layout
                        const layoutProps = layout[1].hasOwnProperty('layoutProps')
                            ? layout[1].layoutProps
                            : {}
                        const routes = layout[1].routes

                        if( !routes.length ) return null

                        return <Route key={'layout'+b} exact path={matchRules}>
                            <Layout {...layoutProps}>
                                <Switch>
                                    {routes.map((route) => renderRoute( route ))}
                                </Switch>
                            </Layout>
                        </Route>
                    })}
                </Switch>
            </Router>
        </ThemeProvider>
    }
}

export default AppRoutes