import React from 'react'

import { EntitySchema } from '../services/EntitySchema'
import {entity_get} from '../@react-fefmwk/entity/services/api'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import CardPeople from '../modules/CardPeople'
import CardCompany from '../modules/CardCompany'
import WidgetStakeholderPracticals from '../modules/widgets/WidgetStakeholderPracticals'
import WidgetStakeholderPracticalsOuters from '../modules/widgets/WidgetStakeholderPracticalsOuters'
import WidgetStakeholderFiles from '../modules/widgets/WidgetStakeholderFiles'
import WidgetStakeholderActivities from '../modules/widgets/WidgetStakeholderActivities'
import WidgetStakeholderReports from '../modules/widgets/WidgetStakeholderReports'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import AddIcon from '@material-ui/icons/Add'

class CardStakeholder extends React.Component {
    constructor(props) {
        super(props)

        const entity = 'stakeholders'
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        this.state = {
            entity: entity,
            id_field: id_field,
            id_entity: this.props.id_entity,
            stakeholder: {},
        }
    }

    async componentDidMount() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        this.setState({
            stakeholder: await entity_get(entity,id_entity),
        })
    }

    render() {
        const id_entity = this.state.id_entity
        const stakeholderObj = this.state.stakeholder
        if( !Object.keys(stakeholderObj).length ) return <LoaderSingleComponent wifth="100%" />

        //const stakeholder = stakeholderObj.hasOwnProperty('stakeholder') ? stakeholderObj.stakeholder : ''
        const id_people = stakeholderObj.hasOwnProperty("id_people") ? stakeholderObj.id_people : 0
        const id_company = stakeholderObj.hasOwnProperty("id_company") ? stakeholderObj.id_company : 0
        const is_obscured = stakeholderObj.hasOwnProperty("is_obscured") ? stakeholderObj.is_obscured : false

        return <Tabber basicname="stakeholdercard"
                tabs={[
                    {
                        slug: "info",
                        label: "info",
                        content: is_obscured
                            ? <Chip label="Non hai i privilegi per gestire questo cliente" variant="outlined" />
                            : <React.Fragment>
                                {id_company > 0
                                    ? <CardCompany
                                        entity={"companies"}
                                        id_field={"id_company"}
                                        id_entity={id_company}
                                    />
                                    : <CardPeople
                                        entity={"people"}
                                        id_field={"id_people"}
                                        id_entity={id_people}
                                    />}
                            </React.Fragment>
                    },
                    {
                        slug: "practicals",
                        label: "pratiche",
                        content: is_obscured
                            ? <Chip label="Non hai i privilegi per gestire le pratiche di questo cliente" variant="outlined" />
                            : <React.Fragment>
                                <Box mb={4}>
                                    <WidgetStakeholderPracticals id_stakeholder={id_entity} />
                                    <Box mt={1}>
                                        <Button variant="contained" size="small" color="primary"
                                            component={AdapterLink} to={"/practicals/add?id_stakeholder="+id_entity}
                                            startIcon={<AddIcon />}
                                        >pratica</Button>
                                    </Box>
                                </Box>
                                <Box>
                                    <WidgetStakeholderPracticalsOuters id_stakeholder={id_entity} />
                                    <Box mt={1}>
                                        <Button variant="text" size="small" color="primary"
                                            component={AdapterLink} to={"/practicals_outers/add?id_stakeholder="+id_entity}
                                            startIcon={<AddIcon />}
                                        >pratica altri istituti</Button>
                                    </Box>
                                </Box>
                            </React.Fragment>
                    },
                    {
                        slug: "documents",
                        label: "documenti",
                        content: is_obscured
                            ? <Chip label="Non hai i privilegi per gestire i documenti di questo cliente" variant="outlined" />
                            : <WidgetStakeholderFiles id_stakeholder={id_entity} />
                    },
                    {
                        slug: "activities",
                        label: "attività",
                        content: is_obscured
                            ? <Chip label="Non hai i privilegi per gestire le attività di questo cliente" variant="outlined" />
                            : <React.Fragment>
                                <Button
                                    variant="text" size="small"
                                    startIcon={<AddIcon fontSize="small" />}
                                    component={AdapterLink}
                                    to={"/practicals/add"}
                                >Pratica</Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                    variant="text" size="small"
                                    startIcon={<AddIcon fontSize="small" />}
                                    component={AdapterLink}
                                    to={"/tasks/add?id_stakeholder="+id_entity}
                                >Assegna task</Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                    variant="text" size="small"
                                    startIcon={<AddIcon fontSize="small" />}
                                    component={AdapterLink}
                                    to={"/reports/add?id_stakeholder="+id_entity}
                                >Scrivi report</Button>
                                <br />
                                <br />
                                <WidgetStakeholderActivities id_stakeholder={id_entity} />
                                <br />
                                <WidgetStakeholderReports id_stakeholder={id_entity} />
                            </React.Fragment>
                    },
                ]}
            />
    }
}

export default CardStakeholder