import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import SecurityIcon from '@material-ui/icons/Security'

class FieldIdInsurance extends React.Component {
    render() {
        const id_insurance = this.props.id_insurance
        const title = this.props.title ? this.props.title : "Scheda assicurazione"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda assicurazione"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_insurance || id_insurance <= 0 || id_insurance === '-'
            ? null
            : <FieldEntityView title={title} icon={SecurityIcon}
                id_entity={id_insurance} entity="insurances"
                fields={['id_insurance','insurance',]}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/insurances/card/'+id_insurance} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdInsurance