import { doCall,getEP } from '../../requests/api'
import { headerTokenKey,delToken,getToken } from './token'
import {
    hook_login,
    hook_logout,
    hook_forgotpassword,
    hook_resetpassword
} from '../../../services/Hooks'

export async function doLogin( data ) {
    const callUrl = getEP( 'login' )

    const login = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify(data)
    },'text')

    if( login ) hook_login()

    return login
}
export async function doLogout() {
    const callUrl = getEP( 'logout' )

    const logout = await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'numeric')

    if( logout ) hook_logout()

    return logout ? delToken() : false
}
export function isLoggedIn() {
    return getToken() ? true : false
}
/**
 * 
 * @param {*} data Example: {email: '<email>'}
 * @returns 
 */
export async function forgotPwd( data ) {
    const callUrl = getEP( 'forgot_password' )

    const forgot = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify(data)
    },'numeric')

    if( forgot ) hook_forgotpassword()

    return forgot
}
/**
 * 
 * @param {*} r Must be the JWT token received by the user in forgot pwd email
 * @returns 
 */
export async function forgotPwdVerify( r ) {
    const callUrl = getEP( 'forgot_password_verify' )

    const verify = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify({r: r})
    },'json.object')

    return verify
}
/**
 * 
 * @param {*} data Example: {id_user: '<id>',password: '<pwd>'}
 * @returns 
 */
export async function resetPwd( data ) {
    const callUrl = getEP( 'reset_password' )

    const reset = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify(data)
    },'numeric')

    if( reset ) hook_resetpassword()

    return reset
}
/**
 * 
 * @param {*} r Must be the JWT token received by the user in forgot pwd email
 * @returns 
 */
export async function verifyEmail( email,link_endpoint = '',link_params = [] ) {
    const callUrl = getEP( 'verify_email' )

    let postObj = {
        email:email,
    }
    if( link_endpoint ) postObj['link_endpoint'] = link_endpoint
    if( link_params ) postObj['link_params'] = link_params

    const verify = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify(postObj)
    },'numeric')

    return verify
}
/**
 * 
 * @param {*} r Must be the JWT token received by the user in verify email notification
 * @returns 
 */
export async function verifyEmailVerify( r ) {
    const callUrl = getEP( 'verify_email_verify' )

    const verify = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify({r: r})
    },'json.object')

    return verify
}
/**
 * 
 * @param {*} data Example: {useremail: '<useremail>',username: '<username>',password: '<pwd>',display_name: '<display_name>'}
 * @returns 
 */
export async function signUp( data,id_user_group = 0 ) {
    const callUrl = getEP( 'sign_up' )

    const signup = await doCall( callUrl,{
        method: 'POST',
        body: JSON.stringify({
            data:data,
            id_user_group:id_user_group
        })
    },'json.object')

    //if( signup ) hook_signup()

    return signup
}