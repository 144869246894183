import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  absoluteBox: {
    marginTop: theme.spacing(2),
    //position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      position: 'absolute',
      minWidth: 300,
      width: '25%',
      bottom: '5%',
    },
  },
})

class AbsoluteBox extends React.Component  {
  render() {
    const title = this.props.title
    const {classes} = this.props

    return <Box className={classes.absoluteBox}>
      {this.props.children}
    </Box>
  }
}

export default withStyles(styles)(AbsoluteBox)