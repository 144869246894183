
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { groupsFromToken } from '../../@react-fefmwk/auth/services/token'
import { entity_fields_info } from '../../@react-fefmwk/entity/services/api'
import { helpersFormSchema } from '../../services/EntityHelpersFormSchema'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import FieldRadios from '../../@react-fefmwk/form/modules/FieldRadios'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({})

class TargetSelection extends React.Component {
    constructor(props) {
        super(props)

        const groups = groupsFromToken()
        let tasks_targets= [
            {id_task_target:'id_stakeholder',task_target:'Cliente'},
            {id_task_target:'id_practical',task_target:'Pratica'},
        ]
        if(groups.includes(String(1)) || groups.includes(String(2)))
            tasks_targets.push({id_task_target:'id_lead',task_target:'Lead'})

        this.state = {
            tasks_fields_info: {},
            tasks_targets: tasks_targets,
        }
    }
    async componentDidMount() {
        let tasks_fields_info = await entity_fields_info('tasks')

        this.setState({
            tasks_fields_info: tasks_fields_info,
        })
    }
    async componentDidUpdate(prevProps,prevState) {
        const formikProps = this.props.formikProps
        const id_task_target = formikProps.values.id_task_target

        if( prevProps.formikProps.values.id_task_target === id_task_target )
            return

        if( id_task_target === 'id_stakeholder' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_lead:0,id_practical:0},
            })
        }
        if( id_task_target === 'id_practical' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_lead:0,id_stakeholder:0},
            })
        }
        if( id_task_target === 'id_lead' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_stakeholder:0,id_practical:0},
            })
        }
    }

    render() {
        const formikProps = this.props.formikProps

        let tasks_fields_info = this.state.tasks_fields_info
        if( Object.keys(tasks_fields_info).length === 0 )
            return <LoaderSingleComponent width="100%" />

        let helpersTextSchema = helpersFormSchema('tasks')
        helpersTextSchema['id_stakeholder'] = 'Cerca un cliente'
        helpersTextSchema['id_practical'] = 'Cerca una pratica'
        helpersTextSchema['id_lead'] = 'Cerca un lead'

        let fields_info = {}
        fields_info['id_stakeholder'] = tasks_fields_info['id_stakeholder']
        fields_info['id_practical'] = tasks_fields_info['id_practical']
        fields_info['id_lead'] = tasks_fields_info['id_lead']

        const id_task_target = formikProps.values.id_task_target
        if( id_task_target === 'id_stakeholder' ) {
            fields_info.id_practical.input_type = 'hidden'
            fields_info.id_lead.input_type = 'hidden'
            fields_info.id_stakeholder.input_type = 'selectbox'
        }
        if( id_task_target === 'id_practical' ) {
            fields_info.id_stakeholder.input_type = 'hidden'
            fields_info.id_lead.input_type = 'hidden'
            fields_info.id_practical.input_type = 'selectbox'
        }
        if( id_task_target === 'id_lead' ) {
            fields_info.id_practical.input_type = 'hidden'
            fields_info.id_stakeholder.input_type = 'hidden'
            fields_info.id_lead.input_type = 'selectbox'
        }

        const tasks_targets = this.state.tasks_targets

        const id_stakeholder = formikProps.values.id_stakeholder
        const id_practical = formikProps.values.id_practical
        const id_lead = formikProps.values.id_lead

        return <React.Fragment>
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
            >
                <Grid item xs={12} md={2}>
                    <FieldRadios
                        title='Target'
                        items={tasks_targets}
                        name='id_task_target'
                        fieldId='id_task_target'
                        fieldName='task_target'
                        value={String(formikProps.values['id_task_target'])}
                        onChange={formikProps.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    {id_task_target
                        ? <React.Fragment>
                            <EntityFormInputs
                                entity={'tasks'}
                                entityFieldsInfo={fields_info}
                                entityHelpersSchema={helpersTextSchema}
                                formikProps={formikProps}
                            />
                        </React.Fragment>
                        : null}
                </Grid>
                <Grid item xs={12} md={7}>
                    {id_stakeholder
                        ? <React.Fragment>
                            <ViewEntity entity="stakeholders" id_entity={id_stakeholder} dense />
                        </React.Fragment>
                        : null}
                    {id_practical
                        ? <React.Fragment>
                            <ViewEntity entity="practicals" id_entity={id_practical} dense />
                        </React.Fragment>
                        : null}
                    {id_lead
                        ? <React.Fragment>
                            <ViewEntity entity="leads" id_entity={id_lead} dense />
                        </React.Fragment>
                        : null}
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(TargetSelection)