import React from 'react'

import { doPromiseAll } from '../@react-fefmwk/requests/api'
import {stringToDateTime,isValidDate} from '../@react-fefmwk/dates/services/api'
import HChart from '../@react-fefmwk/highcharts/modules/HChart'
import Table from '../@react-fefmwk/tables/modules/Table'
//import Tabber from '../@react-fefmwk/tabs/modules/Tabber'
//import ScoreCard from '../@react-fefmwk/cards/modules/ScoreCard'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import DashboardCard from '../@react-fefmwk/cards/modules/DashboardCard'
import {
    metrics_by_origination,
    metrics_by_product,
    metrics_by_agent,
    metrics_by_branch,
    metrics_by_month
} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
//import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'

class StatsAgents extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            stats: [],
            stats_branches: [],
            stats_month: [],
            stats_product: [],
            stats_origination: [],
        }
    }
    async componentDidMount() {
        this.calcMetrics()
    }
    async componentDidUpdate(prevProps) {
        const start_date = this.props.start_date
        const end_date = this.props.end_date
        const branches = this.props.branches
        if( prevProps.start_date !== start_date || prevProps.end_date !== end_date || prevProps.branches !== branches )
            this.calcMetrics()
    }
    async calcMetrics() {
        const stats_list = this.props.hasOwnProperty('stats')
            ? this.props.stats
            : []
        const users_ids = this.props.hasOwnProperty('users_ids')
            ? this.props.users_ids
            : []
        const branches = this.props.hasOwnProperty('branches') && this.props.branches
            ? (typeof this.props.branches === 'string'
                ? [this.props.branches]
                : this.props.branches)
            : []

        const start_date = this.props.start_date
        const end_date = this.props.end_date
        const startDtTime = stringToDateTime( start_date,"dd/MM/yyyy" )
        const endDtTime = stringToDateTime( end_date,"dd/MM/yyyy" )

        if( isValidDate(startDtTime) && isValidDate(endDtTime) && startDtTime < endDtTime ) {
            this.setState({
                loading: true,
                stats: [],
                stats_branches: [],
                stats_month: [],
                stats_product: [],
                stats_origination: [],
            })

            let calls = {}
            if( stats_list.includes('metrics_by_branch') || !stats_list.length )
                calls['stats_branches'] = metrics_by_branch({
                    start_date: start_date,
                    end_date: end_date,
                    users_ids: users_ids,
                    branches: branches,
                    stats: 'activities_n,assigned_leads_n,managed_leads_n,reports_n,practicals_n,compounding_approved,compounding_disbursed,fees_earned,fees_avg'
                })
            if( stats_list.includes('metrics_by_agent') || !stats_list.length )
                calls['stats'] = metrics_by_agent({
                    start_date: start_date,
                    end_date: end_date,
                    users_ids: users_ids,
                    branches: branches,
                    stats: 'activities_n,assigned_leads_n,managed_leads_n,reports_n,practicals_n,compounding_approved,compounding_disbursed,fees_earned,fees_avg'
                })
            if( stats_list.includes('metrics_by_origination') || !stats_list.length )
                calls['stats_origination'] = metrics_by_origination({
                    start_date: start_date,
                    end_date: end_date,
                    users_ids: users_ids,
                    branches: branches,
                    stats: 'practicals_n,compounding_approved,compounding_disbursed,fees_earned,fees_avg'
                })
            if( stats_list.includes('metrics_by_product') || !stats_list.length )
                calls['stats_product'] = metrics_by_product({
                    start_date: start_date,
                    end_date: end_date,
                    users_ids: users_ids,
                    branches: branches,
                    stats: 'practicals_n,compounding_approved,compounding_disbursed,fees_earned,fees_avg'
                })
            if( stats_list.includes('metrics_by_month') || !stats_list.length )
                calls['stats_month'] = metrics_by_month({
                    start_date: start_date,
                    end_date: end_date,
                    users_ids: users_ids,
                    branches: branches,
                    stats: 'compounding_disbursed,compounding_approved'
                })

            const calls_promises = Object.values(calls)
            const calls_keys = Object.keys(calls)
            doPromiseAll(
                calls_promises,
                (response,r) => {//callback
                    const key = calls_keys[r]
                    let st = {}
                    st[key] = response
                    this.setState({
                        ...this.state,
                        ...st
                    })
                },
                () => {//at_finally
                    this.setState({
                        ...this.state,
                        ...{loading: false}
                    })
                }
            )
        }
    }

    render() {
        const loading = this.state.loading
        const stats_origination = this.state.stats_origination
        const stats_product = this.state.stats_product
        const stats_month = this.state.stats_month
        const stats = this.state.stats
        const stats_branches = this.state.stats_branches

        //const rowActionsBranches = actionSchema("branches","/agencies-branch")
        //const rowActionsAgents = actionSchema("agents",EntitySchema["agents"].rootpath)
        let fieldsProps = tableSchema("metrics")

        const LoadingSpinner = <LoaderSingleComponent layout="circular" />
        const LoadingChip = loading
            ? <Chip
                icon={loading ? LoadingSpinner : null}
                label={loading ? "loading..." : "Nessun statistica disponibile"}
                size="small"
                variant="outlined"
                style={{border:0}}
            />
            : null

        const stats_list = this.props.hasOwnProperty('stats')
            ? this.props.stats
            : []

        return <React.Fragment>
            {stats.length === 1 && (stats_list.includes('metrics_by_agent') || !stats_list.length)
                ? <Box mb={1}>
                    {stats.length
                        ? <Paper>
                            <Table material dense highlight
                                data={[stats[0]]}
                                fieldsProps={{
                                    ...fieldsProps,
                                    ...{id_agent: {show: false}}
                                }}
                                defaultPageSize={1}
                                showPagination={false}
                                filterable={false}
                                sortable={false}
                            />
                        </Paper>
                        : LoadingChip}
                </Box>
                : null}
            {stats_list.includes('metrics_by_branch') || !stats_list.length
                ? <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        {stats_branches.length
                            ? <Paper>
                                <Table material dense highlight
                                    {...stats.length === 1 ? {hideHeaders: true} : {}}
                                    data={stats_branches}
                                    fieldsProps={{
                                        ...fieldsProps,
                                        ...{id_agent: {show: false}}
                                    }}
                                    //rowActions={stats.length === 1 ? {} : rowActionsBranches}
                                    //rowActionsField="id_branch"
                                    //rowActionsFieldValue="agencies_branches"
                                    defaultPageSize="100"
                                    showPagination={false}
                                    filterable={false}
                                    sortable={true}
                                />
                            </Paper>
                            : LoadingChip}
                    </Grid>
                </Grid>
                : null}
            {stats_list.includes('metrics_by_month') || !stats_list.length
                ? <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <DashboardCard
                            title="Evoluzione della produzione"
                            contentStyle={{padding:0,paddingTop:8}}
                            /*TopActions={<IconButton size="small" onClick={() => alert('click')}>
                                <AddIcon fontSize="small" />
                            </IconButton>}*/
                            Content={stats_month.length
                                ? <HChart
                                    //type={{compounding_disbursed:'column'}}
                                    type="column"
                                    data={stats_month}
                                    height={200}
                                    name={{
                                        compounding_disbursed: 'Montanti erogati',
                                        compounding_approved: 'Montanti approvati',
                                    }}
                                    chart={{height:200}}
                                    plotOptions={{column: {
                                        //stacking: 'normal',
                                        /*dataLabels: {
                                            formatter: function(){return Highcharts.numberFormat(this.y,0) + '€';}
                                        },*/
                                    }}}
                                    //data_type={[]}
                                    //zIndex={[]}
                                    //index={[]}
                                    //yAxis={[]}
                                    //data={[{"ott-22":1},{"nov-22":3},{"dic-22":4},{"gen-23":2},{"feb-23":5}]}
                                />
                                : <Box p={2}>{LoadingChip}</Box>}
                            />
                    </Grid>
                </Grid>
                : null}
            <Grid container spacing={2}>
                {stats_list.includes('metrics_by_product') || !stats_list.length
                    ? <Grid item md={6} xs={12}>
                        {stats_product.length
                            ? <Paper>
                                <Table material dense highlight
                                    data={stats_product}
                                    fieldsProps={fieldsProps}
                                    //rowActions={rowActionsProds}
                                    //rowActionsField="id_product"
                                    defaultPageSize="100"
                                    showPagination={false}
                                    filterable={false}
                                    sortable={true}
                                />
                            </Paper>
                            : LoadingChip}
                    </Grid>
                    : null}
                {stats_list.includes('metrics_by_origination') || !stats_list.length
                    ? <Grid item md={6} xs={12}>
                        {stats_origination.length
                            ? <Paper>
                                <Table material dense highlight
                                    data={stats_origination}
                                    fieldsProps={fieldsProps}
                                    //rowActions={rowActionsProds}
                                    //rowActionsField="id_practical_origination"
                                    defaultPageSize="100"
                                    showPagination={false}
                                    filterable={false}
                                    sortable={true}
                                />
                            </Paper>
                            : LoadingChip}
                    </Grid>
                    : null}
                </Grid>
            {/*<Grid container spacing={2}>
                <Grid item>
                    <ScoreCard fullWidth
                        title="# prova"
                        value={50}
                        orientation="horizontal"
                    />
                </Grid>
                </Grid>*/}
            {stats.length > 1 && (stats_list.includes('metrics_by_agent') || !stats_list.length)
                ? <Box mt={2}>
                    {stats.length
                        ? <Paper>
                            <Table material dense highlight
                                data={stats}
                                fieldsProps={fieldsProps}
                                //rowActions={rowActionsAgents}
                                //rowActionsField="id_agent"
                                defaultPageSize="100"
                                showPagination={false}
                                filterable={stats.length > 15 ? true : false}
                                sortable={true}
                            />
                        </Paper>
                        : LoadingChip}
                </Box>
                : null}
        </React.Fragment>
    }
}

export default StatsAgents