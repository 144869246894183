import React from 'react'

import FieldText from './FieldText'

class FieldTextarea extends React.Component {

    render() {
        const props = {
            ...{
                InputProps:{
                    style:{minWidth:300}
                }
            },
            ...this.props
        }

        return <React.Fragment>
            <FieldText {...props} multiline={true} />
        </React.Fragment>
    }
}

export default FieldTextarea