import React from 'react'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import {
    stringToDateTime,
    getThemeLocale,
    getDbDateFormat,
    getThemeDateFormat
} from '../../dates/services/api'

import FormHelperText from '@material-ui/core/FormHelperText'
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers'

class FieldDateTime extends React.Component {
    formatDateTime(dtTime,dbFormat,displayFormat) {
        if(!dtTime) return null
        else {
            let converted = 0
            converted = stringToDateTime(dtTime,displayFormat)
            if( !Date.parse(converted) ) {
                converted = stringToDateTime(dtTime,dbFormat)
            }
            return converted
        }
    }
    setValueDateTime(dateTimeString,dbFormat,displayFormat) {
        if(!dateTimeString) return null
        let converted = stringToDateTime(dateTimeString,displayFormat)
        if( Date.parse(converted) ) {
            return format(converted,dbFormat)
        }
        return dateTimeString
    }
    render() {
        const formikProps = this.props.formikProps

        const locale = getThemeLocale()
        const dateFormat = getDbDateFormat(true)
        const dateFormatDisplay = this.props.hasOwnProperty('format')
            ? this.props.format
            : getThemeDateFormat(true)

        const disableToolbar = this.props.disableToolbar ? this.props.disableToolbar : true
        const variant = this.props.variant ? this.props.variant : ""
        let value = this.props.value === null ? '' : this.props.value
        const helperText = this.props.helperText

        value = this.formatDateTime(value,dateFormat,dateFormatDisplay)

        return <MuiPickersUtilsProvider utils={DateFnsUtils} locale={Object.keys(locale).length ? locale : null}>
            <KeyboardDateTimePicker
                disableToolbar={disableToolbar}
                variant={variant}
                format={dateFormatDisplay}
                margin="normal"
                id={this.props.id}
                name={this.props.name}
                error={this.props.error}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                label={this.props.label}
                value={value}
                onChange={(_,dateTimeString) => {
                    dateTimeString = this.setValueDateTime(dateTimeString,dateFormat,dateFormatDisplay)
                    formikProps.setFieldValue(this.props.name,dateTimeString)
                    formikProps.setFieldTouched(this.props.name,true,true)
                }}
                onBlur={this.props.onBlur}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                inputProps={this.props.inputProps}
            />
            <FormHelperText component="div">{helperText}</FormHelperText>
        </MuiPickersUtilsProvider>
    }
}

export default FieldDateTime