/** //MOCKUP PRIVILEGIES OBJECT
 * "privilegies": {
        "list": {
            "users_can": [],
            "groups_can": [],
        },
        "view": {
            "users_can": [],
            "groups_can": [],
        },
        "add": {
            "users_can": [],
            "groups_can": [],
        },
        "update": {
            "users_can": [],
            "groups_can": [],
        },
        "delete": {
            "users_can": [],
            "groups_can": [],
        },
    },
 */
export const EntitySchema = {
    "users": {
        "entity": "users",
        "id_field": "id_user",
        "ref_field": "display_name",
        "title": "Utenti",
        "title_singular": "Utente",
        "rootpath": "/users",
        "db": "chequinto",
        "table": "users",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [1],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1],
            },
        },
    },
    "people": {
        "entity": "people",
        "id_field": "id_people",
        "ref_field": "last_name",
        "title": "Persone",
        "title_singular": "Persona",
        "rootpath": "/people",
        "db": "chequinto",
        "table": "people",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [1],
            },
            "add": {
                "groups_can": [1,2,5],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "companies": {
        "entity": "companies",
        "id_field": "id_company",
        "ref_field": "company_name",
        "title": "Aziende",
        "title_singular": "Azienda",
        "rootpath": "/companies",
        "db": "chequinto",
        "table": "companies",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [1],
            },
            "add": {
                "groups_can": [1,2,3,5],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "addresses": {
        "entity": "addresses",
        "id_field": "id_address",
        "title": "Indirizzi",
        "title_singular": "Indirizzo",
        "rootpath": "/addresses",
        "db": "chequinto",
        "table": "addresses",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [],
            },
        },
    },
    "contacts": {
        "entity": "contacts",
        "id_field": "id_contact",
        "title": "Recapiti",
        "title_singular": "Recapito",
        "rootpath": "/contacts",
        "db": "chequinto",
        "table": "contacts",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [],
            },
        },
    },
    "stakeholders": {
        "entity": "stakeholders",
        "id_field": "id_stakeholder",
        "ref_field": "stakeholder",
        "title": "Contatti",
        "title_singular": "Contatto",
        "rootpath": "/stakeholders",
        "db": "chequinto",
        "table": "stakeholders",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5],
            },
            "view": {
                "groups_can": [1,2,5],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "customers": {
        "entity": "stakeholders",
        "id_field": "id_stakeholder",
        "ref_field": "stakeholder",
        "title": "Clienti",
        "title_singular": "Cliente",
        "rootpath": "/customers",
        "db": "chequinto",
        "table": "stakeholders",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5],
            },
            "view": {
                "groups_can": [1,2,5],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "agents": {
        "entity": "agents",
        "id_field": "id_agent",
        "ref_field": "display_name",
        "title": "Collaboratori",
        "title_singular": "Collaboratore",
        "rootpath": "/agents",
        "db": "chequinto",
        "table": "agents",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5,6],
            },
            "view": {
                "groups_can": [1,2,5,6],
            },
            "add": {
                "groups_can": [1,],
            },
            "update": {
                "groups_can": [1,],
            },
            "delete": {
                "groups_can": [1,],
            },
        },
    },
    "atcs": {
        "entity": "atcs",
        "id_field": "id_atc",
        "ref_field": "atc",
        "title": "ATC",
        "title_singular": "ATC",
        "rootpath": "/atcs",
        "db": "chequinto",
        "table": "atcs",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,3,5,6,7],
            },
            "view": {
                "groups_can": [1,2,3,5,6,7],
            },
            "add": {
                "groups_can": [1,2,3,5,6,7],
            },
            "update": {
                "groups_can": [1,2,3,5,6,7],
            },
            "delete": {
                "groups_can": [1,2,5,6,7],
            },
        },
    },
    "insurances": {
        "entity": "insurances",
        "id_field": "id_insurance",
        "ref_field": "insurance",
        "title": "Assicurazioni",
        "title_singular": "Assicurazione",
        "rootpath": "/insurances",
        "db": "chequinto",
        "table": "insurances",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,3,5,6,7],
            },
            "view": {
                "groups_can": [1,2,3,5,6,7],
            },
            "add": {
                "groups_can": [1,2,5,],
            },
            "update": {
                "groups_can": [1,2,5,],
            },
            "delete": {
                "groups_can": [1,2,5,],
            },
        },
    },
    "insurances_quotations": {
        "entity": "insurances_quotations",
        "id_field": "id_insurance_quotation",
        "title": "Quotazioni assicurazioni",
        "title_singular": "Quotazione",
        "rootpath": "/insurances_quotations",
        "db": "chequinto",
        "table": "insurances_quotations",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [],
            },
            "view": {
                "groups_can": [],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "practicals": {
        "entity": "practicals",
        "id_field": "id_practical",
        "ref_field": "practical_desc",
        "title": "Pratiche",
        "title_singular": "Pratica",
        "rootpath": "/practicals",
        "db": "chequinto",
        "table": "practicals",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5],
            },
            "view": {
                "groups_can": [1,2,5],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "practicals_commissions": {
        "entity": "practicals_commissions",
        "id_field": "id_practical_commission",
        "ref_field": "gross_commission",
        "title": "Provvigioni",
        "title_singular": "Provvigione",
        "rootpath": "/practicals_commissions",
        "db": "chequinto",
        "table": "practicals_commissions",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2],
            },
            "view": {
                "groups_can": [1,2],
            },
            "add": {
                "groups_can": [1,2],
            },
            "update": {
                "groups_can": [1,2],
            },
            "delete": {
                "groups_can": [1,2],
            },
        },
    },
    "practicals_outers": {
        "entity": "practicals_outers",
        "id_field": "id_practical_outer",
        "ref_field": "practical_outer_desc",
        "title": "Pratiche altri istituti",
        "title_singular": "Pratica altro istituto",
        "rootpath": "/practicals_outers",
        //"db": "chequinto",
        "table": "practicals_outers",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5],
            },
            "view": {
                "groups_can": [1,2,5],
            },
            "add": {
                "groups_can": [1,2,3,5,6,7],
            },
            "update": {
                "groups_can": [1,2,3,5,6,7],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "practicals_lenders": {
        "entity": "practicals_lenders",
        "id_field": "id_lender",
        "ref_field": "lender",
        "title": "Istituti creditori",
        "title_singular": "Istituto creditore",
        "rootpath": "/practicals_lenders",
        //"db": "chequinto",
        "table": "practicals_lenders",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5,6,7],
            },
            "view": {
                "groups_can": [1,2,5,6,7],
            },
            "add": {
                "groups_can": [],
            },
            "update": {
                "groups_can": [1,2,5,6,7],
            },
            "delete": {
                "groups_can": [1],
            },
        },
    },
    "products": {
        "entity": "products",
        "id_field": "id_product",
        "ref_field": "product",
        "title": "Prodotti",
        "title_singular": "Prodotto",
        "rootpath": "/products",
        "db": "chequinto",
        "table": "products",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [1],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1],
            },
        },
    },
    "leads": {
        "entity": "leads",
        "id_field": "id_lead",
        "title": "Lead",
        "title_singular": "Lead",
        "rootpath": "/leads",
        "db": "chequinto",
        "table": "leads",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,8],
            },
            "view": {
                "groups_can": [1,2,8],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1,2,8],
            },
        },
    },
    "leads_channels": {
        "entity": "leads_channels",
        "id_field": "id_lead_channel",
        "ref_field": "lead_channel",
        "title": "Canali lead",
        "title_singular": "Canale lead",
        "rootpath": "/leads_channels",
        "db": "chequinto",
        "table": "leads_channels",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,8],
            },
            "view": {
                "groups_can": [1,2,8],
            },
            "add": {
                "groups_can": [1,2],
            },
            "update": {
                "groups_can": [1,2],
            },
            "delete": {
                "groups_can": [1,2],
            },
        },
    },
    "campaigns": {
        "entity": "campaigns",
        "id_field": "id_campaign",
        "ref_field": "campaign",
        "title": "Campagne",
        "title_singular": "Campagna",
        "rootpath": "/campaigns",
        "db": "chequinto",
        "table": "campaigns",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2],
            },
            "view": {
                "groups_can": [1,2],
            },
            "add": {
                "groups_can": [1,2],
            },
            "update": {
                "groups_can": [1,2],
            },
            "delete": {
                "groups_can": [1,2],
            },
        },
    },
    "files": {
        "entity": "files",
        "id_field": "file",
        "title": "File",
        "title_singular": "File",
        "rootpath": "/files",
        "db": "chequinto",
        "table": "",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [1],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [],
            },
        },
    },
    "reports": {
        "entity": "reports",
        "id_field": "id_report",
        "title": "Report",
        "title_singular": "Report",
        "rootpath": "/reports",
        "db": "chequinto",
        "table": "reports",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2],
            },
            "view": {
                "groups_can": [1,2],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1],
            },
        },
    },
    "activities": {
        "entity": "activities",
        "id_field": "id_activity",
        "title": "Attività",
        "title_singular": "Attività",
        "rootpath": "/activities",
        "db": "chequinto",
        "table": "activities",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2],
            },
            "view": {
                "groups_can": [1,2],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1,2],
            },
        },
    },
    "practicals_histories": {
        "entity": "activities",
        "id_field": "id_activity",
        "title": "Eventi pratiche",
        "title_singular": "Evento pratica",
        "rootpath": "/practicals_histories",
        "db": "chequinto",
        "table": "activities",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2,5],
            },
            "view": {
                "groups_can": [1,2,5],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1,2,5],
            },
        },
    },
    "tasks": {
        "entity": "activities",
        "id_field": "id_activity",
        "title": "Task",
        "title_singular": "Task",
        "rootpath": "/tasks",
        "db": "chequinto",
        "table": "activities",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1,2],
            },
            "view": {
                "groups_can": [1,2],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1,2],
            },
        },
    },
    "assignments": {
        "entity": "activities",
        "id_field": "id_activity",
        "title": "Richieste",
        "title_singular": "Richiesta",
        "rootpath": "/assignments",
        "db": "chequinto",
        "table": "activities",
        "timeinfo_table": null,
        "privilegies": {
            "list": {
                "groups_can": [1],
            },
            "view": {
                "groups_can": [1],
            },
            "add": {
                "groups_can": [1],
            },
            "update": {
                "groups_can": [1],
            },
            "delete": {
                "groups_can": [1],
            },
        },
    },
}