//import React from 'react'
//import Formatter from '../@react-fefmwk/formatter/modules/Formatter'

export function tableTimeinfoSchema(entity,cftime){
    switch (cftime) {
        /*case "aum":
        case "soft-nav":
        case "aum-adjusted":
        case "market_value":
            switch (entity) {
                case "vehicles":
                    return {
                        value: {
                            Cell: (props) => {
                                const value=props.value
                                return <Formatter
                                    value={value}
                                    dataType="currency"
                                    currency="EUR"
                                    decimalDigits="2"
                                    exponent="0"
                                />
                            },
                        },
                    }
            }
        break*/
        default:
            return {}
    }
}