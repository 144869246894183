import {
    showAppLoader,
    hideAppLoader
} from '../loader-app/services/api'
import {
    showErrorMsgApp,
    //hideErrorMsgApp
} from '../messages/services/api'
import { serviceUrl,EP } from '../../services/BE'
import {doLogout} from '../auth/services/api'

export function trimSlashes(s,c) {
    if (c === "]") c = "\\]"
    if (c === "^") c = "\\^"
    if (c === "\\") c = "\\\\"
    return s.replace(new RegExp(
        "^[" + c + "]+|[" + c + "]+$", "g"
    ), "")
}
export function currentUrl() {
    return window.location.pathname
}
export function currentDomain() {
    return window.location.hostname
}
export function currentOrigin() {
    return window.location.origin
}
export function getEP( ep,servUrl = '' ) {
    let EPObj = {}
    if(typeof EP === 'function')
        EPObj = EP()
    else
        EPObj = EP

    if( !EPObj.hasOwnProperty(ep) )
        return false

    return trimSlashes(servUrl === '' ? serviceUrl : servUrl,'/') + EPObj[ep]
}
export function onErrorHook(error) {
    if( error.message === 'Not authorized user' ) doLogout()
}
export function doPromiseAll( calls,callback,at_finally = false ) {
    const calls_n = calls.length
    let calls_counter = 0
    Promise.all(calls.map((promise,p) => {
        return promise
            .then((response) => {
                calls_counter += 1
                callback(response,p)
            })
            .then(() => hideAppLoader())
            .then(() => parseInt(calls_counter) === parseInt(calls_n) && at_finally
                ? at_finally()
                : () => true
            )
    }))
}
export async function doCall( url,call,out,options = {} ) {
    const filename = options.hasOwnProperty('filename') ? options.filename : ''
    const loader = options.hasOwnProperty('loader') ? options.loader : true
    const force_download = options.hasOwnProperty('force_download') ? options.force_download : true

    if( loader ) showAppLoader()

    try {
        const promise = await fetch(url,call)
        if (!promise.ok) throw Error(promise.statusText)

        switch( out ) {
            default:
            case 'json.array':
            case 'json.object':
                return promise.json()

            case 'text':
            case 'numeric':
                return promise.text()

            case 'file'://generic for file (extension handled from outside)
            case 'xlsx':
            case 'pdf':
            case 'zip':
            case 'txt':
                return promise.blob()
                    .then(blob => {
                        const newBlob = new File([blob], filename, {type: blob.type})
                        var url = window.URL.createObjectURL(newBlob)
                        if( !force_download ) {
                            return url
                        } else {
                            const _filename = (!filename ? "download." : (filename))+(out === 'file' ? '' : ("."+out))
                            forceDownload(url,_filename)
                        }
                    })
        }
    } catch (error) {
        showErrorMsgApp(error.message)
        onErrorHook(error)
        console.log(error)
        switch( out ) {
            default:
            case 'json.array':
                return []

            case 'json.object':
                return {}

            case 'text':
                return ''

            case 'numeric':
                return 0

            case 'file':
            case 'xlsx':
            case 'pdf':
            case 'zip':
            case 'txt':
                return {}
        }
    } finally {
        hideAppLoader()
    }
}
export function forceDownload(url,filename) {
    var a = document.createElement('a')
    a.href = url
    a.download = filename ? filename : basename(url)
    document.body.appendChild(a)//we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove()//afterwards we remove the element again
}
export function extension( filename ) {
    return filename.split('.').pop();
}
export function basename( path = '' ) {
    if( path === '' )
        path = currentUrl()
    
    return path.split('/').reverse()[0];
}
export function backUrl( depth = 1,path = '' ) {
    if( path === '' )
        path = currentUrl()

    let i = 1;
    for( i = 1; i <= depth; i++ ) {
        path = path.substring( 0,path.lastIndexOf("/") )
    }

    return path
}
export function isValidUrl(urlString) {
    if( !urlString || !typeof urlString === 'string' ) {
        return false
    }

    let origin = currentOrigin()//get current domain with protocol
    origin = !origin ? 'https://domain.com' : origin

    //check if input url is a relative url or contains protocol
    const isRelative = !urlString.includes('://')
    //check if is blob url
    const isBlob = urlString.includes('blob:http')
    //if( isBlob ) return true

    //set test url
    const testUrl = (isRelative ? origin : '') + (isBlob ? urlString.replace('blob:','') : urlString)

    //exec test
    try {
        new URL(testUrl)
        return true
    } catch (err) {
        return false
    }
    //const pattern = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    //return !!pattern.test( testUrl )
}