import React from 'react'

import FieldText from './FieldText'

class FieldEmail extends React.Component {

    render() {
        const inputProps={
            ...{style:{minWidth:300}},
            ...this.props.inputProps
        }
        return <React.Fragment>
            <FieldText {...this.props} InputProps={inputProps} />
        </React.Fragment>
    }
}

export default FieldEmail