import React from 'react'

import {
    entity_create,
    entity_update
} from '../@react-fefmwk/entity/services/api'

import StakeholderType from '../modules/add-stakeholder-stepper/StakeholderType'
import StakeholderData from '../modules/add-stakeholder-stepper/StakeholderData'
import Checkout from '../modules/add-stakeholder-stepper/Checkout'

export const AddStakeholderStepperSchema = [
    {
        "name": "stakeholder_type",
        "icon": false,
        "title": "Tipo contatto",
        "description": "Scegli un tipo di contatto: persona fisica o giuridica?",
        "component": (formikProps) => <StakeholderType formikProps={formikProps} />,
    },
    {
        "name": "stakeholder_data",
        "icon": false,
        "title": "Dati contatto",
        "description": "Compila il form con i dati del contatto",
        "component": (formikProps) => <StakeholderData formikProps={formikProps} />,
    },
    {
        "name": "checkout",
        "icon": false,
        "title": "Checkout",
        "description": "Controlla i dati",
        "component": (formikProps) => <Checkout formikProps={formikProps} />,
    },
]

export const AddStakeholderStepperFormSchema = {
    stakeholder_type: '',
    first_name: '',
    last_name: '',
    main_mobile: '',
    birth_date: '',
    fiscal_code: '',
    birth_place: '',
    professione: '',
    datore_lavoro: '',
    stipendio_mensile: '',
    id_atc_type: '',
    company_name: '',
    vat_number: '',
    id_stakeholder: 0,
    id_people: 0,
    id_company: 0,
    stakeholdernotindb: false
}

export const AddStakeholderStepperValidationSchema = [
    (values) => {
        let errors = {}
        const stakeholder_type = values.stakeholder_type
        const id_people = values.id_people
        const id_company = values.id_company
        const id_stakeholder = values.id_stakeholder
        const stakeholdernotindb = values.stakeholdernotindb

        if( !stakeholder_type )
            errors['stakeholder_type'] = 'Choose a valid stakeholder type'

        if( !stakeholdernotindb && !id_people && !id_company )
            errors['stakeholdernotindb'] = 'Confirm that stakeholder is not already in database'

        if( id_stakeholder > 0 )
            errors['id_stakeholder'] = 'Stakeholder already in DB'

        return errors
    },
    (values) => {
        let errors = {}
        const stakeholder_type = values.stakeholder_type

        if( stakeholder_type === "people" ) {
            if( !values['first_name'] ) errors['first_name'] = 'Indica un nome'
            if( !values['last_name'] ) errors['last_name'] = 'Indica un cognome'
            if( !values['main_mobile'] ) errors['main_mobile'] = 'Indica un telefono'
            if( !/^\d+$/.test(values['main_mobile']) ) errors['main_mobile'] = 'Il telefono deve contenere solo numeri'
        }
        if( stakeholder_type === "company" ) {
            if( !values['company_name'] ) errors['first_name'] = 'Indica una ragione sociale'
            if( !values['vat_number'] ) errors['vat_number'] = 'Indica una partita IVA valida'
            if( values['vat_number'].length != 11 ) errors['vat_number'] = 'Partita IVA non valida'
            if( /\s/g.test(values['vat_number']) ) errors['vat_number'] = 'La partita IVA non può contenere spazi'
        }

        return errors
    },
]

export const AddStakeholderStepperSubmitSchema = [
    {
        "enableSubmit": false,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            return true
        }
    },
    {
        "enableSubmit": true,
        "updateState": true,
        "submit": async (prevState,values,actions) => {
            const stakeholder_type = values.stakeholder_type

            if(stakeholder_type === 'people') {
                const people = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    main_mobile: values.main_mobile,
                }

                let id_people = values.id_people
                if( !id_people ) {
                    const inserted_people = await entity_create( 'people',values )
                    id_people = inserted_people['last_id']
                }
                else {
                    await entity_update( 'people',id_people,values )
                }

                if( id_people ) {
                    let stakeholder = {id_people: id_people,id_company: 0}
                    const inserted_stakeholder = await entity_create( 'stakeholders',stakeholder )
                    const id_stakeholder = inserted_stakeholder['last_id']
                    if( id_stakeholder ) {
                        actions.setFieldValue("id_people",id_people)
                        actions.setFieldValue("id_stakeholder",id_stakeholder)
                        return {...people,...stakeholder}
                    }
                }
            }
            if(stakeholder_type === 'company') {
                const company = {
                    company_name: values.company_name,
                    vat_number: values.vat_number,
                }

                let id_company = values.id_company
                if( !id_company ) {
                    const inserted_company = await entity_create( 'companies',company )
                    id_company = inserted_company['last_id']
                }
                else {
                    await entity_update( 'companies',id_company,values )
                }

                if( id_company ) {
                    let stakeholder = {id_people: 0,id_company: id_company}
                    const inserted_stakeholder = await entity_create( 'stakeholders',stakeholder )
                    const id_stakeholder = inserted_stakeholder['last_id']
                    if( id_stakeholder ) {
                        actions.setFieldValue("id_company",id_company)
                        actions.setFieldValue("id_stakeholder",id_stakeholder)
                        return {...company,...stakeholder}
                    }
                }
            }

            return false
        }
    },
]