import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import AddStakeholder from '../modules/AddStakeholder'

const AddStakeholderPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id_entity
    const queryString = props.route.location.search

    return <Content title={title}>
        <AddStakeholder id_entity={id_entity} queryString={queryString} />
    </Content>
}

export default AddStakeholderPage