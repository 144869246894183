import React from 'react'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import UpdatePracticalCommission from '../modules/UpdatePracticalCommission'

class UpdatePracticalCommissionPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        const queryString = this.props.route.location.search
        let queryStringObj = {}
        if( queryString ) queryStringObj = queryStringToObj(queryString)

        const embed = this.props.route.path.includes('/embed/') ? true : false
        const BoxContent = embed
            ? React.Fragment
            : Content
        const boxContentProps = embed
            ? {}
            : {title: title}
    
        return <BoxContent {...boxContentProps}>
            <UpdatePracticalCommission id_entity={id_entity} precompiledData={queryStringObj} />
        </BoxContent>
    }
}

export default UpdatePracticalCommissionPage