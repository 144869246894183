import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CheckIcon from '@material-ui/icons/Check'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

class FieldIdActivity extends React.Component {
    render() {
        const id_activity = this.props.id_activity
        const title = this.props.title ? this.props.title : "Scheda attività"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda attività"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_activity || id_activity <= 0 || id_activity === '-'
            ? null
            : <FieldEntityView title={title} icon={AssignmentIcon}
                id_entity={id_activity} entity="activities"
                fields={[
                    'id_activity',
                    'activity_stakeholder',
                    'activity_order','activity_target','activity_state',
                    'activity_instruction',
                    'id_agent','id_user',
                    'id_practical','id_lead','id_activity_stakeholder',
                    'id_report','closed_at',
                    'active_at','created_at','updated_at',
                ]}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/activities/card/'+id_activity} target="_blank"
                    >Visualizza</Button>
                    <Button size="small" startIcon={<CheckIcon />}
                        component={AdapterLink} to={'/activities/close/'+id_activity}
                    >Chiudi</Button>
                    <Button size="small" startIcon={<AccessTimeIcon />}
                        component={AdapterLink} to={'/tasks/postpone/'+id_activity}
                    >Rimanda</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdActivity