
import React from 'react'
import Content from '../../../theme-structure/modules/Content'
import {EntitySchema} from '../../../../services/EntitySchema'
import Tabber from '../../../tabs/modules/Tabber'
import ViewEntity from '../ViewEntity'
import EntityTitle from '../EntityTitle'
import TimeinfoEntity from '../timeinfo/TimeinfoEntity'

import {checkPrivilegies} from '../../services/api'
import Page404 from '../../../theme-structure/modules/404'
import Page401 from '../../../theme-structure/modules/401'

const EntityPageView = (props) => {
    const id_entity = props.route.hasOwnProperty('id_entity')
        ? props.route.id_entity
        : props.route.computedMatch.params.id
    const entity = props.route.hasOwnProperty('entity')
        ? props.route.entity
        : props.route.computedMatch.params.entity

    const entityObj = EntitySchema[entity]
    if( !entityObj || !Object.keys(entityObj).length )
        return <Page404 />
    if( !checkPrivilegies( entityObj,"view" ) )
        return <Page401 />

    const timeinfo_table = entityObj.timeinfo_table

    let title = 'Unknown entity'
    if( entityObj && Object.keys(entityObj).length ) {
        title = props.route.hasOwnProperty("title") && props.route.title
            ? props.route.title
            : (entityObj.title_singular+" view")
    }

    return <Content title={title} toolbar={<EntityTitle entity={entity} id_entity={id_entity} />}>
        {timeinfo_table
            ? <Tabber basicname={"entity-view-"+entity+"-"+id_entity}
                tabs={[
                    {
                        slug: "info",
                        label: "info",
                        content: <ViewEntity entity={entity} id_entity={id_entity} />
                    },
                    {
                        slug: "timeinfo",
                        label: "timeinfo",
                        content: <TimeinfoEntity entity={entity} id_entity={id_entity} />
                    },
                ]}
            />
            : <ViewEntity entity={entity} id_entity={id_entity} />
        }
    </Content>
}

export default EntityPageView