import React from 'react'

import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import { EntitySchema } from '../../services/EntitySchema'
import { tableSchema } from '../../services/EntityTableSchema'
import { actionSchema } from '../../services/EntityRowActionsSchema'
import { stakeholders_get_activities } from '../../services/BE'

class WidgetStakeholderActivities extends React.Component {
    constructor(props) {
        super(props)

        const entity = "tasks"
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        this.state = {
            entity: entity,
            id_field: id_field,
            path: this.props.path,
            id_stakeholder: this.props.id_stakeholder,
            activities: [],
        }
    }
    
    async getActivities() {
        const id_stakeholder = this.props.id_stakeholder
        this.setState({
            activities: await stakeholders_get_activities( id_stakeholder ),
        })
    }
    
    async componentDidMount() {
        this.getActivities()
    }

    async componentDidUpdate(prevProps) {
        const id_stakeholder = this.props.id_stakeholder
        if( id_stakeholder !== prevProps.id_stakeholder )
            this.getActivities()
    }

    render() {
        const id_stakeholder = this.props.id_stakeholder
        const entity = this.state.entity
        const id_field = this.state.id_field
        const activities = this.state.activities
        const fieldsProps = tableSchema(entity)

        const tableRowActions = this.props.hasOwnProperty('tableRowActions')
            ? this.props.tableRowActions
            : true
        const rowActions = tableRowActions
            ? actionSchema(entity)
            : {}

        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Attività"

        return <DashboardCard title={dashboardcardTitle}
            Content={activities.length
                ? <Table material highlight dense
                    data={activities}
                    fieldsProps={fieldsProps}
                    rowActions={rowActions}
                    rowActionsField={id_field}
                    showPagination={true}
                    sortable={true}
                    filterable={false}
                />
                : <Chip icon={<ErrorIcon />} label="Nessuna attività trovata" variant="outlined" />}
        />
    }
}

export default WidgetStakeholderActivities