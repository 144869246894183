import React from 'react'

import Chip from '@material-ui/core/Chip'

import ErrorIcon from '@material-ui/icons/Error'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import { EntitySchema } from '../../services/EntitySchema'
import { tableSchema } from '../../services/EntityTableSchema'
import { actionSchema } from '../../services/EntityRowActionsSchema'
import { stakeholders_get_reports } from '../../services/BE'

class WidgetStakeholderReports extends React.Component {
    constructor(props) {
        super(props)

        const entity = "reports"
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        this.state = {
            entity: entity,
            id_field: id_field,
            path: this.props.path,
            id_stakeholder: this.props.id_stakeholder,
            reports: [],
        }
    }
    
    async getReports() {
        const id_stakeholder = this.props.id_stakeholder
        this.setState({
            reports: await stakeholders_get_reports( id_stakeholder ),
        })
    }
    
    async componentDidMount() {
        this.getReports()
    }

    async componentDidUpdate(prevProps) {
        const id_stakeholder = this.props.id_stakeholder
        if( id_stakeholder !== prevProps.id_stakeholder )
            this.getReports()
    }

    render() {
        const id_stakeholder = this.props.id_stakeholder
        const entity = this.state.entity
        const id_field = this.state.id_field
        const reports = this.state.reports
        const fieldsProps = tableSchema(entity)

        const tableRowActions = this.props.hasOwnProperty('tableRowActions')
            ? this.props.tableRowActions
            : true
        const rowActions = tableRowActions
            ? actionSchema(entity)
            : {}

        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Report"

        return <DashboardCard title={dashboardcardTitle}
            Content={reports.length
                ? <Table material highlight dense
                    data={reports}
                    fieldsProps={fieldsProps}
                    rowActions={rowActions}
                    rowActionsField={id_field}
                    showPagination={true}
                    sortable={true}
                    filterable={false}
                />
                : <Chip icon={<ErrorIcon />} label="Nessuna report trovato" variant="outlined" />}
        />
    }
}

export default WidgetStakeholderReports