import { groupsFromToken } from "../@react-fefmwk/auth/services/token"

export function fieldsViewSchema( entity ) {
    const groups = groupsFromToken()

    switch (entity) {
        case 'atcs':
            return [
                'id_atc','atc','atc_type','salary_certificate','approval','waiver','accept_proxy','atc_notes',
            ]
        case 'insurances':
            return [
                'id_insurance','insurance',
            ]
        /*case 'people':
            return (groups.includes(String(1)) || groups.includes(String(6)))
            ? []
            : ['first_name','last_name']
        case 'customers':
        case 'stakeholders':
            return (groups.includes(String(1)) || groups.includes(String(6)))
            ? []
            : ['stakeholder','recent_agents','agencies_branches']*/
        default:
            return []
    }
}
export function fieldsListSchema( entity ) {
    switch (entity) {
        default:
            return []
    }
}