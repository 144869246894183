import Highcharts from 'highcharts/highstock'

const highcharts_defaults = {
    colors: ['red'],
    chart: {
        backgroundColor: "transparent"
    },
    credits: {enabled: false},
    title: {
        text: "",
        style: {
            color: "#000",
            font: "inherit"
        },
    },
    subtitle: {
        text: "",
        style: {
            color: "#000",
            font: "inherit"
        },
    },
    xAxis: {
        //gridLineWidth: 0,
        overflow: "allow",
        labels: {
            padding: 0,
            style: {
                //fontSize: "60%",
                color: "#000",
                textOverflow: "allow",
                width: "100%",
            },
            step: 1,
        },
        title: {
            text: "",
            style: {
                //fontSize: "60%",
                fontStyle: "italic",
                color: "#000"
            }
        }
    },
    yAxis: {
        //gridLineWidth: 0,
        labels: {
            padding: 0,
            style: {
                //fontSize: "60%",
                color: "#000"
            }
        },
        title: {
            text: "",
            style: {
                //fontSize: "60%",
                fontStyle: "italic",
                color: "#000"
            }
        }
    },
    plotOptions: {
        line: {
            lineWidth: 1,
            marker: {radius: 2},
            dataLabels: {
                enabled: true,
                style: {
                    color: "#000",
                    //fontSize: "50%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            }
        },
        area: {
            //fillOpacity: 1,
            //lineWidth: 1,
            //marker: {radius: 2},
            dataLabels: {
                enabled: false,
                style: {
                    color: "#000",
                    //fontSize: "50%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            }
        },
        bar: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                align: "left",
                //formatter: function(){return Highcharts.numberFormat(this.y*100,0) + '%';},
                style: {
                    color: "#000",
                    //fontSize: "60%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            }
        },
        column: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                align: "center",
                crop: false,
                overflow: "none",
                //formatter: function(){return Highcharts.numberFormat(this.y*100,0) + '%';},
                style: {
                    color: "#000",
                    //fontSize: "60%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            }
        },
        waterfall: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                //formatter: function(){return Highcharts.numberFormat(this.y*100,2) + '%';},
                style: {
                    color: "#000",
                    //fontSize: "60%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            }
        },
        pie: {
            borderWidth: 0,
            innerSize: "75%",
            showInLegend: true,
            dataLabels: {
                enabled: false,
                crop: false,
                distance: 5,
                connectorWidth: 0,
                style: {
                    color: "#000",
                    //fontSize: "70%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            }
        },
        scatter: {
            borderWidth: 0,
            dataLabels: {
                enabled: false,
                align: "left",
                //formatter: function(){return Highcharts.numberFormat(this.y*100,0) + '%';},
                style: {
                    color: "#000",
                    //fontSize: "60%",
                    fontWeight: "normal",
                    textOutline: 0
                }
            },
            marker: {
                radius: 3,
                states: {
                    hover: {enabled: true,lineColor: 'lightgrey'}
                }
            },
        },
    },
    tooltip: {
        valueDecimals: 2,
        style: {
            fontWeight: "normal"
        },
        shared: true
    },
    legend: {
        itemStyle: {
            font: "inherit",
            fontWeight: "normal",
            //fontSize: "60%",
            color: "#000",
        },
        //symbolRadius: 0,
        //align: "left",
        itemHoverStyle: {
            color: "grey"
        },
        padding: 0
    },
}

export default highcharts_defaults