import React from 'react'
import Stepper from '../@react-fefmwk/stepper/modules/StepperForm'
import { entity_get } from '../@react-fefmwk/entity/services/api'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AbsoluteBox from '../@react-fefmwk/messages/modules/AbsoluteBox'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

import WidgetLeadPreview from './widgets/WidgetLeadPreview'
import { tableSchema } from '../services/EntityTableSchema'
import {
    AddStakeholderStepperSchema,
    AddStakeholderStepperFormSchema,
    AddStakeholderStepperValidationSchema,
    AddStakeholderStepperSubmitSchema,
} from '../services/AddStakeholderStepperSchema'

class AddStakeholder extends React.Component {
    constructor(props) {
        super(props)

        const id_stakeholder = this.props.id_entity
        const initialValues = Object.assign({},AddStakeholderStepperFormSchema)

        let queryObj = {}
        const queryString = this.props.queryString
        if( queryString ) {
            queryObj = queryStringToObj(queryString)
        }
        let fromLead = queryObj.hasOwnProperty('fromlead')
            ? queryObj.fromlead
            : false

        this.state = {
            id_stakeholder: id_stakeholder,
            form: initialValues,
            fromLead: fromLead,
            lead: {}
        }
    }

    async componentDidMount() {
        const id_stakeholder = this.state.id_stakeholder
        if( !id_stakeholder ) {
            const initialValues = Object.assign({},AddStakeholderStepperFormSchema)
            this.setState({
                form: initialValues
            })
        }
        else {
            const stakeholder = await entity_get( 'stakeholders',id_stakeholder )
            this.setState((prevState) => {
                let form_copy = prevState.form
                form_copy = stakeholder

                //adjust nulls
                Object.keys(form_copy).map((field) => {
                    if( form_copy[field] === null ) form_copy[field] = ""
                    return true
                })

                return {
                    id_stakeholder: stakeholder.id_stakeholder,
                    form: form_copy
                }
            })
        }

        const fromLead = this.state.fromLead
        if( fromLead ) {
            const lead = await entity_get( 'leads',fromLead )
            this.setState({
                lead: lead
            })
        }
    }

    render() {
        const form = this.state.form
        const id_stakeholder = form.id_stakeholder

        let activeStep = 0
        if( id_stakeholder ) activeStep = 2

        if( !id_stakeholder && this.state.id_stakeholder ) return <LoaderSingleComponent />

        const infoFieldsProps = tableSchema("stakeholders")

        const fromLead = this.state.fromLead
        const lead = this.state.lead

        return <React.Fragment>
            <Stepper
                id="add-stakeholder"
                stepperSchema={AddStakeholderStepperSchema}
                infoFieldsProps={infoFieldsProps}
                formSchema={form}
                //infoFieldsList={["id_notice","cached_data"]}
                validationSchema={AddStakeholderStepperValidationSchema}
                submitSchema={AddStakeholderStepperSubmitSchema}
                activeStep={activeStep}
                disableBackAtLastStep
            />
            {fromLead && Object.keys(lead).length
                ? <AbsoluteBox>
                    <WidgetLeadPreview
                        id_lead={lead.id_lead}
                        body={lead.lead_body}
                        date={lead.lead_datetime}
                        subject={lead.lead_subject}
                        from={lead.lead_from}
                        to={lead.lead_to}
                    />
                </AbsoluteBox>
                : null}
        </React.Fragment>
    }
}

export default AddStakeholder