
import React from 'react'
import {withRouter} from "react-router-dom"

import {queryStringToObj} from '../../@react-fefmwk/links/service/api'
import { idFromToken } from '../../@react-fefmwk/auth/services/token'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'
import RedirectBack from '../../@react-fefmwk/links/modules/RedirectBack'
import SnackbarMessage from '../../@react-fefmwk/messages/modules/SnackbarMessage'
import Modal from '../../@react-fefmwk/modals/modules/Modal'

import { stakeholders_get_activities } from '../../services/BE'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddIcon from '@material-ui/icons/Add'
import DashboardIcon from '@material-ui/icons/Dashboard'

class Checkout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectBack: false,
            pushAssign: false
        }
    }
    
    async getActivities( id_stakeholder ) {
        return await stakeholders_get_activities( id_stakeholder )
    }
    async componentDidMount() {
        const formikProps = this.props.formikProps
        const id_stakeholder = formikProps.values.id_stakeholder

        if( id_stakeholder ) {
            const activities = await this.getActivities(id_stakeholder)
            this.setState({
                pushAssign: activities.length ? false : true,
            })
        }

    }

    render() {
        const formikProps = this.props.formikProps
        const id_stakeholder = formikProps.values.id_stakeholder

        const queryStrings = this.props.location.search

        let queryStringsObj = {}
        if( queryStrings )
            queryStringsObj = queryStringToObj(queryStrings)

        const noaction = queryStringsObj.hasOwnProperty('noactionatcheckout')
        const backaction = queryStringsObj.hasOwnProperty('backatcheckout')
        const redirectBack = this.state.redirectBack
        const pushAssign = this.state.pushAssign

        const current_user_id = idFromToken()

        return <React.Fragment>
            {!noaction
                ? <Box mb={2}>
                    <Button variant="contained" color="primary" size="small"
                        startIcon={<AccountBalanceWalletIcon fontSize="small" />}
                        to={"/practicals/add?id_stakeholder="+id_stakeholder}
                        component={AdapterLink}
                        replace
                    >Carica preventivo</Button>
                    &nbsp;&nbsp;
                    <Button variant="text" size="small"
                        startIcon={<CloudUploadIcon />}
                        component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#documents'}
                        replace
                    >Documenti</Button>
                    &nbsp;&nbsp;
                    <Button variant="text" size="small"
                        startIcon={<DashboardIcon />}
                        component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder}
                        replace
                    >Scheda</Button>
                </Box>
                : null}
            <ViewEntity
                entity={"stakeholders"}
                id_field={"id_stakeholder"}
                id_entity={id_stakeholder}
            />
            <SnackbarMessage
                open={id_stakeholder ? true : false}
                autoHideDuration={2000}
                severity="success"
                message={"Stakeholder correctly created"}
                onClose={() => this.setState({redirectBack:backaction})}
            />
            {backaction
                ? null
                : <Modal
                    fullWidth
                    maxWidth="sm"
                    open={pushAssign}
                    printCloseBtn
                    title="Aggiungi attività"
                    content={<React.Fragment>
                        Il contatto è stato creatto correttamente ma non ha nessuna attività: aggiungine una!
                        <Box mt={2}>
                            <Button variant="contained" color="primary" size="small"
                                startIcon={<AddIcon />}
                                component={AdapterLink} to={'/tasks/add?id_stakeholder='+id_stakeholder+'&id_agent='+current_user_id}
                            >Assegnalo a me</Button>
                            &nbsp;&nbsp;
                            <Button variant="text" size="small"
                                startIcon={<AddIcon fontSize="small" />}
                                to={"/practicals/add?id_stakeholder="+id_stakeholder}
                                component={AdapterLink}
                            >preventivo</Button>
                            &nbsp;&nbsp;
                            <Button variant="text" size="small"
                                startIcon={<AddIcon fontSize="small" />}
                                to={"/tasks/add?id_stakeholder="+id_stakeholder}
                                component={AdapterLink}
                            >task</Button>
                            &nbsp;&nbsp;
                            <Button variant="text" size="small"
                                startIcon={<AddIcon fontSize="small" />}
                                to={"/reports/add?id_stakeholder="+id_stakeholder}
                                component={AdapterLink}
                            >report</Button>
                        </Box>
                    </React.Fragment>}
                    //Trigger={TriggerComponent}
                    //tooltipText={linkText}
                    //tooltipPlacement="right"
                    //BackdropProps={BackdropProps}
                    //{...rest}
                />}
            {redirectBack
                ? <RedirectBack />
                : null}
        </React.Fragment>
    }
}

export default withRouter(Checkout)