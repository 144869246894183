import React from 'react'
import Stepper from '../@react-fefmwk/stepper/modules/StepperForm'
import { entity_get } from '../@react-fefmwk/entity/services/api'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AbsoluteBox from '../@react-fefmwk/messages/modules/AbsoluteBox'
import {nowString} from '../@react-fefmwk/dates/services/api'

import WidgetLeadPreview from './widgets/WidgetLeadPreview'

import { tableSchema } from '../services/EntityTableSchema'
import {
    AddReportStepperSchema,
    AddReportStepperFormSchema,
    AddReportStepperValidationSchema,
    AddReportStepperSubmitSchema,
} from '../services/AddReportStepperSchema'

class AddReport extends React.Component {
    constructor(props) {
        super(props)

        const update = this.props.hasOwnProperty('update') ? this.props.update : false
        const id_report = this.props.id_entity
        const initialValues = this.setInitialValues()

        this.state = {
            id_report: id_report,
            form: initialValues,
            update: update,
            fromLead: false,
            lead: {},
        }
    }

    setInitialValues() {
        let initialValues = Object.assign({},AddReportStepperFormSchema)

        initialValues.report_date = nowString()
        initialValues.created_at = nowString()

        const precompiledData = this.props.hasOwnProperty('precompiledData')
            ? this.props.precompiledData
            : {}
        if( Object.keys(precompiledData).length ) {
            initialValues = {...initialValues,...precompiledData}
            if( initialValues.id_stakeholder ) initialValues['id_report_target'] = 'id_stakeholder'
            if( initialValues.id_lead ) initialValues['id_report_target'] = 'id_lead'
            if( initialValues.id_practical ) initialValues['id_report_target'] = 'id_practical'
            if( initialValues.id_activity ) initialValues['id_report_target'] = 'id_activity'
        }

        return initialValues
    }

    async componentDidMount() {
        const precompiledData = this.props.hasOwnProperty('precompiledData')
            ? this.props.precompiledData
            : {}
        const fromlead = precompiledData.hasOwnProperty('id_lead') && precompiledData.id_lead
            ? true
            : false
        let lead = {}
        if( fromlead )
            lead = await entity_get( 'leads',precompiledData.id_lead )

        const id_report = this.state.id_report
        if( !id_report ) {
            const initialValues = this.setInitialValues()
            this.setState({
                form: initialValues,
                fromlead: fromlead,
                lead: lead
            })
        }
        else {
            const report = await entity_get( 'reports',id_report )
            this.setState((prevState) => {
                let form_copy = prevState.form
                form_copy = report

                //adjust nulls
                Object.keys(form_copy).map((field) => {
                    if( form_copy[field] === null ) form_copy[field] = ""
                    return true
                })

                return {
                    id_report: report.id_report,
                    form: form_copy,
                    fromlead: fromlead,
                    lead: lead
                }
            })
        }
    }

    render() {
        const update = this.state.update

        const form = this.state.form
        const id_report = form.id_report
        const id_report_target = form.id_report_target

        let activeStep = 0
        if( !update && id_report_target ) activeStep = 1
        if( id_report ) activeStep = update ? 1 : 2

        if( !id_report && this.state.id_report ) return <LoaderSingleComponent width="100%" />

        const infoFieldsProps = tableSchema("reports")

        const id = update ? "update-report" : "add-report"
        const disableBackAtSteps = this.props.hasOwnProperty('disableBackAtSteps')
            ? this.props.disableBackAtSteps
            : []

        const fromlead = this.state.fromlead
        const lead = this.state.lead

        return <React.Fragment>
            <Stepper
                id={id}
                stepperSchema={AddReportStepperSchema}
                infoFieldsProps={infoFieldsProps}
                formSchema={form}
                //infoFieldsList={["id_notice","cached_data"]}
                validationSchema={AddReportStepperValidationSchema}
                submitSchema={AddReportStepperSubmitSchema}
                activeStep={activeStep}
                disableBackAtLastStep
                disableBackAtSteps={disableBackAtSteps}
            />
            {fromlead && Object.keys(lead).length
                ? <AbsoluteBox>
                    <WidgetLeadPreview
                        id_lead={lead.id_lead}
                        body={lead.lead_body}
                        date={lead.lead_datetime}
                        subject={lead.lead_subject}
                        from={lead.lead_from}
                        to={lead.lead_to}
                    />
                </AbsoluteBox>
                : null}
        </React.Fragment>
    }
}

export default AddReport