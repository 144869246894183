import React from 'react'
import EntityForm from './EntityForm'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

class EntityFormSwitchLegalIdentity extends React.Component {
    constructor(props) {
        super(props)

        const legal_person = this.props.hasOwnProperty('legal_person') ? this.props.legal_person : false
        const showSwitch = this.props.hasOwnProperty('showSwitch') ? this.props.showSwitch : true
        this.state = {
            legal_person: legal_person,
            refresh: false,
            showSwitch: showSwitch,
        }
    }

    async componentDidUpdate(prevProps,prevState) {
        const legal_person = this.state.legal_person
        if( legal_person !== prevState.legal_person ) {
            this.setState({
                refresh: false
            })
        }
    }

    handleChange(e) {
        e.persist()

        this.setState({
            refresh: true,
            legal_person: e.target.checked,
        })
    }

    render() {
        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const refresh = this.state.refresh
        const showSwitch = this.state.showSwitch
        const legal_person = this.state.legal_person
        const queryStrings = this.props.queryStrings
        const onGetData = this.props.hasOwnProperty('onGetData') ? this.props.onGetData : () => {}
        const identityFields = this.props.hasOwnProperty('identityFields')
            ? this.props.identityFields
            : ['id_people','id_company']

        const excludeFields = legal_person ? [identityFields[0]] : [identityFields[1]]
        const disableSwitch = this.props.hasOwnProperty('disableSwitch')
        ? this.props.disableSwitch
        : false

        return <React.Fragment>
            {showSwitch
                ? <FormGroup row={true}>
                    <FormControlLabel disabled={disableSwitch} control={<Switch checked={legal_person} onChange={(e) => this.handleChange(e)} />} label="Abilita persona giuridica"/>
                </FormGroup>
                : null}
            {!refresh
                ? <EntityForm 
                    entity={entity}
                    id_entity={id_entity}
                    queryStrings={queryStrings}
                    excludeFields={excludeFields}
                    onGetData={onGetData}
                />
                : <LoaderSingleComponent />}
        </React.Fragment>
    }
}

export default EntityFormSwitchLegalIdentity