import React from 'react'

import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'

import Chip from '@material-ui/core/Chip'

import { entity_get } from '../services/api'
import { EntitySchema } from '../../../services/EntitySchema'

class EntityTitle extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            obj: {},
        }
    }
    async componentDidMount() {
        const id_entity = this.props.id_entity
        const entity = this.props.entity
        if( entity && id_entity )
            this.get(entity,id_entity)
    }
    async componentDidUpdate(prevProps) {
        const id_entity = this.props.id_entity
        const entity = this.props.entity
        if( id_entity !== prevProps.id_entity || entity !== prevProps.entity )
            this.get(entity,id_entity)
    }
    async get(entity,id_entity) {
        this.setState({
            loading: true
        })
        const obj = await entity_get(
            entity,
            id_entity,
            '',//fields
            {loader:false}//options
        )
        this.setState({
            obj: obj,
            loading: false
        })
    }

    render() {
        const id_entity = this.props.id_entity
        const entity = this.props.entity
        if( !id_entity || !entity ) return null

        const loading = this.state.loading
        const obj = this.state.obj
        const entityObj = EntitySchema[entity]

        const ref_field = this.props.hasOwnProperty('ref_field')
            ? this.props.ref_field
            : (entityObj.hasOwnProperty('ref_field') ? entityObj.ref_field : "ref_field")
        const ref_field_val = obj.hasOwnProperty(ref_field) ? obj[ref_field] : (entity+" # "+id_entity)

        const LoadingSpinner = <LoaderSingleComponent layout="circular" />

        const size = this.props.hasOwnProperty('size') ? this.props.size : "small"
        const color = this.props.hasOwnProperty('color') ? this.props.color : "secondary"
        const variant = this.props.hasOwnProperty('variant') ? this.props.variant : "outlined"

        return <React.Fragment>
            <Chip
                icon={loading ? LoadingSpinner : null}
                label={loading ? "loading..." : ref_field_val}
                size={size}
                color={color}
                variant={variant}
                style={variant === "outlined" ? {border: loading ? 0 : "1px solid"} : {}}
            />
        </React.Fragment>
    }
}

export default EntityTitle