import React from 'react'

import {users_get_stakeholders} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Table from '../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import BlockIcon from '@material-ui/icons/Block'
import AddIcon from '@material-ui/icons/Add'

class MyStakeholders extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            stakeholders: [],
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        const stakeholders = await users_get_stakeholders(id_user)

        this.setState({
            stakeholders: stakeholders
        })
    }

    render() {
        const stakeholders = this.state.stakeholders

        const entity = "stakeholders"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        return <React.Fragment>
            <Box mb={2}>
                <Tooltip title="Aggiungi contatto" placement="right">
                    <Button component={AdapterLink} to="/stakeholders/add" variant="contained" size="small" color="secondary" startIcon={<AddIcon />}>
                        contatto
                    </Button>
                </Tooltip>
            </Box>
            {!stakeholders.length
                ? <Chip icon={<BlockIcon />} label="Nessun contatto trovato" variant="outlined" />
                : <Paper>
                    <Table material dense highlight
                        data={stakeholders}
                        fieldsProps={fieldsProps}
                        rowActions={rowActions}
                        rowActionsField={id_field}
                        showPagination={true}
                        filterable={true}
                        sortable={true}
                    />
                </Paper>}
        </React.Fragment>
    }
}

export default MyStakeholders