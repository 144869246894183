import React from 'react'

import {branches_list_stakeholders} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Table from '../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import BlockIcon from '@material-ui/icons/Block'
import AddIcon from '@material-ui/icons/Add'

class MyBranchStakeholders extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            branches: this.props.branches,
            stakeholders: [],
        }
    }

    async componentDidMount() {
        const branches = this.state.branches
        const stakeholders = await branches_list_stakeholders(branches)

        this.setState({
            stakeholders: stakeholders
        })
    }

    render() {
        const stakeholders = this.state.stakeholders

        const entity = "stakeholders"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        return <React.Fragment>
            <Tooltip title="Aggiungi contatto" placement="right">
                <Fab component={AdapterLink} target="_blank" color="primary" size="small" to={"/stakeholders/add"}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <br />
            <br />
            {!stakeholders.length
                ? <Chip icon={<BlockIcon />} label="Nessun contatto trovato" variant="outlined" />
                : <Paper>
                    <Table material dense highlight
                        data={stakeholders}
                        fieldsProps={fieldsProps}
                        rowActions={rowActions}
                        rowActionsField={id_field}
                        showPagination={true}
                        filterable={true}
                        sortable={true}
                    />
                </Paper>}
        </React.Fragment>
    }
}

export default MyBranchStakeholders