import React from 'react'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import {EntitySchema} from './EntitySchema'
import {exportCSVBtn} from './EntityToolbarSchema'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'

export function addBtn( entity,id_entity,cftime,s='' ) {
    const entityObj = EntitySchema[entity]
    const entityRootPath = entityObj.hasOwnProperty('rootpath') ? entityObj.rootpath : entity
    const path = entityRootPath+"/update/"+id_entity+"/timeinfo"

    return <React.Fragment>
        <Fab title={"Add new "+cftime+" for "+entity} component={AdapterLink} color="primary" size="small" to={path + "/add" + s}>
            <AddIcon />
        </Fab>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function importCSVBtn( entity,id_entity,cftime,s='' ) {
    const entityObj = EntitySchema[entity]
    const entityRootPath = entityObj.hasOwnProperty('rootpath') ? entityObj.rootpath : entity
    const path = entityRootPath+"/update/"+id_entity+"/timeinfo"

    return <React.Fragment>
        <Fab title={"Upload CSV "+cftime+" for "+entity} component={AdapterLink} color="primary" size="small" to={path + "/upload-csv-timeinfo" + s}>
            <PublishIcon />
        </Fab>
        &nbsp;&nbsp;
    </React.Fragment>
}
export function toolbarTimeinfoSchema( entity,id_entity,cftime,s='',data=[] ) {
    switch (entity) {
        default:
            return [
                exportCSVBtn( entity,'',data,"Download CSV "+cftime+" for "+entity ),
                importCSVBtn( entity,id_entity,cftime,s ),
                addBtn(entity,id_entity,cftime,s),
            ]
    }
}