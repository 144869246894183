import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import AdapterLink from '../../links/modules/AdapterLink'

import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import NavTop from './NavTop'

const headerStyle = theme => ({
    homeUrl: {
        textDecoration:"none",
        color:"inherit"
    }
})

class Header extends React.Component {
    render() {
        const { theme,classes } = this.props
        const showAppLogo = this.props.showAppLogo
        const navTopTitle = this.props.navTopTitle
        const NavTopImgPath = this.props.NavTopImgPath ? this.props.NavTopImgPath : ""
        const showUsername = this.props.showUsername
        const showUserDivider = this.props.showUserDivider
        const homeUrl = this.props.homeUrl

        const HomeUrlComp = (homeUrl === false || homeUrl === undefined) ? React.Fragment : AdapterLink
        const HomeUrlCompProps = (homeUrl === false || homeUrl === undefined) ? {} : {to: homeUrl,className: classes.homeUrl}

        const MenuBtn = this.props.MenuBtn

        return <AppBar id="Header" position="fixed" className={this.props.className}>
            <Container maxWidth="xl">
                <Toolbar id="NavTop" disableGutters className={this.props.className}>

                    <Hidden smUp>
                        {MenuBtn
                            ? <Box mr={2} style={{marginLeft:-4}}>
                                {MenuBtn}
                            </Box>
                            : null}
                    </Hidden>

                    {showAppLogo && NavTopImgPath
                        ? <Box style={theme.globals.appHeaderLogoBox}>
                            <HomeUrlComp {...HomeUrlCompProps}>
                                <img src={NavTopImgPath} style={theme.globals.appHeaderLogoImg} alt="App logo" />
                            </HomeUrlComp>
                        </Box>
                        : null}

                    <Box style={theme.globals.appHeaderTitleBox}>
                        {navTopTitle !== ""
                            ? <Typography variant="h5" style={theme.globals.appHeaderTitleText}>
                                <HomeUrlComp {...HomeUrlCompProps}>
                                    {navTopTitle}
                                </HomeUrlComp>
                            </Typography>
                            : null}
                    </Box>

                    <NavTop showUsername={showUsername} showUserDivider={showUserDivider} />
                </Toolbar>
            </Container>
        </AppBar>
    }
}

export default withStyles(headerStyle)(withTheme(Header))