import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const ContentPageStyle = theme => ({
    appPageContent: {
        ...{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        ...theme.globals.appPageContent,
    },
    pageTitle: theme.globals.appPageTitle,
    appPageBoxContent: {
        ...{
            //height:'calc(100% - 62px)',
            flex: "1 auto",
            height: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        ...theme.globals.appPageBoxContent,
    },
})

class Content extends React.Component {
    render() {
        const title = this.props.title
        const Toolbar = this.props.toolbar ? this.props.toolbar : null
        const Navigation = this.props.navigation ? this.props.navigation : null
        const { classes } = this.props
        const maxWidth = this.props.hasOwnProperty('maxWidth')
            ? this.props.maxWidth
            : "xl"

        return <Container maxWidth={maxWidth} className={classes.appPageContent}>
            <Box>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item xs="auto">
                        {Navigation
                            ? <Box mr={0}>{Navigation}</Box>
                            : null}
                    </Grid>
                    <Grid item xs="auto">
                        <Box className={classes.pageTitle}>{title
                            ? <Typography gutterBottom variant="h5" component="h3">
                                {title}
                            </Typography>
                            : null}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md>
                        {Toolbar && title
                            ? <Box ml={2}>{Toolbar}</Box>
                            : null}
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.appPageBoxContent}>
                {this.props.children}
            </Box>
        </Container>
    }
}

export default withRouter(withStyles(ContentPageStyle)(Content))