import * as Yup from 'yup'
import {
    isEqualTo,
    diffDates
} from '../@react-fefmwk/yup/api'
Yup.addMethod(Yup.string,'isEqualTo',isEqualTo)
Yup.addMethod(Yup.string,'onContactType',onContactType)
Yup.addMethod(Yup.string,'diffDates',diffDates)

function onContactType(ref,msg) {
	return this.test({
		name: 'onContactType',
		exclusive: false,
        message: msg || '${path} not correct for ${reference} choice',
		params: {
			reference: ref.path
		},
		test: function(value) {
            const id_contact_type = this.resolve(ref)
            switch(id_contact_type) {
                case 3://email
                case 5://pec
                    return value.toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )
                default:
                    return value.match(
                        /^\d+$/
                    )
            }
		}
	})
}

export function validationSchema( entity ) {
    switch (entity) {
        case 'people':
            return Yup.object().shape({
                main_mobile: Yup.string()
                    .matches(/^[0-9]+$/,"Inserisci solo numeri")
                    //.min(10,'Numero incompleto, almeno 10 caratteri')
                    //.max(10,'Indica al massimo 10 caratteri')
                    .required('Obbligatorio'),
                fiscal_code: Yup.string()
                    .min(16,'Incompleto! almeno 16 caratteri')
                    //.max(16,'Troppo lungo! Al massimo 16 caratteri')
                    //.required('Obbligatorio'),
            })
        case 'companies':
            return Yup.object().shape({
                vat_number: Yup.string()
                    .min(11,'La partita iva deve avere almeno 11 caratteri')
                    .max(11,'Troppo lunga! Al massimo 11 caratteri')
                    .required('Obbligatorio'),
                cf: Yup.string()
                    .min(11,'Il codice fiscale deve avere almeno 11 caratteri')
                    .max(11,'Troppo lunga! Al massimo 11 caratteri'),
            })
        case 'insurances':
            return Yup.object().shape({
                id_company: Yup.number()
                    .required('Obbligatorio'),
            })
        case 'atcs':
            return Yup.object().shape({
                id_company: Yup.number()
                    .required('Obbligatorio'),
                id_atc_type: Yup.number()
                    .required('Obbligatorio'),
            })
        case 'insurances_quotations':
            return Yup.object().shape({
                id_atc: Yup.number()
                    .required('Obbligatorio'),
                id_insurance: Yup.number()
                    .required('Obbligatorio'),
                multiplier: Yup.string()
                    .required('Obbligatorio'),
            })
        case 'contacts':
            return Yup.object().shape({
                id_contact_type: Yup.number()
                    .required('Obbligatorio'),
                contact: Yup.string()
                    .required('Obbligatorio')
                    .onContactType(Yup.ref('id_contact_type')),
            })
        case 'practicals':
            return Yup.object().shape({
                /*maturity_date: Yup.string()
                    .required('Obbligatorio')
                    .diffDates(Yup.ref('sign_date'),'maturity date must be 1 year after sign date',365,'>='),
                tan: Yup.number()
                    .min(0,'Tan must be a positive number')
                    .max(10,'Tan must be between 0 and 10')
                    .required('Obbligatorio'),
                taeg: Yup.number()
                    .min(0,'Taeg must be a positive number')
                    .max(10,'Taeg must be between 0 and 10')
                    .required('Obbligatorio'),*/
            })
        case 'practicals_outers':
            return Yup.object().shape({
                id_product: Yup.number()
                    .required('Obbligatorio'),
                id_stakeholder: Yup.number()
                    .required('Obbligatorio'),
                id_lender: Yup.number()
                    .required('Obbligatorio'),
                id_atc: Yup.number()
                    .required('Obbligatorio'),
                id_practical_state: Yup.number()
                    .required('Obbligatorio'),
                id_user: Yup.number()
                    .required('Obbligatorio'),
                effective_date: Yup.string()
                    .required('Obbligatorio'),
                installment_amount: Yup.number()
                    .min(0,'Tan must be a positive number')
                    .required('Obbligatorio'),
                installments_n: Yup.number()
                    .min(0,'Tan must be a positive number')
                    .required('Obbligatorio'),
                residual_amount: Yup.number()
                    .min(0,'Taeg must be a positive number')
                    .required('Obbligatorio'),
            })
        case 'practicals_commissions':
            return Yup.object().shape({
                id_practical: Yup.number()
                    .required('Obbligatorio'),
                gross_commission: Yup.number()
                    .min(0,'Tan must be a positive number')
                    .required('Obbligatorio'),
            })
        case 'reports':
            return Yup.object().shape({
                report_date: Yup.string()
                    .required('Obbligatorio')
                    .isFutureDate('report date cannot be a future date'),
            })
        default:
            return Yup.object().shape({})
    }
}