import React from 'react'
import {Formik,Form} from 'formik'
import { withTheme } from '@material-ui/core/styles'

import {todayString,isValidDate} from '../@react-fefmwk/dates/services/api'

import FieldDate from '../@react-fefmwk/form/modules/FieldDate'
import FieldSelect from '../@react-fefmwk/form/modules/FieldSelect'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {branches_list} from '../services/BE'

import StatsAgents from './StatsAgents'

import Grid from '@material-ui/core/Grid'

class StatsAgentsForm extends React.Component {
    constructor(props) {
        super(props)

        const todayStr = todayString()
        const today = new Date(todayStr)
        const today_month = today.getMonth()
        let ytd = today.setMonth(0)
        ytd = today.setDate(1)
        const ytdString = todayString(ytd)
        const aYearAgo = today.setMonth(today.getMonth() - 12)
        const aYearAgoStr = todayString(aYearAgo)

        const users_ids = this.props.hasOwnProperty('users_ids')
            ? this.props.users_ids
            : []
        const branches = this.props.hasOwnProperty('branches')
            ? this.props.branches
            : []

        this.state = {
            branchesItems: [],
            form: {
                start_date: today_month <= 2 ? aYearAgoStr : ytdString,
                end_date: todayStr,
                users_ids: users_ids,
                branches: branches,
            },
        }
    }

    async componentDidMount() {
        const users_ids = this.props.hasOwnProperty('users_ids')
            ? this.props.users_ids
            : []
        const branches = this.props.hasOwnProperty('branches')
            ? this.props.branches
            : []

        if( users_ids.length != 1 && !branches.length ) {
            const branchesItems = await branches_list()
            this.setState({
                branchesItems: branchesItems
            })
        }
    }

    validate(values) {
        let errors = {}

        const start_date = values.start_date
        const end_date = values.end_date
        if( !isValidDate( start_date ) )
            errors['start_date'] = 'La data iniziale non è valida'
        if( !isValidDate( end_date ) )
            errors['end_date'] = 'La data finale non è valida'

        return errors
    }

    render() {
        const form = this.state.form
        const branchesItems = this.state.branchesItems
        //const {theme} = this.props

        const stats_list = this.props.hasOwnProperty('stats')
            ? this.props.stats
            : []

        return <React.Fragment>
            <Formik
                initialValues={form}
                validate={(values) => {return this.validate(values)}}
                render={formikProps => {
                    const start_date = formikProps.values.start_date
                    const end_date = formikProps.values.end_date
                    const users_ids = formikProps.values.users_ids
                    const branches = formikProps.values.branches
                    //const isValid = formikProps.isValid /*&& Object.keys(formikProps.touched).length > 0*/

                    const errors = this.validate({start_date:start_date,end_date:end_date})

                    return <React.Fragment>
                        <Form>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <FieldDate
                                        id="stats-agents-start-date"
                                        name="start_date"
                                        label="Inizio analisi"
                                        value={start_date}
                                        onChange={(_, dateString) => {
                                            formikProps.setFieldValue('start_date',dateString)
                                        }}
                                        variant="outlined"
                                        formikProps={formikProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <FieldDate
                                        id="stats-agents-end-date"
                                        name="end_date"
                                        label="Fine analisi"
                                        value={end_date}
                                        onChange={(_, dateString) => {
                                            formikProps.setFieldValue('end_date',dateString)
                                        }}
                                        variant="outlined"
                                        formikProps={formikProps}
                                    />
                                </Grid>
                                <Grid item>
                                    {branchesItems.length && users_ids.length != 1
                                        ? <FieldSelect
                                            title="Seleziona una sede"
                                            name="branches"
                                            fieldId="slug_agency_branch"
                                            fieldName="agency_branch"
                                            nullRow={{"slug_agency_branch":"","agency_branch":"tutte"}}
                                            items={branchesItems}
                                            value={formikProps.values.branches}
                                            onChange={formikProps.handleChange}
                                            variant="standard"
                                        />
                                        : <LoaderSingleComponent width="100%" />}
                                </Grid>
                            </Grid>
                        </Form>
                        {Object.keys(errors).length
                            ? null
                            : <StatsAgents
                                stats={stats_list}
                                start_date={start_date}
                                end_date={end_date}
                                users_ids={users_ids}
                                branches={branches}
                            />}
                    </React.Fragment>
                }}
            />
        </React.Fragment>
    }
}

export default withTheme(StatsAgentsForm)