import React from 'react'
import Content from '../../../theme-structure/modules/Content'
import {EntitySchema} from '../../../../services/EntitySchema'
import DeleteEntity from '../DeleteEntity'
import EntityTitle from '../EntityTitle'

import {checkPrivilegies} from '../../services/api'
import Page404 from '../../../theme-structure/modules/404'
import Page401 from '../../../theme-structure/modules/401'

const EntityPageDelete = (props) => {
    const id_entity = props.route.hasOwnProperty('id_entity')
        ? props.route.id_entity
        : props.route.computedMatch.params.id
    const entity = props.route.hasOwnProperty('entity')
        ? props.route.entity
        : props.route.computedMatch.params.entity

    const entityObj = EntitySchema[entity]
    if( !entityObj || !Object.keys(entityObj).length )
        return <Page404 />
    if( !checkPrivilegies( entityObj,"delete" ) )
        return <Page401 />

    let title = 'Unknown entity'
    if( entityObj && Object.keys(entityObj).length ) {
        title = props.route.hasOwnProperty("title") && props.route.title
            ? props.route.title
            : (entityObj.title_singular+" delete")
    }
    const onGetData = props.route.hasOwnProperty('onGetData') ? props.route.onGetData : () => {}

    return <Content title={title} toolbar={<EntityTitle entity={entity} id_entity={id_entity} />}>
        <DeleteEntity
            entity={entity}
            id_entity={id_entity}
            onGetData={onGetData}
        />
    </Content>
}

export default EntityPageDelete