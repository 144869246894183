import React from 'react'

import {
    users_get_tasks,
    users_get_tasks_stats
} from '../services/BE'
import {groupsFromToken} from '../@react-fefmwk/auth/services/token'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'
import { agencySchema } from '../services/AgencySchema'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import Table from '../@react-fefmwk/tables/modules/Table'

import AddIcon from '@material-ui/icons/Add'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ScoreCard from '../@react-fefmwk/cards/modules/ScoreCard'

import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'

class MyTasks extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            tasks_stats: {},
            tasks: [],
            refresh: false,
            closed: false,
            futured: false,
            feedback_no: false,
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        const tasks_stats = await users_get_tasks_stats(id_user)
        const tasks = await users_get_tasks(id_user)

        this.setState({
            tasks_stats: tasks_stats,
            tasks: tasks
        })
    }

    async componentDidUpdate(prevProps,prevState) {
        const id_user = this.state.id_user
        const closed = this.state.closed
        const futured = this.state.futured
        const feedback_no = this.state.feedback_no
        if( closed !== prevState.closed || futured !== prevState.futured || feedback_no !== prevState.feedback_no ) {
            this.setState({
                tasks_stats: await users_get_tasks_stats(id_user,closed,futured,feedback_no),
                tasks: await users_get_tasks(id_user,closed,futured,feedback_no),
                refresh: false
            })
        }
    }

    handleChange(e,filter) {
        e.persist()

        const closed = filter === 'closed' ? true : false
        const futured = filter === 'futured' ? true : false
        const feedback_no = filter === 'feedback_no' ? true : false
        this.setState({
            refresh: true,
            closed: closed ? e.target.checked : (feedback_no ? false : this.state.closed),
            futured: futured ? e.target.checked : this.state.futured,
            feedback_no: feedback_no ? e.target.checked : (closed ? false : this.state.feedback_no),
        })
    }

    render() {
        const tasks_stats = this.state.tasks_stats
        const tasks = this.state.tasks

        const entity = "tasks"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = {
            ...tableSchema(entity),
            ...{agent: {show: false}}
        }
        const rowActions = actionSchema(entity,path)

        const groups = groupsFromToken()
        const agencyObj = agencySchema()

        const show_btn_addTask = agencyObj.hasOwnProperty('btn_dashboard_addTask') ? agencyObj.btn_dashboard_addTask : false

        const refresh = this.state.refresh
        const closed = this.state.closed
        const futured = this.state.futured
        const feedback_no = this.state.feedback_no

        const tasks_n = tasks_stats.hasOwnProperty('total') ? tasks_stats.total : 0
        const tasks_by_state = tasks_stats.hasOwnProperty('by_state') ? tasks_stats.by_state : []
        const tasks_by_activity_order = tasks_stats.hasOwnProperty('by_activity_order') ? tasks_stats.by_activity_order : []

        return <React.Fragment>
            <Box mb={2}>
                <Grid container spacing={4} alignItems="center">
                    {show_btn_addTask || groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5))
                        ? <Grid item xs="auto">
                            <Button component={AdapterLink} to="/tasks/add" variant="contained" size="small" color="secondary" startIcon={<AddIcon />}>
                                task
                            </Button>
                        </Grid>
                    : null}
                    <Grid item xs="auto">
                        <Switch checked={closed} onChange={(e) => this.handleChange(e,'closed')} /> solo task chiusi
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={futured} onChange={(e) => this.handleChange(e,'futured')} /> solo futuri
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={feedback_no} onChange={(e) => this.handleChange(e,'feedback_no')} /> con feedback negativo
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    {!refresh
                        ? <Grid item xs>
                            <ScoreCard title="# tasks" value={tasks_n} fullWidth />
                        </Grid>
                        : null}
                    {tasks_by_state.length && !refresh
                        ? <React.Fragment>
                            {tasks_by_state.map((row,i) => {
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+row.activity_state} value={row.tasks_n} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                    {tasks_by_activity_order.length && !refresh
                        ? <React.Fragment>
                            {tasks_by_activity_order.map((row,i) => {
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+row.activity_order} value={row.tasks_n} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                </Grid>
            </Box>
            {tasks.length
                ? <Paper>
                    {!refresh
                        ? <Table material dense highlight
                            data={tasks}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                        : <LoaderSingleComponent width="100%" />}
                </Paper>
                : <Chip icon={<SentimentSatisfiedIcon />} label="Nessun task da gestire" variant="outlined" />}
        </React.Fragment>
    }
}

export default MyTasks