import React from 'react'

import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

import FieldFile from '../../@react-fefmwk/form/modules/FieldFile'
import Table from '../../@react-fefmwk/tables/modules/Table'
import { tableSchema } from '../../services/EntityTableSchema'
import { actionSchema } from '../../services/EntityRowActionsSchema'
import {
    stakeholders_get_files,
    stakeholders_upload_files
} from '../../services/BE'

class WidgetStakeholderFiles extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            entity: "files",
            id_field: "file",
            path: this.props.path,
            id_stakeholder: this.props.id_stakeholder,
            files: [],
            test: 0,
            refresh: false,
        }
    }
    
    async componentDidMount() {
        const id_stakeholder = this.state.id_stakeholder
        this.setState({
            files: await stakeholders_get_files( id_stakeholder ),
        })
    }

    render() {
        const entity = this.state.entity
        const id_field = this.state.id_field
        const id_stakeholder = this.state.id_stakeholder
        const files = this.state.files
        const refresh = this.state.refresh
        const fieldsProps = tableSchema(entity)

        const Actions = this.props.hasOwnProperty('Actions')
            ? this.props.Actions
            : true
        const rowActions = Actions
            ? actionSchema(entity)
            : {}

        return <React.Fragment>
            <FieldFile
                name="files[]"
                webkitdirectory={false}
                fileList
                fileListStyle={{}}
                chooseButtonText={"Scegli file"}
                upload_f={async (formFiles) => {
                    const upload = await stakeholders_upload_files(id_stakeholder,formFiles)
                    if( upload ) {
                        this.setState({
                            refresh: true
                        })
                        this.setState({
                            files: await stakeholders_get_files( id_stakeholder ),
                            refresh: false
                        })
                    }
                    return upload
                }}
            />
            <br />
            <br />
            {!files.length
                ? <Chip icon={<ErrorIcon />} label="Nessun file trovato" variant="outlined" />
                : <Paper>
                    {!refresh
                        ? <Table material highlight
                            data={files}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                    : null}
                </Paper>}
        </React.Fragment>
    }
}

export default WidgetStakeholderFiles