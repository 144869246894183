import React from 'react'
import {agencySchema} from './AgencySchema'

const agencyObj = agencySchema()
const agency_name = agencyObj.hasOwnProperty('name') ? agencyObj.name : ""
const year = new Date().getFullYear()
export const NavFooterSchema = [
    {
        "text": "© "+year+" "+agency_name+" / All rights reserved",
        //"xs": 6,
    },
    {
        "text": "Powered by Teuteca Srl",
        //"xs": 2,
    },
    {
        "text": <a href="mailto:support.it@teuteca.com">support.it@teuteca.com</a>,
        //"xs": 2,
    },
]