import React from 'react'

import {users_get_leads} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Table from '../@react-fefmwk/tables/modules/Table'

import BlockIcon from '@material-ui/icons/Block'

class MyLeads extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            leads: [],
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        const leads = await users_get_leads(id_user)

        this.setState({
            leads: leads
        })
    }

    render() {
        const leads = this.state.leads
        if( !leads.length )
            return <Chip icon={<BlockIcon />} label="Nessun lead assegnato" variant="outlined" />

        const entity = "tasks"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        return <React.Fragment>
            <Paper>
                <Table material dense highlight
                    data={leads}
                    fieldsProps={fieldsProps}
                    rowActions={rowActions}
                    rowActionsField={id_field}
                    showPagination={true}
                    filterable={true}
                    sortable={true}
                />
            </Paper>
        </React.Fragment>
    }
}

export default MyLeads