import React from 'react'

import EntityRelFieldActions from '../@react-fefmwk/entity/modules/EntityRelFieldActions'
import Formatter from '../@react-fefmwk/formatter/modules/Formatter'
//import Modal from '../../@react-fefmwk/modals/modules/Modal'

import { Chip } from '@material-ui/core'

class ManageNetCommission extends React.Component {
    render() {
        const value = this.props.value
        const id_practical = this.props.hasOwnProperty('id_practical')
            ? this.props.id_practical
            : 0

        if( !id_practical ) return value

        const Trigger = <Chip size="small" color="secondary" variant="outlined" clickable
            label={<Formatter
                value={value}
                dataType="currency"
                currency="EUR"
                decimalDigits="2"
                exponent="0"
            />}
        />
        const title = "Modifica commissione netta"
        const tooltipText = "Modifica commissione netta"
        const url = ("update/commission/"+id_practical)
        const s = ""

        const onClose = this.props.hasOwnProperty('onClose')
            ? this.props.onClose
            : () => true

        return <React.Fragment>
            <EntityRelFieldActions
                type="drawer"
                //PaperProps={{style:{height:"50vh"}}}
                title={title}
                linkText={tooltipText}
                entity="practicals"
                onClose={onClose}
                Trigger={Trigger}
                url={url}
                s={s}
            />
        </React.Fragment>
    }
}

export default ManageNetCommission