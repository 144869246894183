import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import Wiki from '../modules/Wiki'

class WikiPage extends React.Component {
    render() {
        const title = this.props.route.title

        return <Content title={title}>
            <Wiki />
        </Content>
    }
}

export default WikiPage