import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import {agenciesBranchesFromToken} from '../services/AgencySchema'
import MyBranchStakeholders from '../modules/MyBranchStakeholders'

class MyBranchStakeholdersPage extends React.Component {
    render() {
        const title = this.props.route.title
        const branches = this.props.hasOwnProperty('branches') && this.props.branches
            ? this.props.branches
            : agenciesBranchesFromToken()

        return <Content title={title}>
            <MyBranchStakeholders branches={branches} />
        </Content>
    }
}

export default MyBranchStakeholdersPage