import {EntitySchema} from './EntitySchema'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

export function actionTimeinfoSchema( entity,cftime,id_entity ) {

    const entityObj = EntitySchema[entity]
    const entityRootPath = entityObj.hasOwnProperty('rootpath') ? entityObj.rootpath : entity
    const path = entityRootPath+"/update/"+id_entity+"/timeinfo"

    switch (cftime) {
        /*case "aum":
        case "soft-nav":
            switch (entity) {
                case "vehicles":

                break
                case "shareclasses":

                break
            }
        break*/
        default:
            return [
                {
                    action: "edit",
                    header: "Edit",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Delete",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
    }
}