import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'

import {
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

class SnackbarMessage extends React.Component  {
    constructor(props) {
      super(props)

      this.state = {
        open:this.props.open,
        message:this.props.message,
        severity:this.props.severity,
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        this.setState({
          open:this.props.open,
          message:this.props.message,
          severity:this.props.severity,
        })
      }
    }

    handleClose() {
      this.setState({open: false})
      const onClose = this.props.onClose ? this.props.onClose : () => true
      onClose()
    }

    render() {
      const open = this.state.open
      const message = this.state.message
      const severity = this.props.severity ? this.props.severity : ''
      const anchorOrigin = this.props.anchorOrigin ? this.props.anchorOrigin : {vertical:'top',horizontal:'center'}
      const persistentDuration = this.props.hasOwnProperty('persistentDuration') ? true : false
      const ClickAwayListenerProps = persistentDuration ? {onClickAway: () => null} : {}
      const autoHideDuration = this.props.autoHideDuration ? this.props.autoHideDuration : (persistentDuration ? 100000000000 : 6000)
      const action = this.props.action ? this.props.action : null
      const style = this.props.style ? this.props.style : {}

      const closeBtn = this.props.hasOwnProperty('closeBtn') ? this.props.closeBtn : false

      const {theme} = this.props
      const severityColorMode = this.props.severityColorMode ? this.props.severityColorMode : 'dark'//main,dark,...
      let severityStyle = {}
      if( severity )
        severityStyle = {
          backgroundColor: theme.palette[severity][severityColorMode]
        }

      return <Snackbar 
        ContentProps={{style:severityStyle}}
        severity={severity}
        anchorOrigin={anchorOrigin}
        open={open} 
        autoHideDuration={autoHideDuration}
        ClickAwayListenerProps={ClickAwayListenerProps}
        message={message}
        onClose={() => this.handleClose()}
        action={<React.Fragment>
          {action}
          {closeBtn
            ? <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.handleClose()}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            : null}
        </React.Fragment>}
        style={style}
      />
    }
}

export default withTheme(SnackbarMessage)