import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

class WidgetCompany extends React.Component {
    render() {
        const id_company = this.props.id_company
        if( !id_company ) return <Chip icon={<ErrorIcon />} label="No data available" variant="outlined" />

        const id_atc = this.props.id_atc
        const id_insurance = this.props.id_insurance

        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : []

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const otherActions = this.props.hasOwnProperty('otherActions')
            ? this.props.otherActions
            : []
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Anagrafica"

        const updateBtn = this.props.hasOwnProperty('updateBtn')
            ? this.props.updateBtn
            : true
        const deleteBtn = this.props.hasOwnProperty('deleteBtn')
            ? this.props.deleteBtn
            : true

        let actions = null
        if( dashboardcardActions && (updateBtn || deleteBtn) )
            actions = <React.Fragment>
                {updateBtn
                    ? <Button
                        variant="text" size="small"
                        startIcon={<EditIcon fontSize="small" />}
                        component={AdapterLink}
                        to={"/companies/update/"+id_company}
                    >Modifica</Button>
                    : null}
                {deleteBtn
                    ? <Button variant="text" size="small"
                        startIcon={<DeleteIcon fontSize="small" />}
                        to={"/companies/delete/"+id_company}
                        component={AdapterLink}
                    >Elimina</Button>
                    : null}
                {[...otherActions]}
            </React.Fragment>

        return <React.Fragment>
            <ViewEntity dense
                entity={"companies"}
                id_entity={id_company}
                fields={fields}
                TopActions={actions}
                cardTitle={dashboardcardTitle}
            />
            {id_atc
                ? <React.Fragment>
                    <br />
                    <ViewEntity dense
                        entity={"atcs"}
                        id_entity={id_atc}
                        //cardTitle={dashboardcardTitle}
                        actions={<React.Fragment>
                            <Button
                                variant="text" size="small"
                                startIcon={<EditIcon fontSize="small" />}
                                component={AdapterLink}
                                to={"/atcs/update/"+id_atc}
                            >Modifica</Button>
                        </React.Fragment>}
                    />
                </React.Fragment>
                : null}
            {id_insurance
                ? <React.Fragment>
                    <br />
                    <ViewEntity dense
                        entity={"insurances"}
                        id_entity={id_insurance}
                        //cardTitle={dashboardcardTitle}
                    />
                </React.Fragment>
                : null}
        </React.Fragment>
    }
}

export default WidgetCompany