import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LoginForm from '../@react-fefmwk/auth/modules/LoginForm'
import Button from '@material-ui/core/Button'

const loginStyles = {
    cardcontent: {
        paddingTop: 4,
        paddingBottom: 4,
        "&:last-child": {
            paddingBottom: 4
        },
        textAlign:'center'
    },
    loginLogoContainer: {
        width: '100%',
        marginBottom: 20,
        textAlign: 'center',
    },
    loginBtn: {
        width: '100%',
        marginBottom: 20
    }
}

class Login extends React.Component {
    render() {
        const { classes,theme } = this.props
        const inputProps = {
            inputProps:{style:{textAlign:'center'}},
            InputLabelProps: {
                style: {width:'100%',transformOrigin: 'top center',}
            }
        }

        const login_logo = theme.login_logo

        return <React.Fragment>
            <Box component="div" className={classes.loginLogoContainer}>
                <img src={login_logo} alt="Login logo" style={{maxWidth:'50%'}} />
            </Box>
            <Card elevation={0}>
                <CardContent className={classes.cardcontent}>
                    <LoginForm redirectTo="/home"
                        msgAfterLogin="Login corretto"
                        usernameProps={inputProps}
                        passwordProps={inputProps}
                        buttons={(formikProps) => <React.Fragment>
                            <Button color="primary" variant="contained" className={classes.loginBtn}
                                type="submit"
                            >Login</Button>
                        </React.Fragment>}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    }
}

export default withStyles(loginStyles)(withTheme(Login))