import React from 'react'

import {
    practicals_list_commissions
} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import Table from '../@react-fefmwk/tables/modules/Table'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import ManageNetCommission from './ManageNetCommission'
import ManageGrossCommission from './ManageGrossCommission'

import BlockIcon from '@material-ui/icons/Block'

class PracticalsCommissions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals: [],
            refresh: false,
            showall: true,
        }
    }

    async componentDidMount() {
        const showall = this.state.showall
        const practicals = await this.getPracticalsCommissions(showall)
        this.setState({
            practicals: practicals
        })
    }
    async componentDidUpdate(prevProps,prevState) {
        const refresh = this.state.refresh
        const prev_refresh = prevState.refresh
        const showall = this.state.showall
        if( refresh && !prev_refresh ) {
            const practicals = await this.getPracticalsCommissions(showall)
            this.setState({
                practicals: practicals,
                refresh: false
            })
        }
    }
    async getPracticalsCommissions(showall) {
        const hide_managed = !showall
        return await practicals_list_commissions(hide_managed)
    }

    handleChange(e,filter) {
        e.persist()

        this.setState({
            refresh: true,
            showall: filter === 'showall' ? e.target.checked : this.state.showall,
        })
    }

    render() {
        const practicals = this.state.practicals
        const refresh = this.state.refresh

        const showall = this.state.showall

        const entity = "practicals"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const rowActions = actionSchema(entity,path)
        let fieldsProps = tableSchema(entity)

        fieldsProps['net_commission']['Cell'] = (props) => {
            const value = props.value
            const row = props.row

            const id_practical = row.hasOwnProperty('id_practical') ? row.id_practical : 0
            if( !id_practical ) return value

            return <ManageNetCommission
                value={value}
                id_practical={id_practical}
                onClose={() => this.setState({refresh:true})}
            />
        }
        fieldsProps['gross_commission']['Cell'] = (props) => {
            const value = props.value
            const row = props.row

            const id_practical = row.hasOwnProperty('id_practical') ? row.id_practical : 0
            if( !id_practical ) return value

            const net_commission = row.hasOwnProperty('net_commission') ? row.net_commission : 0
            const id_practical_commission = row.hasOwnProperty('id_practical_commission') ? row.id_practical_commission : 0

            return <ManageGrossCommission
                value={value}
                id_practical={id_practical}
                net_commission={net_commission}
                id_practical_commission={id_practical_commission}
                onClose={() => this.setState({refresh:true})}
            />
        }

        return <React.Fragment>
            <Box mb={2}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs="auto">
                        <Switch checked={showall} onChange={(e) => this.handleChange(e,'showall')} /> mostra tutte
                    </Grid>
                </Grid>
            </Box>
            {practicals.length
                ? <Paper>
                    {!refresh
                        ? <Table material dense highlight
                            data={practicals}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                        : <LoaderSingleComponent width="100%" />}
                </Paper>
                : <Chip icon={<BlockIcon />} label="Nessuna pratica trovata" variant="outlined" />}
        </React.Fragment>
    }
}

export default PracticalsCommissions