
import React from 'react'
import {withRouter} from "react-router-dom"

import {queryStringToObj} from '../../@react-fefmwk/links/service/api'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'
import RedirectBack from '../../@react-fefmwk/links/modules/RedirectBack'
import SnackbarMessage from '../../@react-fefmwk/messages/modules/SnackbarMessage'
//import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import ExternalLink from '../../@react-fefmwk/links/modules/ExternalLink'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

class Checkout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectBack: false
        }
    }
    render() {
        const formikProps = this.props.formikProps
        const id_practical = formikProps.values.id_practical
        const id_stakeholder = formikProps.values.id_stakeholder

        const queryStrings = this.props.location.search

        let queryStringsObj = {}
        if( queryStrings )
            queryStringsObj = queryStringToObj(queryStrings)

        const noaction = queryStringsObj.hasOwnProperty('noactionatcheckout')
        const backaction = queryStringsObj.hasOwnProperty('backatcheckout')
        const redirectBack = this.state.redirectBack

        let viewProps = {}
        if( !noaction )
            viewProps['actions'] = <React.Fragment>
                <Button variant="text" size="small"
                    startIcon={<CloudUploadIcon />}
                    component={ExternalLink} to={'/practicals/card/'+id_practical+'#documents'}
                >Documenti</Button>
                <Button variant="text" size="small"
                    startIcon={<OpenInNewIcon />}
                    component={ExternalLink} to={'/tasks/add?id_practical='+id_practical}
                >Assegna task</Button>
            </React.Fragment>

        return <React.Fragment>
            {!noaction
                ? <Box mb={2}>
                    <Button variant="contained" size="small" color="primary"
                        startIcon={<AccountCircleIcon />}
                        component={ExternalLink} to={'/stakeholders/card/'+id_stakeholder}
                    >Cliente</Button>
                    &nbsp;&nbsp;
                    <Button variant="contained" size="small" color="primary"
                        startIcon={<CloudUploadIcon />}
                        component={ExternalLink} to={'/practicals/card/'+id_practical+'#documents'}
                    >Documenti</Button>
                    &nbsp;&nbsp;
                    <Button variant="contained" size="small" color="primary"
                        startIcon={<OpenInNewIcon />}
                        component={ExternalLink} to={'/tasks/add?id_practical='+id_practical}
                    >Assegna task</Button>
                </Box>
                : null}
            <ViewEntity
                entity={"practicals"}
                id_field={"id_practical"}
                id_entity={id_practical}
                {...viewProps}
            />
            <SnackbarMessage
                open={id_practical ? true : false}
                autoHideDuration={2000}
                severity="success"
                message={"Practical correctly created"}
                onClose={() => this.setState({redirectBack:backaction})}
            />
            {redirectBack
                ? <RedirectBack />
                : null}
        </React.Fragment>
    }
}

export default withRouter(Checkout)