import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import SnackbarMessage from '../../messages/modules/SnackbarMessage'

const styles = {
    loaderLayer: {
        width: '100%',
        height: '100vh',
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: 'white',
        opacity: 0.5,
        position: 'absolute',
        top: 0,
        left: 0,
        paddingTop: 4,
        zIndex: 1200,
    },
    loaderMsg: {
        
    }
}

class ReloadPage extends React.Component  {
    render() {
        const timeout = this.props.timeout ? this.props.timeout : 3000
        const action = this.props.action
            ? this.props.action
            : () => window.location.reload()
        const msg = this.props.msg ? this.props.msg : "Wait while reloading..."

        const { classes } = this.props

        return <React.Fragment>
            <div className={classes.loaderLayer}></div>
            <SnackbarMessage
                className={classes.loaderMsg}
                open={true}
                message={msg}
                autoHideDuration={timeout}
                onClose={action}
            />
        </React.Fragment>
    }
}

export default withStyles(styles)(ReloadPage)