import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import AddIcon from '@material-ui/icons/Add'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import EditIcon from '@material-ui/icons/Edit'

class FieldIdPracticalOuter extends React.Component {
    render() {
        const id_practical_outer = this.props.id_practical_outer
        const id_stakeholder = this.props.id_stakeholder
        const title = this.props.title ? this.props.title : "Scheda pratica altra agenzia"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda pratica altra agenzia"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"

        let actionsProp = {}
        if( id_stakeholder )
            actionsProp['actions'] = <React.Fragment>
                <Button variant="contained" size="small" color="primary"
                    component={AdapterLink} to={'/practicals_outers/card/'+id_practical_outer} target="_blank"
                >Visualizza</Button>
                <Button variant="text" size="small"
                    startIcon={<EditIcon />}
                    component={AdapterLink} to={'/practicals_outers/update/'+id_practical_outer}
                >Modifica</Button>
                <Button variant="text" size="small"
                    startIcon={<CloudUploadIcon />}
                    component={AdapterLink} to={'/practicals_outers/card/'+id_practical_outer+'#documents'} target="_blank"
                >Documenti</Button>
                <Button variant="text" size="small"
                    startIcon={<AddIcon />}
                    component={AdapterLink} to={'/tasks/add?id_stakeholder='+id_stakeholder} target="_blank"
                >Assegna task</Button>
            </React.Fragment>

        return  !id_practical_outer || id_practical_outer <= 0 || id_practical_outer === '-'
            ? null
            : <FieldEntityView title={title} icon={AccountBalanceWalletIcon}
                id_entity={id_practical_outer} entity="practicals_outers"
                fields={[
                    'id_practical_outer','practical_outer_code',
                    'product','stakeholder',
                    'agent','agencies_branches',
                    'lender','atc','practical_state',
                    'effective_date','renewal_date','renewal_date_second',
                    'residual_amount','installments_n','installment_amount',
                ]}
                {...actionsProp}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdPracticalOuter