import React from 'react'
import { toolbarSchema } from '../../../services/EntityToolbarSchema'

class EntityToolbar extends React.Component {
    constructor(props) {
        super(props)

        this.renderToolbar = this.renderToolbar.bind(this)
    }

    renderToolbar( list ) {
        const entity = this.props.entity

        return <React.Fragment>
            {list.map((btn,b) => (
                <React.Fragment key={entity+'-btn-'+b}>
                    {btn}
                </React.Fragment>
            ))}
        </React.Fragment>
    }

    render() {
        const entity = this.props.entity
        const path = this.props.path
        const data = this.props.data
        const toolbar = toolbarSchema( entity,path,data )

        return <React.Fragment>
            {this.renderToolbar( toolbar )}
        </React.Fragment>
    }
}

export default EntityToolbar