import React from 'react'

import {users_get_reports} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'
import {groupsFromToken} from '../@react-fefmwk/auth/services/token'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'
import Table from '../@react-fefmwk/tables/modules/Table'
import Button from '@material-ui/core/Button'

import BlockIcon from '@material-ui/icons/Block'
import AddIcon from '@material-ui/icons/Add'

class MyReports extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            reports: [],
            refresh: false,
            to_my_assignments: true,//default
            my_reports: false,
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        //const to_my_assignments = this.state.to_my_assignments
        const my_reports = this.state.my_reports
        const scope = my_reports ? 'my_reports' : 'to_my_assignments'
        const reports = await users_get_reports(id_user,scope)
        this.setState({
            reports: reports
        })
    }

    async componentDidUpdate(prevProps,prevState) {
        const to_my_assignments = this.state.to_my_assignments
        const my_reports = this.state.my_reports
        if( to_my_assignments !== prevState.to_my_assignments || my_reports !== prevState.my_reports ) {
            const id_user = this.state.id_user
            const scope = my_reports ? 'my_reports' : 'to_my_assignments'
            const reports = await users_get_reports(id_user,scope)
            this.setState({
                reports: reports,
                refresh: false
            })
        }
    }

    handleChange(e,filter) {
        e.persist()

        this.setState({
            refresh: true,
            to_my_assignments: filter === 'to_my_assignments' ? e.target.checked : false,
            my_reports: filter === 'my_reports' ? e.target.checked : false,
        })
    }

    render() {
        const reports = this.state.reports
        const entity = "reports"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        const groups = groupsFromToken()

        const refresh = this.state.refresh
        const to_my_assignments = this.state.to_my_assignments
        const my_reports = this.state.my_reports

        return <React.Fragment>
            <Box mb={2}>
                <Grid container spacing={4} alignItems="center">
                    {groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5))
                        ? <Grid item xs="auto">
                            <Button component={AdapterLink} to="/reports/add" variant="contained" size="small" color="secondary" startIcon={<AddIcon />}>
                                report
                            </Button>
                        </Grid>
                        : null}
                    <Grid item xs="auto">
                        <Switch checked={to_my_assignments} onChange={(e) => this.handleChange(e,'to_my_assignments')} /> alle mie richieste
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={my_reports} onChange={(e) => this.handleChange(e,'my_reports')} /> scritti da me
                    </Grid>
                </Grid>
            </Box>
            {!refresh
                ? reports.length
                    ? <Paper>
                        <Table material dense highlight
                            data={reports}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                    </Paper>
                    : <Chip icon={<BlockIcon />} label="Nessun report trovato" variant="outlined" />
            : <LoaderSingleComponent width="100%" />}
        </React.Fragment>
    }
}

export default MyReports