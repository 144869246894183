import React from 'react'

import { EntitySchema } from '../services/EntitySchema'
import {entity_get} from '../@react-fefmwk/entity/services/api'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'

import WidgetCompany from './widgets/WidgetCompany'
import WidgetCompanyAddresses from './widgets/WidgetCompanyAddresses'
import WidgetCompanyContacts from './widgets/WidgetCompanyContacts'
import WidgetAtcQuotations from './widgets/WidgetAtcQuotations'
import WidgetAtcPracticals from './widgets/WidgetAtcPracticals'
import WidgetAtcCustomers from './widgets/WidgetAtcCustomers'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'

class CardAtc extends React.Component {
    constructor(props) {
        super(props)

        const entity = 'atcs'
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        this.state = {
            entity: entity,
            id_field: id_field,
            id_entity: this.props.id_entity,
            atc: {},
        }
    }
    async componentDidMount() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        this.setState({
            atc: await entity_get(entity,id_entity),
        })
    }
    render() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        const atc = this.state.atc
        const id_company = atc.hasOwnProperty("id_company") ? atc.id_company : 0
        if( !id_company ) return <LoaderSingleComponent wifth="100%" />

        const groups = groupsFromToken()
        const deleteBtn = groups.includes(String(4))
            ? false
            : (this.props.hasOwnProperty('deleteBtn')
                ? this.props.deleteBtn
                : true)

        return <Tabber basicname="atccard"
            tabs={[
                {
                    slug: "info",
                    label: "info",
                    content: <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <WidgetCompany
                                id_company={id_company}
                                //id_atc={id_entity}
                                fields={['id_company','company_name','vat_number','cf']}
                                dashboardcardTitle="Anagrafica"
                                deleteBtn={deleteBtn}
                            />
                            <br />
                            <ViewEntity dense
                                entity={entity}
                                id_entity={id_entity}
                                //cardTitle={dashboardcardTitle}
                                TopActions={<React.Fragment>
                                    <Button
                                        variant="text" size="small"
                                        startIcon={<EditIcon fontSize="small" />}
                                        component={AdapterLink}
                                        to={"/atcs/update/"+id_entity}
                                    >Modifica</Button>
                                </React.Fragment>}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <WidgetCompanyAddresses
                                id_company={id_company}
                                dashboardcardTitle="Indirizzi"
                            />
                            <br />
                            <WidgetCompanyContacts
                                id_company={id_company}
                                dashboardcardTitle="Altri contatti"
                            />
                        </Grid>
                    </Grid>
                },
                {
                    slug: "quotations",
                    label: "quotazioni",
                    content: <React.Fragment>
                        <WidgetAtcQuotations
                            id_atc={id_entity}
                        />
                    </React.Fragment>
                },
                {
                    slug: "practicals",
                    label: "pratiche",
                    content: <React.Fragment>
                        <WidgetAtcPracticals
                            id_atc={id_entity}
                        />
                    </React.Fragment>
                },
                {
                    slug: "customers",
                    label: "clienti",
                    content: <React.Fragment>
                        <WidgetAtcCustomers
                            id_atc={id_entity}
                        />
                    </React.Fragment>
                },
            ]}
        />
    }
}

export default CardAtc