import React from 'react'
import ReactDOM from 'react-dom'
import LoaderApp from '../modules/LoaderApp'

export function showAppLoader() {
    createLoaderAppBox()
    ReactDOM.render(
        <LoaderApp />,
        document.getElementById('LoaderApp')
    )
}
export function hideAppLoader() {
    var loaderBox = document.getElementById("LoaderApp")
    if(typeof(loaderBox) !== 'undefined' && loaderBox !== null)
        loaderBox.remove()
}
function createLoaderAppBox() {
    var loaderBox = document.getElementById("LoaderApp")
    if(typeof(loaderBox) === 'undefined' || loaderBox === null) {
        let loadBox = document.createElement('div')
        loadBox.setAttribute("id","LoaderApp")

        var root = document.getElementById("Root")
        root.appendChild(loadBox)
    }

    return loaderBox
}