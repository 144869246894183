import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import {agenciesBranchesFromToken} from '../services/AgencySchema'
import MyBranchPracticals from '../modules/MyBranchPracticals'

class MyBranchPracticalsPage extends React.Component {
    render() {
        const title = this.props.route.title
        const outers = this.props.route.hasOwnProperty('outers') ? this.props.route.outers : false
        const branches = this.props.hasOwnProperty('branches') && this.props.branches
            ? this.props.branches
            : agenciesBranchesFromToken()

        return <Content title={title}>
            <MyBranchPracticals branches={branches} outers={outers} />
        </Content>
    }
}

export default MyBranchPracticalsPage