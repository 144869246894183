import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const style = theme => {
    return {
        loaderContainer: {
            width: "100%",
            height: "100vh",
            textAlign: "center",
            verticalAlign: "middle",
            backgroundColor: "white",
            opacity: 0.5,
            position: "absolute",
            top: 0,
            left: 0,
            paddingTop: "15%",
            zIndex: 1200
        },
        loaderCirc: {
            zIndex: 10000,
            color: theme.palette.grey[600]
        },
    }
}

class LoaderApp extends Component  {
    render() {
        const {classes} = this.props
        return <div className={classes.loaderContainer}>
            <CircularProgress id="LoaderApp" size={80} className={classes.loaderCirc} />
        </div>
    }
}

export default withStyles(style)(LoaderApp)