import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import CloseIcon from '@material-ui/icons/Close'
import { Typography } from '@material-ui/core'

const style = theme => ({
    closeBtn: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
    },
    contentBox: {
        height: "100%",
        padding: theme.spacing(2)
    },
    titleBox: {
        padding: theme.spacing(2)
    },
})

class ModalDrawer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: this.props.open ? this.props.open : false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }
    componentDidUpdate(prevProps) {
        const open = this.props.open
        const prev_open = prevProps.open
        if( open !== prev_open && open ) this.handleOpen()
        if( open !== prev_open && !open ) this.handleClose()
    }
    handleClose() {
        const onClose = this.props.hasOwnProperty('onClose') ? this.props.onClose : null
        if( onClose ) onClose()
        this.setState({open:false})
    }
    handleOpen() {
        const onOpen = this.props.hasOwnProperty('onOpen') ? this.props.onOpen : null
        if( onOpen ) onOpen()
        this.setState({open:true})
    }
    render() {
        const open = this.state.open
        const title = this.props.title
        const content = this.props.content
        const Trigger = this.props.Trigger

        const anchor = this.props.hasOwnProperty('anchor') ? this.props.anchor : 'bottom'
        const elevation = this.props.hasOwnProperty('elevation') ? this.props.elevation : 16
        const variant = this.props.hasOwnProperty('variant') ? this.props.variant : 'temporary'
        const hideBackdrop = this.props.hasOwnProperty('hideBackdrop') ? this.props.hideBackdrop : false
        const ModalProps = this.props.hasOwnProperty('ModalProps') ? this.props.ModalProps : {}
        const PaperProps = this.props.hasOwnProperty('PaperProps') ? this.props.PaperProps : {}
        const SlideProps = this.props.hasOwnProperty('SlideProps') ? this.props.SlideProps : {}

        const contentAlign = this.props.hasOwnProperty('contentAlign')//left,right,center,justify
            ? this.props.contentAlign
            : "left"

        const tooltipText = this.props.hasOwnProperty('tooltipText') ? this.props.tooltipText : ''
        const tooltipPlacement = this.props.hasOwnProperty('tooltipPlacement') ? this.props.tooltipPlacement : "right"

        const { classes } = this.props

        let printCloseBtn = this.props.hasOwnProperty('printCloseBtn') ? true : false
        //printCloseBtn = fullScreen ? true : printCloseBtn//force print close button in case fullscreen
        const CloseBtn = printCloseBtn
            ? <IconButton color="secondary" onClick={() => this.handleClose()} className={classes.closeBtn}>
                <CloseIcon />
            </IconButton>
            : null

        const TriggerComponent = !Trigger
            ? null
            : (React.isValidElement(Trigger)
                ? React.cloneElement(
                    Trigger,
                    {
                        style: {cursor:'pointer'},
                        onClick: () => this.handleOpen()
                    }
                )
                : <Trigger color="secondary" style={{cursor:'pointer'}} onClick={() => this.handleOpen()} />)

        return  <React.Fragment>
            {tooltipText
                ? <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    {TriggerComponent}
                </Tooltip>
                : TriggerComponent}
            <Drawer
                onClose={() => this.handleClose()}
                open={open}
                anchor={anchor}
                elevation={elevation}
                variant={variant}
                hideBackdrop={hideBackdrop}
                ModalProps={ModalProps}
                PaperProps={PaperProps}
                SlideProps={SlideProps}
                style={{textAlign:contentAlign}}
            >
                {title
                    ? <React.Fragment>
                        <Box className={classes.titleBox}>
                            <Typography component="span" variant="h5">
                                {title}
                            </Typography>
                        </Box>
                        <Divider />
                    </React.Fragment>
                    : null}
                <Box className={classes.contentBox}>
                    {content}
                </Box>
                {CloseBtn}
            </Drawer>
        </React.Fragment>
    }
}

export default withStyles(style)(ModalDrawer)