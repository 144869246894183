import React from 'react'

import {entity_get} from '../@react-fefmwk/entity/services/api'
import {groupsFromToken} from '../@react-fefmwk/auth/services/token'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import Grid from '@material-ui/core/Grid'

import WidgetPerson from './widgets/WidgetPerson'
import WidgetPersonAddresses from './widgets/WidgetPersonAddresses'
import WidgetPersonContacts from './widgets/WidgetPersonContacts'

class CardPeople extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            people: {},
        }
    }

    async componentDidMount() {
        const entity = 'people'
        const id_entity = this.props.id_entity

        this.setState({
            people: await entity_get(entity,id_entity),
        })
    }

    render() {
        const people = this.state.people
        if( !Object.keys(people).length ) return <LoaderSingleComponent width="100%" />

        const is_obscured = people.hasOwnProperty("is_obscured") ? people.is_obscured : false

        const id_entity = this.props.id_entity
        const groups = groupsFromToken()
        const deleteBtn = groups.includes(String(4))
            ? false
            : (this.props.hasOwnProperty('deleteBtn')
                ? this.props.deleteBtn
                : true)

        return <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <WidgetPerson
                    id_people={id_entity}
                    dashboardcardTitle="Anagrafica"
                    deleteBtn={deleteBtn}
                    dashboardcardActions={!is_obscured}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <WidgetPersonAddresses
                    id_people={id_entity}
                    dashboardcardTitle="Indirizzi"
                    dashboardcardActions={!is_obscured}
                />
                <br />
                <WidgetPersonContacts
                    id_people={id_entity}
                    dashboardcardTitle="Altri contatti"
                    dashboardcardActions={!is_obscured}
                />
            </Grid>
        </Grid>
    }
}

export default CardPeople