import React from 'react'
//import { Redirect } from "react-router-dom"
import { Formik,Form,ErrorMessage } from 'formik'

import { doLogin } from '../services/api'
import { setToken,getToken } from '../services/token'

import TextMsg from '../../messages/modules/TextMsg'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            token: '',
            //redirect: false
        }
        
        this.doLogin = this.doLogin.bind(this)
    }
    componentDidMount() {
        const token = getToken()
        this.setState({
            token: token,
            //redirect: token ? true : false,
        })
    }

    validate( values ) {
        let errors = {}

        const username = values.hasOwnProperty('username') ? values.username : ''
        const password = values.hasOwnProperty('password') ? values.password : ''

        if( !username )
            errors['username'] = 'Invalid username'
        if( !password )
            errors['password'] = 'Invalid password'

        //exec hook onValidate
        const onValidate_f = this.props.hasOwnProperty('onValidate')
            ? this.props.onValidate
            : (values,errors) => {return errors}
        errors = onValidate_f(values,errors)

        return errors
    }
    async doLogin(data) {
        const token = await doLogin( data )

        if( token ) {
            setToken( token )
            this.setState({
                token: token,
            })
        }
    }

    render() {
        const token = this.state.token
        //const redirect = this.state.redirect
        const redirectTo = this.props.hasOwnProperty('redirectTo')
            ? this.props.redirectTo
            : '/home'
        const msgAfterLogin = this.props.hasOwnProperty('msgAfterLogin')
            ? this.props.msgAfterLogin
            : 'Login correct'

        const buttons = this.props.hasOwnProperty('buttons')
            ? this.props.buttons
            : (formikProps) => <Button color="primary" variant="contained"
                disabled={!formikProps.isValid}
                type="submit"
            >Login</Button>
        const usernameProps = this.props.hasOwnProperty('usernameProps') ? this.props.usernameProps : {}
        const passwordProps = this.props.hasOwnProperty('passwordProps') ? this.props.passwordProps : {}

        return <React.Fragment>
            <SnackbarMessage
                open={token ? true : false}
                autoHideDuration={2000}
                severity="success"
                message={msgAfterLogin}
                onClose={() => (window.location.href = redirectTo)}
                //onClose={() => this.setState({redirect: true})}
            />
            {/*redirect ? <Redirect to={redirectTo} /> : null*/}
            <Formik
                initialValues={{username: '',password: ''}}
                validate={(values) => {
                    return this.validate(values)
                }}
                onSubmit={(values,actions) => {
                    this.doLogin(values)
                }}
                render={formikProps => {
                    return <Form id="loginForm" onSubmit={formikProps.handleSubmit}>
                        <Box mb={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="username"
                                    type="text"
                                    name="username"
                                    label="Username"
                                    error={formikProps.errors.username && formikProps.touched.username ? true : false}
                                    value={formikProps.values.username}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    margin="normal"
                                    {...usernameProps}
                                />
                                <ErrorMessage name="username">
                                    {(msg) => (<TextMsg severity="error" message={msg} />)}
                                </ErrorMessage>
                                <TextField
                                    id="password"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    error={formikProps.errors.password && formikProps.touched.password ? true : false}
                                    value={formikProps.values.password}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    margin="normal"
                                    {...passwordProps}
                                />
                                <ErrorMessage name="password">
                                    {(msg) => (<TextMsg severity="error" message={msg} />)}
                                </ErrorMessage>
                            </FormControl>
                        </Box>
                        {buttons(formikProps)}
                    </Form>
                }}
            />
        </React.Fragment>
    }
}

export default LoginForm