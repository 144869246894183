import React from 'react'

import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Chip from '@material-ui/core/Chip'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'

class FieldFile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            files: [],
            uploaded: false,
            uploadMsgOpen: false
        }

        this.onChange = this.onChange.bind(this)
        this.doUpload = this.doUpload.bind(this)
        this.emptyFiles = this.emptyFiles.bind(this)
        this.onCloseUploadMsg = this.onCloseUploadMsg.bind(this)
    }

    emptyFiles() {
        this.setState({files: [],uploaded: false})
    }

    onChange(e) {
        e.persist();
        this.setState({
            files: e.target.files
        })
    }

    onCloseUploadMsg() {
        this.setState({uploadMsgOpen:false})
    }

    async doUpload() {
        const files = this.state.files
        const files_n = files.length
        if( !files_n ) return false

        const name = this.props.name
        const upload_f = this.props.upload_f

        let formFiles = new FormData()
        for( let f = 0; f < files_n; f++ )
            formFiles.append(name,files[f])

        const uploaded = await upload_f(
            formFiles,//file uploaded object
            files//clean uploaded files list
        )
        this.setState({
            uploaded: uploaded,
            files: [],
            uploadMsgOpen: true
        })
    }

    render() {
        const label = this.props.label ? this.props.label : ""
        const name = this.props.name
        const helperText = this.props.helperText ? this.props.helperText : ""
        const chooseButtonText = this.props.chooseButtonText ? this.props.chooseButtonText : "Choose"
        const uploadButtonText = this.props.uploadButtonText ? this.props.uploadButtonText : "Upload"
        const fileList = this.props.hasOwnProperty('fileList')
        const fileListStyle = this.props.fileListStyle ? this.props.fileListStyle : {}
        const webkitdirectory = this.props.webkitdirectory ? {webkitdirectory:"webkitdirectory"} : {}
        const multiple = webkitdirectory ? true : this.props.hasOwnProperty('multiple')
        const files = Array.from(this.state.files)
        const files_n = files.length
        const uploaded = this.state.uploaded
        const uploadMsgOpen = this.state.uploadMsgOpen
        const disabled = this.props.disabled
        const size = this.props.size
        const startIcon = this.props.startIcon

        const FilesList = (files_n && fileList)
            ? <List style={fileListStyle}>
                {files.map((file,f) => (
                    <ListItemText key={"file-"+f} primary={<span style={{fontSize:"80%"}}>{file.name}</span>} />
                ))}
            </List>
            : null

        const UploadMsg = uploaded
            ? <Snackbar
                open={uploadMsgOpen}
                anchorOrigin={{vertical:"bottom",horizontal:"left"}}
                message="Upload correct"
                autoHideDuration={3000}
                onClose={() => this.onCloseUploadMsg()}
                action={<IconButton size="small" aria-label="close" color="inherit" onClick={() => this.onCloseUploadMsg()}>
                        <CloseIcon />
                    </IconButton>}
            />
            : null

        return <React.Fragment>
            <FormControl>
                <label>
                    {label ? <p>{label}</p> : null}
                    {helperText ? <p>{helperText}</p> : null}
                    {!files_n
                        ? <Button variant="contained" component="label" disabled={disabled} size={size} startIcon={startIcon}>
                            {chooseButtonText}
                            <input name={name} type="file" hidden disabled={disabled}
                                multiple={multiple}
                                {...webkitdirectory}
                                onChange={this.onChange}
                                onClick={() => this.emptyFiles()}
                            />
                        </Button>
                        : <React.Fragment>
                            <Button size={size} onClick={() => this.doUpload()} variant="contained" color="primary">{uploadButtonText}</Button>
                            &nbsp;&nbsp;&nbsp;
                            <IconButton size="small" onClick={() => this.emptyFiles()}><DeleteIcon size="small" /></IconButton>
                            &nbsp;&nbsp;&nbsp;
                            <Chip label={files_n} size="small" />
                        </React.Fragment>}
                </label>
            </FormControl>
            {UploadMsg}
            {FilesList}
        </React.Fragment>
    }
}

export default FieldFile