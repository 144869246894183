import React from 'react'
import { withTheme } from '@material-ui/styles'

import Chip from '@material-ui/core/Chip'

class FieldTaskState extends React.Component {
    render() {
        const state = this.props.state
        const isClosed = state === 'closed' ? true : false
        const theme = this.props.theme
        const style = {
            color: "white",
            backgroundColor: isClosed ? theme.palette.success.main : theme.palette.warning.main
        }

        return <Chip label={state} size="small" style={style} />
    }
}

export default withTheme(FieldTaskState)