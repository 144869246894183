import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import AddTask from '../modules/AddTask'

const AssignLeadPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id
    const currentUrl = props.route.location.pathname

    const precompiledData = {id_lead: id_entity,id_activity_order:5}

    return <Content title={title}>
        <AddTask id_entity={0}
            url={currentUrl}
            precompiledData={precompiledData}
            disableBackAtSteps={[1]}    
        />
    </Content>
}

export default AssignLeadPage