import React from 'react'
import { Formik,Form } from 'formik'

import {idFromToken} from '../../auth/services/token'
import {nowString} from '../../dates/services/api'
import {overrideFieldsObj} from '../../arrays/api'

import {
    entity_fields_info,
    entity_get,
    entity_update,
    entity_create,
    clear_nulls,
    apply_defaults
} from '../services/api'
import {queryStringToObj} from '../../links/service/api'
import {EntitySchema} from '../../../services/EntitySchema'
import {validationSchema} from '../../../services/EntityValidationSchema'

import EntityFormInputs from './EntityFormInputs'
import LoaderSingleComponent from '../../loader-app/modules/LoaderSingleComponent'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { Box, Chip } from '@material-ui/core'

class EntityForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            refresh: false,
            hideForm: false,
            hideFormMsg: "Rendering form is not allowed",
        }
    }
    async componentDidUpdate(prevProps,prevState) {
        if( prevProps.entity !== this.props.entity || this.state.refresh ) {
            await this.setupForm()
            this.setState({
                refresh: false
            })
        }
    }
    async componentDidMount() {
        await this.setupForm()
    }
    async setupForm() {
        const entity = this.props.entity
        const entitySchema = EntitySchema.hasOwnProperty(entity) ? EntitySchema[entity] : {}
        const entityValidationSchema = validationSchema(entity)
        const queryStrings = queryStringToObj(this.props.queryStrings)
        const precompiledData = this.props.precompiledData ? this.props.precompiledData : {}
        const customFieldsInfo = this.props.customFieldsInfo ? this.props.customFieldsInfo : {}
        const entityInputProps = this.props.entityInputProps ? this.props.entityInputProps : {}
        const gridItemProps = this.props.gridItemProps ? this.props.gridItemProps : {}
        const fieldsOrder = this.props.fieldsOrder ? this.props.fieldsOrder : []
        const id_entity = this.props.id_entity ? this.props.id_entity : null

        //get fields info from BE
        let fields_info = await entity_fields_info(entity)

        //apply custom fields info if set
        if( Object.keys(customFieldsInfo).length )
            fields_info = overrideFieldsObj( fields_info,customFieldsInfo )

        //apply fields and exclude fields
        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : []
        const excludeFields = this.props.hasOwnProperty('excludeFields') ? this.props.excludeFields : []
        if( fields.length ) {
            Object.keys(fields_info).map((fld) => {
                if( !fields.includes(fld) ) delete fields_info[fld]
                return true
            })
        }
        if( excludeFields.length ) {
            excludeFields.map((fld) => {
                if( fields_info.hasOwnProperty(fld) ) delete fields_info[fld]
                return true
            })
        }

        //check if id_entity is set
        let data = {}
        let form = {}
        if( id_entity ) {//update mode
            //get entity row
            data = await entity_get(entity,id_entity)
            const onGetData = this.props.hasOwnProperty('onGetData')
                ? this.props.onGetData
                : () => {}
            this.setState(onGetData(entity,id_entity,data))

            //clear all null
            data = clear_nulls(data)
            form = data
        }
        else {//insert mode
            //apply default values
            form = apply_defaults(fields_info)

            //pre-compile auto fields
            const id_user = idFromToken()
            const timestamp = nowString()
            let autoFields = {}
            if( Object.keys(fields_info).length ) {
                const formKeys = Object.keys(fields_info)
                for (var i = 0; i < formKeys.length; i++) {
                    let field = formKeys[i]
                    let inputType = fields_info[field].input_type
                    if( inputType === 'autouserid' || inputType === 'hidden_autouserid' ) {
                        autoFields[field] = id_user
                    }
                    if( inputType === 'timestamp' || inputType === 'hidden_timestamp' ) {
                        autoFields[field] = timestamp
                    }
                }
            }

            //apply queryStrings values
            form = {...form,...autoFields,...queryStrings,...precompiledData}
        }

        //update state
        this.setState({
            entity: entity,
            entitySchema: entitySchema,
            entityValidationSchema: entityValidationSchema,
            id_entity: id_entity,
            queryStrings: queryStrings,
            precompiledData: precompiledData,
            customFieldsInfo: customFieldsInfo,
            entityInputProps: entityInputProps,
            gridItemProps: gridItemProps,
            fieldsOrder: fieldsOrder,
            rows_updated: 0,
            submitted: false,
            fields_info: fields_info,
            data: data,
            form: form,
        })
    }

    async submitForm( formData,actions ) {
        //hook on submit (before)
        const onSubmit_f = this.props.hasOwnProperty('onSubmit')
            ? this.props.onSubmit
            : () => true
        onSubmit_f(formData,actions)

        //manage submit form
        const entity = this.state.entity
        const id_field = this.state.entitySchema.id_field
        const id_entity = this.state.id_entity
        
        delete formData[id_field]
        let rows_updated = 0
        let last_id = 0
        
        if( !id_entity ) {
            const inserted = await entity_create(entity,[formData],false)
            last_id = inserted.hasOwnProperty('last_id')
            ? inserted.last_id
            : 0
            rows_updated = inserted.hasOwnProperty('affected_rows')
                ? inserted.affected_rows
                : 0
        }
        else {
            rows_updated = await entity_update(entity,id_entity,formData,false)
        }

        this.setState({
            last_id: last_id,
            rows_updated: rows_updated,
            submitted: true
        })
    }

    renderMsg() {
        const last_id = this.state.last_id
        const rows_updated = this.state.rows_updated
        const submitted = this.state.submitted
        const id_entity = this.state.id_entity

        const txtOnCreationOk = this.props.hasOwnProperty('txtOnCreationOk') ? this.props.txtOnCreationOk : (String(rows_updated) + " rows created")
        const txtOnUpdateOk = this.props.hasOwnProperty('txtOnUpdateOk') ? this.props.txtOnUpdateOk : (String(rows_updated) + " rows updated")
        const txtOnError = this.props.hasOwnProperty('txtOnError') ? this.props.txtOnError : 'Error! No rows updated'
        
        let snackbarMessage = ""
        let snackbarStatus = false
        let severity = ""

        if(rows_updated !== false && submitted && id_entity){
            snackbarMessage = txtOnUpdateOk
            snackbarStatus = true
            severity = "success"
        }
        else if(rows_updated !== false && submitted && !id_entity){
            snackbarMessage = txtOnCreationOk
            snackbarStatus = true
            severity = "success"
        }
        else if(rows_updated === undefined && submitted){
            snackbarMessage = txtOnError
            snackbarStatus = true
            severity = "error"
        }
        else{
            snackbarMessage = ""
            snackbarStatus = false
            severity = ""
        }

        const onSubmitOk_f = this.props.hasOwnProperty('onSubmitOk')
            ? this.props.onSubmitOk
            : () => true

        const onSubmitOk = (severity === "success" && rows_updated)
            ? onSubmitOk_f
            : () => true

        return <SnackbarMessage
            open={snackbarStatus}
            message={snackbarMessage}
            severity={severity}
            autoHideDuration={2000}
            onClose={() => {
                this.setState({
                    rows_updated:0,
                    submitted:false
                })
                onSubmitOk(last_id,rows_updated)
            }}
        />
    }

    renderForm( formikProps,FormComp ) {
        const refresh = this.state.refresh//prop to rerender the form after fields_info changes
        const id_entity = this.state.id_entity
        const fields_info = this.state.fields_info
        const entity = this.state.entity
        const entityInputProps = this.state.entityInputProps
        const gridProps = {
            ...{spacing: 4},
            ...this.props.hasOwnProperty('gridProps') ? this.props.gridProps : {}}
        const gridItemProps = this.state.gridItemProps
        const fieldsOrder = this.state.fieldsOrder
        const btnSubmit = this.props.hasOwnProperty('btnSubmit')
            ? this.props.btnSubmit
            : true
        const textBtnSubmit = this.props.hasOwnProperty('textBtnSubmit')
            ? this.props.textBtnSubmit
            : (!id_entity ? 'Insert' : 'Update')
        const startIconBtnSubmit = this.props.hasOwnProperty('startIconBtnSubmit')
            ? this.props.startIconBtnSubmit
            : (!id_entity ? <AddIcon fontSize="small" /> : <EditIcon fontSize="small" />)
        const btnSubmitDisabled = this.props.hasOwnProperty('btnSubmitDisabled')
            ? this.props.btnSubmitDisabled
            : !formikProps.isValid

        return <FormComp id={"entityform-"+entity+"-"+(!id_entity ? "add" : ("update-"+id_entity))}>
            <Grid container {...gridProps}>
                {refresh
                    ? null
                    : <EntityFormInputs
                        entity={entity}
                        id_entity={id_entity}
                        entityFieldsInfo={fields_info}
                        entityInputProps={entityInputProps}
                        gridItemProps={gridItemProps}
                        fieldsOrder={fieldsOrder}
                        formikProps={formikProps}
                        onRefreshForm={() => this.setState({refresh:true})}
                    />}
            </Grid>
            {btnSubmit === false
                ? null
                : <Box mt={2} mb={2}>
                    <Button type="submit" size="small"
                        variant="contained" color="primary"
                        startIcon={startIconBtnSubmit}
                        disabled={btnSubmitDisabled}
                    >
                        {textBtnSubmit}
                    </Button>
                </Box>}
        </FormComp>
    }

    render() {
        const hideForm = this.state.hideForm
        const hideFormMsg = this.state.hideFormMsg
        if( hideForm ) return <Chip label={hideFormMsg} />

        const submitted = this.state.submitted
        const refreshFormOnSubmit = this.props.hasOwnProperty('refreshFormOnSubmit') ? this.props.refreshFormOnSubmit : false
        const refreshFormOnSubmitComp = this.props.hasOwnProperty('refreshFormOnSubmitComp') ? this.props.refreshFormOnSubmitComp : null
        const fields_info = this.state.fields_info
        const form = this.state.form
        const entityValidationSchema = this.state.entityValidationSchema

        if( !fields_info )
            return <LoaderSingleComponent width="100%" />

        const customFormikProps = this.props.hasOwnProperty('customFormikProps') ? this.props.customFormikProps : {}
        if( Object.keys(customFormikProps).length )
            return this.renderForm(customFormikProps,Box)

        return <React.Fragment>
            {submitted && refreshFormOnSubmit
                ? refreshFormOnSubmitComp
                : <Formik
                    validateOnMount={true}
                    enableReinitialize={true}
                    initialValues={form}
                    validate={(values) => {}}
                    validationSchema={entityValidationSchema}
                    onSubmit={(values,actions) => {
                        this.submitForm( values,actions )
                    }}
                    render={formikProps => {
                        return this.renderForm(formikProps,Form)
                    }}
                />}
            {this.renderMsg()}
        </React.Fragment>
    }
}

export default EntityForm