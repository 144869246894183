import React from 'react'

import Dashboard from './Dashboard'

class CardAgent extends React.Component {
    render() {
        const id_entity = this.props.id_entity
        return <Dashboard id_user={id_entity} />
    }
}

export default CardAgent