import React from 'react'

import Modal from '../../@react-fefmwk/modals/modules/Modal'
import DraftsIcon from '@material-ui/icons/Drafts'

class FieldLeadPreview extends React.Component {
    render() {
        const title = this.props.title
        const content = this.props.content

        return <Modal
            fullWidth
            maxWidth="md"
            printCloseBtn
            title={title}
            content={content}
            Trigger={DraftsIcon}
        />
    }
}

export default FieldLeadPreview