import React from 'react'

import Table from '../../../tables/modules/Table'
import TimeinfoToolbar from "./TimeinfoToolbar"

import { entity_get_timeinfo } from '../../services/api'
import { tableTimeinfoSchema } from '../../../../services/EntityTimeinfoTableSchema'
import { actionTimeinfoSchema } from '../../../../services/EntityTimeinfoRowActionsSchema'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

class ViewTimeinfoEntity extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }

    async componentDidMount() {
        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const cf = this.props.cf
        this.setState({
            data: await entity_get_timeinfo( entity,id_entity,cf )
        })
    }

    render() {
        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const cf = this.props.cf
        const cf_nice_name = this.props.cf_nice_name ? this.props.cf_nice_name : this.props.cf
        const cf_name = this.props.cf_name
        const cf_id = this.props.cf_id
        const data = this.state.data

        const rowActions = actionTimeinfoSchema(entity,cf,id_entity)
        const fieldsProps = tableTimeinfoSchema(entity,cf)

        const title = this.props.title
            ? <Typography variant="button" component="h2" style={{marginTop:12,marginBottom:12}}>
                {this.props.title}
            </Typography>
            : null

        return <React.Fragment>
            {title}
            <TimeinfoToolbar entity={entity} id_entity={id_entity} cftime={cf} data={data} s={"?"+cf_name+"="+cf_id} />
            {data.length
                ? <Table material dense highlight
                    data={data}
                    fieldsProps={fieldsProps}
                    rowActions={rowActions}
                    rowActionsField={"id"}
                    showPagination={true}
                    filterable={true}
                    sortable={true}
                />
                : <Chip icon={<ErrorIcon />} label={"No "+cf_nice_name+" found"} variant="outlined" />}
        </React.Fragment>
    }
}

export default ViewTimeinfoEntity