import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DeleteIcon from '@material-ui/icons/Delete'
import BuildIcon from '@material-ui/icons/Build'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import AssignmentIcon from '@material-ui/icons/Assignment'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TimelineIcon from '@material-ui/icons/Timeline'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PaymentIcon from '@material-ui/icons/Payment'
import AddAlarmIcon from '@material-ui/icons/AddAlarm'

import {groupsFromToken,idFromToken} from '../@react-fefmwk/auth/services/token'

import {EntitySchema} from './EntitySchema'

export function actionSchema( entity,path='' ) {
    const EntityObj = EntitySchema[entity]
    if( !path ) {
        path = EntityObj.rootpath
    }

    const groups = groupsFromToken()
    const current_user_id = idFromToken()

    switch (entity) {
        case 'customers':
        case 'stakeholders':
            let stakeholdersList = [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                /*{
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },*/
                {
                    action: "add-task",
                    header: "Assegna task",
                    icon: AssignmentIcon,
                    link: "/tasks/add?id_stakeholder=:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "add-report",
                    header: "Scrivi report",
                    icon: InsertDriveFileIcon,
                    link: "/reports/add?id_stakeholder=:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "add-practical",
                    header: "Nuova pratica",
                    icon: AccountBalanceWalletIcon,
                    link: "/practicals/add?id_stakeholder=:id",
                    linktype: "internal",
                    target: "_blank",
                },
            ]
            /*if( !groups.includes(String(4)) )
                stakeholdersList.push({
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                })*/
            return stakeholdersList
        case 'people':
            return [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
        case 'addresses':
        case 'contacts':
            return [
                {
                    action: "view",
                    header: "Visualizza",
                    icon: VisibilityIcon,
                    link: path+"/view/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
        case 'practicals':
            return [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                /*{
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },*/
                {
                    action: "upload-file",
                    header: "Documenti",
                    icon: CloudUploadIcon,
                    link: "/practicals/card/:id#documents",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "events",
                    header: "Eventi",
                    icon: TimelineIcon,
                    link: "/practicals/card/:id#history",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "add-task",
                    header: "Assegna task",
                    icon: AddIcon,
                    link: "/tasks/add?id_practical=:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "add-report",
                    header: "Scrivi report",
                    icon: AddIcon,
                    link: "/reports/add?id_practical=:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "add-memo-renewal",
                    header: "Memo rinnovo",
                    icon: AddIcon,
                    link: "/tasks/add?id_practical=:id&id_activity_order=23",
                    linktype: "internal"
                },
                {
                    action: "add-event",
                    header: "Evento",
                    icon: AddIcon,
                    link: "/tasks/add?id_practical=:id&event",
                    linktype: "internal"
                },
            ]
        case 'practicals_outers':
            return [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "upload-file",
                    header: "Documenti",
                    icon: CloudUploadIcon,
                    link: "/practicals_outers/card/:id#documents",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
        case 'practicals_types':
            return []
        case 'leads_channels':
            return (props) => {
                let leads_channelsRowActions = [
                    {
                        action: "view",
                        header: "Visualizza",
                        icon: VisibilityIcon,
                        link: path+"/view/:id",
                        linktype: "internal",
                        target: "_blank",
                    }
                ]
                if( groups.includes(String(1)) || groups.includes(String(2)) ) {
                    leads_channelsRowActions.push({
                        action: "edit",
                        header: "Modifica",
                        icon: EditIcon,
                        link: path+"/update/:id",
                        linktype: "internal"
                    })
                    leads_channelsRowActions.push({
                        action: "delete",
                        header: "Elimina",
                        icon: DeleteIcon,
                        link: path+"/delete/:id",
                        linktype: "internal"
                    })
                }

                return leads_channelsRowActions
            }
        case 'leads':
            return (props) => {
                const row = props.row
                const lead_state = row.hasOwnProperty('lead_state')
                    ? row.lead_state
                    : ''

                let leadsRowActions = [
                    {
                        action: "card",
                        header: "Scheda",
                        icon: DashboardIcon,
                        link: path+"/card/:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "manage",
                        header: "Gestisci",
                        icon: BuildIcon,
                        link: path+"/manage/:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "assign",
                        header: "Assegna",
                        icon: DoubleArrowIcon,
                        link: path+"/assign/:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "add-task",
                        header: "Assegna task",
                        icon: AssignmentIcon,
                        link: "/tasks/add?id_lead=:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "add-report",
                        header: "Scrivi report",
                        icon: InsertDriveFileIcon,
                        link: "/reports/add?id_lead=:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                ]
                if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(8)) ) {
                    leadsRowActions.push({
                        action: "delete",
                        header: "Delete",
                        icon: DeleteIcon,
                        link: path+"/delete/:id",
                        linktype: "internal",
                        disabled: (lead_state === 'unmanaged' || lead_state === '') ? false : true
                    })
                }

                return leadsRowActions
            }
        case 'files':
            return [
                /*{
                    action: "view",
                    header: "Visualizza",
                    icon: VisibilityIcon,
                    link: path+"/view/:base64(id)",
                    linktype: "internal"
                },*/
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:base64(id)",
                    linktype: "internal"
                },
            ]
        case 'reports':
            return [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
        case 'tasks':
        case 'activities':
            return (props) => {
                const row = props.row
                const activity_type = row.hasOwnProperty('activity_type')
                    ? row.activity_type
                    : ''

                let activitiesRowActions = []

                if( activity_type.includes('-auto') ) {
                    const id_practical = props.row.hasOwnProperty('id_practical')
                        ? props.row.id_practical
                        : 0
                    /*const id_practical_outer = props.row.hasOwnProperty('id_practical_outer')
                        ? props.row.id_practical_outer
                        : 0
                    const id_stakeholder = props.row.hasOwnProperty('id_activity_stakeholder')
                        ? props.row.id_activity_stakeholder
                        : 0*/
                    const active_at = props.row.hasOwnProperty('active_at')
                        ? props.row.active_at
                        : ''
                    const id_activity_order = props.row.hasOwnProperty('id_activity_order')
                        ? props.row.id_activity_order
                        : 23
                    const id_agent = current_user_id
                    let link_to = "/tasks/add?id_activity_order="+id_activity_order+"&active_at="+active_at+"&id_agent="+id_agent
                    if( id_practical > 0 ) link_to += "&id_practical="+id_practical
                    //if( id_practical_outer > 0 ) link_to += "&id_stakeholder="+id_stakeholder

                    if( activity_type === 'task-auto' || !groups.includes(String(4)) )
                        activitiesRowActions.push({
                            action: "postpone",
                            header: "Calendarizza",
                            icon: AddAlarmIcon,
                            link: link_to,
                            linktype: "internal",
                            target: "_blank",
                        })
                }
                else {
                    activitiesRowActions.push({
                        action: "card",
                        header: "Scheda",
                        icon: DashboardIcon,
                        link: path+"/card/:id",
                        linktype: "internal",
                        target: "_blank",
                    })
                    activitiesRowActions.push({
                        action: "postpone",
                        header: "Rimanda",
                        icon: AccessTimeIcon,
                        link: path+"/postpone/:id",
                        linktype: "internal"
                    })
                    activitiesRowActions.push({
                        action: "close",
                        header: "Chiudi",
                        icon: CheckIcon,
                        link: path+"/close/:id",
                        linktype: "internal"
                    })
                    if( groups.includes(String(1)) ) {
                        activitiesRowActions.push({
                            action: "edit",
                            header: "Modifica",
                            icon: EditIcon,
                            link: path+"/update/:id",
                            linktype: "internal"
                        })
                    }
                    if( groups.includes(String(1)) || groups.includes(String(2)) ) {
                        activitiesRowActions.push({
                            action: "delete",
                            header: "Elimina",
                            icon: DeleteIcon,
                            link: path+"/delete/:id",
                            linktype: "internal"
                        })
                    }
                }

                return activitiesRowActions
            }
        case 'practicals_histories':
            return [
                {
                    action: "view",
                    header: "Visualizza",
                    icon: VisibilityIcon,
                    link: path+"/view/:id",
                    linktype: "internal",
                    target: "_blank",
                },
            ]
        case 'atcs':
            return (props) => {
                let atcsRowActions = [
                    {
                        action: "card",
                        header: "Scheda",
                        icon: DashboardIcon,
                        link: path+"/card/:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "quotations",
                        header: "Quotazioni",
                        icon: PaymentIcon,
                        link: path+"/card/:id#quotations",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "add-quotation",
                        header: "Quotazione",
                        icon: AddIcon,
                        link: "/insurances_quotations/add?id_atc=:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "edit",
                        header: "Modifica",
                        icon: EditIcon,
                        link: path+"/update/:id",
                        linktype: "internal"
                    },
                ]

                if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)) ) {
                    atcsRowActions.push({
                        action: "delete",
                        header: "Elimina",
                        icon: DeleteIcon,
                        link: path+"/delete/:id",
                        linktype: "internal"
                    })
                }

                return atcsRowActions
        }
        case 'insurances':
            return (props) => {
                const row = props.row
                const insurance = row.hasOwnProperty('insurance')
                    ? row.insurance
                    : ''

                let insurancesRowActions = [
                    {
                        action: "card",
                        header: "Scheda",
                        icon: DashboardIcon,
                        link: path+"/card/:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "quotations",
                        header: "Quotazioni",
                        icon: PaymentIcon,
                        link: path+"/card/:id#quotations",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "add-quotation",
                        header: "Quotazione",
                        icon: AddIcon,
                        link: "/insurances_quotations/add?id_insurance=:id",
                        linktype: "internal",
                        target: "_blank",
                    },
                    {
                        action: "add-as-lender",
                        header: "Finanziaria",
                        icon: AddIcon,
                        link: "/practicals_lenders/add?lender="+insurance,
                        linktype: "internal",
                        target: "_blank",
                    },
                ]

                return insurancesRowActions
            }
        case 'insurances_quotations':
            return [
                {
                    action: "view",
                    header: "Visualizza",
                    icon: VisibilityIcon,
                    link: path+"/view/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
            ]
        case 'assignments':
            return [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                },
            ]
        case 'agents':
            let actions = []
            /*actions.push(
                (groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(6)))
                    ? {
                        action: "card",
                        header: "Scheda",
                        icon: DashboardIcon,
                        link: path+"/card/:id",
                        linktype: "internal",
                        target: "_blank",
                    }
                    : {
                        action: "view",
                        header: "Visualizza",
                        icon: VisibilityIcon,
                        link: path+"/view/:id",
                        linktype: "internal",
                        target: "_blank",
                    }
            )*/
            return actions
        case 'branches':
            return []
            /*return [
                {
                    action: "card",
                    header: "Scheda",
                    icon: DashboardIcon,
                    link: path+"/card/:id",
                    linktype: "internal",
                    target: "_blank",
                }
            ]*/
        case "products":
            return [
                {
                    action: "view",
                    header: "Visualizza",
                    icon: VisibilityIcon,
                    link: path+"/view/:id",
                    linktype: "internal",
                    target: "_blank",
                },
            ]
        default:
            return [
                {
                    action: "view",
                    header: "Visualizza",
                    icon: VisibilityIcon,
                    link: path+"/view/:id",
                    linktype: "internal",
                    target: "_blank",
                },
                {
                    action: "edit",
                    header: "Modifica",
                    icon: EditIcon,
                    link: path+"/update/:id",
                    linktype: "internal"
                },
                {
                    action: "delete",
                    header: "Elimina",
                    icon: DeleteIcon,
                    link: path+"/delete/:id",
                    linktype: "internal"
                },
            ]
    }
}