import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import MyPracticals from '../modules/MyPracticals'

class MyPracticalsPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_user = this.props.hasOwnProperty('id_user') && this.props.id_user
            ? this.props.id_user
            : idFromToken()

        return <Content title={title}>
            <MyPracticals id_user={id_user} />
        </Content>
    }
}

export default MyPracticalsPage