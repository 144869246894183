import React from 'react'

import {actionSchema} from '../../services/EntityRowActionsSchema'
import {people_get_contacts} from '../../services/BE'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import ContactsIcon from '@material-ui/icons/Contacts'

class WidgetPersonContacts extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contacts: [],
        }
    }
    
    async updateContacts() {
        const id_people = this.props.id_people
        if( id_people )
            this.setState({
                contacts: await people_get_contacts(id_people),
            })
    }
    
    async componentDidMount() {
        this.updateContacts()
    }
    
    async componentDidUpdate(prevProps) {
        const id_people = this.props.id_people
        if( id_people !== prevProps.id_people )
            this.updateContacts()
    }

    render() {
        const id_people = this.props.id_people
        const contacts = this.state.contacts

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Contacts"

        let contactsRowActions = actionSchema('contacts')
        if( contactsRowActions.length ) {
            contactsRowActions.map((act) => {
                if( act.hasOwnProperty('action') && act.action === 'edit' && act.hasOwnProperty('link') ) {
                    act.link += '?id_people'
                }
            })
        }

        const cardProps = {}
        cardProps['Content'] = contacts.length
            ? <Table material dense
                hideHeaders
                defaultPageSize="100"
                data={contacts}
                rowActions={contactsRowActions}
                rowActionsField="id_contact"
            />
            : <Chip icon={<ErrorIcon />} label="Nessun altro contatto trovato" variant="outlined" />

        if( dashboardcardActions )
            cardProps['TopActions'] = <Button
                variant="text" color="primary" size="small"
                startIcon={<ContactsIcon fontSize="small" />}
                component={AdapterLink}
                to={"/contacts/add?id_people="+id_people}
            >Nuovo</Button>

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetPersonContacts