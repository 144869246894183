
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { groupsFromToken } from '../../@react-fefmwk/auth/services/token'
import { entity_fields_info } from '../../@react-fefmwk/entity/services/api'
import { helpersFormSchema } from '../../services/EntityHelpersFormSchema'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import FieldRadios from '../../@react-fefmwk/form/modules/FieldRadios'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({})

class TargetSelection extends React.Component {
    constructor(props) {
        super(props)

        const groups = groupsFromToken()
        let reports_targets= [
            {id_report_target:'id_stakeholder',report_target:'Cliente'},
            {id_report_target:'id_practical',report_target:'Pratica'},
        ]
        if(groups.includes(String(1)) || groups.includes(String(2))) {
            reports_targets.push({id_report_target:'id_lead',report_target:'Lead'})
            reports_targets.push({id_report_target:'id_activity',report_target:'Task'})
        }

        this.state = {
            reports_fields_info: {},
            reports_targets: reports_targets,
        }
    }
    async componentDidUpdate(prevProps,prevState) {
        const formikProps = this.props.formikProps
        const id_report_target = formikProps.values.id_report_target

        if( prevProps.formikProps.values.id_report_target === id_report_target )
            return

        if( id_report_target === 'id_stakeholder' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_lead:0,id_practical:0,id_activity:0},
            })
        }
        if( id_report_target === 'id_practical' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_lead:0,id_stakeholder:0,id_activity:0},
            })
        }
        if( id_report_target === 'id_lead' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_stakeholder:0,id_practical:0,id_activity:0},
            })
        }
        if( id_report_target === 'id_activity' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_stakeholder:0,id_practical:0,id_lead:0},
            })
        }
    }

    async componentDidMount() {
        let reports_fields_info = await entity_fields_info('reports')

        this.setState({
            reports_fields_info: reports_fields_info,
        })
    }

    render() {
        const formikProps = this.props.formikProps

        let reports_fields_info = this.state.reports_fields_info
        if( Object.keys(reports_fields_info).length === 0 )
            return <LoaderSingleComponent/>

        let helpersTextSchema = helpersFormSchema('reports')
        helpersTextSchema['id_stakeholder'] = 'Cerca un cliente'
        helpersTextSchema['id_practical'] = 'Cerca un pratica'
        helpersTextSchema['id_lead'] = 'Cerca un lead'
        helpersTextSchema['id_activity'] = 'Cerca un task'

        let fields_info = {}
        fields_info['id_stakeholder'] = reports_fields_info['id_stakeholder']
        fields_info['id_practical'] = reports_fields_info['id_practical']
        fields_info['id_lead'] = reports_fields_info['id_lead']
        fields_info['id_activity'] = reports_fields_info['id_activity']

        const id_report_target = formikProps.values.id_report_target
        if( id_report_target === 'id_stakeholder' ) {
            fields_info.id_practical.input_type = 'hidden'
            fields_info.id_lead.input_type = 'hidden'
            fields_info.id_activity.input_type = 'hidden'
            fields_info.id_stakeholder.input_type = 'selectbox'
        }
        if( id_report_target === 'id_practical' ) {
            fields_info.id_stakeholder.input_type = 'hidden'
            fields_info.id_lead.input_type = 'hidden'
            fields_info.id_activity.input_type = 'hidden'
            fields_info.id_practical.input_type = 'selectbox'
        }
        if( id_report_target === 'id_lead' ) {
            fields_info.id_practical.input_type = 'hidden'
            fields_info.id_stakeholder.input_type = 'hidden'
            fields_info.id_activity.input_type = 'hidden'
            fields_info.id_lead.input_type = 'selectbox'
        }
        if( id_report_target === 'id_activity' ) {
            fields_info.id_practical.input_type = 'hidden'
            fields_info.id_stakeholder.input_type = 'hidden'
            fields_info.id_lead.input_type = 'hidden'
            fields_info.id_activity.input_type = 'selectbox'
        }

        const reports_targets = this.state.reports_targets

        const id_stakeholder = formikProps.values.id_stakeholder
        const id_practical = formikProps.values.id_practical
        const id_lead = formikProps.values.id_lead
        const id_activity = formikProps.values.id_activity

        return <React.Fragment>
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
            >
                <Grid item xs={12} md={2}>
                    <FieldRadios
                        title='Target'
                        items={reports_targets}
                        name='id_report_target'
                        fieldId='id_report_target'
                        fieldName='report_target'
                        value={String(formikProps.values['id_report_target'])}
                        onChange={formikProps.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    {id_report_target
                        ? <React.Fragment>
                            <EntityFormInputs
                                entity={'reports'}
                                entityFieldsInfo={fields_info}
                                entityHelpersSchema={helpersTextSchema}
                                formikProps={formikProps}
                            />
                        </React.Fragment>
                        : null}
                </Grid>
                <Grid item xs={12} md={7}>
                    {id_stakeholder
                        ? <React.Fragment>
                            <ViewEntity entity="stakeholders" id_entity={id_stakeholder} dense />
                        </React.Fragment>
                        : null}
                    {id_practical
                        ? <React.Fragment>
                            <ViewEntity entity="practicals" id_entity={id_practical} dense />
                        </React.Fragment>
                        : null}
                    {id_lead
                        ? <React.Fragment>
                            <ViewEntity entity="leads" id_entity={id_lead} dense />
                        </React.Fragment>
                        : null}
                    {id_activity
                        ? <React.Fragment>
                            <ViewEntity entity="activities" id_entity={id_activity} dense />
                        </React.Fragment>
                        : null}
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(TargetSelection)