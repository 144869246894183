import React from 'react'
import { withStyles } from '@material-ui/core/styles'

//import {nowString} from '../../@react-fefmwk/dates/services/api'
import { entity_fields_info, entity_q } from '../../@react-fefmwk/entity/services/api'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import EntityRelFieldActions from '../../@react-fefmwk/entity/modules/EntityRelFieldActions'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Table from '../../@react-fefmwk/tables/modules/Table'
import DividerAdvanced from '../../@react-fefmwk/pieces/modules/DividerAdvanced'

import {tableSchema} from '../../services/EntityTableSchema'
//import {actionSchema} from '../../services/EntityRowActionsSchema'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
//import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
//import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

//import BlockIcon from '@material-ui/icons/Block'
//import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({})

class OldPracticals extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals_fields_info: {},
            refresh: false,
            practicals: [],
            practicals_outers: [],
            noOuters: false,
        }
    }
    customizeFieldsInfo(fieldsInfo) {
        if( fieldsInfo.hasOwnProperty('id_stakeholder') ) {
            fieldsInfo.id_stakeholder['nice_name'] = "Scegli cliente..."
        }

        return fieldsInfo
    }
    async getPracticals(id_stakeholder) {
        const filters = {id_stakeholder: id_stakeholder}
        return await entity_q(
            'practicals',
            {
                fields: ['product_code','effective_date','disbursed_amount','installments_n','installment_amount','renewal_date','id_agent','practical_state'],
                filters: filters,
                orderby: ['effective_date DESC']
            }
        )
    }
    async getPracticalsOuters(id_stakeholder) {
        const filters = {id_stakeholder: id_stakeholder}
        return await entity_q(
            'practicals_outers',
            {
                fields: ['product_code','effective_date','residual_amount','installments_n','installment_amount','renewal_date','id_user','created_at'],
                filters: filters,
                orderby: ['effective_date DESC']
            }
        )
    }
    async componentDidMount() {
        let practicals_fields_info = await entity_fields_info('practicals')
        practicals_fields_info = this.customizeFieldsInfo(practicals_fields_info)

        const formikProps = this.props.formikProps
        const id_stakeholder = formikProps.values.id_stakeholder
        let practicals = []
        let practicals_outers = []
        if( id_stakeholder ) {
            practicals = await this.getPracticals(id_stakeholder)
            practicals_outers = await this.getPracticalsOuters(id_stakeholder)
        }

        this.setState({
            practicals_fields_info: practicals_fields_info,
            practicals: practicals,
            practicals_outers: practicals_outers
        })

        formikProps.setFieldValue('id_stakeholder',id_stakeholder)
    }
    async componentDidUpdate(prevProps,prevState) {
        const formikProps = this.props.formikProps
        const id_stakeholder = formikProps.values.id_stakeholder
        const prev_id_stakeholder = prevProps.formikProps.values.id_stakeholder
        const refresh = this.state.refresh
        const noOuters = this.state.noOuters
        const prev_noOuters = prevState.noOuters

        if( !id_stakeholder && prev_id_stakeholder ) {
            this.setState({
                practicals: [],
                practicals_outers: [],
            })
        }

        if( (id_stakeholder && parseInt(id_stakeholder) !== parseInt(prev_id_stakeholder)) || refresh ) {
            let practicals = []
            let practicals_outers = []
            if( id_stakeholder ) {
                practicals = await this.getPracticals(id_stakeholder)
                practicals_outers = await this.getPracticalsOuters(id_stakeholder)
            }
            
            this.setState({
                practicals: practicals,
                practicals_outers: practicals_outers,
                refresh: false,
            })

            formikProps.setFieldValue('okOuters',(practicals.length + practicals_outers.length > 0) ? true : false)
            formikProps.setFieldValue('estinzione',(practicals_outers.length || practicals.length) ? 'si' : 'no')
        }

        if( noOuters !== prev_noOuters ) {
            formikProps.setFieldValue('noOuters',noOuters)
            formikProps.setFieldValue('okOuters',noOuters)
        }
    }
    handleChange(e) {
        e.persist()

        this.setState({
            noOuters: e.target.checked
        })
    }
    render() {
        let formikProps = this.props.formikProps
        const refresh = this.state.refresh
        const noOuters = this.state.noOuters
        const practicals = this.state.practicals
        const practicals_outers = this.state.practicals_outers
        const practicals_fields_info = this.state.practicals_fields_info
        if( Object.keys(practicals_fields_info).length === 0 ) return <LoaderSingleComponent width="100%" />

        const fields_row1 = {
            id_stakeholder: practicals_fields_info.id_stakeholder
        }

        const fieldsProps = tableSchema('practicals')
        const fieldsPropsOuters = tableSchema('practicals_outers')
        //const rowActions = actionSchema('practicals')
        //const rowActionsOuters = actionSchema('practicals_outers')

        const onRefreshForm = () => this.setState({refresh:true})

        const id_stakeholder = formikProps.values.id_stakeholder
        let queryStringAddPracticalOuter = '1&'
        queryStringAddPracticalOuter += id_stakeholder ? ('id_stakeholder='+id_stakeholder) : ''

        const enableAddPracticalOuter = (id_stakeholder && !noOuters) ? true : false
        const AddPracticalOuter = <span><Button size="small" startIcon={<AddIcon />} variant="contained" color="primary" disabled={!enableAddPracticalOuter}>
            pratica
        </Button></span>

        const disableNoOuters = (!id_stakeholder) ? true : false

        return refresh
            ? null
            : <React.Fragment>
                <Box mb={2}>
                    <Grid container alignItems="center" spacing={4}>
                        <EntityFormInputs
                            entity="practicals"
                            entityFieldsInfo={fields_row1}
                            formikProps={formikProps}
                            onRefreshForm={onRefreshForm}
                        />
                        <Grid item><Typography variant="caption" component="span" style={{lineHeight:0}}>
                            Scegliere un cliente per la pratica.
                            <br />
                            &Egrave; possibile aggiungere pratiche pre-esistenti erogate da altri istituti.
                            <br />
                            Se non ci sono pratiche da aggiungere &egrave; possibile proseguire.
                        </Typography></Grid>
                    </Grid>
                </Box>
                <DividerAdvanced spacing={2}>
                    <Chip label="Altri istituti" size="small" />
                </DividerAdvanced>
                <Box mt={2}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <EntityRelFieldActions
                                entity="practicals_outers"
                                onClose={onRefreshForm}
                                Trigger={AddPracticalOuter}
                                s={queryStringAddPracticalOuter}
                            />
                        </Grid>
                        <Grid item>
                            <Switch checked={noOuters} onChange={(e) => this.handleChange(e)} disabled={disableNoOuters} /> nessuna pratica pre-esistente
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={1}>
                    {refresh
                        ? null
                        : (practicals_outers.length
                            ? <Paper>
                                <Table material dense
                                    data={practicals_outers}
                                    fieldsProps={fieldsPropsOuters}
                                    //rowActions={rowActionsOuters}
                                    //rowActionsField="id_practical_outer"
                                    filterable={false}
                                    sortable={false}
                                />
                            </Paper>
                            : <Chip label="Nessuna pratica pre-esistente" variant="outlined" size="small" />)}
                </Box>
                <DividerAdvanced spacing={2} mt={4}>
                    <Chip label="Agenzia" size="small" />
                </DividerAdvanced>
                <Box mt={2}>
                    {refresh
                        ? null
                        : (practicals.length
                            ? <Paper>
                                <Table material dense
                                    data={practicals}
                                    fieldsProps={fieldsProps}
                                    //rowActions={rowActions}
                                    //rowActionsField="id_practical"
                                    filterable={false}
                                    sortable={false}
                                />
                            </Paper>
                            : <Chip label="Nessuna pratica pre-esistente" variant="outlined" size="small" />)}
                </Box>
            </React.Fragment>
    }
}

export default withStyles(styles)(OldPracticals)