//import React from 'react'

import HomeIcon from '@material-ui/icons/Home'
import StoreIcon from '@material-ui/icons/Store'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import InboxIcon from '@material-ui/icons/Inbox'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
//import EventIcon from '@material-ui/icons/Event'
import AssignmentIcon from '@material-ui/icons/Assignment'
import WorkIcon from '@material-ui/icons/Work'
//import CheckBoxIcon from '@material-ui/icons/CheckBox'
//import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import BusinessIcon from '@material-ui/icons/Business'
import SecurityIcon from '@material-ui/icons/Security'
import ExtensionIcon from '@material-ui/icons/Extension'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import {agenciesBranchesFromToken} from './AgencySchema'
import {groupsFromToken} from '../@react-fefmwk/auth/services/token'

export const ShowUserAvatar = false
export const CloseBtn = true
export const NavSideStartState = 'open'

export function getNavSideSchema() {
    const groups = groupsFromToken()

    //sales group has no nav side menu
    if( groups.includes(String(4)) )
        return []

    const branches = agenciesBranchesFromToken()
    const main_branch = branches.length ? branches[0] : "nessuna"

    let NSschema = []

    //monitoring dashboards block
    let block_dashboards = {
        "block": "dashboards",
        "list": []
    }
    //personal monitoring dashboard (for all users)
    block_dashboards.list.push({
        "slug": "personal-dashboard",
        "icon": HomeIcon,
        "title": "Dashboard",
        "collapse": false,
        "path": "/home",
        "path_type": "internal",
    })
    //monitoring dashboards for super managers
    if( groups.includes(String(1)) || groups.includes(String(2)) ) {
        block_dashboards.list.push({
            "slug": "agencies-branch-dashboard",
            "icon": StoreIcon,
            "title": "Sedi",
            "collapse": false,
            "path": "/agencies-branch/dashboard",
            "path_type": "internal",
        })
        block_dashboards.list.push({
            "slug": "agents-dashboard",
            "icon": RecordVoiceOverIcon,
            "title": "Collaboratori",
            "collapse": false,
            "path": "/agents/dashboard",
            "path_type": "internal",
        })
    }
    //monitoring dashboards for branch managers
    if( groups.includes(String(1)) || groups.includes(String(6)) ) {
        block_dashboards.list.push({
            "slug": "my-branch",
            "icon": StoreIcon,
            "title": main_branch.toUpperCase(),
            "collapse": false,
            "path": "/my-branch",
            "path_type": "internal",
        })
        block_dashboards.list.push({
            "slug": "my-branch-agents",
            "icon": RecordVoiceOverIcon,
            "title": "Collaboratori",
            "collapse": false,
            "path": "/my-branch/agents",
            "path_type": "internal",
        })
    }
    NSschema.push(block_dashboards)

    //network block
    if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)) || groups.includes(String(8)) ) {
        let block_network = {
            "block": "network",
            "title": "network",
            "list": []
        }
        if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)) ) {
            block_network.list.push({
                "slug": "stakeholders",
                "icon": SupervisedUserCircleIcon,
                "title": "Contatti",
                "collapse": false,
                "path": "/stakeholders",
                "path_type": "internal",
            })
        }
        if( groups.includes(String(1)) ) {
            block_network.list.push({
                "slug": "rubrica",
                "icon": PermContactCalendarIcon,
                "title": "Rubrica",
                "collapse": true,
                "list": [
                    {
                        "slug": "people",
                        "title": "Persone",
                        "path": "/people",
                        "path_type": "internal",
                    },
                    {
                        "slug": "companies",
                        "title": "Aziende",
                        "path": "/companies",
                        "path_type": "internal",
                    },
                    {
                        "slug": "addresses",
                        "title": "Indirizzi",
                        "path": "/addresses",
                        "path_type": "internal",
                    },
                    {
                        "slug": "contacts",
                        "title": "Recapiti",
                        "path": "/contacts",
                        "path_type": "internal",
                    },
                ],
            })
        }
        if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(8)) ) {
            let leads_list = [
                {
                    "slug": "leads",
                    "title": "Lead",
                    "path": "/leads",
                    "path_type": "internal",
                },
                {
                    "slug": "leads_channels",
                    "title": "Canali",
                    "path": "/leads_channels",
                    "path_type": "internal",
                },
            ]
            if( groups.includes(String(1)) ) {
                leads_list.push({
                    "slug": "campaigns",
                    "title": "Campagne",
                    "path": "/campaigns",
                    "path_type": "internal",
                })
            }
            block_network.list.push({
                "slug": "leads",
                "icon": InboxIcon,
                "title": "Leads",
                "collapse": true,
                "list": leads_list
            })
        }
        NSschema.push(block_network)
    }

    //production block
    if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)) || groups.includes(String(6)) || groups.includes(String(7)) ) {
        let block_production = {
            "block": "production",
            "title": "produzione" + (groups.includes(String(6)) ? (": "+main_branch) : ""),
            "list": []
        }
        //production block for super managers
        if( groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)) ) {
            let practicals_list = [
                {
                    "slug": "practicals-inner",
                    "title": "Agenzia",
                    "path": "/practicals",
                    "path_type": "internal",
                },
            ]
            practicals_list.push({
                "slug": "practicals-outers",
                "title": "Altri istituti",
                "path": "/practicals_outers",
                "path_type": "internal",
            })
            practicals_list.push({
                "slug": "practicals-commissions",
                "title": "Provvigioni",
                "path": "/practicals/commissions",
                "path_type": "internal",
            })
            block_production.list.push({
                "slug": "practicals",
                "icon": AccountBalanceWalletIcon,
                "title": "Pratiche",
                "collapse": true,
                "list": practicals_list
            })
            if( groups.includes(String(1)) ) {
                block_production.list.push({
                    "slug": "customers",
                    "icon": PersonPinCircleIcon,
                    "title": "Clienti",
                    "collapse": false,
                    "path": "/customers",
                    "path_type": "internal",
                })
                block_production.list.push({
                    "slug": "products",
                    "icon": ExtensionIcon,
                    "title": "Prodotti",
                    "collapse": false,
                    "path": "/products",
                    "path_type": "internal",
                })
            }
        }
        //production block for branch managers
        if( groups.includes(String(1)) || groups.includes(String(6)) || groups.includes(String(7)) ) {
            block_production.list.push({
                "slug": "my-branch-stakeholders",
                "icon": SupervisedUserCircleIcon,
                "title": "Contatti",
                "collapse": false,
                "path": "/my-branch/stakeholders",
                "path_type": "internal",
            })
            block_production.list.push({
                "slug": "my-branch-practicals",
                "icon": AccountBalanceWalletIcon,
                "title": "Pratiche",
                "collapse": true,
                "list": [
                    {
                        "slug": "my-branch-practicals-inner",
                        "title": "Agenzia",
                        "path": "/my-branch/practicals",
                        "path_type": "internal",
                    },
                    {
                    "slug": "my-branch-practicals-outers",
                    "title": "Altri istituti",
                    "path": "/my-branch/practicals_outers",
                    "path_type": "internal",
                }
                ]
            })
        }

        //ATC & Insurances & Finanziarie (lenders)
        block_production.list.push({
            "slug": "atcs",
            "icon": BusinessIcon,
            "title": "ATC",
            "collapse": false,
            "path": "/atcs",
            "path_type": "internal",
        })
        block_production.list.push({
            "slug": "insurances",
            "icon": SecurityIcon,
            "title": "Assicurazioni",
            "collapse": false,
            "path": "/insurances",
            "path_type": "internal",
        })
        block_production.list.push({
            "slug": "lenders",
            "icon": AccountBalanceIcon,
            "title": "Finanziarie",
            "collapse": false,
            "path": "/practicals_lenders",
            "path_type": "internal",
        })

        NSschema.push(block_production)
    }

    if( groups.includes(String(1)) || groups.includes(String(2)) ) {
        let agenda_list = [
            {
                "slug": "reports",
                "icon": InsertDriveFileIcon,
                "title": "Report",
                "collapse": false,
                "path": "/reports",
                "path_type": "internal",
            },
            {
                "slug": "tasks",
                "icon": AssignmentIcon,
                "title": "Task",
                "collapse": false,
                "path": "/tasks",
                "path_type": "internal",
            },
        ]
        if( groups.includes(String(1)) ) {
            agenda_list.push({
                "slug": "activities",
                "icon": WorkIcon,
                "title": "Attività",
                "collapse": false,
                "path": "/activities",
                "path_type": "internal",
            })
        }
        
        let block_agenda = {
            "block": "agenda",
            "title": "agenda",
            "list": agenda_list
        }
        NSschema.push(block_agenda)
    }

    return NSschema
}