import React from 'react'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import AddPractical from '../modules/AddPractical'

const AddPracticalPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id
    const currentUrl = props.route.location.pathname
    
    const queryString = props.route.location.search
    let queryStringObj = {}
    if( queryString ) queryStringObj = queryStringToObj(queryString)

    const disableBackAtSteps = Object.keys(queryStringObj).length
        ? [1]
        : []

    return <Content title={title}>
        <AddPractical id_entity={id_entity}
            url={currentUrl}
            precompiledData={queryStringObj}
            disableBackAtSteps={disableBackAtSteps}
        />
    </Content>
}

export default AddPracticalPage