import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'
import Modal from '../../modals/modules/Modal'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'
import { isUnderMaintenance } from '../../theme-structure/services/api'

import { Box,Typography,Chip,Divider } from '@material-ui/core'

const MaintenanceBannerStyle = theme => ({})

class MaintenanceBanner extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            um: {},
            //open: false
        }
    }
    async componentDidMount() {
        const um = await isUnderMaintenance()
        //const open = um.hasOwnProperty('is_undermaintenance') ? um.is_undermaintenance : false
        this.setState({
            um: um,
            //open: open
        })
    }
    async componentDidUpdate(prevProps,prevState) {
        const um_be = await isUnderMaintenance()
        //const open_be = um.hasOwnProperty('is_undermaintenance') ? um.is_undermaintenance : this.state.open
        //const open = this.state.open
        const um = this.state.um
        const prev_um = prevState.um
        if( um !== um_be && um != prev_um ) {
            this.setState({
                um: um,
                //open: open_be
            })
        }
    }
    render() {
        //const { classes } = this.props
        const um = this.state.um
        const is_undermaintenance = um.hasOwnProperty('is_undermaintenance') ? um.is_undermaintenance : false
        const since = um.hasOwnProperty('since') ? um.since : ''
        const to = um.hasOwnProperty('to') ? um.to : ''
        const is_in_timeframe = um.hasOwnProperty('is_in_timeframe') ? um.is_in_timeframe : false
        const is_excluded = um.hasOwnProperty('is_excluded') ? um.is_excluded : true

        const show_modal = is_undermaintenance
        const show_warning = is_in_timeframe && is_excluded ? true : false

        return <React.Fragment>
            <Modal
                open={show_modal}
                //fullWidth
                //maxWidth="xs"
                disableBackdropClick
                title="UNDER MAINTENANCE"
                content={<Box>
                    <Box>
                        <Typography component="span" variant="body2">
                            App is currently down for maintenance.
                            <br />
                            Thanks for your patience.
                        </Typography>
                    </Box>
                    <Box mt={2} mb={2}>
                        <Divider />
                    </Box>
                    <Box mt={2} mb={1}>
                        <Chip label={"Since: "+since} size="small" variant="outlined" />
                    </Box>
                    <Box mb={1}>
                        <Chip label={"To: "+to} size="small" variant="outlined" />
                    </Box>
                </Box>}
            />
            {show_warning
                ? <SnackbarMessage
                    severity="warning"
                    open={true}
                    anchorOrigin={{vertical:'top',horizontal:'left'}}
                    persistentDuration
                    message="System is under maintenance"
                />
                : null}
        </React.Fragment>
    }
}

export default withStyles(MaintenanceBannerStyle)(withTheme(MaintenanceBanner))