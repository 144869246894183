import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import AddReport from '../modules/AddReport'

const UpdateReportPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id
    const currentUrl = props.route.location.pathname

    return <Content title={title}>
        <AddReport id_entity={id_entity} url={currentUrl} update={true} disableBackAtSteps={[1]} />
    </Content>
}

export default UpdateReportPage