import React from 'react'
import clsx from 'clsx'
import { withStyles,withTheme } from '@material-ui/core/styles'

import {NavFooterSchema} from '../../../services/NavFooterSchema'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const FooterStyle = theme => ({
    appFooter: {
        ...theme.globals.appFooter,
        ...{
            position: 'absolute',
            width:'100%',
            bottom: '1%',
            zIndex: -1,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    },
    footerCenter: {textAlign:'center'},
    footerLeft: {
        textAlign:'left',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        },
    },
})

class Footer extends React.Component {
    renderItems() {
        return NavFooterSchema.map((item,i) => {
            const text = item.text
            const lg = item.hasOwnProperty('xs') ? item.xs : (
                this.props.hasOwnProperty('itemXs') ? this.props.itemXs : null
            )

            return <Grid item xs={12} md={lg} key={"app-footer-item-"+i}>
                {text}
            </Grid>
        })
    }
    render() {
        const { classes } = this.props
        const containerSize = this.props.hasOwnProperty('containerSize')
            ? this.props.containerSize
            : "xl"
        const textAlign = this.props.hasOwnProperty('textAlign')
            ? this.props.textAlign
            : "left"
        const footerClasses = clsx(
            textAlign === 'center' ? classes.footerCenter : classes.footerLeft,
            classes.appFooter,
            this.props.hasOwnProperty('className') ? this.props.className : ""
        )
        const customStyle = this.props.hasOwnProperty('customStyle')
            ? this.props.customStyle
            : {}

        return <footer id="Footer" className={footerClasses} style={customStyle}>
            <Container maxWidth={containerSize}>
                <Grid container spacing={0}>
                    {this.renderItems()}
                </Grid>
            </Container>
        </footer>
    }
}

export default withStyles(FooterStyle)(withTheme(Footer))