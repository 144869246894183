import {
    headerTokenKey,
    getToken
} from '../@react-fefmwk/auth/services/token'
import {
    currentDomain,
    doCall,
    getEP
} from '../@react-fefmwk/requests/api'
import {getAgencyFromDomain,agencySchema} from './AgencySchema'

const current_domain = currentDomain()
const agency = getAgencyFromDomain( current_domain )
const agencyObj = agencySchema( agency )
export const serviceUrl = current_domain === agencyObj.domain_dev
    ? agencyObj.serviceUrl_dev
    : agencyObj.serviceUrl_prod

export const EP = {
    //##AUTHENTICATION
    "login": "/login",
    "logout": "/logout",
    "is_undermaintenance": "/is_undermaintenance",

    //##OTHER
    "read_csv":"/filemanager/read_csv",
    "upload_files":"/filemanager/upload_files",

    //#FILES/FILEMANAGER
    "shareddocs_list": "/shareddocs/list",
    "shareddocs_upload": "/shareddocs/upload",
    //"files_list": "/files/list",
    "files_get": "/filemanager/file/get",
    //"files_create": "/files/create",
    //"files_update": "/files/update",
    "files_delete": "/filemanager/file/delete",
    //"files_fields": "/files/fields",
    //"files_upload": "/files/upload",
    
    //##ENTITIES
    "row_get": "/tables/get",
    "row_update": "/tables/update",
    "row_delete": "/tables/delete",

    "users_list": "/users/list",
    "users_get": "/users/get",
    "users_get_tasks": "/users/get",
    "users_get_tasks_stats": "/users/get",
    "users_get_renewals": "/users/get",
    "users_get_renewals_stats": "/users/get",
    "users_get_assignments": "/users/get",
    "users_get_assignments_stats": "/users/get",
    "users_get_leads": "/users/get",
    "users_get_reports": "/users/get",
    "users_get_practicals": "/users/get",
    "users_get_practicals_stats": "/users/get",
    "users_get_stakeholders": "/users/get",
    "users_create": "/users/create",
    "users_update": "/users/update",
    "users_delete": "/users/delete",
    "users_fields": "/users/fields",

    "branches_list": "/users/list/branches",
    "branches_list_stakeholders": "/branches",
    "branches_list_practicals": "/branches",
    "branches_list_practicals_outers": "/branches",

    "agents_list": "/users/list/agents",
    "agents_get": "/users/get",
    "agents_create": "/users/create",
    "agents_update": "/users/update",
    "agents_delete": "/users/delete",
    "agents_fields": "/users/fields",

    "people_list": "/people/list",
    "people_get": "/people/get",
    "people_get_addresses": "/people/get",
    "people_get_contacts": "/people/get",
    "people_create": "/people/create",
    "people_update": "/people/update",
    "people_delete": "/people/delete",
    "people_fields": "/people/fields",

    "companies_q": "/companies/Q",
    "companies_list": "/companies/list",
    "companies_get": "/companies/get",
    "companies_get_addresses": "/companies/get",
    "companies_get_contacts": "/companies/get",
    "companies_create": "/companies/create",
    "companies_update": "/companies/update",
    "companies_delete": "/companies/delete",
    "companies_fields": "/companies/fields",

    "addresses_list": "/addresses/list",
    "addresses_get": "/addresses/get",
    "addresses_create": "/addresses/create",
    "addresses_update": "/addresses/update",
    "addresses_delete": "/addresses/delete",
    "addresses_fields": "/addresses/fields",

    "contacts_list": "/contacts/list",
    "contacts_get": "/contacts/get",
    "contacts_create": "/contacts/create",
    "contacts_update": "/contacts/update",
    "contacts_delete": "/contacts/delete",
    "contacts_fields": "/contacts/fields",

    "stakeholders_q": "/stakeholders/Q",
    "stakeholders_list": "/stakeholders/list",
    "stakeholders_get": "/stakeholders/get",
    "stakeholders_get_practicals": "/stakeholders/get",
    "stakeholders_get_practicals_outers": "/stakeholders/get",
    "stakeholders_get_activities": "/stakeholders/get",
    "stakeholders_get_reports": "/stakeholders/get",
    "stakeholders_get_files": "/stakeholders/get",
    "stakeholders_upload_files": "/stakeholders/upload-files",
    "stakeholders_create": "/stakeholders/create",
    "stakeholders_update": "/stakeholders/update",
    "stakeholders_delete": "/stakeholders/delete",
    "stakeholders_fields": "/stakeholders/fields",

    "customers_list": "/stakeholders/list/customers",
    "customers_get": "/stakeholders/get",
    "customers_get_files": "/stakeholders/get",
    "customers_create": "/stakeholders/create",
    "customers_update": "/stakeholders/update",
    "customers_delete": "/stakeholders/delete",
    "customers_fields": "/stakeholders/fields",

    "atcs_list": "/atcs/list",
    "atcs_get": "/atcs/get",
    "atcs_get_quotations": "/atcs/get",
    "atcs_get_practicals": "/atcs/get",
    "atcs_get_customers": "/atcs/get",
    "atcs_create": "/atcs/create",
    "atcs_update": "/atcs/update",
    "atcs_delete": "/atcs/delete",
    "atcs_fields": "/atcs/fields",

    "insurances_list": "/insurances/list",
    "insurances_get": "/insurances/get",
    "insurances_get_quotations": "/insurances/get",
    "insurances_create": "/insurances/create",
    "insurances_update": "/insurances/update",
    "insurances_delete": "/insurances/delete",
    "insurances_fields": "/insurances/fields",

    "insurances_quotations_q": "/insurances_quotations/Q",
    "insurances_quotations_list": "/insurances_quotations/list",
    "insurances_quotations_get": "/insurances_quotations/get",
    "insurances_quotations_create": "/insurances_quotations/create",
    "insurances_quotations_update": "/insurances_quotations/update",
    "insurances_quotations_delete": "/insurances_quotations/delete",
    "insurances_quotations_fields": "/insurances_quotations/fields",

    "practicals_q": "/practicals/Q",
    "practicals_list": "/practicals/list",
    "practicals_list_commissions": "/practicals/list/commissions",
    "practicals_list_originations": "/practicals/list/originations",
    "practicals_list_events": "/practicals/list/events",
    "practicals_get": "/practicals/get",
    "practicals_get_history": "/practicals/get",
    "practicals_get_files": "/practicals/get",
    "practicals_upload_files": "/practicals/upload-files",
    "practicals_create": "/practicals/create",
    "practicals_update": "/practicals/update",
    "practicals_delete": "/practicals/delete",
    "practicals_fields": "/practicals/fields",

    "practicals_commissions_q": "/practicals_commissions/Q",
    "practicals_commissions_list": "/practicals_commissions/list",
    "practicals_commissions_get": "/practicals_commissions/get",
    "practicals_commissions_create": "/practicals_commissions/create",
    "practicals_commissions_update": "/practicals_commissions/update",
    "practicals_commissions_delete": "/practicals_commissions/delete",
    "practicals_commissions_fields": "/practicals_commissions/fields",

    "practicals_outers_q": "/practicals_outers/Q",
    "practicals_outers_list": "/practicals_outers/list",
    "practicals_outers_get": "/practicals_outers/get",
    "practicals_outers_create": "/practicals_outers/create",
    "practicals_outers_update": "/practicals_outers/update",
    "practicals_outers_delete": "/practicals_outers/delete",
    "practicals_outers_fields": "/practicals_outers/fields",

    "practicals_lenders_list": "/practicals_lenders/list",
    "practicals_lenders_get": "/practicals_lenders/get",
    "practicals_lenders_create": "/practicals_lenders/create",
    "practicals_lenders_update": "/practicals_lenders/update",
    "practicals_lenders_delete": "/practicals_lenders/delete",
    "practicals_lenders_fields": "/practicals_lenders/fields",

    "products_list": "/products/list",
    "products_get": "/products/get",
    "products_create": "/products/create",
    "products_update": "/products/update",
    "products_delete": "/products/delete",
    "products_fields": "/products/fields",

    "leads_list": "/leads/list",
    "leads_get": "/leads/get",
    "leads_get_body": "/leads/get",
    "leads_get_reports": "/leads/get",
    "leads_get_tasks": "/leads/get",
    "leads_lastfetch": "/leads/fetch/last",
    "leads_fetch": "/leads/fetch",
    "leads_create": "/leads/create",
    "leads_update": "/leads/update",
    "leads_delete": "/leads/delete",
    "leads_delete_info": "/leads/delete",
    "leads_fields": "/leads/fields",

    "leads_channels_list": "/leads/channels/list",
    "leads_channels_get": "/leads/channels/get",
    "leads_channels_create": "/leads/channels/create",
    "leads_channels_update": "/leads/channels/update",
    "leads_channels_delete": "/leads/channels/delete",
    "leads_channels_fields": "/leads/channels/fields",

    "campaigns_list": "/campaigns/list",
    "campaigns_get": "/campaigns/get",
    "campaigns_create": "/campaigns/create",
    "campaigns_update": "/campaigns/update",
    "campaigns_delete": "/campaigns/delete",
    "campaigns_fields": "/campaigns/fields",

    "reports_list": "/reports/list",
    "reports_get": "/reports/get",
    "reports_create": "/reports/create",
    "reports_update": "/reports/update",
    "reports_delete": "/reports/delete",
    "reports_fields": "/reports/fields",

    "activities_list": "/activities/list",
    "activities_get": "/activities/get",
    "activities_get_reports": "/activities/get",
    "activities_create": "/activities/create",
    "activities_update": "/activities/update",
    "activities_delete": "/activities/delete",
    "activities_fields": "/activities/fields",

    "practicals_histories_list": "/practicals/list/histories",
    "practicals_histories_get": "/activities/get",
    "practicals_histories_create": "/activities/create",
    "practicals_histories_update": "/activities/update",
    "practicals_histories_delete": "/activities/delete",
    "practicals_histories_fields": "/activities/fields",

    "tasks_list": "/activities/list/tasks",
    "tasks_get": "/activities/get",
    "tasks_create": "/activities/create",
    "tasks_update": "/activities/update",
    "tasks_delete": "/activities/delete",
    "tasks_fields": "/activities/fields",

    "assignments_list": "/activities/list/assignments",
    "assignments_get": "/activities/get",
    "assignments_create": "/activities/create",
    "assignments_update": "/activities/update",
    "assignments_delete": "/activities/delete",
    "assignments_fields": "/activities/fields",

    "metrics_by_origination": "/metrics/by/origination",
    "metrics_by_product": "/metrics/by/product",
    "metrics_by_agent": "/metrics/by/agent",
    "metrics_by_branch": "/metrics/by/branch",
    "metrics_by_month": "/metrics/by/month",

}

export async function shareddocs_list( subfolder = '' ) {
    let callUrl = getEP( 'shareddocs_list' )
    callUrl += '?subfolder='+subfolder

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function shareddocs_upload( formFiles ) {
    const callUrl = getEP( 'shareddocs_upload' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: formFiles
    },'json.array')
}
export async function people_get_addresses( id_entity ) {
    let callUrl = getEP( 'people_get_addresses' )
    callUrl = callUrl+'/'+id_entity+'/addresses'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function companies_get_addresses( id_entity ) {
    let callUrl = getEP( 'companies_get_addresses' )
    callUrl = callUrl+'/'+id_entity+'/addresses'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function people_get_contacts( id_entity ) {
    let callUrl = getEP( 'people_get_contacts' )
    callUrl = callUrl+'/'+id_entity+'/contacts'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function companies_get_contacts( id_entity ) {
    let callUrl = getEP( 'companies_get_contacts' )
    callUrl = callUrl+'/'+id_entity+'/contacts'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function practicals_get_history( id_entity ) {
    let callUrl = getEP( 'practicals_get_history' )
    callUrl = callUrl+'/'+id_entity+'/history'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function stakeholders_get_practicals( id_entity ) {
    let callUrl = getEP( 'stakeholders_get_practicals' )
    callUrl = callUrl+'/'+id_entity+'/practicals'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function stakeholders_get_practicals_outers( id_entity ) {
    let callUrl = getEP( 'stakeholders_get_practicals_outers' )
    callUrl = callUrl+'/'+id_entity+'/practicals_outers'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function stakeholders_get_activities( id_entity ) {
    let callUrl = getEP( 'stakeholders_get_activities' )
    callUrl = callUrl+'/'+id_entity+'/activities'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function stakeholders_get_reports( id_entity,fields = '' ) {
    let callUrl = getEP( 'stakeholders_get_reports' )
    callUrl = callUrl+'/'+id_entity+'/reports'
    callUrl += '?fields='+fields

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function activities_get_reports( id_entity,fields = '' ) {
    let callUrl = getEP( 'activities_get_reports' )
    callUrl = callUrl+'/'+id_entity+'/reports'
    callUrl += '?fields='+fields

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function stakeholders_get_files( id_entity ) {
    let callUrl = getEP( 'stakeholders_get_files' )
    callUrl = callUrl+'/'+id_entity+'/files'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function stakeholders_upload_files( id_stakeholder,formFiles ) {
    let callUrl = getEP( 'stakeholders_upload_files' )
    callUrl += '/' + id_stakeholder

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: formFiles
    },'json.array')
}
export async function practicals_get_files( id_entity ) {
    let callUrl = getEP( 'practicals_get_files' )
    callUrl = callUrl+'/'+id_entity+'/files'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    })
}
export async function practicals_upload_files( id_practical,formFiles ) {
    let callUrl = getEP( 'practicals_upload_files' )
    callUrl += '/' + id_practical

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: formFiles
    },'json.array')
}
export async function leads_lastfetch() {
    const callUrl = getEP( 'leads_lastfetch' )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'text')
}
export async function leads_fetch() {
    const callUrl = getEP( 'leads_fetch' )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function practicals_list_commissions( hide_managed = false ) {
    let callUrl = getEP( 'practicals_list_commissions' )
    callUrl += '?hide_managed='+hide_managed

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function practicals_list_originations() {
    const callUrl = getEP( 'practicals_list_originations' )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function practicals_list_events() {
    const callUrl = getEP( 'practicals_list_events' )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_tasks( id_user,closed = false,futured = false,feedback_no = false ) {
    let callUrl = getEP( 'users_get_tasks' )
    callUrl = callUrl+'/'+id_user+'/tasks?closed='+closed+'&futured='+futured+'&feedback_no='+feedback_no

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_tasks_stats( id_user,closed = false,futured = false,feedback_no = false ) {
    let callUrl = getEP( 'users_get_tasks_stats' )
    callUrl = callUrl+'/'+id_user+'/tasks/stats?closed='+closed+'&futured='+futured+'&feedback_no='+feedback_no

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function users_get_renewals( id_user,closed = false,futured = false,next_month = true,feedback_no = false ) {
    let callUrl = getEP( 'users_get_renewals' )
    callUrl = callUrl+'/'+id_user+'/renewals?closed='+closed+'&futured='+futured+'&next_month='+next_month+'&feedback_no='+feedback_no

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_renewals_stats( id_user,closed = false,futured = false,next_month = true,feedback_no = false ) {
    let callUrl = getEP( 'users_get_renewals_stats' )
    callUrl = callUrl+'/'+id_user+'/renewals/stats?closed='+closed+'&futured='+futured+'&next_month='+next_month+'&feedback_no='+feedback_no

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.objec')
}
export async function users_get_assignments( id_user,closed = false,futured = false ) {
    let callUrl = getEP( 'users_get_assignments' )
    callUrl = callUrl+'/'+id_user+'/assignments?closed='+closed+'&futured='+futured

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_assignments_stats( id_user,closed = false,futured = false ) {
    let callUrl = getEP( 'users_get_assignments_stats' )
    callUrl = callUrl+'/'+id_user+'/assignments/stats?closed='+closed+'&futured='+futured

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function users_get_leads( id_user ) {
    let callUrl = getEP( 'users_get_leads' )
    callUrl = callUrl+'/'+id_user+'/leads'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_reports( id_user,scope ) {
    let callUrl = getEP( 'users_get_reports' )
    callUrl = callUrl+'/'+id_user+'/reports'
    callUrl += '?scope='+scope

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_stakeholders( id_user ) {
    let callUrl = getEP( 'users_get_stakeholders' )
    callUrl = callUrl+'/'+id_user+'/stakeholders'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_practicals( id_user,closed = false ) {
    let callUrl = getEP( 'users_get_practicals' )
    callUrl = callUrl+'/'+id_user+'/practicals?closed='+closed

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function users_get_practicals_stats( id_user,closed = false ) {
    let callUrl = getEP( 'users_get_practicals_stats' )
    callUrl = callUrl+'/'+id_user+'/practicals/stats?closed='+closed

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.object')
}
export async function leads_get_body( id_lead ) {
    let callUrl = getEP( 'leads_get_body' )
    callUrl = callUrl+'/'+id_lead+'/body'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'text')
}
export async function leads_get_reports( id_lead ) {
    let callUrl = getEP( 'leads_get_reports' )
    callUrl = callUrl+'/'+id_lead+'/reports'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function leads_get_tasks( id_lead ) {
    let callUrl = getEP( 'leads_get_tasks' )
    callUrl = callUrl+'/'+id_lead+'/tasks'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function atcs_get_quotations( id_atc ) {
    let callUrl = getEP( 'atcs_get_quotations' )
    callUrl = callUrl+'/'+id_atc+'/quotations'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function atcs_get_practicals( id_atc ) {
    let callUrl = getEP( 'atcs_get_practicals' )
    callUrl = callUrl+'/'+id_atc+'/practicals'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function atcs_get_customers( id_atc ) {
    let callUrl = getEP( 'atcs_get_customers' )
    callUrl = callUrl+'/'+id_atc+'/customers'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
export async function insurances_get_quotations( id_insurance ) {
    let callUrl = getEP( 'insurances_get_quotations' )
    callUrl = callUrl+'/'+id_insurance+'/quotations'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json.array')
}
/*{
    start_date: "",
    "end_date": "",
    stats: [] | "",
    users_ids: [] | "",
}*/
export async function metrics_by_origination( args = {} ) {
    const callUrl = getEP( 'metrics_by_origination' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify(args)
    },'json.array',{loader:false})
}
/*{
    start_date: "",
    "end_date": "",
    stats: [] | "",
    users_ids: [] | "",
}*/
export async function metrics_by_product( args = {} ) {
    const callUrl = getEP( 'metrics_by_product' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify(args)
    },'json.array',{loader:false})
}
/*{
    start_date: "",
    "end_date": "",
    stats: [] | "",
    users_ids: [] | "",
}*/
export async function metrics_by_agent( args = {} ) {
    const callUrl = getEP( 'metrics_by_agent' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify(args)
    },'json.array',{loader:false})
}
/*{
    start_date: "",
    "end_date": "",
    stats: [] | "",
    branches: "",
}*/
export async function metrics_by_branch( args = {} ) {
    const callUrl = getEP( 'metrics_by_branch' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify(args)
    },'json.array',{loader:false})
}
/*{
    start_date: "",
    "end_date": "",
    stats: [] | "",
    users_ids: [] | "",
    month_name: true | false,
}*/
export async function metrics_by_month( args = {} ) {
    const callUrl = getEP( 'metrics_by_month' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: JSON.stringify(args)
    },'json.array',{loader:false})
}
export async function branches_list() {
    const callUrl = getEP( 'branches_list' )

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json_array')
}
export async function branches_list_stakeholders( branch ) {
    let callUrl = getEP( 'branches_list_stakeholders' )
    callUrl += '/'+branch+'/list/stakeholders'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json_array')
}
export async function branches_list_practicals( branch,closed = false ) {
    let callUrl = getEP( 'branches_list_practicals' )
    callUrl += '/'+branch+'/list/practicals?closed='+closed

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json_array')
}
export async function branches_list_practicals_outers( branch ) {
    let callUrl = getEP( 'branches_list_practicals_outers' )
    callUrl += '/'+branch+'/list/practicals_outers'

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken()
        }
    },'json_array')
}

export async function read_csv( filename ) {
    let callUrl = getEP( 'read_csv' )

    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken(),
        },
        body: JSON.stringify({
            filename
        })
    })
}

export async function upload_file( file ) {
    let callUrl = getEP( 'upload_files' )

    let formData = new FormData()
    formData.append(file.name,file)

    const upload = await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: formData
    })

    if(upload)
        return upload[0]
    else
        return false
}