import {
    headerTokenKey,
    getToken
} from '../../auth/services/token'
import {
    doCall,
    getEP
} from '../../requests/api'

export async function upload_files( formFiles,folder = 'uploads',return_paths = false ) {
    let callUrl = getEP( 'upload_files' )

    formFiles.append('folder',folder)
    formFiles.append('return_paths',return_paths)
    return await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: formFiles
    },'json.array')
}
export async function uploader( files,subpath = '' ) {
    let callUrl = getEP( 'file_upload' )
    
    let formData = new FormData()
    formData.append('file',files[0])
    formData.append('subpath',subpath)
    
    const upload = await doCall( callUrl,{
        method: 'POST',
        headers: {
            [headerTokenKey]: getToken()
        },
        body: formData
    })

    return upload
}