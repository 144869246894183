import React from 'react'

import { leads_fetch,leads_lastfetch } from '../services/BE'

import ReloadPage from '../@react-fefmwk/loader-app/modules/ReloadPage'
import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'

import Fab from '@material-ui/core/Fab'
import SyncIcon from '@material-ui/icons/Sync'

class SyncLeadsBtn extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            lastfetch: '',
            affected_rows: 0
        }
    }

    async componentDidUpdate() {
        const lastfetch = this.state.lastfetch
        if( lastfetch ) {
            const fetch = await leads_fetch()
            this.setState({
                affected_rows: fetch.affected_rows ? fetch.affected_rows : 0,
                lastfetch: ''
            })
        }
    }

    async sync() {
        const lastfetch = await leads_lastfetch()
        if( lastfetch ) {
            this.setState({
                lastfetch: lastfetch
            })
        }
    }

    render() {
        const lastfetch = this.state.lastfetch
        const affected_rows = this.state.affected_rows

        return <React.Fragment>
            {lastfetch !== ''
                ? <SnackbarMessage
                    open={true}
                    message={"Preparing fetch from "+lastfetch}
                    autoHideDuration={2000}
                    //onClose={this.sync()}
                />
                : null}
            {affected_rows > 0
                ? <ReloadPage
                    timeout="2000"
                    action={() => window.location.reload()}
                    msg="Wait while reloding fetched leads..."
                />
                : null}
            <Fab title={"Sync from lead@chequinto.com"} onClick={() => this.sync()} color="primary" size="small">
                <SyncIcon />
            </Fab>
            &nbsp;&nbsp;
        </React.Fragment>
    }
}

export default SyncLeadsBtn