import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityTitle from '../@react-fefmwk/entity/modules/EntityTitle'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'

import { idFromToken } from '../@react-fefmwk/auth/services/token'

import Dashboard from '../modules/Dashboard'

import { Button,Grid } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

class DashboardPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_user = idFromToken()

        const toolbar = <React.Fragment>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto"><EntityTitle entity="users" id_entity={id_user} /></Grid>
                <Grid item xs={12} md style={{textAlign:"left"}}>
                    <Button component={AdapterLink} to="/atcs" variant="text" size="small" color="secondary" endIcon={<ChevronRight />}>
                        atc
                    </Button>
                    &nbsp;&nbsp;
                    <Button component={AdapterLink} to="/insurances" variant="text" size="small" color="secondary" endIcon={<ChevronRight />}>
                        assicurazioni
                    </Button>
                    &nbsp;&nbsp;
                    <Button component={AdapterLink} to="/insurances_quotations" variant="text" size="small" color="secondary" endIcon={<ChevronRight />}>
                        quotazioni
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>

        return <Content title={title} toolbar={toolbar}>
            <Dashboard />
        </Content>
    }
}

export default DashboardPage