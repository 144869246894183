import React from 'react'

import FieldFile from '../@react-fefmwk/form/modules/FieldFile'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Table from '../@react-fefmwk/tables/modules/Table'

import { groupsFromToken } from '../@react-fefmwk/auth/services/token'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import {shareddocs_list,shareddocs_upload} from '../services/BE'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import { Typography } from '@material-ui/core'

class SharedDocs extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            files: [],
        }
    }

    async componentDidMount() {
        this.setState({
            loading: true
        })
        const files = await shareddocs_list()
        this.setState({
            loading: false,
            files: files
        })
    }

    render() {
        const entity = "files"
        const id_field = "file"
        const files = this.state.files
        const loading = this.state.loading

        const groups = groupsFromToken()
        const is_manager = (groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)))
            ? true
            : false

        let fieldsProps = tableSchema(entity)
        if(!is_manager) fieldsProps[id_field] = {show: false}
        const rowActions = is_manager ? actionSchema(entity) : []

        const UploadBtn = is_manager
            ? <Box mb={2}>
                <FieldFile
                    name="files[]"
                    webkitdirectory={false}
                    fileList
                    fileListStyle={{}}
                    chooseButtonText={"Carica file"}
                    upload_f={async (formFiles) => {
                        const upload = await shareddocs_upload(formFiles)
                        if( upload ) {
                            this.setState({
                                loading: true,
                                files: [],
                            })
                            const _files = await shareddocs_list()
                            this.setState({
                                files: _files,
                                loading: false
                            })
                        }
                        return upload
                    }}
                />
            </Box>
            : null

        const FilesList = !files.length
            ? (loading ? <LoaderSingleComponent width="100%" /> : <Chip label="Nessun documento caricato" variant="text" />)
            : <Paper><Table material highlight
                data={files}
                fieldsProps={fieldsProps}
                rowActions={rowActions}
                rowActionsField={id_field}
                defaultPageSize={100}
                showPagination={false}
                filterable={false}
                sortable={true}
            /></Paper>

        return <React.Fragment>
            <Grid container spacing={4}>
                <Grid item md={8} xs={12}>
                    {FilesList}
                </Grid>
                <Grid item md={4} xs={12}>
                    {is_manager
                    ? <React.Fragment>
                        <Box mb={4}>
                            <Typography component="h4" variant="overline">CARICARE DOCUMENTI</Typography>
                            <Typography component="p" variant="caption">
                                Cliccare sul bottone qui sotto, &egrave; possibile caricare pi&ugrave; documenti contemporaneamente.
                                Al termine del caricamento la lista a fianco di aggiorna automaticamente.
                            </Typography>
                            <Box mt={2}>{UploadBtn}</Box>
                        </Box>
                        <Box mb={4}>
                            <Typography component="h4" variant="overline">ELIMINARE UN DOCUMENTO</Typography>
                            <Typography component="p" variant="caption">Cliccare sulle opzioni del documento (tre puntini verticali presenti su ogni riga della liista a fianco), poi su Elimina. Infine confermare.</Typography>
                        </Box>
                        <Box mb={4}>
                            <Typography component="h4" variant="overline">RINOMINARE UN DOCUMENTO</Typography>
                            <Typography component="p" variant="caption">Eliminare il documento da rinomnare e ricaricarlo con un nuovo nome.</Typography>
                        </Box>
                    </React.Fragment>
                    : <React.Fragment>
                        <Box mb={4}>
                            <Typography component="h4" variant="overline">DOCUMENTI CONDIVISI</Typography>
                            <Typography component="p" variant="caption">
                                I documenti qui a fianco sono a disposizione di tutti gli utenti.
                                <br />
                                Per dubbi o correzioni contattare i referenti dell'agenzia.
                            </Typography>
                        </Box>
                    </React.Fragment>}
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default SharedDocs