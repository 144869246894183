import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import Footer from '../Footer'

import Container from '@material-ui/core/Container'

const LoginPageStyle = theme => ({
    root: {
        marginTop: '10%',
        marginBottom: '10%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})

class Login extends React.Component {
    render() {
        const { classes } = this.props

        return <main id="Login" className={classes.root}>
            <Container maxWidth="sm">
                {this.props.children}
            </Container>
            <Footer containerSize="md" textAlign="center" itemXs={4} />
        </main>
    }
}

export default withRouter(withStyles(LoginPageStyle)(Login))