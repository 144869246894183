import React from 'react'
import { withTheme } from '@material-ui/styles'

import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'

class FieldReportFeedback extends React.Component {
    render() {
        const feedback = this.props.feedback
        const isOk = feedback === 'ok' ? true : false
        const theme = this.props.theme
        const styleUp = {color: theme.palette.success.main}
        const styleDown = {color: theme.palette.error.main}

        return isOk
            ? <ThumbUpIcon style={styleUp} />
            : <ThumbDownIcon style={styleDown} />
    }
}

export default withTheme(FieldReportFeedback)