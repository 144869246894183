import React from 'react'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Tabber from '../@react-fefmwk/tabs/modules/Tabber'
import DashboardCard from '../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'

import WidgetStakeholderFiles from './widgets/WidgetStakeholderFiles'
import WidgetStakeholderActivities from '../modules/widgets/WidgetStakeholderActivities'
import WidgetStakeholderReports from '../modules/widgets/WidgetStakeholderReports'

import { EntitySchema } from '../services/EntitySchema'
import { tableSchema } from '../services/EntityTableSchema'
import {entity_get} from '../@react-fefmwk/entity/services/api'
import {
    practicals_get_history
} from '../services/BE'
import {actionSchema} from '../services/EntityRowActionsSchema'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

class CardPracticalOuter extends React.Component {
    constructor(props) {
        super(props)

        const entity = 'practicals_outers'
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        this.state = {
            entity: entity,
            id_field: id_field,
            id_entity: this.props.id_entity,
            practical_outer: {},
        }
    }

    async componentDidMount() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity

        this.setState({
            practical_outer: await entity_get(entity,id_entity),
        })
    }

    render() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity
        const practical_outer = this.state.practical_outer
        if( !Object.keys(practical_outer).length ) return <LoaderSingleComponent width="100%" />

        const renewal_date = practical_outer.renewal_date+" 00:00:00"

        const id_atc = practical_outer.hasOwnProperty("id_atc") ? practical_outer.id_atc : 0
        const id_stakeholder = practical_outer.hasOwnProperty("id_stakeholder") ? practical_outer.id_stakeholder : 0
        const is_obscured = practical_outer.hasOwnProperty("is_obscured") ? practical_outer.is_obscured : false

        return <Tabber basicname="practicaloutercard"
            tabs={[
                {
                    slug: "info",
                    label: "info",
                    content: <React.Fragment>
                        {is_obscured
                            ? <Box mb={1}><Chip label="Non hai i privilegi accedere a questa pratica" variant="outlined" /></Box>
                            : null}
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <ViewEntity dense noCard
                                    entity={entity}
                                    id_entity={id_entity}
                                    fields={[
                                        'id_practical_outer','practical_outer_code',
                                        'product','stakeholder',
                                        'agent','agencies_branches',
                                        'lender','atc','practical_state',
                                        'effective_date','renewal_date','renewal_date_second',
                                        'residual_amount','installments_n','installment_amount',
                                        'user','created_at','updated_at'
                                    ]}
                                    TopActions={is_obscured
                                        ? {}
                                        : <React.Fragment>
                                            <Button variant="text" size="small"
                                                component={AdapterLink} to={'/practicals_outers/update/'+id_entity}
                                            >Modifica</Button>&nbsp;&nbsp;
                                            <Button variant="text" size="small"
                                                //startIcon={<DeleteIcon />}
                                                component={AdapterLink} to={'/practicals_outers/delete/'+id_entity}
                                            >Elimina</Button>
                                        </React.Fragment>}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {id_atc
                                    ? <Box mb={2}>
                                        <ViewEntity dense
                                            entity="atcs"
                                            id_entity={id_atc}
                                            //cardTitle={dashboardcardTitle}
                                            TopActions={<React.Fragment>
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/atcs/card/"+id_atc}
                                                >Visualizza</Button>&nbsp;&nbsp;
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/atcs/card/"+id_atc+"#quotations"}
                                                >Quotazioni</Button>&nbsp;&nbsp;
                                                <Button
                                                    variant="text" size="small"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/atcs/update/"+id_atc}
                                                >Modifica</Button>
                                            </React.Fragment>}
                                        />
                                    </Box>
                                    : null}
                                {id_stakeholder
                                    ? <Box mb={2}>
                                        <ViewEntity dense
                                            entity="stakeholders"
                                            id_entity={id_stakeholder}
                                            //cardTitle={dashboardcardTitle}
                                            fields={[
                                                'id_stakeholder','stakeholder',
                                                'main_mobile','vat_number','cf',
                                                'practicals_n','last_practical_at',
                                                'recent_agents','agencies_branches','products'
                                            ]}
                                            TopActions={<React.Fragment>
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/stakeholders/card/"+id_stakeholder}
                                                >Visualizza</Button>&nbsp;&nbsp;
                                                <Button
                                                    variant="text" size="small" color="primary"
                                                    //startIcon={<EditIcon fontSize="small" />}
                                                    component={AdapterLink}
                                                    to={"/stakeholders/card/"+id_stakeholder+"#practicals"}
                                                >Pratiche</Button>
                                            </React.Fragment>}
                                        />
                                    </Box>
                                    : null}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                },
                {
                    slug: "documents",
                    label: "documenti",
                    content: is_obscured
                        ? <Chip label="Non hai i privilegi per gestire i documenti di questa pratica" variant="outlined" />
                        : <React.Fragment>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography component="h4" variant="subtitle2">Documenti del contatto</Typography>
                                    <br />
                                    <WidgetStakeholderFiles id_stakeholder={id_stakeholder} />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                },
                {
                    slug: "activities",
                    label: "attività",
                    content: is_obscured
                        ? <Chip label="Non hai i privilegi per gestire le attività di questo cliente" variant="outlined" />
                        : <React.Fragment>
                            <Button
                                variant="text" size="small"
                                startIcon={<AddIcon fontSize="small" />}
                                component={AdapterLink}
                                to={"/tasks/add?id_stakeholder="+id_stakeholder}
                            >Assegna task</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                variant="text" size="small"
                                startIcon={<AddIcon fontSize="small" />}
                                component={AdapterLink}
                                to={"/reports/add?id_stakeholder="+id_stakeholder}
                            >Scrivi report</Button>
                            <br />
                            <br />
                            <WidgetStakeholderActivities id_stakeholder={id_stakeholder} />
                            <br />
                            <WidgetStakeholderReports id_stakeholder={id_stakeholder} />
                        </React.Fragment>
                },
            ]}
        />
    }
}

export default CardPracticalOuter