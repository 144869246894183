import { createMuiTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

import {it} from "date-fns/locale"

import {agencySchema} from '../services/AgencySchema'

const agencyObj = agencySchema()
const palette = agencyObj.palette
const assets_path = agencyObj.assets_path

const defaultTheme = createMuiTheme()
const appHeaderBarHeight = 55
const appNavSideWidth = 250
export default createMuiTheme({
    assets_path: assets_path,
    homeUrl: "/home",
    login_logo: assets_path+"/logo-login.png",
    navtop_logo: assets_path+"/logo-login.png",
    navtop_title: "",
    navtop_showusername: false,
    palette: palette,
    locale: it,
    globals: {
        appHeaderBar: {
            //boxShadow: 'none',
            //backgroundColor: 'transparent',
            color: palette.secondary.main,
            backgroundColor: 'white',
            height: appHeaderBarHeight,
            minHeight: appHeaderBarHeight,
            borderRadius: 0,
        },
        appHeaderTitleBox: {
            flexGrow: 1,
        },
        appHeaderTitleText: {
            //fontWeight: 400,
            color: 'inherit',
        },
        appHeaderLogoBox: {
            marginRight: 20,
            height: '70%',
            maxHeight: 60
        },
        appHeaderLogoImg: {
            height: '100%',
        },
        appHeaderNavTopBox: {
            color: 'inherit',
        },
        appNavTopItem: {
            textDecoration: 'none',
            color: 'inherit',
            marginLeft: '1%',
            marginRight: '1%'
        },
        appNavTopItemIcon: {
            paddingTop:5,
            //textTransform: 'uppercase',
            fontWeight: 400,
        },
        appNavTopItemText: {
            paddingTop:5,
            //textTransform: 'uppercase',
            fontWeight: 400
        },
        appNavTopItemIconSelected: {
            color: palette.primary.main,
            fontWeight: 600,
        },
        appNavTopItemTextSelected: {
            color: palette.primary.main,
            fontWeight: 600
        },
        appNavTopItemDivider: {
            marginLeft: '1%',
            marginRight: '1%'
        },
        appUserAvatar: {
            width: 35,
            height: 35,
            lineHeight: 35,
            fontSize: '108%',
            backgroundColor: palette.secondary.main,
            textDecoration: 'none',
            '&:hover': {
                transform: 'scale(1.1)'
            },
            cursor:'pointer'
        },
        appNavSideDrawer: {
            width: appNavSideWidth,
            borderRadius: 0,
            //backgroundColor: 'transparent',
            //borderRight: 0,
        },
        appNavSideCloseBtn: {
            borderTop: '1px solid '+palette.grey[200],
        },
        appNavSideBlockTitle: {
            paddingBottom: 0,
            borderTop: '1px solid '+palette.grey[200],
        },
        appNavSideItem: {
            paddingLeft: defaultTheme.spacing(3)
        },
        appNavSideItemTitle: {
            paddingBottom: 0,
            fontWeight: 900,
            color: palette.grey[700],
        },
        appNavSideItemIcon: {
            minWidth: 30,
            color: palette.grey[600],
        },
        appNavSideItemText: {
            color: palette.grey[600],
        },
        appNavSideItemSelected: {
            backgroundColor: fade(palette.primary.main,0.1),
            borderRight: "3px solid",
            borderRightColor: palette.primary.main,
            borderRadius: 2,
        },
        appNavSideItemIconSelected: {
            fontWeight: 900,
            color: palette.primary.main,
        },
        appNavSideItemTextSelected: {
            fontWeight: 900,
            color: palette.primary.main,
        },
        appNavSideItemTextInCollapsedSelected: {
            fontWeight: 900,
            color: palette.grey[700]
        },
        navSideUserBoxContainerClass: {
            borderBottom: '1px solid '+palette.grey[200],
        },
        navSideUserBox: {
            backgroundColor: palette.grey[100],
            borderRadius: 10,
            padding: defaultTheme.spacing(1),
            margin: defaultTheme.spacing(2),
        },
        navSideUserBoxNoUsername: {
            backgroundColor: 'transparent',
            padding: defaultTheme.spacing(1,0),
            margin: defaultTheme.spacing(2),
        },
        navSideUserText: {
            textDecoration: 'none',
            color: palette.grey[600]
        },
        appBody: {
            //---------height: 'calc(100% - 2*'+appHeaderBarHeight+'px)',
            paddingTop: appHeaderBarHeight,
            paddingBottom: appHeaderBarHeight,
        },
        appBodyNoHeader: {
            //---------height: 'calc(100% - '+appHeaderBarHeight+'px)',
            paddingBottom: appHeaderBarHeight,
        },
        appPageTitle: {
            marginTop: 15,
            marginBottom: 15,
            //color: palette.secondary.main,
            //fontWeight: 'bold'
        },
        appPageContent: {
            //height: '90%',
            //---------minHeight: '80vh',
        },
        appFooter: {
            fontSize: '90%',
            fontWeight: 400,
            color: palette.grey[500],
            '& a': {
                textDecoration: 'none',
                color: 'inherit',
                fontWeight: 600,
            },
            '& a:hover': {
                color: palette.grey[700],
            },
        },
        FormMuiButton: {
            marginTop: 16,
        },
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottomColor: palette.secondary.main
                }
            },
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: palette.secondary.main,
                }
            }
        },
    },
})