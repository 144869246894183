import React from 'react'

import Modal from '../../@react-fefmwk/modals/modules/Modal'

import WidgetTaskReports from '../../modules/widgets/WidgetTaskReports'

import Chip from '@material-ui/core/Chip'

class FieldReportsN extends React.Component {
    render() {
        const title = "Report del task"
        const reports_n = this.props.reports_n
        const id_activity = this.props.hasOwnProperty('id_activity') ? this.props.id_activity : 0

        return reports_n > 0 && id_activity
            ? <Modal
                fullWidth
                maxWidth="lg"
                printCloseBtn
                title={title}
                content={<WidgetTaskReports noTitle noCard
                    id_activity={id_activity}
                    fields="id_report,report_type,report_author,task_receiver,report_content,report_feedback"
                />}
                Trigger={<Chip label={reports_n} size="small" color="secondary" />}
            />
            : <Chip label={reports_n} size="small" color="default" />
    }
}

export default FieldReportsN