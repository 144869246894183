import React from 'react'

import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {practicals_list_events} from '../services/BE'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

class Wiki extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            events: [],
        }
    }

    async componentDidMount() {
        const events = await practicals_list_events()
        this.setState({
            events: events
        })
    }

    render() {
        const events = this.state.events
        if( !events.length ) return <LoaderSingleComponent width="100%" />

        const EventsList = events.map((event,i) => {
            const itemEvent = (i+1)+". "+event.activity_order
            const itemFrom = event.event_from
            const itemTo = event.event_to
            const itemType = event.event_type
            const itemScope = event.event_scope
            const itemClosing = event.event_closing
            const itemState = event.practical_state
            return <React.Fragment key={event.id_practical_event}>
                {i === 0 ? null : <Divider />}
                <ListItem>
                    <ListItemText
                        primary={<React.Fragment>
                            {itemEvent}
                            &nbsp;&nbsp;
                            <Chip label={itemType} size="small" color="primary" />
                        </React.Fragment>}
                        secondary={<React.Fragment>
                            Stato pratica: {itemState}
                            <br />
                            {itemFrom === "-" ? null : (itemFrom+" > "+itemTo)}
                            <br />
                            {itemScope}
                            <br />
                            {itemClosing}
                        </React.Fragment>}
                    />
                    <ListItemSecondaryAction>
                        <InfoOutlinedIcon color="secondary" />
                    </ListItemSecondaryAction>
                </ListItem>
            </React.Fragment>
        })

        return <Box style={{height:"85vh",overflowY:"auto"}}>
            <Paper>
                <List>{EventsList}</List>
            </Paper>
        </Box>
    }
}

export default Wiki