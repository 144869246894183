import React from 'react'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Table from '../../tables/modules/Table'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

import EntityToolbar from "./EntityToolbar"
import LoaderSingleComponent from "../../loader-app/modules/LoaderSingleComponent"

import { selectFieldsFromList } from '../../arrays/api'
import { entity_list } from '../services/api'
import { tableSchema } from '../../../services/EntityTableSchema'
import { actionSchema } from '../../../services/EntityRowActionsSchema'
import { EntitySchema } from '../../../services/EntitySchema'
import { fieldsListSchema } from '../../../services/EntityFieldsSchema'

class Entity extends React.Component {
    constructor(props) {
        super(props)

        const entity = this.props.entity
        const entityObj = EntitySchema[entity]
        this.state = {
            entity: entity,
            entityObj: entityObj,
            data:[],
        }
    }

    async loadEntity() {
        let newProps = {...this.props}

        const entity = newProps.entity
        const entityObj = EntitySchema[entity]
        const data = newProps.hasOwnProperty('data')
            ? newProps.data
            : await entity_list( newProps.entity )

        newProps["data"] = data
        newProps["entityObj"] = entityObj
        newProps["refresh"] = false

        this.setState(newProps)
    }

    async componentDidMount() {
        this.loadEntity()
    }

    async componentDidUpdate(prevProps){
        if( prevProps.entity !== this.props.entity ) {
            this.setState({refresh:true})
            this.loadEntity()
        }
    }

    render() {
        const entity = this.state.entity
        const entityObj = this.state.entityObj
        if( !entity || !entityObj || !Object.keys(entityObj).length )
            return <Chip icon={<ErrorIcon />} label="Unmanaged entity" variant="outlined" />

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const refresh = this.state.refresh
        const fieldsProps = tableSchema(entity)
        const rowActions = actionSchema(entity,path)

        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : fieldsListSchema(entity)
        let data = this.state.data
        if(fields.length) {
            data = selectFieldsFromList( data,fields )
        }

        const printToolbar = this.props.hasOwnProperty('printToolbar') ? this.props.printToolbar : true
        const printPaper = this.props.hasOwnProperty('printPaper') ? this.props.printPaper : true
        const WrapComp = printPaper ? Paper : Box

        return <React.Fragment>
            {printToolbar
                ? <Box mb={3}>
                    <EntityToolbar entity={entity} path={path} data={data}/>
                </Box>
                : null}
            {!data.length || refresh
                ? <LoaderSingleComponent width="100%"/>
                : <WrapComp>
                    <Table material dense highlight
                        data={data}
                        fieldsProps={fieldsProps}
                        rowActions={rowActions}
                        rowActionsField={id_field}
                        showPagination={true}
                        filterable={true}
                        sortable={true}
                    />
                </WrapComp>
            }
        </React.Fragment>
    }
}

export default Entity