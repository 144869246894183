import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import PracticalsCommissions from '../modules/PracticalsCommissions'

class PracticalsCommissionsPage extends React.Component {
    render() {
        const title = this.props.route.title

        return <Content title={title}>
            <PracticalsCommissions />
        </Content>
    }
}

export default PracticalsCommissionsPage