import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import {idFromToken} from '../@react-fefmwk/auth/services/token'
import ViewEntity from '../@react-fefmwk/entity/modules/ViewEntity'

class ProfilePage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_user = idFromToken()

        return <Content title={title}>
            <ViewEntity
                id_entity={id_user}
                entity="users"
            />
        </Content>
    }
}

export default ProfilePage