import React from 'react'

//import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
//import Button from '@material-ui/core/Button'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

class FieldIdLender extends React.Component {
    render() {
        const id_lender = this.props.id_lender
        const title = this.props.title ? this.props.title : "Scheda istituto creditore"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda istituto creditore"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_lender || id_lender <= 0 || id_lender === '-'
            ? null
            : <FieldEntityView title={title} icon={AccountBalanceIcon}
                id_entity={id_lender} entity="practicals_lenders"
                fields={['id_lender','lender']}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdLender