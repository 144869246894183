import React from 'react'
import ReactDOM from 'react-dom'
import ErrorMsgApp from '../modules/ErrorMsgApp'

export function showErrorMsgApp( message = '' ) {
    createErrorMsgAppBox()
    ReactDOM.render(
        <ErrorMsgApp message={message} />,
        document.getElementById('ErrorMsgApp')
    )
}
function createErrorMsgAppBox() {
    var errorBox = document.getElementById("ErrorMsgApp")
    if(typeof(errorBox) === 'undefined' || errorBox === null) {
        let errBox = document.createElement('div')
        errBox.setAttribute("id","ErrorMsgApp")

        var root = document.getElementById("Root")
        root.appendChild(errBox)
    }

    return errorBox
}