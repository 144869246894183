import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {nowString} from '../../@react-fefmwk/dates/services/api'
import { entity_fields_info, entity_q } from '../../@react-fefmwk/entity/services/api'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import EntityRelFieldActions from '../../@react-fefmwk/entity/modules/EntityRelFieldActions'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import Table from '../../@react-fefmwk/tables/modules/Table'

import {tableSchema} from '../../services/EntityTableSchema'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
//import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

//import BlockIcon from '@material-ui/icons/Block'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { Tooltip, Typography } from '@material-ui/core'

const styles = theme => ({})

class AtcInsurancesQuotations extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals_fields_info: {},
            refresh: false,
            quotations: [],
        }
    }
    customizeFieldsInfo(fieldsInfo) {
        if( fieldsInfo.hasOwnProperty('id_atc') ) {
            fieldsInfo.id_atc['nice_name'] = "Scegli ATC..."
        }

        if( fieldsInfo.hasOwnProperty('id_insurance_quotation') ) {
            fieldsInfo.id_insurance_quotation['disabled'] = true
            fieldsInfo.id_insurance_quotation['nice_name'] = "Quotazione scelta"
        }

        if( fieldsInfo.hasOwnProperty('id_insurance') ) {
            fieldsInfo.id_insurance['disabled'] = true
            fieldsInfo.id_insurance['nice_name'] = "Assicurazione scelta"
        }

        return fieldsInfo
    }
    async getQuotations(id_atc) {
        const filters = {id_atc: id_atc}
        return await entity_q(
            'insurances_quotations',
            {
                fields: ['id_insurance_quotation','id_insurance','insurance','multiplier','quotation_notes','quotation_date','inserted_by',],
                filters: filters,
                orderby: ['quotation_date DESC']
            }
        )
    }
    async componentDidMount() {
        let practicals_fields_info = await entity_fields_info('practicals')
        practicals_fields_info = this.customizeFieldsInfo(practicals_fields_info)

        const formikProps = this.props.formikProps
        const id_atc = formikProps.values.id_atc
        let quotations = []
        if( id_atc ) {
            quotations = await this.getQuotations(id_atc)
        }

        this.setState({
            practicals_fields_info: practicals_fields_info,
            quotations: quotations
        })

        formikProps.setFieldValue('load_date',nowString())
    }
    async componentDidUpdate(prevProps) {
        const formikProps = this.props.formikProps
        const id_atc = formikProps.values.id_atc
        const prev_id_atc = prevProps.formikProps.values.id_atc
        const refresh = this.state.refresh

        if( (id_atc && parseInt(id_atc) !== parseInt(prev_id_atc)) ) {
            const quotations = await this.getQuotations(id_atc)
            this.setState({
                quotations: quotations,
                refresh: true
            })
            formikProps.setFieldValue('id_insurance','')
            formikProps.setFieldValue('id_insurance_quotation','')
        }
        if( refresh ) {
            let practicals_fields_info = await entity_fields_info('practicals')   
            practicals_fields_info = this.customizeFieldsInfo(practicals_fields_info)
            const quotations = await this.getQuotations(id_atc)
            this.setState({
                practicals_fields_info: practicals_fields_info,
                quotations: quotations,
                refresh: false
            })
        }
    }
    renderInput = (props) => {
        const formikProps = this.props.formikProps
        const id_insurance_quotation = props.value
        const id_insurance = props.row.id_insurance

        const chosenQuotation = formikProps.values.id_insurance_quotation
        const isSelected = parseInt(id_insurance_quotation) === parseInt(chosenQuotation) ? true : false

        const RadioButtonIcon = isSelected ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon

        return <RadioButtonIcon size="small"
            color={isSelected ? "primary" : "secondary"}
            onClick={() => {
                formikProps.setFieldValue('id_insurance',id_insurance)
                formikProps.setFieldValue('id_insurance_quotation',id_insurance_quotation)
            }}
        />
        /*return <Chip size="small"
            icon={isSelected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            //label={isSelected ? "Selezionato" : "Seleziona"}
            variant={isSelected ? "contained" : "outlined"}
            color={isSelected ? "primary" : "default"}
            onClick={() => {
                formikProps.setFieldValue('id_insurance',id_insurance)
                formikProps.setFieldValue('id_insurance_quotation',id_insurance_quotation)
            }}
        />*/
    }
    clearSelection() {
        const formikProps = this.props.formikProps
        formikProps.setFieldValue('id_insurance','')
        formikProps.setFieldValue('id_insurance_quotation','')
    }
    render() {
        let formikProps = this.props.formikProps
        const entity = 'practicals'
        const refresh = this.state.refresh
        const quotations = this.state.quotations
        const practicals_fields_info = this.state.practicals_fields_info
        if( Object.keys(practicals_fields_info).length === 0 ) return <LoaderSingleComponent width="100%" />

        const fields_row1 = {
            id_atc: practicals_fields_info.id_atc,
            id_insurance: practicals_fields_info.id_insurance,
            id_insurance_quotation: practicals_fields_info.id_insurance_quotation,
        }

        let fieldsProps = tableSchema('insurances_quotations.choose')
        fieldsProps['id_insurance_quotation'] = {
            Header: "Scegli quotazione",
            Cell: this.renderInput
        }

        const onRefreshForm = () => this.setState({refresh:true})

        const id_atc = formikProps.values.id_atc
        const id_insurance = formikProps.values.id_insurance
        const id_insurance_quotation = formikProps.values.id_insurance_quotation
        let queryStringNewQuotation = '1&'
        queryStringNewQuotation += id_atc ? ('id_atc='+id_atc) : ''
        queryStringNewQuotation += id_insurance ? ('id_insurance='+id_insurance) : ''

        const enableNewQuotation = id_insurance_quotation ? false : true
        const AddNewQuotation = <span><Button size="small" color="primary" variant="contained" startIcon={<AddIcon />} disabled={!enableNewQuotation}>
            quotazione
        </Button></span>

        return refresh
            ? null
            : <React.Fragment>
                <Box mb={2}>
                    <Grid container spacing={4}>
                        <EntityFormInputs
                            entity={entity}
                            entityFieldsInfo={fields_row1}
                            formikProps={formikProps}
                            onRefreshForm={onRefreshForm}
                        />
                    </Grid>
                </Box>
                <Divider />
                <Box mt={2}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <EntityRelFieldActions
                                entity="insurances_quotations"
                                onClose={onRefreshForm}
                                Trigger={AddNewQuotation}
                                s={queryStringNewQuotation}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => this.clearSelection()} disabled={enableNewQuotation}>
                                <Tooltip title="Cancella selezione">
                                    <DeleteIcon />
                                </Tooltip>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} md={6}><Typography variant="body2" component="span">
                            <i>&Egrave; possibile aggiungere una nuova quotazione se non è presente la quotazione desiderata per la ATC scelta</i> 
                        </Typography></Grid>
                    </Grid>
                    
                </Box>
                <Box mt={1}>
                    {refresh || !id_atc
                        ? null
                        : (quotations.length
                            ? <Paper>
                                <Table material dense
                                    data={quotations}
                                    fieldsProps={fieldsProps}
                                    filterable={false}
                                    sortable={false}
                                    showPagination={true}
                                />
                            </Paper>
                            : null)}
                </Box>
            </React.Fragment>
    }
}

export default withStyles(styles)(AtcInsurancesQuotations)