import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddIcon from '@material-ui/icons/Add'

class FieldIdPractical extends React.Component {
    render() {
        const id_practical = this.props.id_practical
        const title = this.props.title ? this.props.title : "Scheda pratica"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda pratica"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_practical || id_practical <= 0 || id_practical === '-'
            ? null
            : <FieldEntityView title={title} icon={AccountBalanceWalletIcon}
                id_entity={id_practical} entity="practicals"
                fields={[
                    'id_practical','practical_code',
                    'product','stakeholder','practical_state',
                    'agent','agencies_branches',
                    'origination','atc',
                    'load_date','effective_date','renewal_date','renewal_date_second',
                    'disbursed_amount','installments_n','installment_amount','compounding_amount',
                    'commission','tan','taeg',
                    'estinzione',
                ]}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/practicals/card/'+id_practical} target="_blank"
                    >Visualizza</Button>
                    <Button variant="text" size="small"
                        startIcon={<CloudUploadIcon />}
                        component={AdapterLink} to={'/practicals/card/'+id_practical+'#documents'} target="_blank"
                    >Documenti</Button>
                    <Button variant="text" size="small"
                        startIcon={<AddIcon />}
                        component={AdapterLink} to={'/tasks/add?id_practical='+id_practical} target="_blank"
                    >Assegna task</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdPractical