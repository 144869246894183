import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'

class FieldIdAgent extends React.Component {
    render() {
        const id_agent = this.props.id_agent
        const title = this.props.title ? this.props.title : "Scheda collaboratore"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda collaboratore"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_agent || id_agent <= 0 || id_agent === '-'
            ? null
            : <FieldEntityView title={title} icon={RecordVoiceOverIcon}
                id_entity={id_agent} entity="agents"
                fields={['display_name','user_email']}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/agents/view/'+id_agent} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdAgent