import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

class Settings extends React.Component {
    render() {
        const title = this.props.route.title

        return <Content title={title}>
            Coming soon
        </Content>
    }
}

export default Settings