import React, { Component } from 'react'
import {todayString,nowString} from '../../dates/services/api'

const DEFAULT_LOCALE_STRING = 'de-DE' // 'en-us'; //'de-DE'
const DEFAULT_CURRENCY = "EUR"
const DEFAULT_DECIMAL_STRING = 2
const DEFAULT_EMPTY_VALUE = "-"

class Formatter extends Component  {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value, 
            dataType: this.props.dataType, //integer, decimal, percent, string, date, currency
            currency: this.props.currency, //EUR, USD, GBP, CHF
            decimalDigits: this.props.decimalDigits, // 2, 3, 4 ...
            exponent: this.props.exponent, // 0, 3, 6
        };

        //Default
        if(!this.state.value){
            this.state.value = DEFAULT_EMPTY_VALUE;
        }

        if(!this.state.dataType){
            this.state.dataType = "string";
        }

        if(!this.state.currency){
            this.state.currency = DEFAULT_CURRENCY;
        }

        if(this.state.decimalDigits === undefined){
            this.state.decimalDigits = DEFAULT_DECIMAL_STRING;
        }

        if(!this.state.exponent){
            this.state.exponent = 0;
        }

    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props) {
          this.setState(
           this.props,
          );
        }
    }

    render(){
        var value = this.state.value;
        
        const exponentialNotationFactor = Math.pow(10, this.state.exponent);

        //INTEGER
        if(this.state.dataType === "integer"){       
            value =  parseFloat(value).toLocaleString(DEFAULT_LOCALE_STRING,{ style:'decimal', minimumFractionDigits:0, maximumFractionDigits:0 })
        }

        //DECIMAL
        else if(this.state.dataType === "decimal"){ 
            value = parseFloat(value) / exponentialNotationFactor;
            if(isNaN(value))
                value = DEFAULT_EMPTY_VALUE;
            else
                value = value.toLocaleString(DEFAULT_LOCALE_STRING,{ style:'decimal', minimumFractionDigits:this.state.decimalDigits, maximumFractionDigits:this.state.decimalDigits })
        }

        //PERCENT
        else if(this.state.dataType === "percent"){ 
            value = parseFloat(value)
            if(isNaN(value))
                value = DEFAULT_EMPTY_VALUE;
            else
                value = parseFloat(value).toLocaleString(DEFAULT_LOCALE_STRING,{ style:'percent', minimumFractionDigits:this.state.decimalDigits, maximumFractionDigits:this.state.decimalDigits })
        }

        //PERCENT100
        else if(this.state.dataType === "percent100"){ 
            value = parseFloat(value/100)
            if(isNaN(value))
                value = DEFAULT_EMPTY_VALUE;
            else
                value = parseFloat(value).toLocaleString(DEFAULT_LOCALE_STRING,{ style:'percent', minimumFractionDigits:this.state.decimalDigits, maximumFractionDigits:this.state.decimalDigits })
        }

        //STRING
        else if(this.state.dataType === "string"){
           
        }

        //DATE
        else if(this.state.dataType === "date"){
            value = todayString(value)
        }

        //DATETIME
        else if(this.state.dataType === "datetime"){
            value = nowString(value)
        }

        //CURRENCY
        else if(this.state.dataType === "currency"){
            value = parseFloat(value) / exponentialNotationFactor;
            if(isNaN(value))
                value = DEFAULT_EMPTY_VALUE;
            else
                value = value.toLocaleString(DEFAULT_LOCALE_STRING,{ style:'currency', currency:this.state.currency, minimumFractionDigits:this.state.decimalDigits, maximumFractionDigits:this.state.decimalDigits })
        }
        
        return(
            <React.Fragment >
                {value}
            </React.Fragment>
        )
    }
    
}

export default Formatter;