import React, { Component } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'

class LoaderSingleComponent extends Component  {
    render() {
        const width = this.props.hasOwnProperty('width') ? this.props.width : 20
        const height = width
        const layout = this.props.hasOwnProperty('layout') ? this.props.layout : 'linear'
        const color = this.props.hasOwnProperty('color') ? this.props.color : 'primary'

        switch(layout) {
            case 'circular':
                return <CircularProgress color={color} style={{width:width,height:height}} />
            break
            case 'linear':
            default:
                return <LinearProgress color={color} style={{width:!width ? 50 : width}} />
            break
        }
    }
}

export default LoaderSingleComponent