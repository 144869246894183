import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

class WidgetLead extends React.Component {
    render() {
        const id_lead = this.props.id_lead
        if( !id_lead ) return <Chip icon={<ErrorIcon />} label="No data available" variant="outlined" />

        const fields = this.props.hasOwnProperty('fields') ? this.props.fields : []

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Anagrafica"

        let actions = null
        if( dashboardcardActions )
            actions = <React.Fragment>
                <Button
                    variant="contained" color="primary" size="small"
                    startIcon={<EditIcon fontSize="small" />}
                    component={AdapterLink}
                    to={"/leads/manage/"+id_lead}
                >Gestisci</Button>
                <Button variant="text" size="small"
                    startIcon={<DeleteIcon fontSize="small" />}
                    to={"/leads/assign/"+id_lead}
                    component={AdapterLink}
                >Assegna</Button>
            </React.Fragment>

        return <ViewEntity dense
            entity={"leads"}
            id_entity={id_lead}
            fields={fields}
            actions={actions}
            cardTitle={dashboardcardTitle}
        />
    }
}

export default WidgetLead