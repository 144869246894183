import React from 'react'

import {
    entity_create,
    entity_update
} from '../@react-fefmwk/entity/services/api'
import {nowString} from '../@react-fefmwk/dates/services/api'
import {idFromToken} from '../@react-fefmwk/auth/services/token'

import TargetSelection from '../modules/add-report-stepper/TargetSelection'
import ReportData from '../modules/add-report-stepper/ReportData'
import Checkout from '../modules/add-report-stepper/Checkout'

export const AddReportStepperSchema = [
    {
        "name": "target-selection",
        "icon": false,
        "title": "Target",
        "description": "Seleziona il target del report",
        "component": (formikProps) => <TargetSelection formikProps={formikProps} />,
    },
    {
        "name": "report_data",
        "icon": false,
        "title": "Report data ",
        "description": "Compila il campi del report",
        "component": (formikProps) => <ReportData formikProps={formikProps} />,
    },
    {
        "name": "checkout",
        "icon": false,
        "title": "Checkout",
        "description": "Controlla i dati inseriti",
        "component": (formikProps) => <Checkout formikProps={formikProps} />,
    },
]

export const AddReportStepperFormSchema = {
    id_report: 0,
    id_report_target: '',
    id_lead: 0,
    id_stakeholder: 0,
    id_practical: 0,
    id_activity: 0,
    id_report_type: '',
    report_date: nowString(),
    report_title: '',
    report_content: '',
    report_feedback: 'ok',
    created_at: nowString(),
    id_user: parseInt(idFromToken()),
}

export const AddReportStepperValidationSchema = [
    (values) => {
        let errors = {}
        const id_report_target = values.id_report_target
        const id_lead = values.id_lead
        const id_stakeholder = values.id_stakeholder
        const id_practical = values.id_practical
        const id_activity = values.id_activity

        if( !id_report_target )
            errors['id_report_target'] = 'Choose a valid report target'
        
        if( !id_lead && !id_stakeholder && !id_practical && !id_activity )
            errors['id_report_target'] = 'Choose a valid lead or practical or stakeholder or task'

        return errors
    },
    (values) => {
        let errors = {}
        const id_report_type = values.id_report_type
        const report_date = values.report_date
        const report_title = values.report_title
        const report_content = values.report_content
        const report_feedback = values.report_feedback

        if( !id_report_type ) errors['id_report_type'] = 'Scegli un tipo di report valido'
        if( !report_date ) errors['report_date'] = 'Scegli una data valida per il report'
        if( !report_title ) errors['report_title'] = 'Inserisci un titolo'
        if( !report_content ) errors['report_content'] = 'Inserisci il testo del report'
        if( !report_feedback ) errors['report_feedback'] = 'Sei riuscito ad eseguire il task?'

        const report_dt = new Date(report_date)
        const today_dt = new Date()
        if( report_dt > today_dt ) errors['report_date'] = 'Data futura non valida'

        return errors
    },
]

export const AddReportStepperSubmitSchema = [
    {
        "enableSubmit": false,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            return true
        }
    },
    {
        "enableSubmit": true,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            const id = prevState.id
            if( id === "add-report" ) {
                const inserted_report = await entity_create( 'reports',values )
                const id_report = inserted_report['last_id']
                actions.setFieldValue("id_report",id_report)
                return id_report
            }
            else {
                const id_report = prevState.form.id_report
                if(id_report) {
                    const updated_report = await entity_update( 'reports',id_report,values )
                    return updated_report
                }
            }
        }
    },
]