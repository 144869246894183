import React from 'react'
import { withStyles,withTheme } from '@material-ui/core/styles'

import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

const style = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 200
    },
    defaultValue: {
        color:theme.palette.grey[500],
        textTransform:"uppercase",
    }
})

class FieldAutocomplete extends React.Component {

    constructor(props){
        super(props)

        const valueSelected = this.props.valueSelected
        const sortDropdownKey = this.props.sortDropdownKey
        const fieldId = this.props.fieldId
        const name = this.props.name

        let items = []
        const nullRow = this.props.hasOwnProperty('nullRow') ? this.props.nullRow : {}
        if( Object.keys(nullRow).length ) items.push(nullRow)
        items = [
            ...items,
            ...this.sortItemsByKey(this.props.items,sortDropdownKey)
        ]

        const optionSelected = this.optionSelected(items,fieldId,valueSelected)

        const autocomplete_id = this.props.hasOwnProperty('id') ? this.props.id : ("field-autocomplete-"+name+"-"+fieldId)
        this.state = {
            autocomplete_id: autocomplete_id,
            items: items,
            valueSelected: valueSelected,
            optionSelected: optionSelected,
            selected: false,
            nullRow: nullRow
        }
    }
    componentDidMount(){
        const name = this.props.name
        const autocomplete_id = this.state.autocomplete_id

        //Take the Reference of Close Button
        const closeIconElement = document.getElementById(autocomplete_id)
            .getElementsByClassName("MuiAutocomplete-clearIndicator")[0]
        //Add a Click Event Listener to the button
        if( closeIconElement !== undefined ) {
            closeIconElement.addEventListener("click",() => {
                this.setState({
                    valueSelected: '',
                    optionSelected: '',
                    selected: false,
                })
                const formikProps = this.props.hasOwnProperty('formikProps') ? this.props.formikProps : {}
                if( Object.keys(formikProps).length ) {
                    formikProps.setFieldValue(name,'')
                }
            })
        }
    }
    componentDidUpdate(prevProps,prevState) {
        const valueSelected = this.props.hasOwnProperty('valueSelected')
            ? this.props.valueSelected
            : this.state.valueSelected
        const prev_valueSelected = prevState.valueSelected
        const items = this.props.items
        const fieldId = this.props.fieldId

        if(valueSelected !== prev_valueSelected) {
            const optionSelected = this.optionSelected(
                    items,
                    fieldId,
                    valueSelected
            )
            this.setState({
                //items: this.sortItemsByKey(items,sortDropdownKey),
                valueSelected: valueSelected,
                optionSelected: optionSelected
            })
        }
    }

    optionSelected(items,fieldId,valueSelected) {
        return !valueSelected
            ? ""
            : items.filter(el => {
                return el[fieldId].toString() === valueSelected.toString()
            })[0]
    }

    sortItemsByKey( array,key="" ) {
        if(!key) return array

        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key]
            return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
    }

    handleChange(item,fieldId) {
        const name = this.props.name
        const formikProps = this.props.hasOwnProperty('formikProps') ? this.props.formikProps : {}

        if(item) {
            this.setState({
                valueSelected: item[fieldId],
                optionSelected: item
            })

            if( Object.keys(formikProps).length ) {
                formikProps.setFieldValue(name,item[fieldId])
                formikProps.setFieldTouched(name,true)
            }
            /*else {
                const onChange = this.props.hasOwnProperty('onChange') ? this.props.onChange : () => true
                onChange(item,fieldId)
            }*/
        }
        //custom onChange
        const onChange = this.props.hasOwnProperty('onChange')
            ? this.props.onChange
            : () => true
        onChange(item,fieldId,formikProps)
    } 

    render() {
        const name = this.props.name
        const items = this.state.items
        if( items.length === 0) return <Chip label={"No "+name+" found"} variant="outlined" style={{marginTop:20}}/>

        const autocomplete_id = this.state.autocomplete_id
        const title = this.props.title ? this.props.title : ""
        const id = this.props.id ? this.props.id : ""
        const fieldId = this.props.fieldId
        const fieldName = this.props.fieldName
        const error = this.props.error
        const helperText = this.props.helperText
        const variant = this.props.variant ? this.props.variant : "outlined"
        const disabled = this.props.disabled
        const optionSelected = this.state.optionSelected
        let optionSelectedLabel = ""
        if(optionSelected)
            optionSelectedLabel = optionSelected[fieldName]

        const nullRow = this.state.nullRow

        const { classes } = this.props
        const { theme } = this.props

        const inputProps = this.props.inputProps
        const formControlStyle = {
            width: inputProps && inputProps.hasOwnProperty('width') ? inputProps.width : '100%',
            minWidth: inputProps && inputProps.hasOwnProperty('minWidth') ? inputProps.minWidth : 300,
        }

        return <React.Fragment>
            <FormControl id={autocomplete_id} variant={variant} className={classes.formControl} style={formControlStyle}>
                <Autocomplete
                    options={items}
                    getOptionLabel={option => option[fieldName] !== undefined ? String(option[fieldName]) : ""}
                    getOptionSelected={(option) => optionSelectedLabel !== undefined && option[fieldName] === optionSelectedLabel}
                    onChange={(event,item) => this.handleChange(item,fieldId)}
                    filterOptions={createFilterOptions({
                        ignoreAccents: false
                    })}
                    renderInput={(params) => {
                        const value = params.inputProps.value
                        const styleObj = String(value) === String(items[0][fieldName]) && Object.keys(nullRow).length
                            ? {style: {
                                color:theme.palette.grey[500],
                                textTransform:"uppercase",
                            }}
                            : {}
                        return <TextField
                            {...params}
                            error={error}
                            label={title}
                            variant="outlined"
                            inputProps={{...params.inputProps,...styleObj}}
                        />
                    }}
                    renderOption={(option) => {
                        if (String(option[fieldName]) === String(items[0][fieldName]) && Object.keys(nullRow).length)
                            return <span className={classes.defaultValue}>{option[fieldName]}</span>
                        return <span>{option[fieldName]}</span>
                    }}
                    disabled={disabled}
                    value={optionSelected}
                    label={title}
                    autoHighlight={true}
                    noOptionsText={title}
                />
                <FormHelperText component="div">{helperText}</FormHelperText>
                <input type="hidden" 
                    id={id}
                    name={name}
                />
            </FormControl>
        </React.Fragment>
    }
}

export default withStyles(style)(withTheme(FieldAutocomplete))