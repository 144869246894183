import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {selectFieldsFromList} from '../../@react-fefmwk/arrays/api'
import {nowString} from '../../@react-fefmwk/dates/services/api'
import { entity_fields_info } from '../../@react-fefmwk/entity/services/api'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import DividerAdvanced from '../../@react-fefmwk/pieces/modules/DividerAdvanced'

import {
    stakeholders_get_practicals_outers,
    stakeholders_get_practicals
} from '../../services/BE'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
//import Divider from '@material-ui/core/Divider'

const styles = theme => ({})

class PracticalData extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            practicals_fields_info: {},
            practicals_outers: [],
            practicals: [],
            refresh: false
        }
    }
    async componentDidMount() {
        const formikProps = this.props.formikProps
        let id_stakeholder = formikProps.values.id_stakeholder
        let practicals_outers = await stakeholders_get_practicals_outers(id_stakeholder)
        let practicals = await stakeholders_get_practicals(id_stakeholder)

        let practicals_fields_info = await entity_fields_info('practicals')
        practicals_fields_info = this.filterPracticalsOutersByCurrentIdStakeholder(practicals_fields_info,practicals_outers)
        practicals_fields_info = this.filterPracticalsByCurrentIdStakeholder(practicals_fields_info,practicals)

        this.setState({
            practicals_fields_info: practicals_fields_info,
            practicals_outers: practicals_outers,
            practicals: practicals,
        })

        formikProps.setFieldValue('load_date',nowString())
    }
    async componentDidUpdate(prevProps) {
        let formikProps = this.props.formikProps
        const estinzione = formikProps.values.estinzione
        const prev_estinzione = prevProps.formikProps.values.estinzione
        const refresh = this.state.refresh

        if( estinzione !== prev_estinzione && estinzione === 'no' ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{
                    est_type: '',
                    est_installment_amount: 0,
                    est_installments_n: 0,
                    est_effective_date: '',
                    est_counterpart: '',
                    est_residual_amount: 0,
                }
            },true)
        }

        if( refresh ) {
            let id_stakeholder = formikProps.values.id_stakeholder
            let practicals_outers = await stakeholders_get_practicals_outers(id_stakeholder)
            let practicals = await stakeholders_get_practicals(id_stakeholder)
            let practicals_fields_info = await entity_fields_info('practicals')
            practicals_fields_info = this.filterPracticalsOutersByCurrentIdStakeholder(practicals_fields_info,practicals_outers)
            practicals_fields_info = this.filterPracticalsByCurrentIdStakeholder(practicals_fields_info,practicals)
            this.setState({
                practicals_fields_info: practicals_fields_info,
                refresh: false
            })
        }
    }
    filterPracticalsOutersByCurrentIdStakeholder(practicals_fields_info,practicals_outers) {
        let id_practical_outer = practicals_fields_info.hasOwnProperty('id_practical_outer')
            ? practicals_fields_info.id_practical_outer
            : {}
        if( !Object.keys(id_practical_outer).length ) return practicals_fields_info

        //console.log(practicals_outers)
        //console.log(selectFieldsFromList( practicals_outers,["id_stakeholder","practical_outer"] ))
        practicals_fields_info.id_practical_outer.input_options.items = practicals_outers
        return practicals_fields_info
    }
    filterPracticalsByCurrentIdStakeholder(practicals_fields_info,practicals) {
        let id_practical_est = practicals_fields_info.hasOwnProperty('id_practical_est')
            ? practicals_fields_info.id_practical_est
            : {}
        if( !Object.keys(id_practical_est).length ) return practicals_fields_info

        //console.log(practicals)
        //console.log(selectFieldsFromList( practicals_outers,["id_stakeholder","practical_outer"] ))
        practicals_fields_info.id_practical_est.input_options.items = practicals
        return practicals_fields_info
    }
    render() {
        let formikProps = this.props.formikProps
        const entity = 'practicals'
        const refresh = this.state.refresh
        const practicals_fields_info = this.state.practicals_fields_info
        if( Object.keys(practicals_fields_info).length === 0 )
            return <LoaderSingleComponent width="100%" />

        const estinzione = formikProps.values.estinzione
    
        const marginDivider = 1
        const fields_row1 = {
            id_agent: practicals_fields_info.id_agent,
            id_practical_origination: practicals_fields_info.id_practical_origination,
            load_date: practicals_fields_info.load_date,
        }
        const fields_row1a = {
            id_stakeholder: practicals_fields_info.id_stakeholder,
            hire_date: practicals_fields_info.hire_date,
        }
        let fields_row2 = {
            id_product: practicals_fields_info.id_product,
            id_lender: practicals_fields_info.id_lender,
            effective_date: practicals_fields_info.effective_date,
            disbursed_amount: practicals_fields_info.disbursed_amount,
            installments_n: practicals_fields_info.installments_n,
            installment_amount: practicals_fields_info.installment_amount,
            //commission: practicals_fields_info.commission,
            tan: practicals_fields_info.tan,
            taeg: practicals_fields_info.taeg,
        }
        if( practicals_fields_info.hasOwnProperty('commission') ) {
            fields_row2['commission'] = practicals_fields_info.commission
        }
        const fields_row3 = {
            estinzione: practicals_fields_info.estinzione,
            id_practical_est: {
                ...practicals_fields_info.id_practical_est,
                ...{disabled: estinzione === 'si' ? false : true}
            },
            id_practical_outer: {
                ...practicals_fields_info.id_practical_outer,
                ...{disabled: estinzione === 'si' ? false : true}
            },
        }
        const fields_row4 = {
            notes: practicals_fields_info.notes,
        }

        return refresh
            ? null
            : <React.Fragment>
                <Box>
                    <Grid container spacing={4}>
                        <EntityFormInputs
                            entity={entity}
                            entityFieldsInfo={fields_row1}
                            formikProps={formikProps}
                            onRefreshForm={() => this.setState({refresh:true})}
                        />
                    </Grid>
                </Box>
                <DividerAdvanced spacing={2} mt={marginDivider} mb={marginDivider}>
                    <Chip label="Info cliente" size="small" />
                </DividerAdvanced>
                <Box>
                    <Grid container spacing={4}>
                        <EntityFormInputs
                            entity={entity}
                            entityFieldsInfo={fields_row1a}
                            formikProps={formikProps}
                            onRefreshForm={() => this.setState({refresh:true})}
                        />
                    </Grid>
                </Box>
                <DividerAdvanced spacing={2} mt={marginDivider} mb={marginDivider}>
                    <Chip label="Dati pratica" size="small" />
                </DividerAdvanced>
                <Box>
                    <Grid container spacing={4}>
                        <EntityFormInputs
                            entity={entity}
                            entityFieldsInfo={fields_row2}
                            formikProps={formikProps}
                            onRefreshForm={() => this.setState({refresh:true})}
                        />
                    </Grid>
                </Box>
                <DividerAdvanced spacing={2} mt={marginDivider} mb={marginDivider}>
                    <Chip label="Estinzione pratica pre-esistente (agenzia / esterna)" size="small" />
                </DividerAdvanced>
                <Grid container spacing={4}>
                    <EntityFormInputs
                        entity={entity}
                        entityFieldsInfo={fields_row3}
                        formikProps={formikProps}
                        onRefreshForm={() => this.setState({refresh:true})}
                    />
                </Grid>
                <DividerAdvanced spacing={2} mt={marginDivider} mb={marginDivider}>
                    <Chip label="Note" size="small" />
                </DividerAdvanced>
                <Grid container direction="column" spacing={4}>
                    <EntityFormInputs
                        entity={entity}
                        entityFieldsInfo={fields_row4}
                        formikProps={formikProps}
                        onRefreshForm={() => this.setState({refresh:true})}
                        entityInputProps={{
                            notes: {
                                rows: 3,
                            },
                        }}
                    />
                </Grid>
            </React.Fragment>
    }
}

export default withStyles(styles)(PracticalData)