import {now} from '../../dates/services/api'

export function setSeries( data,dataOptions = {} ) {
    //set defaults
    const first_item = data[0]
    if( !Object.keys(first_item).length ) return []
    const keys = Object.keys(first_item)
    const xField = (dataOptions.hasOwnProperty('xField') && dataOptions.xField) ? dataOptions.xField : keys[0]
    const nFields = keys.length
    const type = dataOptions.hasOwnProperty('type') && dataOptions.type ? dataOptions.type : 'line'
    const data_type = dataOptions.hasOwnProperty('data_type') && dataOptions.data_type ? dataOptions.data_type : ''
    const name = dataOptions.hasOwnProperty('name') && dataOptions.name ? dataOptions.name : 'default'
    const visible = dataOptions.hasOwnProperty('visible') ? dataOptions.visible : true
    const index = dataOptions.hasOwnProperty('index') ? dataOptions.index : 0
    const zIndex = dataOptions.hasOwnProperty('zIndex') ? dataOptions.zIndex : 0
    const yAxis = dataOptions.hasOwnProperty('yAxis') ? dataOptions.yAxis : 0

    let categories = []
    let series = []
    let x = []
    let y = []

    //loop over rows
    data.map((row,r) => {
        const fields = Object.keys(row)

        //X FIELD
        fields.forEach((fld,f) => {
            if( fld === xField ) {
                let val = row[fld]

                //prepare dataInput
                let _type = typeof type === 'object' && type.hasOwnProperty(fld) ? type[fld] : type
                let _data_type = typeof data_type === 'object' && data_type.hasOwnProperty(fld) ? data_type[fld] : data_type

                //fill xAxis and format xAxis values bsed on data_type
                x[r] = setDataType( val,_data_type )

                //fill categories based on chart type
                switch( _type ) {
                    case "bar":
                    case "column":
                    case "waterfall":
                        categories[r] = String( val )
                    break
                }
            }
        })

        //OTHER FIELDS
        fields.forEach((fld,f) => {
            if( fld !== xField ) {
                let val = row[fld]
    
                //prepare dataInput
                let _type = typeof type === 'object' && type.hasOwnProperty(fld) ? type[fld] : type
                let _data_type = typeof data_type === 'object' && data_type.hasOwnProperty(fld) ? data_type[fld] : data_type
                let _name = typeof name === 'object' && name.hasOwnProperty(fld) ? name[fld] : name
                let _visible = typeof visible === 'object' && visible.hasOwnProperty(fld) ? visible[fld] : visible
                let _index = typeof index === 'object' && index.hasOwnProperty(fld) ? index[fld] : index
                let _zIndex = typeof zIndex === 'object' && zIndex.hasOwnProperty(fld) ? zIndex[fld] : zIndex
                let _yAxis = typeof yAxis === 'object' && yAxis.hasOwnProperty(fld) ? yAxis[fld] : yAxis    

                y = val === null ? val : (_data_type ? setDataType(val,_data_type) : parseFloat(val))

                //init series
                if( !series.hasOwnProperty(fld) ) series[fld] = {}
                if( !series[fld].hasOwnProperty('data') ) series[fld]['data'] = []
                if( !series[fld].hasOwnProperty('name') ) series[fld]['name'] = _name === 'default' ? fld : _name
                if( !series[fld].hasOwnProperty('visible') ) series[fld]['visible'] = _visible
                if( !series[fld].hasOwnProperty('type') ) series[fld]['type'] = _type
                if( !series[fld].hasOwnProperty('index') ) series[fld]['index'] = _index
                if( !series[fld].hasOwnProperty('zIndex') ) series[fld]['zIndex'] = _zIndex
                if( !series[fld].hasOwnProperty('yAxis') ) series[fld]['yAxis'] = _yAxis

                //fill data
                series[fld]['data'][r] = xField !== false
                    ? [x[r],y]
                    : [y]
            }
        })
    })

    return {
        series: Object.values(series),
        categories: categories
    }
}

function setDataType( val,data_type ) {
    if( val === null || val === undefined ) return null

    switch( data_type ) {
        case "date":
        case "time":
        case "datetime":
            val = Date.parse(val)//dates must be in milliseconds
        break
        case "float":
        case "double":
            val = parseFloat( val )
        break
        case "int":
        case "integer":
            val = parseInt( val )
        break
        case "string":
            val = String( val )
        break
        default:
            //silence is golden
        break
    }

    return val
}