import React from 'react'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import CardTask from '../modules/CardTask'

class CardTaskPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        return <Content title={title}>
            <CardTask id_entity={id_entity} />
        </Content>
    }
}

export default CardTaskPage