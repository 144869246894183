import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityFormSwitchLegalIdentity from '../@react-fefmwk/entity/modules/EntityFormSwitchLegalIdentity'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

const UpdateAddressPage = (props) => {
    const title = props.route.title
    const id_entity = props.route.computedMatch.params.id
    const queryStrings = props.route.location.search

    const queryStringsObj = queryStringToObj(queryStrings)
    const legal_person = queryStringsObj.hasOwnProperty('id_company') ? true : false
    const showSwitch = (queryStringsObj.hasOwnProperty('id_company') || queryStringsObj.hasOwnProperty('id_people'))
        ? false
        : true

    const onGetData = (entity,id_entity,data) => {
        if(data.hasOwnProperty('is_obscured') && data.is_obscured !== false)
            return {hideForm: true,hideFormMsg:"Non hai i permessi per gestire il form"}
    }

    return <Content title={title}>
        <EntityFormSwitchLegalIdentity entity="addresses" id_entity={id_entity} queryStrings={queryStrings} showSwitch={showSwitch} legal_person={legal_person} onGetData={onGetData} />
    </Content>
}

export default UpdateAddressPage