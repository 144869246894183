import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {companies_get_contacts} from '../../services/BE'

import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import ContactsIcon from '@material-ui/icons/Contacts'

class WidgetCompanyContacts extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contacts: [],
            loading: false
        }
    }
    
    async updateContacts() {
        this.setState({
            contacts: [],
            loading: true
        })
        const id_company = this.props.id_company
        if( id_company )
            this.setState({
                contacts: await companies_get_contacts(id_company),
                loading: false
            })
    }
    
    async componentDidMount() {
        this.updateContacts()
    }
    
    async componentDidUpdate(prevProps) {
        const id_company = this.props.id_company
        const refreshContent = this.props.refreshContent
        if( id_company !== prevProps.id_company || (!refreshContent && refreshContent !== prevProps.refreshContent) ) {
            this.updateContacts()
        }
    }

    render() {
        const id_company = this.props.id_company
        const loading = this.props.loading
        const contacts = this.state.contacts

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const TopActions = this.props.hasOwnProperty('TopActions')
            ? this.props.TopActions
            : null
        const otherActions = this.props.hasOwnProperty('otherActions')
            ? this.props.otherActions
            : []
        const noRowActions = this.props.hasOwnProperty('noRowActions')
            ? this.props.noRowActions
            : false
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Contacts"

        const fieldsProps = tableSchema('contacts')
        let contactsRowActions = noRowActions ? [] : actionSchema('contacts')
        if( contactsRowActions.length ) {
            contactsRowActions.map((act) => {
                if( act.hasOwnProperty('action') && act.action === 'edit' && act.hasOwnProperty('link') ) {
                    act.link += '?id_company'
                }
            })
        }

        let cardProps = {...this.props}
        cardProps['Content'] = contacts.length
            ? (loading
                ? <LoaderSingleComponent width="100%" />
                : <Table material dense
                    //hideHeaders
                    filterable={false}
                    defaultPageSize="100"
                    data={contacts}
                    fieldsProps={fieldsProps}
                    rowActions={contactsRowActions}
                    rowActionsField="id_contact"
                />)
            : <Chip icon={<ErrorIcon />} label="Nessun altro contatto trovato" variant="outlined" />

        if( dashboardcardActions )
            cardProps['TopActions'] = TopActions
                ? TopActions
                : <React.Fragment>
                    <Button
                        variant="text" color="primary" size="small"
                        startIcon={<ContactsIcon fontSize="small" />}
                        component={AdapterLink}
                        to={"/contacts/add?id_contact_use=2&id_company="+id_company}
                    >Nuovo</Button>&nbsp;&nbsp;
                    {[...otherActions]}
                </React.Fragment>

        return <DashboardCard title={dashboardcardTitle} {...cardProps} />
    }
}

export default WidgetCompanyContacts