import React from 'react'

//import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
//import Button from '@material-ui/core/Button'
import PaymentIcon from '@material-ui/icons/Payment'

class FieldIdInsuranceQuotation extends React.Component {
    render() {
        const id_insurance_quotation = this.props.id_insurance_quotation
        const title = this.props.title ? this.props.title : "Quotazione ATC"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Quotazione ATC"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_insurance_quotation || id_insurance_quotation <= 0 || id_insurance_quotation === '-'
            ? null
            : <FieldEntityView title={title} icon={PaymentIcon}
                id_entity={id_insurance_quotation} entity="insurances_quotations"
                //fields={['id_insurance_quotation','insurance_quotation',]}
                /*actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/insurances_quotations/card/'+id_insurance_quotation} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}*/
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdInsuranceQuotation