
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { entity_fields_info } from '../../@react-fefmwk/entity/services/api'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import Grid from '@material-ui/core/Grid'

const styles = theme => ({})

class ReportData extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reports_fields_info: {},
        }
    }
    async componentDidMount() {
        let reports_fields_info = await entity_fields_info('reports')

        this.setState({
            reports_fields_info: reports_fields_info,
        })
    }
    render() {
        let formikProps = this.props.formikProps
        const entity = 'reports'
        const reports_fields_info = this.state.reports_fields_info
        if( Object.keys(reports_fields_info).length === 0 )
            return <LoaderSingleComponent width="100%" />

        const id_report_target = formikProps.values.id_report_target

        let fields_row1 = {
            id_report_type: reports_fields_info.id_report_type,
            report_date: reports_fields_info.report_date,
        }
        if( id_report_target === 'id_activity' ) fields_row1['report_feedback'] = reports_fields_info.report_feedback

        const fields_row2 = {
            report_title: reports_fields_info.report_title
        }
        const fields_row3 = {
            report_content: reports_fields_info.report_content
        }
        const fields_row4 = {
            id_user: reports_fields_info.id_user,
        }

        const entityInputProps = {
            report_title: {
                style:{width:'100%',}
            },
            report_content: {
                style:{width:'100%',},
                rows: 15,
            },
        }

        return <React.Fragment>
            <Grid container spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row1}
                    formikProps={formikProps}
                />
            </Grid>
            <Grid container direction="column" spacing={4} >
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row2}
                    entityInputProps={entityInputProps}
                    formikProps={formikProps}
                />
            </Grid>
            <Grid container direction="column" spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row3}
                    entityInputProps={entityInputProps}
                    formikProps={formikProps}
                />
            </Grid>
            <Grid container direction="column" spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row4}
                    entityInputProps={entityInputProps}
                    formikProps={formikProps}
                />
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(ReportData)