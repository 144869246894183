import React from 'react'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityTitle from '../@react-fefmwk/entity/modules/EntityTitle'

import CardAtc from '../modules/CardAtc'

class CardAtcPage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        return <Content title={title} toolbar={<EntityTitle entity="atcs" id_entity={id_entity} />}>
            <CardAtc id_entity={id_entity} />
        </Content>
    }
}

export default CardAtcPage