import React from 'react'
import { withTheme } from '@material-ui/styles'

import { Tooltip } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

class FieldIsObscured extends React.Component {
    render() {
        const is_obscured = this.props.is_obscured
        const theme = this.props.theme

        const Visibility = is_obscured ? VisibilityOffIcon : VisibilityIcon
        const style = {
            color: is_obscured
                ? theme.palette.grey[400]
                : theme.palette.grey[600]
        }
        const title = is_obscured ? "non accessibile" : "accessibile"

        return <Tooltip title={title} placement="right">
            <Visibility fontSize="small" style={style} />
        </Tooltip>
    }
}

export default withTheme(FieldIsObscured)