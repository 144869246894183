import React from 'react'
import AppRoutes from './@react-fefmwk/app/modules/AppRoutes'
import {agencySchema} from './services/AgencySchema'

class App extends React.Component {
    constructor(props) {
        super(props)

        const agencyObj = agencySchema()
        const assets_path = agencyObj.assets_path

        //set title
        document.title = agencyObj.name

        //set favicon
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = assets_path+"/favicon.ico"

        //set background image
        const backgroundImage = assets_path+"/login-background.png"
        let html = document.querySelector('html')
        html.style.backgroundImage = "url('"+backgroundImage+"')"
    }
    render() {
        return <AppRoutes />
    }
}

export default App