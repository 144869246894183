import React from 'react'
import {isValidUrl} from '../../requests/api'
import Page404 from '../../theme-structure/modules/404'

class IFrame extends React.Component {
    render() {
        let {
            src,
            scrolling,
            ifErrorComp,
            style,
            ...iframeProps
        } = this.props

        scrolling = scrolling === undefined ? "yes" : scrolling
        ifErrorComp = ifErrorComp === undefined ? <Page404 /> : ifErrorComp
        style = style === undefined ? {} : style

        const iframeStyle = {
            ...{
                width: '100%',
                height: '95%',
                border: 'none',
                overflow: 'hidden',
                overflowX: 'hidden',
                overflowY: 'hidden',
            },
            ...style
        }

        return (!src || !isValidUrl(src))
            ? ifErrorComp
            : <iframe {...iframeProps} src={src} scrolling={scrolling} style={iframeStyle} />
    }
}

export default IFrame