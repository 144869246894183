export const headerTokenKey = "TOKEN"

export function setToken( token,localTokenKey = 'AUTH' ) {
    localStorage.setItem( localTokenKey,token )
    return getToken() ? true : false
}
export function getToken( localTokenKey = 'AUTH' ) {
    return localStorage.getItem( localTokenKey )
}
export function delToken( localTokenKey = 'AUTH' ) {
    localStorage.removeItem( localTokenKey )
    return getToken() ? false : true
}
export function decodeToken( token ) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
}
export function usernameFromToken() {
    const token = getToken()
    if( !token ) return 'user.name'
    const payload = decodeToken(token)
    return payload.data.username ? payload.data.username : 'user.name'
}
export function displaynameFromToken() {
    const username = usernameFromToken()
    return username.replace('.',' ')
}
export function idFromToken() {
    const token = getToken()
    if( !token ) return 'id'
    const payload = decodeToken(token)
    return payload.data.ID ? payload.data.ID : 'id'
}
export function groupsFromToken() {
    const token = getToken()
    if( !token ) return []
    const payload = decodeToken(token)
    return payload.data.groups ? payload.data.groups : []
}
export function expiresatFromToken() {
    const token = getToken()
    if( !token ) return []
    const payload = decodeToken(token)
    return payload.hasOwnProperty('exp') ? payload.exp : 0
}