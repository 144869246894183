import React from 'react'

import Paper from '@material-ui/core/Paper'
import LoaderSingleComponent from "../../../loader-app/modules/LoaderSingleComponent"
import Tabber from "../../../tabs/modules/Tabber"
import ViewTimeinfoEntity from './ViewTimeinfoEntity'
import { entity_fields_cfstime } from '../../../entity/services/api'

class TimeinfoEntity extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fields_timeinfo: {},
        }
    }

    async componentDidMount() {
        const entity = this.props.entity
        this.setState({
            fields_timeinfo: await entity_fields_cfstime( entity )
        })
    }

    render() {
        const fields_timeinfo = this.state.fields_timeinfo
        if( !Object.keys(fields_timeinfo) ) return <LoaderSingleComponent width="100%" />

        const entity = this.props.entity
        const id_entity = this.props.id_entity
        const cfstimeinfo = Object.values(fields_timeinfo)
        const tabs = cfstimeinfo.map((cftime) => {
            return {
                slug: cftime.name,
                label: cftime.nice_name,
                content: <ViewTimeinfoEntity
                    title={cftime.nice_name}
                    entity={entity}
                    id_entity={id_entity}
                    cf={cftime.name}
                    cf_nice_name={cftime.nice_name}
                    cf_name={cftime.id_cf_name}
                    cf_id={cftime[cftime.id_cf_name]}
                />
            }
        })
        if( !tabs.length ) return <LoaderSingleComponent/>

        return <React.Fragment>
            <Paper>
                <Tabber basicname="timeinfos" orientation="vertical"
                    updateUrl={false}
                    tabs={tabs}
                />
            </Paper>
        </React.Fragment>
    }
}

export default TimeinfoEntity