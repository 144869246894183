import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'

class FieldIdUser extends React.Component {
    render() {
        const id_user = this.props.id_user
        const title = this.props.title ? this.props.title : "Scheda utente"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda utente"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_user || id_user <= 0 || id_user === '-'
            ? null
            : <FieldEntityView title={title} icon={PersonIcon}
                id_entity={id_user} entity="users"
                fields={['id_user','display_name','user_email']}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/users/view/'+id_user} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdUser