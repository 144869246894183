export function diffDates(ref,msg,threshold,operator) {
    return this.test({
        name: 'diffDates',
        exclusive: false,
        message: msg || '${path} not correct for ${reference} choice',
        params: {
            reference: ref.path
        },
        test: function(value) {
            const threshold_date = this.resolve(ref)
            const threshold_dt = new Date(threshold_date);
            const dt = new Date(value);
            // To calculate the time difference of two dates
            const diff = dt.getTime() - threshold_dt.getTime();            
            // To calculate the no. of days between two dates
            var diffdays = diff / (1000 * 3600 * 24);
            switch(operator) {
                default:
                case '<=':
                    return diffdays <= threshold
                case '<':
                    return diffdays < threshold
                case '>=':
                    return diffdays >= threshold
                case '>':
                    return diffdays > threshold
            }
        }
    })
}
export function isFutureDate(ref,msg) {
	return this.test({
		name: 'isFutureDate',
		exclusive: false,
        message: msg || '${path} cannot be future date',
		params: {
			reference: ref.path
		},
		test: function(value) {
            return new Date(value) <= new Date()
		}
	})
}
export function isEqualTo(ref,msg) {
	return this.test({
		name: 'isEqualTo',
		exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
            return value === this.resolve(ref)
		}
	})
}
export function requiredIf(ref,msg,options) {
	return this.test({
		name: 'requiredIf',
		exclusive: false,
        message: msg || '${path} not correct for ${reference} choice',
		params: {
			reference: ref.path
		},
		test: function(value) {
            const id_ref_value = this.resolve(ref)
            if( options.includes(id_ref_value) )
                return value ? true : false
            else
                return true
		}
	})
}
/**
 * La funzione permette di validare un campo (field) sulla base dei valori di un altro campo di riferimento (ref_field).
 * Esempio:
 * Yup.object().shape({
 *    field: Yup.string()
 *        .validateIf(
 *            Yup.ref('ref_field'),
 *            {//msg: {<ref_field>: [custom msg foreach ref_field]}
 *                1: 'Value must be a valid IBAN code',
 *                2: 'Withholding tax must be a valid number',
 *                3: 'You can choose: tax | advanced',
 *            },
 *            {//test_f: {<ref_field>: [list of values foreach ref_field]}
 *                1: (value) => {
 *                    const _match = String(value).match("IBAN-REGEX")
 *                    return _match === null ? false : true
 *                },
 *                2: (value) => {
 *                    return !isNaN(String(value)) && !isNaN(parseFloat(String(value))) ? true : false
 *                },
 *                3: (value) => ['tax','advanced'].includes(String(value))
 *            }
 *        )
 *        .required('Required'),
 * })
 * 
 * @param {*} ref 
 * @param {*} msg 
 * @param {*} options 
 * @returns 
 */

export function validateIf(ref,msg,options) {
	return this.test({
		name: 'validateIf',
		exclusive: false,
        //message: String(msg),
		params: {
			reference: ref.path
		},
		test: function(value) {
            const id_ref_value = this.resolve(ref)
            const test_f = options.hasOwnProperty(id_ref_value) ? options[id_ref_value] : (() => true)
            if( typeof test_f !== 'function' ) return true//if not valid test_f return always true
            //prepare error obj
            const errorMsg = {
                path: this.path,
                message: (typeof msg === 'object' && msg !== null)
                    ? (msg.hasOwnProperty(id_ref_value) ? String(msg[id_ref_value]) : '${path} not correct for ${reference} choice')
                    : String(msg)
            }
            //return test result
            return test_f(value)
                ? true
                : this.createError(errorMsg)
		}
	})
}