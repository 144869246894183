import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles,withTheme } from '@material-ui/core/styles'

import Header from '../Header'
import Footer from '../Footer'

import '../theme.css'

const StandardPageNoNavSideMenuStyle = theme => ({
    app: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    appHeaderBar: theme.globals.appHeaderBar,
    appBody: {
        ...theme.globals.appBody,
        ...{
            flex: "1 auto",
            height: 0,
            //overflowY:"auto"
        },
    },
    monoBody: {
        width: '100%',
        height: '100%',
        //overflowY: 'auto'
    },
})

class StandardPageNoNavSideMenu extends React.Component {
    render() {
        const { classes,theme } = this.props

        const NavTopTitle = theme.hasOwnProperty('navtop_title') ? theme.navtop_title : ""
        const NavTopImgPath = theme.hasOwnProperty('navtop_logo') ? theme.navtop_logo : ""
        const ShowUsername = theme.hasOwnProperty('navtop_showusername') ? theme.navtop_showusername : ""
        const homeUrl = theme.hasOwnProperty('homeUrl') ? theme.homeUrl : false

        return <div id="App" className={classes.app}>
            <Header className={classes.appHeaderBar}
                showAppLogo={true}
                navTopTitle={NavTopTitle}
                NavTopImgPath={NavTopImgPath}
                showUsername={ShowUsername}
                showUserDivider={true}
                homeUrl={homeUrl}
            />
            <div id="AppBody" className={classes.appBody}>
                <div id="Mono" className={classes.monoBody}>
                    {this.props.children}
                </div>
            </div>
            <Footer itemXs={4} textAlign="center" />
        </div>
    }
}

export default withRouter(withStyles(StandardPageNoNavSideMenuStyle)(withTheme(StandardPageNoNavSideMenu)))