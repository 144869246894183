import React from 'react'

import Formatter from '../@react-fefmwk/formatter/modules/Formatter'
import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import {hasOwnPath} from '../@react-fefmwk/arrays/api'
import {idFromToken,groupsFromToken} from '../@react-fefmwk/auth/services/token'

import { agenciesBranchesFromToken } from './AgencySchema'

import FieldIdContactType from '../modules/fields/FieldIdContactType'
import FieldIdPeople from '../modules/fields/FieldIdPeople'
import FieldIdCompany from '../modules/fields/FieldIdCompany'
import FieldIdStakeholder from '../modules/fields/FieldIdStakeholder'
import FieldIdAtc from '../modules/fields/FieldIdAtc'
import FieldIdInsurance from '../modules/fields/FieldIdInsurance'
import FieldIdInsuranceQuotation from '../modules/fields/FieldIdInsuranceQuotation'
import FieldIdAgent from '../modules/fields/FieldIdAgent'
import FieldIdUser from '../modules/fields/FieldIdUser'
import FieldIdProduct from '../modules/fields/FieldIdProduct'
import FieldIdLender from '../modules/fields/FieldIdLender'
//import FieldReported from '../modules/fields/FieldReported'
import FieldIdPractical from '../modules/fields/FieldIdPractical'
import FieldIdPracticalOuter from '../modules/fields/FieldIdPracticalOuter'
import FieldIdLead from '../modules/fields/FieldIdLead'
import FieldExternalUrl from '../modules/fields/FieldExternalUrl'
import FieldLeadPreview from '../modules/fields/FieldLeadPreview'
import FieldIdReportType from '../modules/fields/FieldIdReportType'
import FieldLongText from '../modules/fields/FieldLongText'
import FieldTaskState from '../modules/fields/FieldTaskState'
import FieldPracticalState from '../modules/fields/FieldPracticalState'
import FieldLeadState from '../modules/fields/FieldLeadState'
import FieldReportFeedback from '../modules/fields/FieldReportFeedback'
import FieldIdActivity from '../modules/fields/FieldIdActivity'
import FieldIdReport from '../modules/fields/FieldIdReport'
import FieldMultiLineCell from '../modules/fields/FieldMultiLineCell'
import FieldIsObscured from '../modules/fields/FieldIsObscured'
import FieldBoolean from '../modules/fields/FieldBoolean'
import FieldReportsN from '../modules/fields/FieldReportsN'

import WidgetLeadPreview from '../modules/widgets/WidgetLeadPreview'

import { IconButton,Tooltip,Chip,Box } from '@material-ui/core'

import LockIcon from '@material-ui/icons/Lock'
import AddAlarmIcon from '@material-ui/icons/AddAlarm'

export function tableSchema( entity ) {
    const current_id_user = idFromToken()
    const current_groups_ids = groupsFromToken()
    const current_agency_branches = agenciesBranchesFromToken()

    switch (entity) {
        case 'users':
            return {
                id_user: {show: false},
                groups_ids: {show: false},
                agencies_branches_ids: {show: false},
                user_pass: {
                    Header: "Password",
                    Cell: (props) => {
                        return <LockIcon color="secondary" />
                    },
                    filterable: false,
                },
            }
        case 'contacts':
        case 'addresses':
            return {
                id_address: {
                    Header: "ID",
                    nice_name: "ID indirizzo",
                    width:75,
                },
                id_contact: {
                    Header: "ID",
                    nice_name: "ID contatto",
                    width:75,
                },
                id_people: {
                    Header: "Persona",
                    nice_name: "Persona",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Visualizza persona"
                        return <FieldIdPeople id_people={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                id_company: {
                    nice_name: "Azienda",
                    Header: "Azienda",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Visualizza azienda"
                        return <FieldIdCompany id_company={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                id_contact_type: {
                    Header: "Type",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIdContactType id_contact_type={value} />
                    },
                    filterable: true,
                },
                contact_type: {
                    Header: "Tipo contatto",
                    nice_name: "Tipo contatto",
                    width: 150,
                },
                address_use: {
                    Header: "Tipo indirizzo",
                    nice_name: "Tipo indirizzo",
                    width: 150,
                },
                contact_use: {
                    Header: "Uso contatto",
                    nice_name: "Uso contatto",
                    width: 150,
                },
                address: {
                    Header: "Indirizzo",
                    nice_name: "Indirizzo",
                },
                zip_code: {
                    Header: "CAP",
                    nice_name: "CAP",
                    width: 100,
                },
                city: {
                    Header: "Città",
                    nice_name: "Città",
                },
                address_notes: {
                    nice_name: "Note",
                    Header: "Note",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Note"
                        return <FieldLongText title={title} text={value} maxChars={0} />
                    },
                    width: 120
                },
                contact_notes: {
                    nice_name: "Note",
                    Header: "Note",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Note"
                        return <FieldLongText title={title} text={value} maxChars={0} ifEmpty={null} />
                    },
                    width: 120
                },
                recent_agents: {
                    nice_name: "Seguito da",
                    Header: "Seguito da",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                    width:300
                },
                agencies_branches: {
                    nice_name: "Sedi competenza",
                    Header: "Sedi",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                is_managed: {
                    nice_name: "Gestito",
                    Header: "Gestito",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                },
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false
                },
            }
        case 'people':
            return {
                practical_origination: {nice_name: "Origine contatto",Header: "Origine contatto"},
                people_notes: {
                    nice_name: "Note",
                    Header: "Note",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Note"
                        return <FieldLongText title={title} text={value} />
                    },
                },
                recent_agents: {
                    nice_name: "Seguito da",
                    Header: "Seguito da",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                    width:300
                },
                agencies_branches: {
                    nice_name: "Sedi competenza",
                    Header: "Sedi",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                last_practical_at: {Header: "Ultima decorrenza",nice_name: "Decorrenza pratica più recente"},
                products: {
                    nice_name: "Prodotti recenti",
                    Header: "Prodotti recenti",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                is_managed: {
                    nice_name: "Gestito",
                    Header: "Gestito",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                },
                is_customer: {
                    nice_name: "Cliente",
                    Header: "Cliente",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                },
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false
                },
            }
        case 'companies':
            return {
                vat_number: {Header: "PI",nice_name: "Partita IVA",width:150},
                cf: {Header: "CF",nice_name: "Codice fiscale",width:150},
                recent_agents: {
                    nice_name: "Seguito da",
                    Header: "Seguito da",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                    width:300
                },
                agencies_branches: {
                    nice_name: "Sedi competenza",
                    Header: "Sedi",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                last_practical_at: {Header: "Ultima decorrenza",nice_name: "Decorrenza pratica più recente"},
                products: {
                    nice_name: "Prodotti recenti",
                    Header: "Prodotti recenti",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                is_managed: {
                    nice_name: "Gestito",
                    Header: "Gestito",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                    width:100
                },
                is_customer: {
                    nice_name: "Cliente",
                    Header: "Cliente",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                    width:100
                },
                is_atc: {
                    nice_name: "ATC",
                    Header: "ATC",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                    width:100
                },
                is_insurance: {
                    nice_name: "Assicurazione",
                    Header: "Assic.",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                    width:100
                },
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false
                },
            }
        case 'customers':
        case 'stakeholders':
            return {
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false
                },
                id_people: {
                    nice_name: "Persona",
                    Header: "Persona",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Visualizza contatto"
                        return <FieldIdPeople id_people={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:100
                },
                id_company: {
                    nice_name: "Azienda",
                    Header: "Azienda",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Visualizza azienda"
                        return <FieldIdCompany id_company={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                id_agent: {
                    nice_name: "Scheda collaboratore",
                    Header: "Sch.Collab",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda collaboratore"
                        return <FieldIdAgent id_agent={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                agent: {nice_name: "Seguito da",Header: "Seguito da"},
                recent_agents: {
                    nice_name: "Seguito da",
                    Header: "Seguito da",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                    width:300
                },
                agencies_branches: {
                    nice_name: "Sedi competenza",
                    Header: "Sedi",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                last_practical_at: {Header: "Ultima decorrenza",nice_name: "Decorrenza pratica più recente"},
                products: {
                    nice_name: "Prodotti recenti",
                    Header: "Prodotti recenti",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                practicals_outers_n: {
                    nice_name: "N. di pratiche altri istituti",
                    Header: "# pratiche esterne",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                practicals_n: {
                    nice_name: "# pratiche totali",
                    Header: "# tot. pratiche",
                    Cell: (props) => {
                        const value=props.value
                        const id_stakeholder = hasOwnPath(props,'row.id_stakeholder') ? props.row.id_stakeholder : 0
                        const to = id_stakeholder ? (entity+"/card/"+id_stakeholder+"#practicals") : ""
                        const LinkComp = to !== false && value > 0
                            ? AdapterLink
                            : Box
                        const clickableProp = to !== false && value > 0
                            ? {clickable:true}
                            : {}
                        return <LinkComp to={to} target="_blank">
                            <Tooltip title={value > 0 ? "Mostra tutte le pratiche" : "Nessuna pratica da mostrare"} placement="right">
                                <Chip label={value} {...clickableProp} size="small" color={value > 0 ? "secondary" : "default"} />
                            </Tooltip>
                        </LinkComp>
                    },
                },
                id_stakeholder: {Header: "Id contatto",nice_name: "Id contatto"},
                stakeholder: {Header: "Contatto",nice_name: "Contatto"},
                main_mobile: {Header: "Telefono",nice_name: "Telefono"},
                first_name: {Header: "Nome",nice_name: "Nome"},
                last_name: {Header: "Cognome",nice_name: "Cognome"},
                company_name: {Header: "Nome azienda",nice_name: "Nome azienda"},
                vat_number: {Header: "Partita IVA",nice_name: "Partita IVA"},
            }
        case 'practicals_lenders':
            return {
                lender: {nice_name: "Istituto di credito",Header: "Finanziaria"},
                practicals_n: {
                    nice_name: "N. di pratiche",
                    Header: "# pratiche agenzia",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                practicals_outers_n: {
                    nice_name: "N. di pratiche altri istituti",
                    Header: "# pratiche esterne",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
            }
        break
        case 'practicals':
        case 'practicals_outers':
            return {
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false,
                },
                id_practical: {nice_name: "Id pratica",Header: "Id pratica"},
                id_practical_outer: {nice_name: "Id pratica",Header: "Id pratica"},
                practical_code: {nice_name: "Codice pratica",Header: "Cod.",width:100},
                practical_outer_code: {nice_name: "Codice pratica",Header: "Cod.",width:100},
                practical_desc: {nice_name: "Descrizione",Header: "Descrizione",show:false},
                practical_outer_desc: {nice_name: "Descrizione",Header: "Descrizione",show:false},
                practical_outer: {nice_name: "Descrizione",Header: "Descrizione",show:false},
                id_stakeholder: {
                    nice_name: "Scheda cliente",
                    Header: "Sch.Cliente",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda cliente"
                        return <FieldIdStakeholder id_stakeholder={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:75
                },
                stakeholder: {nice_name: "Nome cliente",Header: "Nome cliente"},
                id_lender: {
                    nice_name: "Scheda istituto creditore",
                    Header: "Erogato da",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda istituto creditore"
                        return <FieldIdLender id_lender={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                lender: {nice_name: "Erogata da",Header: "Erogata da"},
                estinzione: {nice_name: "Estinzione",Header: "Estinz.",width: 75},
                id_agent: {
                    nice_name: "Scheda collaboratore",
                    Header: "Sch.Collab",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda collaboratore"
                        return <FieldIdAgent id_agent={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                agent: {nice_name: "Nome collaboratore",Header: "Collaboratore"},
                agencies_branches: {
                    nice_name: "Sede competenza",
                    Header: "Sede",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                id_user: {
                    nice_name: "Inserita da",
                    Header: "Inserita da",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda operatore"
                        return <FieldIdUser id_user={value} title={title} />
                    },
                    filterable: true,
                },
                user: {nice_name: "Inserita da",Header: "Inserita da"},
                id_product: {
                    nice_name: "Scheda prodotto",
                    Header: "Sch.Prod",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda prodotto"
                        return <FieldIdProduct id_product={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:100
                },
                id_lender: {
                    nice_name: "Scheda istituto creditore",
                    Header: "Erogato da",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda istituto creditore"
                        return <FieldIdLender id_lender={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                lender: {nice_name: "Erogata da",Header: "Erogata da"},
                id_lead: {
                    nice_name: "Scheda lead",
                    Header: "Sch.Lead",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda lead"
                        return <FieldIdLead id_lead={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                id_atc: {
                    nice_name: "Scheda ATC",
                    Header: "Sch.ATC",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda ATC"
                        return <FieldIdAtc id_atc={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:75
                },
                atc: {nice_name: "Nome ATC",Header: "Nome ATC"},
                id_insurance: {
                    nice_name: "Scheda assicurazione",
                    Header: "Sch.Assic",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda assicurazione"
                        return <FieldIdInsurance id_insurance={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                insurance: {nice_name: "Nome assicurazione",Header: "Assicurazione"},
                id_insurance_quotation: {
                    nice_name: "Scgeda quotazione ATC",
                    Header: "Sch.Quot",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda quotazione ATC"
                        return <FieldIdInsuranceQuotation id_insurance_quotation={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                insurance_quotation: {nice_name: "Quotazione ATC",Header: "Quotazione ATC"},
                id_practical_origination: {nice_name: "Id sorgente",Header: "Id sorgente",show: false},
                origination: {nice_name: "Sorgente",Header: "Sorgente"},
                product: {nice_name: "Prodotto",Header: "Prodotto"},
                product_code: {nice_name: "Codice prodotto",Header: "Prod.",width:75},
                residual_amount: {
                    nice_name: "Debito residuo",
                    Header: "Residuo",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                disbursed_amount: {
                    nice_name: "Erogato",
                    Header: "Erogato",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                compounding_amount: {
                    nice_name: "Montante lordo",
                    Header: "Montante lordo",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                load_date: {nice_name: "Data caricamento",Header: "Data caricamento"},
                effective_date: {nice_name: "Decorrenza",Header: "Decorrenza"},
                renewal_date: {nice_name: "Primo rinnovo",Header: "1° rinnovo"},
                renewal_date_second: {nice_name: "Secondo rinnovo",Header: "2° rinnovo"},
                practical_state: {
                    nice_name: "Stato",
                    Header: "Stato",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldPracticalState state={value} />
                    },
                },
                id_practical_state: {nice_name: "Id stato",Header: "Id stato"},
                priority: {nice_name: "Pos. timeline",Header: "Pos. timeline"},
                in_timeline: {nice_name: "In timeline",Header: "In timeline"},
                id_activity: {
                    nice_name: "Ultimo evento",
                    Header: "Ultimo evento",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda evento"
                        return <FieldIdActivity id_activity={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                id_activity_order: {nice_name: "Id ordine",Header: "Id ordine"},
                installments_n: {nice_name: "N. rate",Header: "N. rate",width:80},
                installment_amount: {
                    nice_name: "Importo rata",
                    Header: "Rata",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                est_installment_amount: {
                    nice_name: "Importo rata in estinzione",
                    Header: "Importo rata in estinzione",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                est_residual_amount: {
                    nice_name: "Debito residuo",
                    Header: "Debito residuo",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                est_type: {nice_name: "Tipo impegno",Header: "Tipo impegno"},
                est_installments_n: {nice_name: "N. rate in estinzione",Header: "N. rate in estinzione"},
                est_effective_date: {nice_name: "Decorrenza impegno",Header: "Decorrenza impegno"},
                est_counterpart: {nice_name: "Finanziaria da estinguere",Header: "Finanziaria da estinguere"},
                commission: {
                    nice_name: "Commissioni",
                    Header: "Commissioni",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                net_commission: {
                    nice_name: "Commissione netta",
                    Header: "Netta €",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                    show: (current_groups_ids.includes(String(1)) || current_groups_ids.includes(String(2)))
                        ? true
                        : false
                },
                id_practical_commission: {show:false},
                gross_commission: {
                    nice_name: "Commissione lorda",
                    Header: "Lorda €",
                    show: (current_groups_ids.includes(String(1)) || current_groups_ids.includes(String(2)))
                        ? true
                        : false
                },
                tan: {
                    nice_name: "TAN",
                    Header: "TAN",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="percent100"
                            currency=""
                            decimalDigits="1"
                            exponent="0"
                        />
                    },
                },
                taeg: {
                    nice_name: "TAEG",
                    Header: "TAEG",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="percent100"
                            currency=""
                            decimalDigits="1"
                            exponent="0"
                        />
                    },
                },
                notes: {
                    nice_name: "Note",
                    Header: "Note",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Note"
                        return <FieldLongText title={title} text={value} />
                    },
                    width: 300
                },
                created_at: {Header: "Inserita il",nice_name: "Inserita il"},
                updated_at: {Header: "Aggiornata il",nice_name: "Aggiornata il"},
            }
        case 'products':
            return {
                product: {Header: "Prodotto",},
                product_code: {Header: "Codice prodotto",},
            }
        case 'insurances':
        case 'atcs':
            return {
                id_company: {
                    nice_name: "Scheda azienda",
                    Header: "Sch.Azienda",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda azienda"
                        return <FieldIdCompany id_company={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                vat_number: {Header: "PI",nice_name: "Partita IVA"},
                cf: {Header: "CF",nice_name: "Codice fiscale"},
                insurance: {nice_name: "Assicurazione",Header: "Assicurazione"},
                practicals_outers_n: {
                    nice_name: "N. di pratiche altri istituti",
                    Header: "# pratiche esterne",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                practicals_n: {
                    nice_name: "N. di pratiche",
                    Header: "# pratiche",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                quotations_n: {
                    nice_name: "N. di quotazioni",
                    Header: "# quotazioni",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                atcs_n: {
                    nice_name: "N. di ATC quotate",
                    Header: "# ATC",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                insurances_n: {
                    nice_name: "N. di assicurazioni",
                    Header: "# assicurazioni",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                atc: {nice_name: "ATC",Header: "ATC"},
                atc_type: {nice_name: "Tipo di ATC",Header: "Tipo ATC"},
                is_lender: {
                    nice_name: "è anche finanziaria",
                    Header: "è finanziaria",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldBoolean value={value} />
                    },
                    filterable: true,
                },
                atc_notes: {
                    nice_name: "Note",
                    Header: "Note",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Note"
                        return <FieldLongText title={title} text={value} />
                    },
                    width: 120
                },
            }
        case 'insurances_quotations':
        case 'insurances_quotations.choose':
            return {
                multiplier: {
                    nice_name: "Moltiplicatore",
                    Header: "Moltip.",
                    width:100
                },
                quotation_notes: {
                    nice_name: "Note quotazione",
                    Header: "Note"
                },
                id_atc: {
                    nice_name: "Scheda ATC",
                    Header: "Sch.ATC",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda ATC"
                        return <FieldIdAtc id_atc={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                atc: {nice_name: "Nome ATC",Header: "ATC"},
                id_insurance: {
                    nice_name: "Scheda assicurazione",
                    Header: "Sch.Assic",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda assicurazione"
                        return <FieldIdInsurance id_insurance={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:100
                },
                insurance: {nice_name: "Nome assicurazione",Header: "Assicurazione"},
                id_user: {
                    nice_name: "Inserita da",
                    Header: "Inserita da",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda operatore"
                        return <FieldIdUser title={title} id_user={value} />
                    },
                    filterable: true,
                },
                inserted_by: {nice_name: "Inserita da",Header: "Inserita da"},
                created_at: {Header: "Inserita il",nice_name: "Inserita il"},
                updated_at: {Header: "Aggiornata il",nice_name: "Aggiornata il"},
            }
        case 'files':
            return {
                url: {
                    Header: "",
                    nice_name: "Visualizza o scarica",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldExternalUrl url={value} />
                        //return <FieldFileUrl url={value} />
                    },
                    filterable: false,
                    width:100
                },
            }
        case 'campaigns':
            return {
                campaign: {Header: "Nome campagna",},
                id_campaign_type: {Header: "Tipo campagna",},
                leads_generation: {Header: "Genera leads?",},
            }
        case 'leads':
            return {
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false
                },
                agent: {nice_name: "Nome collaboratore",Header: "Collaboratore"},
                id_agent: {
                    nice_name: "Scheda collaboratore",
                    Header: "Sch.Collab",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda collaboratore"
                        return <FieldIdAgent id_agent={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                agencies_branches: {
                    nice_name: "Sede competenza",
                    Header: "Sede",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldMultiLineCell value={value} />
                    },
                    filterable: true,
                },
                lead_state: {
                    nice_name: "Stato",
                    Header: "Stato",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldLeadState state={value} />
                    },
                },
                stakeholder: {nice_name: "Nome contatto",Header: "Nome contatto",},
                id_stakeholder: {
                    nice_name: "Scheda contatto",
                    Header: "Sch.Contatto",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda contatto"
                        return <FieldIdStakeholder id_stakeholder={value} tooltipText={title} />
                    },
                    filterable: true,
                },
                lead_body: {
                    nice_name: "Corpo",
                    Header: "Corpo",
                    Cell: (props) => {
                        const value = props.value
                        if( !props.hasOwnProperty('row') )
                            return <div style={{whiteSpace:'pre-line'}}>{value}</div>

                        const from = props.row.lead_from
                        const to = props.row.lead_to
                        const subject = props.row.lead_subject
                        const date = props.row.lead_datetime
                        return <FieldLeadPreview
                            title={"Anteprima lead"}
                            content={<WidgetLeadPreview
                                accordion={false}
                                body={value}
                                date={date}
                                subject={subject}
                                title={"Anteprima lead"}
                                from={from}
                                to={to}
                            />}
                        />
                    },
                    filterable: true,
                },
                lead_channel: {Header: "Canale",nice_name: "Canale"},
                lead_subject: {Header: "Oggetto",nice_name: "Oggetto"},
                lead_datetime: {Header: "Data inserimento",nice_name: "Data inserimento"},
                lead_sender_name: {Header: "Richiedente",nice_name: "Richiedente"},
                lead_sender_phone: {Header: "Telefono richiedente",nice_name: "Telefono richiedente"},
            }
        case 'leads_channels':
            return {
                lead_channel: {nice_name: "Canale lead",Header: "Canale"},
                leads_n: {
                    nice_name: "N. di leads",
                    Header: "# leads",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                practicals_n: {
                    nice_name: "N. di pratiche",
                    Header: "# pratiche",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                quotations_n: {
                    nice_name: "N. di quotazioni",
                    Header: "# quotazioni",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
                retired_n: {
                    nice_name: "N. ritirate",
                    Header: "# ritirate",
                    Cell: (props) => {
                        const value=props.value
                        return <Chip label={value} size="small" color={value > 0 ? "secondary" : "default"} />
                    },
                },
            }
        case 'reports':
            return {
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false,
                    width:100
                },
                id_report_type: {
                    nice_name: "Tipo report",
                    Header: "Tipo report",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIdReportType id_report_type={value} />
                    },
                    filterable: true,
                    width:80
                },
                report_stakeholder: {Header: "Nome cliente",nice_name: "Nome cliente",width: 200},
                id_report_stakeholder: {
                    nice_name: "Scheda cliente",
                    Header: "Sch.Cliente",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda cliente"
                        return <FieldIdStakeholder id_stakeholder={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                agent: {Header: "Nome collaboratore",nice_name: "Nome collaboratore"},
                id_agent: {
                    nice_name: "Scheda collaboratore",
                    Header: "Sch.Collab",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda collaboratore"
                        return <FieldIdAgent id_agent={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_task_receiver: {
                    nice_name: "Scheda destinatario task",
                    Header: "Sch.Dest",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda destinatario task"
                        return <FieldIdUser title={title} id_user={value} />
                    },
                    filterable: true,
                    width:80
                },
                task_receiver: {
                    Header: "Destinatario",
                    nice_name: "Destinatario",
                    Cell: (props) => {
                        const value=props.value
                        return value === 'None' ? '-' : value
                    },
                },
                id_stakeholder: {
                    nice_name: "Scheda cliente",
                    Header: "Sch.Cliente",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda cliente"
                        return <FieldIdStakeholder id_stakeholder={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_practical: {
                    nice_name: "Scheda pratica",
                    Header: "Sch.Pratica",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda pratica"
                        return <FieldIdPractical id_practical={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_lead: {
                    nice_name: "Scheda lead",
                    Header: "Sch.Lead",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda lead"
                        return <FieldIdLead id_lead={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_activity: {
                    nice_name: "Scheda task",
                    Header: "Sch.Task",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda task"
                        return <FieldIdActivity id_activity={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_user: {
                    nice_name: "Scheda autore",
                    Header: "Sch.Autore",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda firmatario"
                        return <FieldIdUser title={title} id_user={value} />
                    },
                    filterable: true,
                    width:80
                },
                report_author: {Header: "Firma",nice_name: "Firma"},
                report_type: {Header: "Tipo",nice_name: "Tipo"},
                report_date: {Header: "Data",nice_name: "Data"},
                report_title: {Header: "Titolo",nice_name: "Titolo"},
                report_feedback: {
                    Header: "Feedback",
                    nice_name: "Feedback",
                    Cell: (props) => {
                        const value = props.value
                        return <FieldReportFeedback feedback={value} />
                    },
                    width:80
                },
                report_content: {
                    nice_name: "Report",
                    Header: "Report",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Report"
                        return <FieldLongText title={title} text={value} />
                    },
                    width: 300
                },
                id_report_target: {Header: "Target",nice_name: "Target"},
                report_target: {Header: "Target",nice_name: "Target",width:120},
                created_at: {Header: "Creato il",nice_name: "Creato il"},
                updated_at: {Header: "Aggiornato il",nice_name: "Aggiornato il"},
            }
        case 'tasks':
        case 'assignments':
        case 'practicals_histories':
        case 'activities':
            return {
                is_obscured: {
                    nice_name: "Visibile",
                    Header: "Visibile",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldIsObscured is_obscured={value} />
                    },
                    filterable: true,
                    show: false,
                    width:100
                },
                id_task: {nice_name: "Id"},
                id_activity: {nice_name: "Id"},
                activity_stakeholder: {Header: "Nome cliente",nice_name: "Nome cliente",width: 200},
                id_activity_stakeholder: {
                    nice_name: "Scheda cliente",
                    Header: "Sch.Cliente",
                    Cell: (props) => {
                        const value = props.value
                        const title = "Scheda cliente"
                        return <FieldIdStakeholder id_stakeholder={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_renewal: {
                    nice_name: "Gestisci rinnovo",
                    Header: "Rinnovo",
                    Cell: (props) => {
                        const value = props.value
                        const id_practical = props.row.hasOwnProperty('id_practical')
                            ? props.row.id_practical
                            : 0
                        const id_practical_outer = props.row.hasOwnProperty('id_practical_outer')
                            ? props.row.id_practical_outer
                            : 0
                        const id_stakeholder = props.row.hasOwnProperty('id_activity_stakeholder')
                            ? props.row.id_activity_stakeholder
                            : 0
                        const active_at = props.row.hasOwnProperty('active_at')
                            ? props.row.active_at
                            : ''
                        const id_activity_order = props.row.hasOwnProperty('id_activity_order')
                            ? props.row.id_activity_order
                            : 23

                        let link_to = "/tasks/add?id_activity_order="+id_activity_order+"&active_at="+active_at+"&id_agent="+current_id_user
                        if( id_practical > 0 ) link_to += "&id_practical="+id_practical
                        if( id_practical_outer > 0 ) link_to += "&id_stakeholder="+id_stakeholder

                        const title = "Scheda task"
                        return value > 0
                            ? <FieldIdActivity id_activity={value} tooltipText={title} />
                            : <IconButton size="small"
                                component={AdapterLink} to={link_to}
                                disabled={id_practical ? false : true}
                            >
                                <Tooltip title="Segna in agenda" placement="right">
                                    <AddAlarmIcon />
                                </Tooltip>
                            </IconButton>
                    },
                    width: 80,
                    filterable: true,
                },
                renewal_date: {nice_name: "Primo rinnovo",Header: "1° rinnovo"},
                renewal_date_second: {nice_name: "Secondo rinnovo",Header: "2° rinnovo"},
                lender: {nice_name: "Erogata da",Header: "Erogata da"},
                active_at: {Header: "Data",nice_name: "Attivo il"},
                created_at: {Header: "Creato il",nice_name: "Creato il"},
                updated_at: {Header: "Aggiornato il",nice_name: "Aggiornato il"},
                agent: {
                    Header: "Assegnato a",
                    nice_name: "Assegnato a",
                    show: true,
                },
                id_agent: {
                    nice_name: "Assegnato a",
                    Header: "Assegnato a",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda assegnatario"
                        return <FieldIdAgent id_agent={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                display_name: {
                    Header: "Assegnato da",
                    nice_name: "Assegnato da",
                    show: true,
                },
                id_user: {
                    nice_name: "Assegnato da",
                    Header: "Assegnato da",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda assegnatore"
                        return <FieldIdUser title={title} id_user={value} />
                    },
                    filterable: true,
                    width:80
                },
                stakeholder: {Header: "Nome cliente",nice_name: "Nome cliente",width: 200},
                id_stakeholder: {
                    nice_name: "Cliente",
                    Header: "Cliente",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Stakeholder view"
                        return <FieldIdStakeholder id_stakeholder={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_practical: {
                    nice_name: "Pratica",
                    Header: "Pratica",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda pratica"
                        return <FieldIdPractical id_practical={value} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_practical_outer: {
                    nice_name: "Pratica altra agenzia",
                    Header: "Pratica Est.",
                    Cell: (props) => {
                        const value=props.value
                        const id_stakeholder = props.row.hasOwnProperty('id_activity_stakeholder') ? props.row.id_activity_stakeholder : 0
                        const title = "Scheda pratica altra agenzia"
                        return <FieldIdPracticalOuter id_practical_outer={value} id_stakeholder={id_stakeholder} tooltipText={title} />
                    },
                    filterable: true,
                    width:80
                },
                id_lead: {
                    nice_name: "Scheda lead",
                    Header: "Sch.Lead",
                    Cell: (props) => {
                        const value=props.value
                        let id_activity = 0
                        let activity_state = 'open'
                        if( props.hasOwnProperty('row') ) {
                            id_activity=props.hasOwnProperty('row')
                                ? props.row.id_activity
                                : 0
                            activity_state=props.hasOwnProperty('row')
                                ? props.row.activity_state
                                : 0
                        }
                        const title = "Scheda lead"
                        return <FieldIdLead id_lead={value} tooltipText={title} id_activity={id_activity} activity_state={activity_state} />
                    },
                    filterable: true,
                    width:80
                },
                activity_type: {Header: "Tipo",nice_name: "Tipo"},
                id_activity_order: {Header: "Ordine",nice_name: "Ordine",show:false},
                activity_order: {Header: "Ordine",nice_name: "Ordine"},
                activity_instruction: {
                    nice_name: "Istruzioni",
                    Header: "Istruzioni",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Istruzioni"
                        return <FieldLongText title={title} text={value} />
                    },
                    width: 320
                },
                id_task_target: {Header: "Target",nice_name: "Target",width:80},
                id_activity_target: {Header: "Target",nice_name: "Target",width:80},
                activity_target: {Header: "Target",nice_name: "Target"},
                activity_state: {
                    Header: "Stato",
                    nice_name: "Stato",
                    Cell: (props) => {
                        const value=props.value
                        return <FieldTaskState state={value} />
                    },
                    width:100
                },
                id_report: {
                    nice_name: "Report",
                    Header: "Report",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Report view"
                        return <FieldIdReport title={title} id_report={value} />
                    },
                    filterable: true,
                    width:80
                },
                closed_at: {Header: "Chiuso il",nice_name: "Chiuso il"},
                closer_user: {Header: "Chiuso da",nice_name: "Chiuso da"},
                reports_n: {
                    Header: "# reports",
                    nice_name: "# reports",
                    Cell: (props) => {
                        const value=props.value
                        const id_activity = hasOwnPath(props,'row.id_activity')
                            ? props.row.id_activity
                            : (hasOwnPath(props,'row.id_renewal')
                                ? props.row.id_renewal
                                : (hasOwnPath(props,'row.id_task')
                                    ? props.row.id_task
                                    : 0))
                        return id_activity
                            ? <FieldReportsN reports_n={value} id_activity={id_activity} />
                            : value
                    },
                    width:80
                },
                id_closer_user: {
                    Header: "Chiuso da",
                    nice_name: "Chiuso da",
                    Cell: (props) => {
                        const value=props.value
                        const title = "Scheda firma report"
                        return <FieldIdUser title={title} id_user={value} />
                    },
                    filterable: true,
                    width:80
                },
            }
        case "metrics":
            const is_super_manager = (current_groups_ids.includes(String(1)) || current_groups_ids.includes(String(2))) ? true : false
            const is_branch_manager =  current_groups_ids.includes(String(6)) ? true : false
            return {
                id_agent: {Header: "",show: false},
                id_branch: {Header: ""},
                agent: {
                    nice_name: "Collaboratore",
                    Header: "Collaboratore",
                    Cell: (props) => {
                        const value=props.value
                        const id_agent = props.row.hasOwnProperty('id_agent') ? props.row.id_agent : 0
                        const is_current_user = parseInt(current_id_user) === parseInt(id_agent) ? true : false
                        const color = is_current_user ? "primary": "secondary"
                        const clickable = is_current_user ? false : true
                        const component = clickable ? AdapterLink : "span"
                        const to = clickable ? ("/agents/card/"+id_agent) : null
                        return (is_super_manager || is_branch_manager)
                            ? <Chip clickable={clickable} component={component} to={to} label={value} size="small" color={color} />
                            : <Chip label={value} size="small" color={color} />
                    },
                    width:200
                },
                agencies_branches: {
                    nice_name: "Sede",
                    Header: "Sede",
                    Cell: (props) => {
                        const value=props.value
                        const is_current_branch = current_agency_branches.includes(value)
                        const color = is_current_branch ? "primary": "secondary"
                        const clickable = is_current_branch ? false : true
                        const component = clickable ? AdapterLink : "span"
                        const to = clickable ? ("/agencies-branch/card/"+value) : null
                        return (is_super_manager || is_branch_manager)
                            ? <Chip clickable={clickable} component={component} to={to} label={value} size="small" color={color} />
                            : <Chip label={value} size="small" color={color} />
                    },
                    width:200
                },
                product_code: {Header: "Prodotto"},
                origination: {Header: "Origine"},
                practicals_n: {
                    nice_name: "N. pratiche lavorate",
                    Header: "# pratiche",
                },
                activities_n: {
                    nice_name: "N. task",
                    Header: "# task",
                },
                reports_n: {
                    nice_name: "N. report",
                    Header: "# report",
                },
                assigned_leads_n: {
                    nice_name: "N. lead assegnati",
                    Header: "# lead",
                },
                managed_leads_n: {
                    nice_name: "N. lead gestiti",
                    Header: "# lead gestiti",
                },
                compounding_approved: {
                    nice_name: "Montanti approvati",
                    Header: "Montanti approvati",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                compounding_disbursed: {
                    nice_name: "Montanti erogati",
                    Header: "Montanti erogati",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                fees_earned: {
                    nice_name: "Provvigioni generate",
                    Header: "Provvigioni",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="currency"
                            currency="EUR"
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
                fees_avg: {
                    nice_name: "Media provigionale",
                    Header: "Media provigionale",
                    Cell: (props) => {
                        const value=props.value
                        return <Formatter
                            value={value}
                            dataType="percent"
                            currency=""
                            decimalDigits="2"
                            exponent="0"
                        />
                    },
                },
            }
        default:
            return {}
    }
}