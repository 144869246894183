import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = {
    widgetBox: {
        textAlign:'left',
    },
    titleWidgetBox: {},
    contentWidgetBox: {
        textAlign: 'left',
        whiteSpace: 'pre-line',
        lineHeight: 1.5,
    }
}

class WidgetLeadPreview extends React.Component {
    render() {
        const accordion = this.props.hasOwnProperty('accordion') ? this.props.accordion : true
        const expanded = this.props.hasOwnProperty('expanded') ? this.props.expanded : true
        const id_lead = this.props.id_lead
        const body = this.props.body
        const date = this.props.date ? this.props.date : ''
        //const subject = this.props.subject ? this.props.subject : ''
        //const from = this.props.from ? this.props.from : ''
        //const to = this.props.to ? this.props.to : ''

        if( !body ) return "-"

        const {classes} = this.props

        const leadInfo = <Box className={classes.contentWidgetBox}>
            {id_lead
                ? <React.Fragment>
                    {"ID: "+id_lead}
                    <br />
                </React.Fragment>
                : null}
            {date
                ? <React.Fragment>
                    {"Date: "+date}
                    <br />
                </React.Fragment>
                : null}
            {body
                ? <React.Fragment>
                    {body}
                </React.Fragment>
                : null}
        </Box>
        const leadPreview = accordion
            ? <Accordion defaultExpanded={expanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Preview lead {id_lead ? ("#"+id_lead) : null}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {leadInfo}
                </AccordionDetails>
            </Accordion>
            : leadInfo

        return <React.Fragment>
            <Box className={classes.widgetBox}>
                {leadPreview}
            </Box>
        </React.Fragment>
    }
}

export default withStyles(styles)(WidgetLeadPreview)