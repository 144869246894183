
import React from 'react'
import {withRouter} from "react-router-dom"

import {queryStringToObj} from '../../@react-fefmwk/links/service/api'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'
import RedirectBack from '../../@react-fefmwk/links/modules/RedirectBack'
import SnackbarMessage from '../../@react-fefmwk/messages/modules/SnackbarMessage'

class Checkout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            redirectBack: false
        }
    }
    render() {
        const formikProps = this.props.formikProps
        const id_task = formikProps.values.id_task

        const queryStrings = this.props.location.search

        let queryStringsObj = {}
        if( queryStrings )
            queryStringsObj = queryStringToObj(queryStrings)

        //const noaction = queryStringsObj.hasOwnProperty('noactionatcheckout')
        const backaction = queryStringsObj.hasOwnProperty('backatcheckout')
        const redirectBack = this.state.redirectBack

        let viewProps = {}

        return <React.Fragment>
            <ViewEntity
                entity={"tasks"}
                id_entity={id_task}
                {...viewProps}
            />
            <SnackbarMessage
                open={id_task ? true : false}
                autoHideDuration={2000}
                severity="success"
                message={"Task correctly created"}
                onClose={() => this.setState({redirectBack:backaction})}
            />
            {redirectBack
                ? <RedirectBack />
                : null}
        </React.Fragment>
    }
}

export default withRouter(Checkout)