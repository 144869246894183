import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import WidgetLead from './widgets/WidgetLead'
import WidgetLeadReports from './widgets/WidgetLeadReports'
import WidgetLeadTasks from './widgets/WidgetLeadTasks'

class CardLead extends React.Component {
    render() {
        const id_entity = this.props.id_entity

        return <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <WidgetLead
                    id_lead={id_entity}
                    dashboardcardTitle="Lead"
                    fields={['lead_datetime','lead_subject','lead_body','id_stakeholder','stakeholder']}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <Box mb={1}>
                    <WidgetLeadReports
                        id_lead={id_entity}
                        dashboardcardTitle="Report"
                    />
                </Box>
                <Box mb={1}>
                    <WidgetLeadTasks
                        id_lead={id_entity}
                        dashboardcardTitle="Task"
                    />
                </Box>
            </Grid>
        </Grid>
    }
}

export default CardLead