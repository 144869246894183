import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import StatsAgentsForm from '../modules/StatsAgentsForm'

class DashboardAgenciesBranchesPage extends React.Component {
    render() {
        const title = this.props.route.title

        return <Content title={title}>
            <StatsAgentsForm stats={['metrics_by_branch','metrics_by_month','metrics_by_product','metrics_by_origination']} />
        </Content>
    }
}

export default DashboardAgenciesBranchesPage