import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityFormSwitchLegalIdentity from '../@react-fefmwk/entity/modules/EntityFormSwitchLegalIdentity'
import {queryStringToObj} from '../@react-fefmwk/links/service/api'

const AddAddressPage = (props) => {
    const title = props.route.title
    const queryStrings = props.route.location.search

    const queryStringsObj = queryStringToObj(queryStrings)
    const legal_person = queryStringsObj.hasOwnProperty('id_company') ? true : false
    const showSwitch = (queryStringsObj.hasOwnProperty('id_company') || queryStringsObj.hasOwnProperty('id_people'))
        ? false
        : true

    return <Content title={title}>
        <EntityFormSwitchLegalIdentity entity="addresses" queryStrings={queryStrings} showSwitch={showSwitch} legal_person={legal_person} />
    </Content>
}

export default AddAddressPage