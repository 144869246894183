import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import CloseIcon from '@material-ui/icons/Close'

const style = theme => ({
    closeBtn: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
    },
})

class Modal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: this.props.open ? this.props.open : false
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }
    componentDidUpdate(prevProps) {
        const open = this.props.open
        const prev_open = prevProps.open
        if( open !== prev_open && open ) this.handleOpen()
        if( open !== prev_open && !open ) this.handleClose()
    }
    handleClose() {
        const onClose = this.props.hasOwnProperty('onClose') ? this.props.onClose : null
        if( onClose ) onClose()
        this.setState({open:false})
    }
    handleOpen() {
        const onOpen = this.props.hasOwnProperty('onOpen') ? this.props.onOpen : null
        if( onOpen ) onOpen()
        this.setState({open:true})
    }
    render() {
        const open = this.state.open
        const title = this.props.title
        const content = this.props.content
        const Trigger = this.props.Trigger

        const fullScreen = this.props.fullScreen
        const fullWidth = this.props.fullWidth
        const maxWidth = this.props.maxWidth
        const PaperComponent = this.props.PaperComponent
        const PaperProps = this.props.PaperProps
        const BackdropProps = this.props.BackdropProps
        const disableEscapeKeyDown = this.props.hasOwnProperty('disableEscapeKeyDown')
            ? {disableEscapeKeyDown:true}
            : {}
        const disableBackdropClick = this.props.hasOwnProperty('disableBackdropClick')
            ? {disableBackdropClick:true}
            : {}
        const scroll = this.props.scroll
        const contentAlign = this.props.hasOwnProperty('contentAlign')//left,right,center,justify
            ? this.props.contentAlign
            : "left"
        const contentStyle = this.props.hasOwnProperty('contentStyle')//custom style to dialog content comp
            ? this.props.contentStyle
            : {}

        const tooltipText = this.props.hasOwnProperty('tooltipText') ? this.props.tooltipText : ''
        const tooltipPlacement = this.props.hasOwnProperty('tooltipPlacement') ? this.props.tooltipPlacement : "right"

        const { classes } = this.props

        const hideCloseBtn = this.props.hasOwnProperty('hideCloseBtn') ? true : false
        let printCloseBtn = this.props.hasOwnProperty('printCloseBtn') ? true : false
        printCloseBtn = fullScreen ? true : printCloseBtn//force print close button in case fullscreen
        const CloseBtn = hideCloseBtn === true
            ? null
            : (printCloseBtn
                ? <IconButton color="secondary" onClick={() => this.handleClose()} className={classes.closeBtn}>
                    <CloseIcon />
                </IconButton>
                : null)

        const TriggerComponent = !Trigger
            ? null
            : (React.isValidElement(Trigger)
                ? React.cloneElement(
                    Trigger,
                    {
                        style: {cursor:'pointer'},
                        onClick: () => this.handleOpen()
                    }
                )
                : <Trigger color="secondary" style={{cursor:'pointer'}} onClick={() => this.handleOpen()} />)

        return  <React.Fragment>
            {tooltipText
                ? <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    {TriggerComponent}
                </Tooltip>
                : TriggerComponent}
            <Dialog
                onClose={() => this.handleClose()}
                open={open}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperComponent={PaperComponent}
                PaperProps={PaperProps}
                scroll={scroll}
                {...disableBackdropClick}
                {...disableEscapeKeyDown}
                BackdropProps={BackdropProps}
                style={{textAlign:contentAlign}}
            >
                {title
                    ? <DialogTitle>
                        {title}
                    </DialogTitle>
                    : null}
                <DialogContent style={contentStyle}>
                    {content}
                </DialogContent>
                <Divider />
                {CloseBtn}
            </Dialog>
        </React.Fragment>
    }
}

export default withStyles(style)(Modal)