import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { entity_q,entity_fields_info } from '../../@react-fefmwk/entity/services/api'
import { helpersFormSchema } from '../../services/EntityHelpersFormSchema'

import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import ViewEntity from '../../@react-fefmwk/entity/modules/ViewEntity'
import WidgetPerson from '../widgets/WidgetPerson'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldRadios from '../../@react-fefmwk/form/modules/FieldRadios'

import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
//import AddIcon from '@material-ui/icons/Add'
//import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const styles = theme => ({})

class StakeholderType extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            stakeholders_fields_info: {}
        }
    }
    async componentDidUpdate(prevProps) {
        const formikProps = this.props.formikProps
        const prevFormikProps = prevProps.formikProps
        const stakeholdernotindb = formikProps.values.stakeholdernotindb
        const prev_stakeholder_type = prevFormikProps.values.stakeholder_type
        const stakeholder_type = formikProps.values.stakeholder_type
        const id_people = formikProps.values.id_people
        const id_company = formikProps.values.id_company

        let id_stakeholder = formikProps.values.id_stakeholder
        if( ((!id_people && !id_company) || (prevFormikProps.values.id_people !== id_people || prevFormikProps.values.id_company !== id_company)) && id_stakeholder > 0 )
            formikProps.setFieldValue('id_stakeholder',0)
        if( (id_people > 0 || id_company > 0) && (prevFormikProps.values.id_people !== id_people || prevFormikProps.values.id_company !== id_company) ) {
            const stakeholder = await entity_q(
                'stakeholders',
                {filters:{id_company:id_company,id_people:id_people}}
            )
            id_stakeholder = stakeholder.length && stakeholder[0].hasOwnProperty('id_stakeholder')
                ? stakeholder[0].id_stakeholder
                : 0
            if( id_stakeholder > 0 ) formikProps.setFieldValue('id_stakeholder',id_stakeholder)
        }
        if( (stakeholdernotindb && (id_people > 0 || id_company > 0)) || prev_stakeholder_type !== stakeholder_type ) {
            formikProps.setValues({
                ...formikProps.values,
                ...{id_people:0,first_name:'',last_name:'',f_code:'',birthday:'',id_stakeholder:0},
                ...{id_company:0,company_name:'',vat_number:'',id_stakeholder:0}
            })
        }
    }

    async componentDidMount() {
        let stakeholders_fields_info = await entity_fields_info('stakeholders')
        stakeholders_fields_info.id_people.nice_name = 'cerca persona...'
        stakeholders_fields_info.id_company.nice_name = 'cerca azienda...'
        this.setState({
            stakeholders_fields_info: stakeholders_fields_info,
        })
    }

    render() {
        const formikProps = this.props.formikProps

        let stakeholders_fields_info = this.state.stakeholders_fields_info
        if( Object.keys(stakeholders_fields_info).length === 0 )
            return <LoaderSingleComponent width="100%" />

        let helpersTextSchema = helpersFormSchema('stakeholders')

        const id_stakeholder = formikProps.values.id_stakeholder
        const stakeholdernotindb = formikProps.values.stakeholdernotindb
        const stakeholder_type = formikProps.values.stakeholder_type
        if( stakeholder_type === 'people' ) {
            stakeholders_fields_info.id_company.input_type = 'hidden'
            stakeholders_fields_info.id_people.input_type = 'selectbox'
            helpersTextSchema['id_people'] = 'Controlla se il contatto è in database'
            stakeholders_fields_info.id_people.disabled = stakeholdernotindb
        }
        if( stakeholder_type === 'company' ) {
            stakeholders_fields_info.id_company.input_type = 'selectbox'
            stakeholders_fields_info.id_people.input_type = 'hidden'
            helpersTextSchema['id_company'] = 'Controlla se l\'azienda è in database'
            stakeholders_fields_info.id_company.disabled = stakeholdernotindb
        }

        const stakeholder_types = [
            {id_stakeholder_type: 'people',stakeholder_type: 'Persona fisica'},
            {id_stakeholder_type: 'company',stakeholder_type: 'Azienda'},
        ]

        const id_people = formikProps.values.id_people
        const id_company = formikProps.values.id_company

        return <React.Fragment>
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
            >
                <Grid item xs={12} md={2}>
                    <FieldRadios
                        title='Tipo contatto'
                        items={stakeholder_types}
                        name='stakeholder_type'
                        fieldId='id_stakeholder_type'
                        fieldName='stakeholder_type'
                        value={String(formikProps.values['stakeholder_type'])}
                        onChange={formikProps.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    {stakeholder_type
                        ? <React.Fragment>
                            <EntityFormInputs
                                entity={'stakeholders'}
                                entityFieldsInfo={stakeholders_fields_info}
                                entityHelpersSchema={helpersTextSchema}
                                formikProps={formikProps}
                            />
                            Conferma che il contatto non è già in database <Switch
                                name="stakeholdernotindb"
                                disabled={id_stakeholder > 0 && (id_people || id_company) ? true : false}
                                value={stakeholdernotindb}
                                inputProps={{'aria-label':'primary checkbox'}}
                                onChange={formikProps.handleChange}
                            />
                            {id_stakeholder > 0 && (id_people || id_company)
                                ? <Box mt={2}>
                                    <Chip label="Il contatto è già presente in DB"
                                        color="secondary"
                                        //icon={<AdapterLink replace to={"/stakeholders/card/"+id_stakeholder}><OpenInNewIcon size="small" /></AdapterLink>}
                                    />&nbsp;&nbsp;
                                    <Button variant="text" size="small"
                                        startIcon={<OpenInNewIcon fontSize="small" />}
                                        to={"/stakeholders/card/"+id_stakeholder}
                                        component={AdapterLink}
                                        replace
                                    >Scheda</Button>
                                    {/*<Box mt={1}>
                                        <Button variant="text" size="small"
                                            startIcon={<AddIcon fontSize="small" />}
                                            to={"/practicals/add?id_stakeholder="+id_stakeholder}
                                            component={AdapterLink}
                                            replace
                                        >Preventivo</Button>
                                        <br />
                                        <Button variant="text" size="small"
                                            startIcon={<CloudUploadIcon />}
                                            component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#documents'}
                                            replace
                                        >Documenti</Button>
                                        <br />
                                        <Button variant="text" size="small"
                                            startIcon={<AddIcon />}
                                            component={AdapterLink} to={'/tasks/add?id_stakeholder='+id_stakeholder}
                                            replace
                                        >Assegna</Button>
                                    </Box>*/}
                                </Box>
                                : null}
                        </React.Fragment>
                        : null}
                </Grid>
                <Grid item xs={12} md={7}>
                    {id_stakeholder
                        ? <React.Fragment>
                            <Box mb={1}>
                                <Chip label="Il contatto è già gestito da altri colleghi o sedi" color="secondary" />
                            </Box>
                            <ViewEntity entity="stakeholders" id_entity={id_stakeholder} dense />
                        </React.Fragment>
                        : (id_people || id_company
                            ? <React.Fragment>
                                <Box mb={1}>
                                    <Chip label="Il contatto non è ancora seguito da nessuna sede" color="secondary" />
                                </Box>
                                {id_people
                                    ? <React.Fragment>
                                        <WidgetPerson
                                            id_people={id_people}
                                            fields={['id_people','first_name','last_name','main_mobile']}
                                            dashboardcardTitle={"Anagrafica"}
                                            dashboardcardActions={false}
                                        />
                                    </React.Fragment>
                                    : null}
                                {id_company
                                    ? <React.Fragment>
                                        <ViewEntity entity="companies" id_entity={id_company} dense />
                                    </React.Fragment>
                                    : null}
                            </React.Fragment>
                            : null)}
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(StakeholderType)