import React from 'react'

class FieldHidden extends React.Component {
    render() {
        return <React.Fragment>
            <input {...this.props} type="hidden" />
        </React.Fragment>
    }
}

export default FieldHidden