import React from "react"

import DashboardCard from "../@react-fefmwk/cards/modules/DashboardCard"
import Accordions from "../@react-fefmwk/accordion/modules/Accordions"
import EntityForm from "../@react-fefmwk/entity/modules/EntityForm"
import EntityRelFieldActions from "../@react-fefmwk/entity/modules/EntityRelFieldActions"
import LoaderSingleComponent from "../@react-fefmwk/loader-app/modules/LoaderSingleComponent"

import WidgetCompaniesList from "./widgets/WidgetCompaniesList"
import WidgetCompanyAddresses from "./widgets/WidgetCompanyAddresses"
import WidgetCompanyContacts from "./widgets/WidgetCompanyContacts"

import {Chip, Typography, Grid, Button, Box} from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'

class AddCompany extends React.Component{
    constructor(props){
        super(props)

        const initialValues = this.props.hasOwnProperty('initialValues') ? this.props.initialValues : {}
        const id_company = 0
        this.state = {
            enable_step1: id_company ? false : true,
            enable_step2: id_company ? true : false,
            id_company: id_company,
            initialValues: initialValues,
            refresh: false,
        }
    }

    render(){
        const id_company = this.state.id_company
        const initialValues = this.state.initialValues
        const enable_step1 = this.state.enable_step1
        const enable_step2 = this.state.enable_step2
        const refresh = this.state.refresh

        return <React.Fragment>
            <Accordions list={[
                {
                    headerGrid: <React.Fragment>
                        <Grid item xs="auto">
                            <Chip label={1} color="secondary" />
                        </Grid>
                        <Grid item xs="auto" md={4}>
                            <Typography variant="body1">Dati della nuova <Typography component="span" variant="overline" color="primary">azienda</Typography></Typography>
                        </Grid>
                    </React.Fragment>,
                    disabled: !enable_step1,
                    startOpen: enable_step1,
                    content: <React.Fragment>
                        {enable_step1
                            ? <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <EntityForm 
                                        entity="companies"
                                        precompiledData={initialValues}
                                        onSubmitOk={(last_id) => {
                                            this.setState({
                                                id_company: last_id,
                                                enable_step1: false,
                                                enable_step2: true
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {refresh
                                    ? <LoaderSingleComponent width="100%" />
                                    : <WidgetCompaniesList
                                        fields={['company_name AS Cerca tra le aziende già censite','vat_number','cf','is_atc','is_insurance']}
                                        noRowActions={true}
                                        dashboardcardActions={false}
                                        defaultPageSize={5}
                                        showPagination={false}
                                    />}
                                </Grid>
                            </Grid>
                            : null}
                    </React.Fragment>
                },
                {
                    headerGrid: <React.Fragment>
                        <Grid item xs="auto">
                            <Chip label={2} color="secondary" />
                        </Grid>
                        <Grid item xs="auto" md={4}>
                            <Typography variant="body1">
                                Aggiungi 
                                &nbsp;<Typography component="span" variant="overline" color="primary">indirizzi</Typography> e 
                                &nbsp;<Typography component="span" variant="overline" color="primary">recapiti</Typography>
                            </Typography>
                        </Grid>
                    </React.Fragment>,
                    disabled: !enable_step2,
                    startOpen: enable_step2,
                    content: <React.Fragment>
                        {enable_step2
                            ? <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <WidgetCompanyAddresses noCard noRowActions={true}
                                        id_company={id_company}
                                        dashboardcardTitle="Indirizzi"
                                        refreshContent={refresh}
                                        TopActions={<React.Fragment>
                                            <EntityRelFieldActions
                                                entity="addresses"
                                                onOpen={() => this.setState({refresh:true})}
                                                onClose={() => this.setState({refresh:false})}
                                                Trigger={<Button size="small" color="primary" variant="contained"
                                                    startIcon={<AddIcon />}
                                                    disabled={!enable_step2}
                                                >
                                                    indirizzo
                                                </Button>}
                                                s={"id_company="+id_company}
                                            />
                                        </React.Fragment>}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <WidgetCompanyContacts noCard noRowActions={true}
                                        id_company={id_company}
                                        dashboardcardTitle="Recapiti"
                                        refreshContent={refresh}
                                        TopActions={<EntityRelFieldActions
                                            entity="contacts"
                                            onOpen={() => this.setState({refresh:true})}
                                            onClose={() => this.setState({refresh:false})}
                                            Trigger={<Button size="small" color="primary" variant="contained"
                                                startIcon={<AddIcon />}
                                                disabled={!enable_step2}
                                            >
                                                recapito
                                            </Button>}
                                            s={"id_company="+id_company}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                            : null}
                    </React.Fragment>
                }
            ]} />
        </React.Fragment>
    }
}
export default AddCompany