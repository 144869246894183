import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ErrorMessage } from 'formik'

import EntityRelFieldActions from './EntityRelFieldActions'

import { upload_files } from '../../form/services/api'

import TextMsg from '../../messages/modules/TextMsg'
import FieldText from '../../form/modules/FieldText'
import FieldDate from '../../form/modules/FieldDate'
import FieldDateTime from '../../form/modules/FieldDateTime'
import FieldSelect from '../../form/modules/FieldSelect'
import FieldAutocomplete from '../../form/modules/FieldAutocomplete'
import FieldEmail from '../../form/modules/FieldEmail'
import FieldTextarea from '../../form/modules/FieldTextarea'
import FieldNumber from '../../form/modules/FieldNumber'
import FieldRadios from '../../form/modules/FieldRadios'
import FieldHidden from '../../form/modules/FieldHidden'
import FieldBoolean from '../../form/modules/FieldBoolean'
import FieldFile from '../../form/modules/FieldFile'

import Grid from '@material-ui/core/Grid'
import PublishIcon from '@material-ui/icons/Publish'

const styles = theme => ({
    errorMsg: {
        marginLeft: 14
    },
})

class EntityFormInput extends React.Component {
    render() {
        const field = this.props.field
        const inputType = this.props.inputType
        const formikProps = this.props.formikProps
        const niceName = this.props.niceName
        const placeholder = this.props.placeholder
        const helperText = this.props.helperText
        const inputOptions = this.props.inputOptions ? this.props.inputOptions : {}
        const inputProps = this.props.inputProps ? this.props.inputProps : {}
        let disabled = this.props.disabled
        let defaultVal = this.props.default

        //let value = (formikProps.values[field] || formikProps.values[field] === null) ? formikProps.values[field] : ""
        let value = (!formikProps.values.hasOwnProperty(field) || formikProps.values[field] === null) ? "" : formikProps.values[field]
        let error = (formikProps.errors[field] || formikProps.errors[field] === null) && (formikProps.touched[field] || formikProps.touched[field] === null)

        const {classes} = this.props

        let nullRow = {}
        switch( inputType ) {
            case "boolean":
                const labelTrue = inputOptions && "labelTrue" in inputOptions ? inputOptions.labelTrue : "Yes"
                const labelFalse = inputOptions && "labelFalse" in inputOptions ? inputOptions.labelFalse : "No"
                return <React.Fragment>
                    <FieldBoolean
                        title={niceName}
                        name={field}
                        error={error}
                        value={value}
                        disabled={disabled}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helperText}
                        inputProps={inputProps}
                        labelTrue={labelTrue}
                        labelFalse={labelFalse}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            case "date":
                return <React.Fragment>
                    <FieldDate
                        title={niceName}
                        label={niceName}
                        disabled={disabled}
                        value={value}
                        /*onChange={(_,dateString) => {
                            console.log(dateString)
                            formikProps.setFieldValue(field,dateString)
                            formikProps.setFieldTouched(field,true,true)
                        }}*/
                        onBlur={formikProps.handleBlur}
                        name={field}
                        error={error}
                        type="string"
                        variant="outlined"
                        helperText={helperText}
                        inputProps={inputProps}
                        formikProps={formikProps}
                        {...inputOptions}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            case "timestamp":
                disabled = true
            case "datetime":
                return <React.Fragment>
                    <FieldDateTime
                        title={niceName}
                        label={niceName}
                        disabled={disabled}
                        value={value}
                        /*onChange={(_,dateTimeString) => {
                            formikProps.setFieldValue(field,dateTimeString)
                            formikProps.setFieldTouched(field,true,true)
                        }}*/
                        onBlur={formikProps.handleBlur}
                        name={field}
                        error={error}
                        type="string"
                        variant="outlined"
                        helperText={helperText}
                        inputProps={inputProps}
                        formikProps={formikProps}
                        {...inputOptions}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            case "radio":
            case "selectbox":
            case "autouserid":
                const fieldId = inputOptions && "ref_key" in inputOptions ? inputOptions.ref_key : ""
                const fieldName = inputOptions && "ref_field" in inputOptions ? inputOptions.ref_field : ""
                const items = inputOptions && "items" in inputOptions ? inputOptions.items : []

                if( defaultVal !== null ) {
                    nullRow[fieldId] = String(defaultVal)
                    nullRow[fieldName] = "default"
                }

                const ref_entity = this.props.hasOwnProperty('ref_entity') ? this.props.ref_entity : null
                const onRefreshForm = this.props.hasOwnProperty('onRefreshForm') ? this.props.onRefreshForm : () => true

                switch( inputType ) {
                    case "radio":
                        return <React.Fragment>
                            {disabled
                                ? null
                                : <EntityRelFieldActions entity={ref_entity} onClose={onRefreshForm} />}
                            <FieldRadios
                                title={niceName}
                                label={niceName}
                                items={items}
                                name={field}
                                error={error}
                                fieldId={fieldId}
                                disabled={disabled}
                                fieldName={fieldName}
                                sortDropdownKey={fieldName}
                                value={String(value)}
                                onChange={(_,val) => {
                                    formikProps.setFieldValue(field,val)
                                    formikProps.setFieldTouched(field,true,true)
                                }}
                                onBlur={formikProps.handleBlur}
                                helperText={helperText}
                                inputProps={inputProps}
                            />
                            <ErrorMessage name={field}> 
                                {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                            </ErrorMessage>
                        </React.Fragment>

                    case "autouserid":
                        disabled = true
                    case "selectbox":
                        return items.length <= 10
                            ? <React.Fragment>
                                <FieldSelect
                                    title={niceName}
                                    name={field}
                                    error={error}
                                    fieldId={fieldId}
                                    disabled={disabled}
                                    fieldName={fieldName}
                                    sortDropdownKey={fieldName} 
                                    items={items}
                                    value={value}
                                    nullRow={nullRow}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    helperText={helperText}
                                    inputProps={inputProps}
                                />
                                {disabled
                                    ? null
                                    : <EntityRelFieldActions entity={ref_entity} onClose={onRefreshForm} />}
                                <ErrorMessage name={field}> 
                                    {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                                </ErrorMessage>
                            </React.Fragment>
                            : <React.Fragment>
                                <FieldAutocomplete
                                    title={niceName}
                                    name={field}
                                    error={error}
                                    fieldId={fieldId}
                                    disabled={disabled}
                                    fieldName={fieldName}
                                    sortDropdownKey={fieldName} 
                                    items={items}
                                    valueSelected={value}
                                    nullRow={nullRow}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    formikProps={formikProps}
                                    helperText={helperText}
                                    inputProps={inputProps}
                                />
                                {disabled
                                    ? null
                                    : <EntityRelFieldActions entity={ref_entity} onClose={onRefreshForm} />}
                                <ErrorMessage name={field}> 
                                    {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                                </ErrorMessage>
                            </React.Fragment>
                }

            case "textarea":
                return <React.Fragment>
                    <FieldTextarea
                        title={niceName}
                        name={field}
                        error={error}
                        type="string"
                        value={value}
                        disabled={disabled}
                        variant="outlined"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helperText}
                        placeholder={placeholder}
                        inputProps={inputProps}
                        {...inputOptions}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            case "email":
                return <React.Fragment>
                    <FieldEmail
                        title={niceName}
                        name={field}
                        error={error}
                        value={value}
                        disabled={disabled}
                        variant="outlined"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helperText}
                        placeholder={placeholder}
                        inputProps={inputProps}
                        {...inputOptions}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            case "file":
                const multiple = inputOptions.hasOwnProperty('multiple') ? inputOptions.multiple : true
                const webkitdirectory = inputOptions.hasOwnProperty('webkitdirectory') ? inputOptions.webkitdirectory : false
                const folder = inputOptions.hasOwnProperty('folder') ? inputOptions.folder : 'files'
                const file_type_field = inputOptions.hasOwnProperty('file_type_field') ? inputOptions.file_type_field : ''
                const file_name_field = inputOptions.hasOwnProperty('file_name_field') ? inputOptions.file_name_field : ''

                const field_file = "files"+(multiple ? "[]" : "")
                return <React.Fragment>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs="auto">
                            <FieldFile size="small" startIcon={<PublishIcon />}
                                name={field_file}
                                multiple={multiple}
                                disabled={disabled}
                                webkitdirectory={webkitdirectory}
                                fileList
                                //fileListStyle={{overflow:'auto',height:400}}
                                chooseButtonText="Select"
                                helperText={helperText}
                                upload_f={async (formFiles,files) => {
                                    const uploads = await upload_files(
                                        formFiles,//files from form
                                        folder,
                                        true//return_paths
                                    )
                                    if( !uploads.includes(false) ) {
                                        formikProps.setFieldValue(field,uploads[0])
                                        if( files.length ) {
                                            if( file_type_field ) formikProps.setFieldValue(file_type_field,files[0]['type'])
                                            if( file_name_field ) formikProps.setFieldValue(file_name_field,files[0]['name'].split('.').slice(0, -1).join('.'))
                                        }
                                    }
                                }}
                            /></Grid>
                        <Grid item xs>
                            <FieldText
                                title={niceName}
                                name={field}
                                error={error}
                                type="string"
                                value={value}
                                disabled={true}
                                variant="standard"
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                helperText={helperText}
                                placeholder={placeholder}
                                inputProps={inputProps}
                            />
                            <ErrorMessage name={field}> 
                                {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                            </ErrorMessage>
                        </Grid>
                    </Grid>
                </React.Fragment>

            case "number":
                /*const min = inputOptions && "min" in inputOptions ? inputOptions.min : ""
                const max = inputOptions && "max" in inputOptions ? inputOptions.max : ""
                const step = inputOptions && "step" in inputOptions ? inputOptions.step : ""

                let numberProps = {}
                if( min ) numberProps.min = min
                if( max ) numberProps.max = max
                if( step ) numberProps.step = step
                numberProps = {
                    ...inputProps,
                    ...numberProps
                }*/

                return <React.Fragment>
                    <FieldNumber
                        title={niceName}
                        name={field}
                        error={error}
                        type="number"
                        value={value}
                        disabled={disabled}
                        variant="outlined"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helperText}
                        inputProps={inputProps}
                        {...inputOptions}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            case "hidden_timestamp":
            case "hidden_autouserid":
            case "hidden":
                return <React.Fragment>
                    <FieldHidden
                        name={field}
                        value={value}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>

            default:
                return <React.Fragment>
                    <FieldText
                        title={niceName}
                        name={field}
                        error={error}
                        type="string"
                        value={value}
                        disabled={disabled}
                        variant="outlined"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        helperText={helperText}
                        placeholder={placeholder}
                        inputProps={inputProps}
                        {...inputOptions}
                    />
                    <ErrorMessage name={field}> 
                        {(msg) => (<TextMsg severity="error" message={msg} className={classes.errorMsg} />)}
                    </ErrorMessage>
                </React.Fragment>
        }
    }
}

export default withStyles(styles)(EntityFormInput)