import React from 'react'
import Stepper from '../@react-fefmwk/stepper/modules/StepperForm'
import { entity_get } from '../@react-fefmwk/entity/services/api'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import AbsoluteBox from '../@react-fefmwk/messages/modules/AbsoluteBox'
import {nowString} from '../@react-fefmwk/dates/services/api'

import WidgetLeadPreview from './widgets/WidgetLeadPreview'

import { groupsFromToken } from '../@react-fefmwk/auth/services/token'
import { tableSchema } from '../services/EntityTableSchema'
import {
    AddPracticalStepperSchema,
    AddPracticalStepperFormSchema,
    AddPracticalStepperValidationSchema,
    AddPracticalStepperSubmitSchema,
} from '../services/AddPracticalStepperSchema'

import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'

class AddPractical extends React.Component {
    constructor(props) {
        super(props)

        const update = this.props.hasOwnProperty('update') ? this.props.update : false
        const id_practical = this.props.id_entity
        const initialValues = this.setInitialValues()

        this.state = {
            id_practical: id_practical,
            form: initialValues,
            update: update
        }
    }

    setInitialValues() {
        let initialValues = Object.assign({},AddPracticalStepperFormSchema)

        initialValues.load_date = nowString()

        const precompiledData = this.props.hasOwnProperty('precompiledData')
            ? this.props.precompiledData
            : {}
        if( Object.keys(precompiledData).length ) {
            initialValues = {...initialValues,...precompiledData}
            if( initialValues.id_lead ) initialValues['id_practical_origination'] = 1
        }

        return initialValues
    }

    async componentDidMount() {
        const precompiledData = this.props.hasOwnProperty('precompiledData')
            ? this.props.precompiledData
            : {}
        const fromlead = precompiledData.hasOwnProperty('id_lead') && precompiledData.id_lead
            ? true
            : false
        let lead = {}
        if( fromlead )
            lead = await entity_get( 'leads',precompiledData.id_lead )

        const id_practical = this.state.id_practical
        if( !id_practical ) {
            const initialValues = this.setInitialValues()
            this.setState({
                form: initialValues,
                fromlead: fromlead,
                lead: lead
            })
        }
        else {
            const practical = await entity_get( 'practicals',id_practical )
            this.setState((prevState) => {
                let form_copy = prevState.form
                form_copy = practical

                //adjust nulls
                Object.keys(form_copy).map((field) => {
                    if( form_copy[field] === null ) form_copy[field] = ""
                    return true
                })

                return {
                    id_practical: practical.id_practical,
                    form: form_copy,
                    fromlead: fromlead,
                    lead: lead
                }
            })
        }
    }

    render() {
        const update = this.state.update

        const groups = groupsFromToken()
        const isManager = [1,2,5].filter(v => groups.includes(String(v))).length ? true : false

        const form = this.state.form
        const id_practical = form.id_practical
        const id_practical_origination = form.id_practical_origination
        const is_obscured = form.hasOwnProperty("is_obscured") ? form.is_obscured : false
        const priority = form.hasOwnProperty("priority")
            ? (form.priority===null ? 0 : form.priority)
            : 0
        if(is_obscured)
            return <Box mb={1}>
                <Chip label="Non hai i privilegi per gestire gli eventi di questa pratica" variant="outlined" />
            </Box>
        if(priority >= 4 && !isManager)//stato approvata
            return <Box mb={1}>
                <Chip label="L'attuale stato della pratica non permette la modifica" variant="outlined" />
            </Box>

        let activeStep = 0
        if( !update && id_practical_origination ) activeStep = 1
        if( id_practical ) activeStep = update ? 3 : 4

        if( !id_practical && this.state.id_practical ) return <LoaderSingleComponent width="100%" />

        const infoFieldsProps = tableSchema("practicals")

        const id = update ? "update-practical" : "add-practical"
        const disableBackAtSteps = this.props.hasOwnProperty('disableBackAtSteps')
            ? this.props.disableBackAtSteps
            : []

        const fromlead = this.state.fromlead
        const lead = this.state.lead

        return <React.Fragment>
            <Stepper
                id={id}
                stepperSchema={AddPracticalStepperSchema}
                infoFieldsProps={infoFieldsProps}
                formSchema={form}
                //infoFieldsList={["id_notice","cached_data"]}
                validationSchema={AddPracticalStepperValidationSchema}
                submitSchema={AddPracticalStepperSubmitSchema}
                activeStep={activeStep}
                disableBackAtLastStep
                disableBackAtSteps={disableBackAtSteps}
            />
            {fromlead && Object.keys(lead).length
                ? <AbsoluteBox>
                    <WidgetLeadPreview
                        id_lead={lead.id_lead}
                        body={lead.lead_body}
                        date={lead.lead_datetime}
                        subject={lead.lead_subject}
                        from={lead.lead_from}
                        to={lead.lead_to}
                    />
                </AbsoluteBox>
                : null}
        </React.Fragment>
    }
}

export default AddPractical