import React from 'react'
import { Redirect } from "react-router-dom"

import { doLogout } from '../services/api'
import { UserDropdownSchema } from '../../../services/NavTopSchema'

import UserAvatar from './UserAvatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import AdapterLink from '../../links/modules/AdapterLink'
import ExternalLink from '../../links/modules/ExternalLink'
//import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
//import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

function UserDropdown(props) {
    const [state,setState] = React.useState({
        logged: true,
        anchorEl: null
    })

    const anchorEl = state.anchorEl
    const open = Boolean(anchorEl)
    const logged = state.logged

    const handleClick = (event) => {
        setState({
            logged: state.logged,
            anchorEl: event.currentTarget
        })
    }
    const handleClose = () => {
        setState({
            logged: state.logged,
            anchorEl: null
        })
    }
    const handleLogOut = async () => {
        if( await doLogout() )
            setState({
                logged: false,
                anchorEl: null
            })
    }

    if( !logged ) return <Redirect to='/' />

    const showUsername = props.showUsername

    let dropdownList = null
    if( UserDropdownSchema.length ) {
        dropdownList = UserDropdownSchema.map((item) => {
            const slug = item.slug
            const path_type = item.path_type
            const path = item.path
            const Icon = item.icon
            const title = item.title
            return <MenuItem key={slug} component={path_type === "external" ? ExternalLink : AdapterLink} to={path} onClick={handleClose}>
                <ListItemIcon>
                    {Icon ? <Icon fontSize="small" /> : null}
                </ListItemIcon>
                <ListItemText>{title}</ListItemText>
            </MenuItem>
        })
    }

    return <React.Fragment>
        <UserAvatar {...props}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            showUsername={showUsername}
        />
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {dropdownList}
            {dropdownList ? <Divider /> : null}
            <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                    <ExitToAppIcon fontSize="small" id="LogoutButton" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </MenuItem>
      </Menu>
    </React.Fragment>
}

export default UserDropdown