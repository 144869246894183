import React from 'react'

import {
    users_get_assignments,
    users_get_assignments_stats
} from '../services/BE'
import { tableSchema } from '../services/EntityTableSchema'
import { actionSchema } from '../services/EntityRowActionsSchema'
import { EntitySchema } from '../services/EntitySchema'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'
import Table from '../@react-fefmwk/tables/modules/Table'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
import ScoreCard from '../@react-fefmwk/cards/modules/ScoreCard'

import BlockIcon from '@material-ui/icons/Block'

class MyAssignments extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id_user: this.props.id_user,
            assignments_stats: {},
            assignments: [],
            refresh: false,
            closed: false,
            futured: false,
        }
    }

    async componentDidMount() {
        const id_user = this.state.id_user
        const assignments_stats = await users_get_assignments_stats(id_user)
        const assignments = await users_get_assignments(id_user)

        this.setState({
            assignments_stats: assignments_stats,
            assignments: assignments
        })
    }

    async componentDidUpdate(prevProps,prevState) {
        const id_user = this.state.id_user
        const closed = this.state.closed
        const futured = this.state.futured
        if( closed !== prevState.closed || futured !== prevState.futured ) {
            this.setState({
                assignments_stats: await users_get_assignments_stats(id_user,closed,futured),
                assignments: await users_get_assignments(id_user,closed,futured),
                refresh: false
            })
        }
    }

    handleChange(e,filter) {
        e.persist()

        this.setState({
            refresh: true,
            closed: filter === 'closed' ? e.target.checked : this.state.closed,
            futured: filter === 'futured' ? e.target.checked : this.state.futured,
        })
    }

    render() {
        const assignments = this.state.assignments
        const assignments_stats = this.state.assignments_stats

        const entity = "assignments"
        const entityObj = EntitySchema[entity]

        const path = entityObj.rootpath
        const id_field = entityObj.id_field
        const fieldsProps = {
            ...tableSchema(entity),
            ...{display_name: {show: false}}
        }
        const rowActions = actionSchema(entity,path)

        const refresh = this.state.refresh
        const closed = this.state.closed
        const futured = this.state.futured

        const assignments_n = assignments_stats.hasOwnProperty('total') ? assignments_stats.total : 0
        const assignments_by_state = assignments_stats.hasOwnProperty('by_state') ? assignments_stats.by_state : []
        const assignments_by_activity_order = assignments_stats.hasOwnProperty('by_activity_order') ? assignments_stats.by_activity_order : []

        return <React.Fragment>
            <Box mb={2}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs="auto">
                        <Switch checked={closed} onChange={(e) => this.handleChange(e,'closed')} /> includi richieste chiuse
                    </Grid>
                    <Grid item xs="auto">
                        <Switch checked={futured} onChange={(e) => this.handleChange(e,'futured')} /> solo future
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    {!refresh
                        ? <Grid item xs>
                            <ScoreCard title="# richieste" value={assignments_n} fullWidth />
                        </Grid>
                        : null}
                    {assignments_by_state.length && !refresh
                        ? <React.Fragment>
                            {assignments_by_state.map((row,i) => {
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+row.activity_state} value={row.assignments_n} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                    {assignments_by_activity_order.length && !refresh
                        ? <React.Fragment>
                            {assignments_by_activity_order.map((row,i) => {
                                return <Grid item xs key={i}>
                                    <ScoreCard title={"# "+row.activity_order} value={row.assignments_n} fullWidth />
                                </Grid>
                            })}
                        </React.Fragment>
                        : null}
                </Grid>
            </Box>
            {assignments.length
                ? <Paper>
                    {!refresh
                        ? <Table material dense highlight
                            data={assignments}
                            fieldsProps={fieldsProps}
                            rowActions={rowActions}
                            rowActionsField={id_field}
                            showPagination={true}
                            filterable={true}
                            sortable={true}
                        />
                    : <LoaderSingleComponent width="100%" />}
                </Paper>
                : <Chip icon={<BlockIcon />} label="Non hai assegnato richieste" variant="outlined" />}
        </React.Fragment>
    }
}

export default MyAssignments