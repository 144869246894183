import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

const style = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 200,
        width: '100%'
    },
    labelOnError: {
        color: theme.palette.error[500]
    },
    defaultValue: {
        color:theme.palette.grey[500],
        textTransform:"uppercase",
    }
})

class FieldSelect extends React.Component {

    sortItemsByKey(array, key="") {
        if(!key)
            return array;
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key]
            return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
    }

    render() {
        const name = this.props.name
        if( !this.props.hasOwnProperty("items") || !this.props.items.length )
            return <Chip label={"No "+name+" found"} variant="outlined" style={{marginTop:20}}/>

        const title = this.props.title ? this.props.title : ""
        const id = this.props.id ? this.props.id : ""
        const fieldId = this.props.fieldId
        const fieldName = this.props.fieldName
        const error = this.props.error
        const value = this.props.value
        let items = []
        const nullRow = this.props.hasOwnProperty('nullRow') ? this.props.nullRow : {}
        if( Object.keys(nullRow).length ) items.push(nullRow)
        items = [
            ...items,
            ...this.sortItemsByKey(this.props.items,this.props.sortDropdownKey)
        ]
        const helperText = this.props.helperText
        const variant = this.props.variant ? this.props.variant : "outlined"

        const { classes } = this.props

        const menuItems = items.map((item,i) => {
            if( Object.keys(nullRow).length && i === 0 )
                return <MenuItem key={i}
                    value={item[fieldId]}
                >
                    <span className={classes.defaultValue}>{item[fieldName]}</span>
                </MenuItem>
            else
                return <MenuItem key={i}
                    value={item[fieldId]}
                    disabled={item["disabled"] ? item["disabled"] : false}
                >
                    {item[fieldName]}
                </MenuItem>
            }
        )

        const inputProps = {
            ...this.props.inputProps,
            ...{id: id,name: name}
        }

        return <FormControl variant={variant} className={classes.formControl}>
            <InputLabel htmlFor={id} className={error ? classes.labelOnError : ''}>{title}</InputLabel>
            <Select label={title}
                disabled={this.props.disabled}
                inputProps={inputProps}
                error={error}
                value={value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
            >
                {menuItems}
            </Select>
            <FormHelperText component="div">{helperText}</FormHelperText>
        </FormControl>
    }
}

export default withStyles(style)(FieldSelect)