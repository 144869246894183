import React from 'react'

import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import { tableSchema } from '../../services/EntityTableSchema'
import { actionSchema } from '../../services/EntityRowActionsSchema'
import { stakeholders_get_practicals } from '../../services/BE'

class WidgetStakeholderPracticals extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            entity: "practicals",
            id_field: "id_practical",
            path: this.props.path,
            id_stakeholder: this.props.id_stakeholder,
            practicals: [],
        }
    }
    
    async getPracticals() {
        const id_stakeholder = this.props.id_stakeholder
        this.setState({
            practicals: await stakeholders_get_practicals( id_stakeholder ),
        })
    }
    
    async componentDidMount() {
        this.getPracticals()
    }

    async componentDidUpdate(prevProps) {
        const id_stakeholder = this.props.id_stakeholder
        if( id_stakeholder !== prevProps.id_stakeholder )
            this.getPracticals()
    }

    render() {
        const entity = this.state.entity
        const id_field = this.state.id_field
        const practicals = this.state.practicals

        const tableRowActions = this.props.hasOwnProperty('tableRowActions')
            ? this.props.tableRowActions
            : true
        const rowActions = tableRowActions
            ? actionSchema(entity)
            : {}

        let fieldsProps = tableSchema(entity)
        if( !tableRowActions )
            fieldsProps = {
                ...fieldsProps,
                ...{id_practical:{show:false}}
            }

        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Pratiche agenzia"

        return <DashboardCard title={dashboardcardTitle} contentStyle={practicals.length ? {padding:0} : {}}
            Content={practicals.length
                ? <Table material highlight dense
                    data={practicals}
                    fieldsProps={fieldsProps}
                    rowActions={rowActions}
                    rowActionsField={id_field}
                    showPagination={true}
                    filterable={practicals.length > 5 ? true : false}
                    sortable={true}
                />
                : <Chip icon={<ErrorIcon />} label="Nessuna pratica trovata" variant="outlined" />}
        />
    }
}

export default WidgetStakeholderPracticals