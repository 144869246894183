Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var CQgrey = {
  50: "#fcfbf7",
  100: "#f7f6f2",
  200: "#f2f1ed",
  300: "#e8e6e2",
  400: "#c5c4c0",
  500: "#a7a6a2",
  600: "#7d7c79",
  700: "#696864",
  800: "#494945",
  900: "#282724",
  A100: '#CEDBAC',
  A200: '#A4BD64',
  A400: '#687546',
  A700: '#565D44'
};

var _default = CQgrey;
exports.default = _default;