import React from 'react'
import {withTheme} from '@material-ui/core/styles'

import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

class FieldBoolean extends React.Component {
    render() {
        const value = typeof this.props.value == "boolean" ? this.props.value : parseInt(this.props.value)
        const fontSize = this.props.hasOwnProperty('fontSize') ? this.props.fontSize : "small"
        const trueIcon = this.props.hasOwnProperty('trueIcon') ? this.props.trueIcon : CheckCircleIcon
        const falseIcon = this.props.hasOwnProperty('falseIcon') ? this.props.falseIcon : ErrorIcon

        const {theme} = this.props

        const Icon = value ? trueIcon : falseIcon
        const style = {
            color: value ? theme.palette.success.main : theme.palette.error.main
        }

        return <Icon style={style} fontSize={fontSize} />
    }
}

export default withTheme(FieldBoolean)