import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  error: {color: theme.palette.error[500]},
  warning: {color: theme.palette.warning[500]},
  success: {color: theme.palette.success[500]},
})

class TextMsg extends React.Component  {
    render() {
      const message = this.props.message ? this.props.message : 'Error'
      const severity = this.props.severity ? this.props.severity : 'error'
      const {classes} = this.props

      const className = this.props.className ? this.props.className : ''
      const classNames = clsx(className,classes[severity])

      return <Typography component="p" variant="caption" className={classNames}>
        {message}
      </Typography>
    }
}

export default withStyles(styles)(TextMsg)