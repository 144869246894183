import React from 'react'

import Page401 from '../@react-fefmwk/theme-structure/modules/401'
import Content from '../@react-fefmwk/theme-structure/modules/Content'
import EntityTitle from '../@react-fefmwk/entity/modules/EntityTitle'

import CardAgent from '../modules/CardAgent'

import {entity_get} from '../@react-fefmwk/entity/services/api'
import {groupsFromToken} from '../@react-fefmwk/auth/services/token'
import {agenciesBranchesFromToken} from '../services/AgencySchema'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

class CardAgentPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            accessible: null,
            loading: false,
        }
    }
    async componentDidMount() {
        this.setState({
            loading: true
        })
        const current_user_groups = groupsFromToken()
        const current_branches = agenciesBranchesFromToken()
        const id_agent = this.props.route.computedMatch.params.id
        const agent = await entity_get('users',id_agent)
        const agent_branch = agent.hasOwnProperty('agencies_branches') ? agent.agencies_branches : "none"

        const is_supermanager = current_user_groups.includes(String(1)) || current_user_groups.includes(String(2))
            ? true
            : false
        const is_branchmanager = current_user_groups.includes(String(6))
            ? true
            : false
        const is_in_branch = current_branches.includes(String(agent_branch))
            ? true
            : false

        const accessible = is_supermanager
            ? true
            : (is_branchmanager && is_in_branch
                ? true
                : false)
        this.setState({
            accessible: accessible,
            loading: false
        })

    }
    render() {
        const accessible = this.state.accessible
        const loading = this.state.loading
        if( accessible === null || loading ) {
            return <LoaderSingleComponent width="100%" />
        }
        if( !accessible ) {
            return <Page401 />
        }

        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        return <Content title={title} toolbar={<EntityTitle entity="agents" id_entity={id_entity} />}>
            <CardAgent id_entity={id_entity} />
        </Content>
    }
}

export default CardAgentPage