import React from 'react'
import { Redirect } from "react-router-dom"
import { withStyles } from '@material-ui/core/styles'

import AppRoute from './AppRoute'
import MaintenanceBanner from './MaintenanceBanner'
import SnackbarMessage from '../../messages/modules/SnackbarMessage'

import { getToken,delToken,expiresatFromToken } from '../../auth/services/token'
import { now } from '../../dates/services/api'

import AlarmIcon from '@material-ui/icons/Alarm'

const styles = theme => ({
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
        color: theme.palette.warning[500]
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
})

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props)

        const token = getToken()
        const expires_at = expiresatFromToken()
        const time = Math.floor(now()/1000)
        const allow = this.allowRoute( time,expires_at )
        this.state = {
            token: token,
            expires_at: expires_at,
            allow: allow
        }
    }
    componentDidUpdate(prevProps,prevState) {
        const expires_at = this.state.expires_at
        const time = this.state.time
        if( this.state.allow !== prevState.allow ) {
            const allow = this.allowRoute( time,expires_at )
            const token = getToken()
            this.setState({
                token: token,
                allow: allow
            })
        }
    }
    allowRoute( time,expires_at ) {
        let allow = true
        if( expires_at <= time && allow ) {
            /*console.log(expires_at)
            console.log(time)
            console.log(time-expires_at)*/
            allow = false
        }

        return allow
    }
    render() {
        const Component = this.props.component
        const token = this.state.token
        const allow = this.state.allow

        const {classes} = this.props

        return <AppRoute {...this.props}>
            <SnackbarMessage
                open={!allow}
                autoHideDuration={2000}
                //severity="success"
                message={<span id="login-required-message" className={classes.message}>
                    <AlarmIcon className={classes.icon} />
                    {"Login required..."}
                </span>}
                onClose={() => {
                    delToken()
                    this.setState({
                        allow:true,
                        token:null//reset token in state after deletion
                    })
                }}
            />
            {!allow
                ? null
                : (token
                    ? <Component route={this.props} />
                    : <Redirect to='/' />)
            }
            <MaintenanceBanner />
        </AppRoute>
    }
}

export default withStyles(styles)(PrivateRoute)