import { agencySchema } from "./AgencySchema"

export function helpersFormSchema( entity ) {
    const agencyObj = agencySchema()
    const agencyName = agencyObj.hasOwnProperty('name') ? agencyObj.name : "il nome agenzia"
    switch (entity) {
        case 'people':
            return {
                main_mobile: '(i) Inserire senza spazi né prefissi',
            }
        case 'contacts':
            return {
                contact: '(i) Inserire una mail valida oppure solo numeri per altri tipi di contatto',
                contact_notes: '(i) Note libere (es. nome referente in caso di contatto ATC o Ass.)',
                id_people: '(i) Da compilare solo per persona fisica',
                id_company: '(i) Da compilare solo per persona giuridica',
            }
        case 'addresses':
            return {
                id_people: '(i) Da compilare solo per persona fisica',
                id_company: '(i) Da compilare solo per persona giuridica',
            }
        case 'atcs':
        case 'insurances':
            return {
                id_company: '(i) Se non presente aggiungere nuova azienda',
                vat_number: '(i) Se sconosciuta inserire VATN000000<id>',
            }
        case 'insurances_quotations':
            return {
                multiplier: '(i) Indicare ND per ATC pubbliche',
            }
        case 'companies':
            return {
                vat_number: '(i) Se sconosciuta inserire VATN000000<id>',
                cf: '(i) Se sconosciuto lasciare vuoto',
            }
        case 'practicals':
            return {
                id_lender: ('(i) Il valore DEFAULT corrisponde a '+agencyName),
                id_practical_outer: '(i) Scegli pratica esterna pre-esistente se estinzione si',
                id_practical_est: '(i) Scegli pratica agenzia pre-esistente se estinzione si',
            }
        default:
            return {}
    }
}