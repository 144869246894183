import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

import { getEP,doCall } from '../../requests/api'
import { headerTokenKey,getToken } from '../../auth/services/token'
//import {idFromToken,groupsFromToken} from '../../auth/services/token'

export function withMediaQuery(Component) {
    return withTheme((props) => {
        const {theme} = props

        const isXl = useMediaQuery(theme.breakpoints.down('xl'))
        const isLg = useMediaQuery(theme.breakpoints.down('lg'))
        const isMd = useMediaQuery(theme.breakpoints.down('md'))
        const isXs = useMediaQuery(theme.breakpoints.down('xs'))
        const isSm = useMediaQuery(theme.breakpoints.down('sm'))

        return <Component {...props} isMd={isMd} isXs={isXs} isSm={isSm} isLg={isLg} isXl={isXl} />
    })
}
export async function isUnderMaintenance() {
    const func = "is_undermaintenance"
    const callUrl = getEP( func )
    if( !callUrl )
        return false

    return await doCall( callUrl,{
        method: 'GET',
        headers: {
            [headerTokenKey]: getToken(),
        },
    },'array_object',{loader: false})
}