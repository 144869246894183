import React from 'react'

import {
    entity_create,
    entity_update
} from '../@react-fefmwk/entity/services/api'
import {nowString} from '../@react-fefmwk/dates/services/api'
import {idFromToken} from '../@react-fefmwk/auth/services/token'

import Origination from '../modules/add-practical-stepper/Origination'
import PracticalData from '../modules/add-practical-stepper/PracticalData'
import AtcInsurancesQuotations from '../modules/add-practical-stepper/AtcInsurancesQuotations'
import OldPracticals from '../modules/add-practical-stepper/OldPracticals'
import Checkout from '../modules/add-practical-stepper/Checkout'

export const AddPracticalStepperSchema = [
    {
        "name": "origination",
        "icon": false,
        "title": "Sorgente",
        "description": "Scegli la sorgente che ha originato la pratica",
        "component": (formikProps) => <Origination formikProps={formikProps} />,
    },
    {
        "name": "old_practicals",
        "icon": false,
        "title": "Pre-esistenti",
        "description": "Inserisci eventuali pratiche pre-esistenti",
        "component": (formikProps) => <OldPracticals formikProps={formikProps} />,
    },
    {
        "name": "atc_insurances_quotations",
        "icon": false,
        "title": "Quotazione ATC",
        "description": "Seleziona una quotazione assicurativa per la ATC della pratica",
        "component": (formikProps) => <AtcInsurancesQuotations formikProps={formikProps} />,
    },
    {
        "name": "practical_data",
        "icon": false,
        "title": "Dati pratica",
        "description": "Compila il form con i dati della pratica",
        "component": (formikProps) => <PracticalData formikProps={formikProps} />,
    },
    {
        "name": "checkout",
        "icon": false,
        "title": "Checkout",
        "description": "Controlla i dati",
        "component": (formikProps) => <Checkout formikProps={formikProps} />,
    },
]

export const AddPracticalStepperFormSchema = {
    //id_practical_origination: '',
    id_lead: '',
    id_lender: 1,
    id_stakeholder: '',
    //id_practical: '',
    load_date: nowString(),
    id_agent: parseInt(idFromToken()),
    id_atc: '',
    id_insurance: '',
    id_insurance_quotation: '',
    id_practical_outer: '',
    id_practical_est: '',
    estinzione: 'no',
}

export const AddPracticalStepperValidationSchema = [
    (values) => {
        let errors = {}
        const id_practical_origination = values.id_practical_origination
        const id_lead = values.id_lead

        if( !id_practical_origination )
            errors['id_practical_origination'] = 'Scegli una sorgente valida'
        
        if( parseInt(id_practical_origination) === 1 && !id_lead )
            errors['id_lead'] = 'Selezione un lead valido'

        return errors
    },
    (values) => {
        let errors = {}
        
        const id_stakeholder = values.id_stakeholder
        const okOuters = values.okOuters

        if( !id_stakeholder ) errors['id_stakeholder'] = 'Scegli un cliente'
        if( !okOuters ) errors['okOuters'] = 'Inserisci almeno un pratica o indica che non sono previste'

        return errors
    },
    (values) => {
        let errors = {}

        const id_insurance_quotation = values.id_insurance_quotation

        if( !id_insurance_quotation ) errors['id_insurance_quotation'] = 'Scegli una quotazione'

        return errors
    },
    (values) => {
        let errors = {}

        const id_product = values.id_product
        const id_stakeholder = values.id_stakeholder
        const id_agent = values.id_agent
        const id_practical_origination = values.id_practical_origination
        const load_date = values.load_date
        const effective_date = values.effective_date
        const installments_n = values.installments_n
        const installment_amount = values.installment_amount
        const disbursed_amount = values.disbursed_amount
        const commission = values.commission
        //const id_atc = values.id_atc
        //const sign_date = values.sign_date
        const estinzione = values.estinzione
        const id_practical_outer = values.id_practical_outer
        const id_practical_est = values.id_practical_est

        if( !id_product ) errors['id_product'] = 'Scegli un prodotto valido'
        if( !id_stakeholder ) errors['id_stakeholder'] = 'Scegli un contatto valido'
        if( !id_agent ) errors['id_agent'] = 'Scegli un collaboratore valido'
        if( !id_practical_origination ) errors['id_practical_origination'] = 'Scegli una sorgente valida'
        if( !load_date ) errors['load_date'] = 'Indica una data di caricamento valida'
        if( !effective_date ) errors['effective_date'] = 'Indica una data di decorrenza valida'
        if( installments_n < 0 ) errors['installments_n'] = 'Il numero di rate deve essere un numero intero positivo'
        if( installment_amount < 0 ) errors['installment_amount'] = 'L\'importo rata deve essere un numero positivo'
        if( disbursed_amount < 0 ) errors['disbursed_amount'] = 'L\'importo erogato deve essere un numero positivo'
        if( commission < 0 ) errors['commission'] = 'Le commisioni devono essere un numero positivo'
        //if( !id_atc ) errors['id_atc'] = 'Choose a valid ATC'
        //if( !sign_date ) errors['sign_date'] = 'Choose a valid sign date'
        if( estinzione === 'si' && !id_practical_outer && !id_practical_est ) {
            if( !id_practical_outer )
                errors['id_practical_outer'] = 'Scegli pratica in estinzione oppure indica estinzione no'
            if( !id_practical_est )
                errors['id_practical_est'] = 'Scegli pratica in estinzione oppure indica estinzione no'
        }

        return errors
    },
]

export const AddPracticalStepperSubmitSchema = [
    {
        "enableSubmit": false,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            return true
        }
    },
    {
        "enableSubmit": false,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            return true
        }
    },
    {
        "enableSubmit": false,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            return true
        }
    },
    {
        "enableSubmit": true,
        "updateState": false,
        "submit": async (prevState,values,actions) => {
            const id = prevState.id
            if( id === "add-practical" ) {
                const inserted_practical = await entity_create( 'practicals',values )
                const id_practical = inserted_practical['last_id']
                actions.setFieldValue("id_practical",id_practical)
                return id_practical
            }
            else {
                const id_practical = prevState.form.id_practical
                if(id_practical) {
                    const updated_practical = await entity_update( 'practicals',id_practical,values )
                    return updated_practical
                }
            }
        }
    },
]