import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import BusinessIcon from '@material-ui/icons/Business'

class FieldIdCompany extends React.Component {
    render() {
        const id_company = this.props.id_company
        const title = this.props.title ? this.props.title : "Scheda azienda"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda azienda"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_company || id_company <= 0 || id_company === '-'
            ? null
            : <FieldEntityView title={title} icon={BusinessIcon}
                id_entity={id_company} entity="companies"
                fields={[]}
                /*actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/companies/view/'+id_company} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}*/
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdCompany