import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from "react-router-dom"

import '../theme.css'

const EmbedPageStyle = theme => ({
    app: {
        height: '100vh',
    },
})

class EmbedPage extends React.Component {
    render() {
        let html = document.querySelector('html')
        html.style.backgroundImage = ""
        let body = document.querySelector('body')
        body.style.overflowX = "hidden";

        const { classes } = this.props

        return <div id="App" className={classes.app}>
            {this.props.children}
        </div>
    }
}

export default withRouter(withStyles(EmbedPageStyle)(EmbedPage))