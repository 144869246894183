import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {
    entity_get,
    entity_fields_info
} from '../@react-fefmwk/entity/services/api'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import BlockIcon from '@material-ui/icons/Block'

import {idFromToken} from '../@react-fefmwk/auth/services/token'

const styles = theme => ({
    stepDescription: {
        color: theme.palette.grey[700],
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
})

class PostponeTask extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            enable_postpone: false,
            fields_info: {},
            task: {},
            id_agent: 0,
        }
    }

    async componentDidMount() {
        const id_entity = this.props.id_entity

        const task = await entity_get('tasks',id_entity)
        let fields_info = await entity_fields_info('tasks')
        const id_agent = task.id_agent

        const current_user_id = idFromToken()
        const enable_postpone = parseInt(current_user_id) === parseInt(id_agent)
            ? true
            : this.state.enable_postpone

        //modify fields info
        if( enable_postpone && Object.keys(fields_info).length ) {
            const reveal_fields = ['active_at']
            for (const field in fields_info) {
                if(!reveal_fields.includes(field))
                    fields_info[field].input_type = 'hidden'
            }
        }

        this.setState({
            enable_postpone: enable_postpone,
            fields_info: fields_info,
            task: task,
            id_agent: id_agent
        })
    }

    render() {
        const task = this.state.task
        const fields_info = this.state.fields_info
        if( !Object.keys(fields_info).length || !Object.keys(task).length )
            return <LoaderSingleComponent width="100%" />

        const enable_postpone = this.state.enable_postpone
        if( !enable_postpone )
            return <Chip icon={<BlockIcon />} label="Non hai i provilegi per rimandare questo task" variant="outlined" />

        const id_agent = this.state.id_agent
        if( !id_agent )
            return <Chip icon={<BlockIcon />} label="Task associato ad utente non valido" variant="outlined" />

        const entity = 'tasks'
        const id_entity = this.props.id_entity

        const { classes,...rest } = this.props

        return <React.Fragment>
            <Typography gutterBottom className={classes.stepDescription} variant="body2" component="div">
                <InfoIcon fontSize="small" />&nbsp;Indica una data e un'ora
            </Typography>
            <EntityForm 
                entity={entity}
                id_entity={id_entity}
                customFieldsInfo={fields_info}
                {...rest}
            />
        </React.Fragment>
    }
}

export default withStyles(styles)(PostponeTask)