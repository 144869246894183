import React from 'react'
import Content from '../../../theme-structure/modules/Content'
import {EntitySchema} from '../../../../services/EntitySchema'
import Entity from '../Entity'
import GoBackLink from '../../../links/modules/GoBackLink'

import {checkPrivilegies} from '../../services/api'
import Page404 from '../../../theme-structure/modules/404'
import Page401 from '../../../theme-structure/modules/401'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const EntityPage = (props) => {
    const entity = props.route.hasOwnProperty('entity')
        ? props.route.entity
        : props.route.computedMatch.params.entity

    const entityObj = EntitySchema[entity]
    if( !entityObj || !Object.keys(entityObj).length )
        return <Page404 />
    if( !checkPrivilegies( entityObj,"list" ) )
        return <Page401 />

    let title = 'Unknown entity'
    if( entityObj && Object.keys(entityObj).length ) {
        title = props.route.hasOwnProperty("title") && props.route.title
            ? props.route.title
            : entityObj.title
    }

    return <Content title={title}
        navigation={<GoBackLink type="button" variant="text"
            Component={(props) => <IconButton {...props} size="small"><ArrowBackIcon /></IconButton>}
        />}
    >
        <Entity entity={entity} />
    </Content>
}

export default EntityPage