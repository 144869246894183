import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import EntityForm from '../@react-fefmwk/entity/modules/EntityForm'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import {
    entity_fields_info
} from '../@react-fefmwk/entity/services/api'

const styles = theme => ({
    stepDescription: {
        color: theme.palette.grey[700],
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
})

class UpdatePracticalCommission extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fields_info: {},
        }
    }

    async componentDidMount() {
        let fields_info = await entity_fields_info('practicals')

        //modify fields info
        if( Object.keys(fields_info).length ) {
            //reveal commission field
            if( fields_info.hasOwnProperty('commission') )
                fields_info.commission.input_type = 'number'

            //hide other fields
            const reveal_fields = ['commission']
            for (const field in fields_info) {
                if(!reveal_fields.includes(field))
                    fields_info[field].input_type = 'hidden'
            }
        }

        this.setState({
            fields_info: fields_info,
        })
    }

    render() {
        const fields_info = this.state.fields_info
        if( !Object.keys(fields_info).length )
            return <LoaderSingleComponent width="100%" />

        const entity = 'practicals'
        const id_entity = this.props.id_entity

        const { classes,...rest } = this.props

        return <React.Fragment>
            <EntityForm 
                entity={entity}
                id_entity={id_entity}
                customFieldsInfo={fields_info}
                {...rest}
            />
        </React.Fragment>
    }
}

export default withStyles(styles)(UpdatePracticalCommission)