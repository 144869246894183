import React from 'react'

import Content from '../@react-fefmwk/theme-structure/modules/Content'
import CardPeople from '../modules/CardPeople'

class CardPeoplePage extends React.Component {
    render() {
        const title = this.props.route.title
        const id_entity = this.props.route.computedMatch.params.id

        return <Content title={title}>
            <CardPeople id_entity={id_entity} />
        </Content>
    }
}

export default CardPeoplePage