import React from 'react'

import EntityRelFieldActions from '../@react-fefmwk/entity/modules/EntityRelFieldActions'
import Formatter from '../@react-fefmwk/formatter/modules/Formatter'
//import Modal from '../../@react-fefmwk/modals/modules/Modal'
import AddIcon from '@material-ui/icons/Add'

import { Chip, IconButton } from '@material-ui/core'

class ManageGrossCommission extends React.Component {
    render() {
        const value = this.props.value
        const net_commission = this.props.hasOwnProperty('net_commission')
            ? parseFloat(this.props.net_commission)
            : 0
        const id_practical_commission = this.props.hasOwnProperty('id_practical_commission')
            ? parseFloat(this.props.id_practical_commission)
            : 0
        const id_practical = this.props.hasOwnProperty('id_practical')
            ? this.props.id_practical
            : 0

        if( !id_practical ) return value

        const addMode = value === null ? true : false

        if( !addMode && !id_practical_commission ) return value

        const Trigger = addMode
            ? <IconButton size="small" color="primary"><AddIcon fontSize="small" /></IconButton>
            : <Chip size="small" color="primary" variant="outlined" clickable
                label={<Formatter
                    value={value}
                    dataType="currency"
                    currency="EUR"
                    decimalDigits="2"
                    exponent="0"
                />}
            />
        const title = addMode
            ? "Indica commissione lorda"
            : "Modifica commissione lorda"
        const tooltipText = addMode
            ? "Indica commissione lorda"
            : "Modifica commissione lorda"
        const url = addMode
            ? ""
            : ("update/"+id_practical_commission)
        const s = addMode
            ? ("1&id_practical="+id_practical+"&gross_commission="+net_commission)
            : ""

        const onClose = this.props.hasOwnProperty('onClose')
            ? this.props.onClose
            : () => true

        return <React.Fragment>
            <EntityRelFieldActions
                type="drawer"
                //PaperProps={{style:{height:"50vh"}}}
                title={title}
                linkText={tooltipText}
                entity="practicals_commissions"
                onClose={onClose}
                Trigger={Trigger}
                url={url}
                s={s}
            />
        </React.Fragment>
    }
}

export default ManageGrossCommission