import React from 'react'

import AppRoute from './AppRoute'

class PublicRoute extends React.Component {
    render() {
        const Component = this.props.component
        return <AppRoute {...this.props}>
            <Component route={this.props} />
        </AppRoute>
    }
}

export default PublicRoute