import React from 'react'
import { withRouter } from "react-router-dom"

const GoBackLink = React.forwardRef((props,ref) => {
    const { history,location,match,staticContext,Component,...rest } = props
    const canGoBack = window.hasOwnProperty('navigation')
        ? window.navigation.canGoBack
        : false

    return history.action === 'PUSH' || canGoBack
        ? <Component innerRef={ref} {...rest} onClick={() => history.goBack()} />
        : null
})

export default withRouter(GoBackLink)