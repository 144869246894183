import React from 'react'
import Content from '../@react-fefmwk/theme-structure/modules/Content'

import StatsAgentsForm from '../modules/StatsAgentsForm'

class DashboardAgentsPage extends React.Component {
    render() {
        const title = this.props.route.title

        return <Content title={title}>
            <StatsAgentsForm stats={['metrics_by_branch','metrics_by_agent']} />
        </Content>
    }
}

export default DashboardAgentsPage