import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AddIcon from '@material-ui/icons/Add'

class FieldIdStakeholder extends React.Component {
    render() {
        const id_stakeholder = this.props.id_stakeholder
        const title = this.props.title ? this.props.title : "Scheda contatto"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda contatto"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_stakeholder || id_stakeholder <= 0 || id_stakeholder === '-'
            ? null
            : <FieldEntityView title={title} icon={AccountCircleIcon}
                id_entity={id_stakeholder} entity="stakeholders"
                fields={[
                    'id_stakeholder',
                    'stakeholder',
                    'main_mobile',
                    'people_notes'
                ]}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder} target="_blank"
                    >Visualizza</Button>
                    <Button variant="text" size="small"
                        startIcon={<CloudUploadIcon />}
                        component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder+'#documents'} target="_blank"
                    >Documenti</Button>
                    <Button variant="text" size="small"
                        startIcon={<AddIcon />}
                        component={AdapterLink} to={'/tasks/add?id_stakeholder='+id_stakeholder} target="_blank"
                    >Assegna task</Button>
                    <Button variant="text" size="small"
                        startIcon={<AddIcon />}
                        component={AdapterLink} to={'/reports/add?id_stakeholder='+id_stakeholder} target="_blank"
                    >Scrivi report</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdStakeholder