import React from 'react'
import { Link } from "react-router-dom"

const ExternalLink = React.forwardRef((props,ref) => {
    const target = props.hasOwnProperty('target') ? props.target : "_blank"
    return target !== '_blank'
        ? <a href={props.to}>{props.children}</a>
        : <Link innerRef={ref} {...props} to={{pathname:props.to}} target={target} />
})

export default ExternalLink