import React from 'react'

import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'
import FieldEntityView from '../../@react-fefmwk/entity/modules/FieldEntityView'
import Button from '@material-ui/core/Button'
import ExtensionIcon from '@material-ui/icons/Extension'

class FieldIdProduct extends React.Component {
    render() {
        const id_product = this.props.id_product
        const title = this.props.title ? this.props.title : "Scheda prodotto"
        const tooltipText = this.props.tooltipText ? this.props.tooltipText : "Scheda prodotto"
        const tooltipPlacement = this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"
        return  !id_product || id_product <= 0 || id_product === '-'
            ? null
            : <FieldEntityView title={title} icon={ExtensionIcon}
                id_entity={id_product} entity="products"
                fields={[]}
                actions={<React.Fragment>
                    <Button variant="contained" size="small" color="primary"
                        component={AdapterLink} to={'/products/view/'+id_product} target="_blank"
                    >Visualizza</Button>
                </React.Fragment>}
                tooltipText={tooltipText}
                tooltipPlacement={tooltipPlacement}
            />
    }
}

export default FieldIdProduct