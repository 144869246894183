import React from 'react'

import {tableSchema} from '../../services/EntityTableSchema'
import {actionSchema} from '../../services/EntityRowActionsSchema'
import {insurances_get_quotations} from '../../services/BE'

import DashboardCard from '../../@react-fefmwk/cards/modules/DashboardCard'
import Table from '../../@react-fefmwk/tables/modules/Table'
import AdapterLink from '../../@react-fefmwk/links/modules/AdapterLink'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'

class WidgetInsuranceQuotations extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            quotations: [],
        }
    }
    
    async updateQuotations() {
        const id_insurance = this.props.id_insurance
        if( id_insurance )
            this.setState({
                quotations: await insurances_get_quotations(id_insurance),
            })
    }
    
    async componentDidMount() {
        this.updateQuotations()
    }
    
    async componentDidUpdate(prevProps) {
        const id_insurance = this.props.id_insurance
        if( id_insurance !== prevProps.id_insurance )
            this.updateQuotations()
    }

    render() {
        const id_insurance = this.props.id_insurance
        const quotations = this.state.quotations

        const dashboardcardActions = this.props.hasOwnProperty('dashboardcardActions')
            ? this.props.dashboardcardActions
            : true
        const otherActions = this.props.hasOwnProperty('otherActions')
            ? this.props.otherActions
            : []
        const dashboardcardTitle = this.props.dashboardcardTitle
                ? this.props.dashboardcardTitle
                : "Quotations"

        const quotationsRowActions = actionSchema('insurances_quotations')
        const fieldsProps = tableSchema('insurances_quotations')

        const cardProps = {}
        cardProps['Content'] = quotations.length
            ? <Table material dense
                data={quotations}
                fieldsProps={fieldsProps}
                rowActions={quotationsRowActions}
                rowActionsField="id_insurance_quotation"
                showPagination={true}
            />
            : <Chip icon={<ErrorIcon />} label="Nessuna quotazione disponibile" variant="outlined" />

        if( dashboardcardActions )
            cardProps['TopActions'] = <React.Fragment>
                <Button
                    variant="contained" color="primary" size="small"
                    startIcon={<AddIcon fontSize="small" />}
                    component={AdapterLink}
                    to={"/insurances_quotations/add?id_insurance="+id_insurance}
                >Nuovo</Button>&nbsp;&nbsp;
                {[...otherActions]}
            </React.Fragment>

        return <DashboardCard title={dashboardcardTitle} noTitle {...cardProps} />
    }
}

export default WidgetInsuranceQuotations