
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import { entity_fields_info } from '../../@react-fefmwk/entity/services/api'
import EntityFormInputs from '../../@react-fefmwk/entity/modules/EntityFormInputs'
import LoaderSingleComponent from '../../@react-fefmwk/loader-app/modules/LoaderSingleComponent'

import Grid from '@material-ui/core/Grid'

const styles = theme => ({})

class TaskData extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            tasks_fields_info_full: {},
            tasks_fields_info: {},
        }
    }
    async componentDidMount() {
        const formikProps = this.props.formikProps
        let tasks_fields_info = await entity_fields_info('tasks')

        if( formikProps.values.hasOwnProperty('event') ){
            formikProps.setFieldValue('id_agent',0)
            tasks_fields_info.id_agent.input_type = 'hidden'
            tasks_fields_info.id_agent.disabled = true
        }

        const tasks_fields_info_full = JSON.parse(JSON.stringify(tasks_fields_info))

        this.setState({
            tasks_fields_info_full: tasks_fields_info_full,
            tasks_fields_info: tasks_fields_info,
        })
    }
    componentDidUpdate(prevProps,prevState) {
        const formikProps = this.props.formikProps
        const id_task_target = formikProps.values.id_task_target

        let tasks_fields_info = this.state.tasks_fields_info
        const tasks_fields_info_full = this.state.tasks_fields_info_full

        if( Object.keys(tasks_fields_info_full).length ) {
            let fields_info_items_full = tasks_fields_info_full.id_activity_order.input_options.items
            let fields_info_items = tasks_fields_info.id_activity_order.input_options.items
            const items_full_n = fields_info_items_full.length
            const items_n = fields_info_items.length

            if( id_task_target !== 'id_practical' ) {
                if( items_full_n === items_n ) {
                    let items = [...tasks_fields_info.id_activity_order.input_options.items]
                    items.map((item,i) => {//delete not practicals orders
                        const activity_order = item.activity_order
                        if( activity_order.includes("pratica |") ) delete items[i]
                    })
                    items = items.filter(item => {//filter undefined values after deletion
                        return item !== undefined
                    })
                    //check if deletion produced object
                    if( !Array.isArray(items) ) items = Object.values(items)

                    //update fields_info
                    tasks_fields_info.id_activity_order.input_options.items = items
                    this.setState({
                        tasks_fields_info: tasks_fields_info,
                    })
                }
            }
            else {
                if( items_full_n !== items_n ) {
                    //restore original fields_info
                    this.setState({
                        tasks_fields_info: tasks_fields_info_full,
                    })
                }
            }
        }
    }
    render() {
        let formikProps = this.props.formikProps
        const entity = 'tasks'
        let tasks_fields_info = this.state.tasks_fields_info
        if( Object.keys(tasks_fields_info).length === 0 )
            return <LoaderSingleComponent width="100%" />

        const fields_row1 = {
            id_activity_order: tasks_fields_info.id_activity_order,
            id_agent: tasks_fields_info.id_agent,
            active_at: tasks_fields_info.active_at,
        }
        const fields_row2 = {
            activity_instruction: tasks_fields_info.activity_instruction
        }
        const fields_row3 = {
            id_user: tasks_fields_info.id_user,
        }

        const entityInputProps = {
            activity_instruction: {
                style:{width:'100%',},
                rows: 15,
            },
        }

        return <React.Fragment>
            <Grid container spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row1}
                    formikProps={formikProps}
                />
            </Grid>
            <Grid container direction="column" spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row2}
                    entityInputProps={entityInputProps}
                    formikProps={formikProps}
                />
            </Grid>
            <Grid container direction="column" spacing={4}>
                <EntityFormInputs
                    entity={entity}
                    entityFieldsInfo={fields_row3}
                    entityInputProps={entityInputProps}
                    formikProps={formikProps}
                />
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(styles)(TaskData)