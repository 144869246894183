import React from 'react'
import { Formik,Form } from 'formik'
import { withStyles,withTheme } from '@material-ui/core/styles'

import AdapterLink from '../@react-fefmwk/links/modules/AdapterLink'
import LoaderSingleComponent from '../@react-fefmwk/loader-app/modules/LoaderSingleComponent'
//import DashboardCard from '../@react-fefmwk/cards/modules/DashboardCard'
import SnackbarMessage from '../@react-fefmwk/messages/modules/SnackbarMessage'

import { EntitySchema } from '../services/EntitySchema'
import {
    entity_fields_info,
    entity_get,
    entity_update,
    entity_delete_info
} from '../@react-fefmwk/entity/services/api'
import EntityFormInputs from '../@react-fefmwk/entity/modules/EntityFormInputs'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import {addBtn} from '../services/EntityToolbarSchema'

import WidgetLeadPreview from './widgets/WidgetLeadPreview'
import WidgetPerson from './widgets/WidgetPerson'
import WidgetPersonContacts from './widgets/WidgetPersonContacts'
import WidgetPersonAddresses from './widgets/WidgetPersonAddresses'
import WidgetStakeholderPracticals from './widgets/WidgetStakeholderPracticals'
import WidgetStakeholderPracticalsOuters from './widgets/WidgetStakeholderPracticalsOuters'
import WidgetLeadReports from './widgets/WidgetLeadReports'
import WidgetLeadTasks from './widgets/WidgetLeadTasks'

import {groupsFromToken} from '../@react-fefmwk/auth/services/token'
import { agenciesBranchesFromToken } from '../services/AgencySchema'

const styles = theme => ({
    stepDescription: {
        color: theme.palette.grey[700],
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
})

class ManageLead extends React.Component {
    constructor(props) {
        super(props)

        const entity = 'leads'
        const entityObj = EntitySchema[entity]
        const id_field = entityObj.id_field

        const precompiledData = this.props.hasOwnProperty('precompiledData')
            ? this.props.precompiledData
            : {}
        const id_activity = precompiledData.hasOwnProperty('id_activity')
            ? precompiledData.id_activity
            : 0
        const activity_state = precompiledData.hasOwnProperty('activity_state')
            ? precompiledData.activity_state
            : 'open'

        this.state = {
            entity: entity,
            id_field: id_field,
            id_entity: this.props.id_entity,
            lead: {},
            stakeholder: {},
            stakeholders_fields_info: {},
            submit: false,
            id_activity: id_activity,
            activity_state: activity_state,
        }
    }

    async componentDidUpdate(prevProps,prevState) {
        const stakeholder = this.state.stakeholder
        const id_stakeholder = stakeholder.id_stakeholder
        if( id_stakeholder !== prevState.stakeholder.id_stakeholder ) {
            this.updateStakeholder( id_stakeholder )
        }
    }

    async componentDidMount() {
        const entity = this.state.entity
        const id_entity = this.state.id_entity

        const lead = await entity_get(entity,id_entity)
        const id_stakeholder = lead.id_stakeholder

        const stakeholders_fields_info = await entity_fields_info(entity)

        this.setState({
            lead: lead,
            stakeholders_fields_info: stakeholders_fields_info
        })

        this.updateStakeholder( id_stakeholder )
    }

    async updateStakeholder( id_stakeholder ) {
        let stakeholder = {}
        if( id_stakeholder ) {
            stakeholder = await entity_get('stakeholders',id_stakeholder)
        }

        this.setState({
            stakeholder: stakeholder
        })
    }

    validate(values) {
        let errors = []
        const id_stakeholder = values.id_stakeholder

        if( !id_stakeholder )
            errors['id_stakeholder'] = 'Scegli un contatto'

        return errors
    }
    async submit(values,actions) {
        const id_lead = this.state.id_entity
        const id_stakeholder = values.id_stakeholder
        const update = await entity_update(
            'leads',
            id_lead,
            {id_stakeholder:id_stakeholder},//formData
            false
        )
        if( update ) {
            const stakeholder = await entity_get('stakeholders',id_stakeholder)
            const newLead = {...this.state.lead}
            newLead['id_stakeholder'] = id_stakeholder

            this.setState({
                stakeholder: stakeholder,
                lead: newLead,
                submit: true
            })
        }
    }
    async disJoin() {
        const id_lead = this.state.id_entity
        const del = await entity_delete_info(
            'leads',
            id_lead,
            'id_stakeholder'//cf name
        )
        if( del ) {
            const newLead = {...this.state.lead}
            newLead['id_stakeholder'] = null

            this.setState({
                stakeholder: {},
                lead: newLead,
                submit: true
            })
        }
    }

    render() {
        const lead = this.state.lead
        if( !Object.keys(lead).length ) return <LoaderSingleComponent/>

        const groups = groupsFromToken()
        const agencies_branches = agenciesBranchesFromToken()

        const isManager = (groups.includes(String(1)) || groups.includes(String(2)) || groups.includes(String(5)))
            ? true
            : false

        const stakeholder = this.state.stakeholder
        const id_stakeholder = stakeholder.id_stakeholder
        const id_people = stakeholder.id_people
        const id_company = stakeholder.id_company
        const is_obscured = stakeholder.hasOwnProperty('is_obscured') ? stakeholder.is_obscured : true

        const id_entity = this.state.id_entity
        const entity = this.state.entity
        const stakeholders_fields_info = this.state.stakeholders_fields_info
        const stakeholder_agencies_branches = (stakeholder.hasOwnProperty('agencies_branches') && stakeholder.agencies_branches)
            ? stakeholder.agencies_branches
            : []
        //stakeholder gestibile se seguito dalla current branch oppure ancora da nessuna
        const canManageSTakeholder = agencies_branches.filter(ab => stakeholder_agencies_branches.includes(ab)).length || !stakeholder_agencies_branches.langth

        const disable_disjoin = (lead.id_stakeholder && (canManageSTakeholder || isManager))
            ? false
            : true

        const { classes,theme } = this.props

        const id_activity = this.state.id_activity
        const activity_state = this.state.activity_state

        return <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <Box mb={4}>
                        <WidgetLeadPreview
                            body={lead.lead_body}
                            date={lead.lead_datetime}
                            subject={lead.lead_subject}
                            from={lead.lead_from}
                            to={lead.lead_to}
                        />
                    </Box>
                    <Box>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{id_stakeholder:id_stakeholder}}
                            validate={async (values) => {
                                const errors = this.validate(values)

                                if( !Object.keys(errors).length ) {
                                    const id_stakeholder = values.id_stakeholder
                                    if( id_stakeholder ) {
                                        this.setState({
                                            stakeholder: await entity_get('stakeholders',id_stakeholder)
                                        })
                                    }
                                }
                            }}
                            onSubmit={(values,actions) => {
                                const errors = this.validate(values)
                                if( Object.keys(errors).length || !errors ) {
                                    actions.setStatus(false)
                                    actions.setErrors(errors)
                                }
                                else {
                                    actions.setStatus(true);
                                    this.submit(values,actions)
                                }
                            }}
                            render={formikProps => {
                                return <Form id={"manage-lead-form"}>
                                    <Typography gutterBottom className={classes.stepDescription} variant="body2" component="div">
                                        <InfoIcon fontSize="small" />&nbsp;Scegli un contatto già presente o aggiungine uno nuovo
                                    </Typography>
                                    <Grid container spacing={2} alignItems="center">
                                        <EntityFormInputs
                                            entity={entity}
                                            entityFieldsInfo={{id_stakeholder: stakeholders_fields_info['id_stakeholder']}}
                                            formikProps={formikProps}
                                        />
                                        <Grid item xs>
                                            {disable_disjoin
                                                ? addBtn(
                                                    'stakeholders',
                                                    '/stakeholders',
                                                    '?noactionatcheckout&backatcheckout&fromlead='+id_entity,
                                                    {component: IconButton,color:"secondary",size:"medium",style:{marginTop:theme.spacing(2)}}
                                                )
                                                : null}
                                        </Grid>
                                    </Grid>
                                    {is_obscured && id_stakeholder
                                        ? <Box mt={1}><Chip label="Non sei autorizzato a gestire il lead per questo contatto" variant="outlined" /></Box>
                                        : <React.Fragment>
                                            <Box mt={1}>
                                                <Button type="submit" size="small"
                                                    variant="contained" color="primary"
                                                    disabled={!disable_disjoin}
                                                >Associa</Button>
                                                {id_activity
                                                    ? <React.Fragment>
                                                        &nbsp;&nbsp;
                                                        <Button size="small"
                                                            variant="contained" color="primary"
                                                            disabled={disable_disjoin || activity_state!=='open'}
                                                            component={AdapterLink} to={'/reports/add?id_activity='+id_activity+'&id_report_type=6&id_lead='+id_entity}
                                                        >Chiudi</Button>
                                                    </React.Fragment>
                                                    : null}
                                                <React.Fragment>
                                                    &nbsp;&nbsp;
                                                    <Button type="button" size="small"
                                                        variant="text"
                                                        disabled={disable_disjoin}
                                                        onClick={() => this.disJoin()}
                                                    >Dissocia</Button>
                                                </React.Fragment>
                                            </Box>
                                            <Box mt={2}>
                                                <Button size="small" variant="text" disabled={disable_disjoin}
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/stakeholders/card/'+id_stakeholder}
                                                >Visualizza contatto</Button>
                                                <br />
                                                <Button size="small" variant="text" disabled={disable_disjoin}
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/people/update/'+id_people}
                                                >Aggiorna anagrafica</Button>
                                                <br />
                                                <Button size="small" variant="text" disabled={disable_disjoin}
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/contacts/add?id_people='+id_people}
                                                >Aggiungi altri recapiti</Button>
                                                <br />
                                                <Button size="small" variant="text" disabled={disable_disjoin}
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/addresses/add?id_people='+id_people}
                                                >Aggiungi indirizzi</Button>
                                                <br />
                                                <Button size="small" variant="text"
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/reports/add?'+(!disable_disjoin ? 'id_stakeholder='+id_stakeholder : 'id_lead='+id_entity)}
                                                >Scrivi report</Button>
                                                <br />
                                                <Button size="small" variant="text"
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/tasks/add?'+(!disable_disjoin ? 'id_stakeholder='+id_stakeholder : 'id_lead='+id_entity)}
                                                >Assegna task</Button>
                                                <br />
                                                <Button size="small" variant="text" disabled={disable_disjoin}
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/practicals/add?id_stakeholder='+id_stakeholder+'&id_lead='+id_entity}
                                                >Carica preventivo</Button>
                                                <br />
                                                <Button size="small" variant="text" disabled={disable_disjoin}
                                                    startIcon={<OpenInNewIcon />}
                                                    component={AdapterLink} to={'/practicals_outers/add?id_stakeholder='+id_stakeholder+'&id_lead='+id_entity}
                                                >Carica pre-esistente</Button>
                                            </Box>
                                        </React.Fragment>}
                                </Form>
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            {id_people || id_company
                                ? <React.Fragment>
                                    <WidgetPerson
                                        id_people={id_people}
                                        dashboardcardTitle={"Anagrafica "+stakeholder.stakeholder}
                                        dashboardcardActions={false}
                                    />
                                </React.Fragment>
                                : null}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {id_people || id_company
                                ? <React.Fragment>
                                    <Box mb={1}>
                                        <WidgetPersonContacts
                                            id_people={id_people}
                                            dashboardcardTitle={"Recapiti "+stakeholder.stakeholder}
                                            dashboardcardActions={false}
                                        />
                                    </Box>
                                    <Box mb={1}>
                                        <WidgetPersonAddresses
                                            id_people={id_people}
                                            dashboardcardTitle={"Indirizzi "+stakeholder.stakeholder}
                                            dashboardcardActions={false}
                                        />
                                    </Box>
                                    <Box mb={1}>
                                        <WidgetStakeholderPracticals
                                            id_stakeholder={id_stakeholder}
                                            dashboardcardTitle={"Pratiche "+stakeholder.stakeholder}
                                            dashboardcardActions={false}
                                            tableRowActions={false}
                                        />
                                    </Box>
                                    <Box mb={1}>
                                        <WidgetStakeholderPracticalsOuters
                                            id_stakeholder={id_stakeholder}
                                            dashboardcardTitle={"Pratiche altre agenzie "+stakeholder.stakeholder}
                                            dashboardcardActions={false}
                                            tableRowActions={false}
                                        />
                                    </Box>
                                </React.Fragment>
                                : null}
                            <Box mb={1}>
                                <WidgetLeadTasks
                                    id_lead={id_entity}
                                    dashboardcardTitle="Task del lead"
                                    dashboardcardActions={false}
                                    tableRowActions={false}
                                />
                            </Box>
                            <Box mb={1}>
                                <WidgetLeadReports
                                    id_lead={id_entity}
                                    dashboardcardTitle="Report sul lead"
                                    dashboardcardActions={false}
                                    tableRowActions={false}
                                />
                            </Box>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {this.state.submit && this.state.stakeholder.id_stakeholder
                ? <React.Fragment>
                    {id_activity
                        ? <SnackbarMessage
                            open={true}
                            message="Ora ricordati di chiudere il task"
                            autoHideDuration={10000}
                            style={{marginTop:60}}
                            action={<Button size="small"
                                variant="text" color="primary"
                                disabled={disable_disjoin}
                                component={AdapterLink} to={'/reports/add?id_activity='+id_activity+'&id_report_type=6'}
                            >Chiudi</Button>}
                        />
                        : null}
                    <SnackbarMessage
                        open={true}
                        message="Lead correttamente associato"
                        severity="success"
                        onClose={() => this.setState({submit:false})}
                    />
                </React.Fragment>
                : null}
            {this.state.submit && !this.state.stakeholder.id_stakeholder
                ? <React.Fragment>
                    <SnackbarMessage
                        open={true}
                        message="Lead correttamente dissociato"
                        severity="success"
                        onClose={() => this.setState({submit:false})}
                    />
                </React.Fragment>
                : null}
            <SnackbarMessage
                open={!id_activity ? true : false}
                message="Gestione del lead senza alcun task assegnato"
                severity="warning"
                autoHideDuration={30000}
                anchorOrigin={{vertical:'bottom',horizontal:'left'}}
            />
        </React.Fragment>
    }
}

export default withStyles(styles)(withTheme(ManageLead))